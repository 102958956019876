import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { preReqStepAction } from "../../redux/actions/preReqStepAction";

import { Button } from "@storybook/stories/Button/Button";
import { preReqCompStep1 } from "./Steps/PreReqCompStep1";
import { preReqMoreStep1 } from "./Steps/PreReqMoreStep1";
import { preReqDenied } from "./Steps/PreReqDenied";

import "./prerequisite.css";

const PreReqMain = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const expSubAllowed = user?.prereqExpProofSubmissionAllowed;
  const certSubAllowed = user?.prereqCertSubmissionAllowed;

  return (
    <div className="flex flex-col pre-req-btns">
      <Button
        buttonType="basic"
        size="medium"
        label="CompTIA A+ or Network+ Certification"
        disabled={!certSubAllowed}
        onClick={ () => certSubAllowed ? dispatch(preReqStepAction(preReqCompStep1)) : null }
      />
      <Button
        buttonType="basic"
        size="medium"
        label="More than 1 year of professional experience in IT field"
        disabled={!expSubAllowed}
        onClick={ () => expSubAllowed ? dispatch(preReqStepAction(preReqMoreStep1)) : null }
      />
      <Button
        buttonType="basic"
        size="medium"
        label="I have none of these"
        onClick={ () => dispatch(preReqStepAction(preReqDenied)) }
      />
    </div>
  );
}

export const preReqMain = {
  icon: null,
  title: "Do you have one of the following?",
  openDialog: true,
  showCloseIcon: false,
  confirmText: null,
  size: 'extra-large',
  showCloseIcon: true,
  extraClass: 'modal-prereq text-left',
  children: <PreReqMain />,
};