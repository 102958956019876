import {
  IconPPT,
  IconMA,
  IconFWS,
  IconCU,
  IconMeetMyTeam,
  IconCareerHub
} from "../../assets";

import { EPP_MSG, SPP_MSG } from "./data";

/**
 * Need Help section icon data.
 */
export const helpIcons = [
  {
    icon: IconMA,
    name: "IconMyAccomodate",
    link: "/accessibility-services",
    target: "_self",
    title: "Accessibility Services",
    disabledMsg: "",
  },
  {
    icon: IconMeetMyTeam,
    name: "IconMeetMyTeam",
    link: "/my-team",
    target: "_self",
    title: "My Team",
    disabledMsg: EPP_MSG,
  },
  {
    icon: IconPPT,
    name: "IconPPT",
    link: "/learning-center",
    target: "_self",
    title: "Tutoring Services",
    disabledMsg: EPP_MSG,
  },
  {
    icon: IconCareerHub,
    name: "IconCareerHub",
    link: "/career-services",
    target: "_self",
    title: "Career Services",
    disabledMsg: SPP_MSG,
  },
  {
    icon: IconFWS,
    name: "IconFWS",
    link: "/wellness-services",
    target: "_self",
    title: "Wellness Services",
    disabledMsg: SPP_MSG,
  },
  {
    icon: IconCU,
    name: "IconCU",
    link: "/help",
    target: "_self",
    title: "Ask Us A Question",
    disabledMsg: "",
  },
];
