/**
 * List of app features that can be set to true or false
 */
export const FEATURE_FLAGS = {
  STAGE_FEATURE_ENABLED: process.env.STAGE_FEATURE_ENABLED === "true",
  AR_SERVICES_ENABLED: true,
};

/**
 * List of environment variables that can be set to true or false.
 */
export const ENV_FLAGS = {
  STAGE_FEATURE_ENABLED: process.env.STAGE_FEATURE_ENABLED === "true",
};

/**
 * List of environment types (process.env.NODE_ENV) that can be set
 * to true or false.
 */
export const ENV_TYPE = {
  STAGE: "development",
  PROD: "production",
};
