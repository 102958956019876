import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PortalHeader } from "../../components/Header/PortalHeader";
import { Intro } from "@storybook/stories/Intro/Intro";
import { Resources } from "@storybook/stories/Resources/Resources";
import { MeetYourTeam } from "@storybook/stories/MeetYourTeam/MeetYourTeam";

import {
  learnerStatusLookup as status,
  studentProgramLookup,
} from "../../utils/helpers/data";
import {
  LearningResourcesData,
  MeetYourTeamData,
  getUpdatedContent,
  updateResourceContent,
  PASSLinks,
} from "../../utils/helpers/tutoringlearning";

import "./tutoringlearning.css";

export const TutoringLearning = ({ user }) => {
  const [updatedLRData, setUpdatedLRData] = useState([]);

  useEffect(() => {
    // Update the content of the Learning Resources section based on the user's program and learner status
    if (user) {
      setUpdatedLRData(
        getUpdatedContent(
          user,
          LearningResourcesData,
          studentProgramLookup,
          PASSLinks,
          updateResourceContent
        )
      );
    }
  }, [user]);

  return (
    <article
      className="storybook-learning"
      data-testid="learningResourcesContainer"
    >
      <PortalHeader user={user} activeLink="services" />
      <main>
        <div className="container">
          {user && (
            <>
              <Intro
                title="Tutoring and Learning Center"
                content={
                  <>
                    The Tutoring and Learning Center (TLC), provides
                    comprehensive instructional support, including computer
                    skills and digital literacy tutoring, reading and writing
                    assistance and course content tutoring.
                  </>
                }
              />
              <Resources
                resources={
                  updatedLRData.length === 0
                    ? LearningResourcesData
                    : updatedLRData
                }
              />
              <MeetYourTeam teammembers={MeetYourTeamData} />
            </>
          )}
        </div>
      </main>
    </article>
  );
};

TutoringLearning.propTypes = {
  user: PropTypes.shape({}),
};

export default TutoringLearning;
