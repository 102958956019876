import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import user from "./userReducer";
import token from "./tokenReducer";
import signOut from "./signOutReducer";
import { buttonChatReducer as buttonChatDisabled } from "./helpReducer";
import appointment, {
  appointmentLinksReducer as appointmentLinks,
} from "./appointmentReducer";
import myTeam from "./myteamReducer";
import preReqStep from "./preReqStepReducer";
import avatar, { avatarsReducer as avatars } from "./avatarReducer";
import loading from "./loadingReducer";
import broadcastMsgs from "./broadcastMsgReducer";
import topicOfMonth from "./tomReducer";
import { devicesReducer as devices } from "./devicesReducer";
import arservices, {
  enrollmentVerificationReducer as enrollmentVerification,
  completedProgramsReducer as completedPrograms,
} from "./arservicesReducer";
import { staticReducer as staticContent } from "./staticReducer";
import theme from "./themeReducer";

const rootReducer = combineReducers({
  apiCallsInProgress,
  loading,
  token,
  user,
  broadcastMsgs,
  appointment,
  appointmentLinks,
  signOut,
  myTeam,
  preReqStep,
  avatars,
  avatar,
  buttonChatDisabled,
  topicOfMonth,
  arservices,
  enrollmentVerification,
  completedPrograms,
  devices,
  staticContent,
  theme,
});

export default rootReducer;
