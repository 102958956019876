import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

import "./alert.css";

export const Alert = ({
  children,
  type,
  visibility,
  dismissable,
  onDismiss,
}) => {
  const alertClass = ["storybook-alert"];
  const [showAlert, setShowAlert] = useState(visibility);

  const handleDismiss = () => {
    if (onDismiss) onDismiss();
    setShowAlert(false);
  };

  switch (type) {
    case "error":
      alertClass.push("storybook-alert--error");
      break;
    case "success":
      alertClass.push("storybook-alert--success");
      break;
    case "warning":
      alertClass.push("storybook-alert--warning");
      break;
    default:
      alertClass.push("storybook-alert--info");
  }

  return (
    showAlert && (
      <section className={alertClass.join(" ")} data-testid="alertContainer">
        {dismissable && (
          <button
            className="storybook-alert-close"
            data-testid="closeAlert"
            data-dismiss="storybook-alert"
            aria-label="Close Alert"
            onClick={handleDismiss}
          >
            <CloseIcon />
            <span className="sr-only">Close Alert</span>
          </button>
        )}
        {children}
      </section>
    )
  );
};

Alert.propTypes = {
  /**
   * Content of the alert. Use "header" and "p" tags to structure content. "header" is optional.
   */
  children: PropTypes.node.isRequired,
  /**
   * Type of alert (determines color scheme). Select from the following options:
   */
  type: PropTypes.oneOf(["error", "success", "warning", "info"]),
  /**
   * Whether the alert is visible or not.
   */
  visibility: PropTypes.bool,
  /**
   * Show or hide the close button. If false, the alert cannot be dismissed.
   */
  dismissable: PropTypes.bool,
  /**
   * Function to be passed by the parent component and ran when the alert is dismissed.
   */
  onDismiss: PropTypes.func,
};

Alert.defaultProps = {
  type: "info",
  visibility: true,
  dismissable: true,
  onDismiss: () => {},
};
