import React, { useState } from "react";

import { Card } from "@storybook/stories/Card/Card";
import { Modal } from "@storybook/stories/Modal/Modal";
import { Button } from "@storybook/stories/Button/Button";

import { IconPhone, IconEmail, IconSlack } from "../../assets";

import "./my-team-member-details.css";

export const MyTeamMemberDetails = ({
  memberType,
  course,
  role,
  department_name,
  department_number,
  email,
  slack,
  calendly_link,
  phone,
  availability,
  user,
  applyAltColor,
  profile_image,
  name,
  biography
}) => {
  const isCounselor = (memberType === "Counselor");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const properCaseDay = (day) => {
    return day
      .toLowerCase()
      .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  };
  const formattedOfficeHours = Array.isArray(availability)
    ? availability.map((day) => properCaseDay(day)).join(", ")
    : availability;

  const calendlyParams = calendly_link
    ? `${calendly_link}?full_name=${user.firstName} ${user.lastName}&email=${user.email}`
    : "";
  const altColor = applyAltColor;

  const showBioModal = () => {
    setIsModalOpen(true);
    return;
  };

  const closeBioModal = () => {
    setIsModalOpen(false);
  }

  return (
    <>
      <b className="storybook-myteam-member--bio"
        onClick={showBioModal}
      >
        Read Bio
      </b>
      <div className="storybook-myteam-member">
        {memberType === "Counselor" ? (
          <>
            <h3 className="storybook-myteam-title">{role}</h3>
            <p className="storybook-myteam-desc">
              Check availability by clicking on schedule a meeting button.
            </p>
          </>
        ) : (
          <>
            <p
              className={`storybook-myteam-course-details ${
                altColor ? "alt-color" : ""
              }`}
            >
              {course}
            </p>
            <p className="storybook-myteam-office-hours">
              {formattedOfficeHours}
            </p>
          </>
        )}
        <Button
          buttonType="basic"
          size="small"
          label="Schedule a meeting"
          onClick={() => window.open(calendlyParams, "_blank")}
        />
        <div className="storybook-myteam-member-contact">
          <p className="storybook-myteam-slack">
            <a href={slack} target="_blank" rel="noreferrer">
              <img src={IconSlack} className="slack-logo" alt="" /> Slack
            </a>
          </p>
          <p className="storybook-myteam-email">
            <a href={`mailto:${email}`}>
              <img src={IconEmail} /> Email
            </a>
            <span>{email}</span>
          </p>
          {isCounselor && (
            <p className="storybook-myteam-phone">
              <img src={IconPhone} /> {phone} (Text)
            </p>
          )}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          icon={{
            name: "",
            color: "",
            size: "300px",
            isImage: true,
            imgUrl: profile_image
          }}
          confirmText="Close"
          confirmClick={() => closeBioModal()}
          cancelClick={closeBioModal}
          openDialog={true}
          showCloseIcon={true}
          extraClass="modal-bio text-left"
          size="large"
        >
          <p className="modal-bio--role">
            {role ?? course}
          </p>
          <h2>{name}</h2>
          <div className="modal-bio--row">
            <b>Availability</b>
            <p>{formattedOfficeHours}</p>
          </div>
          {biography?.quote && (
            <div className="modal-bio--row">
              <h3>{biography.quote}</h3>
            </div>
          )}
          {biography?.text && (
            <div className="modal-bio--row">
              {isCounselor ? (
                <b>Success Counselor's Bio</b>
              ) : (
                <b>Instructor’s Bio</b>
              )}
              <p>{biography.text}</p>
            </div>
          )}
          {biography?.additional_link && (
            <div className="modal-bio--row">
              <a href={biography.additional_link} target="_blank">
                {biography.additional_link_title}
              </a>
            </div>
          )}
          <div className="modal-bio--row">
            <Button
              buttonType="basic"
              size="medium"
              label="Schedule a meeting"
              onClick={() => window.open(calendlyParams, "_blank")}
            />
          </div>
          <div className="storybook-myteam-member-contact">
            <p className="storybook-myteam-slack">
              <a href={slack} target="_blank" rel="noreferrer">
                <img src={IconSlack} className="slack-logo" alt="" /> Slack
              </a>
            </p>
            <p className="storybook-myteam-email">
              <a href={`mailto:${email}`}>
                <img src={IconEmail} />
                Email: {email}
              </a>
            </p>
            {isCounselor && (
              <p className="storybook-myteam-phone">
                <img src={IconPhone} /> {phone} (Text)
              </p>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
