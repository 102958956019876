import React from "react";

import { Skeleton } from "@mui/material";

import "./skeletonmyteams.css";

export const SkeletonMyTeams = () => {
  return (
    <article className="storybook-myteam">
      <main className="skeleton-container" data-testid="skeletonContainer">
        <div className="storybook-myteam-members">
          {[...Array(2).keys()].map((index) => (
            <section
              className={`storybook-myteam-${
                index % 2 ? "counselors" : "instructors"
              }`}
              key={index}
            >
              <Skeleton
                variant="text"
                sx={{ fontSize: "2.5rem", width: "50%", margin: "0 auto" }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  width: "90%",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  width: "70%",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              />
              <Skeleton variant="rounded" height={200} />
            </section>
          ))}
        </div>
        <section className="storybook-myteam-copy">
          <Skeleton
            variant="text"
            sx={{
              fontSize: "1rem",
              width: "90%",
              textAlign: "center",
              margin: "0 auto",
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: "1rem",
              width: "90%",
              textAlign: "center",
              margin: "0 auto",
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: "1rem",
              width: "20%",
              textAlign: "center",
              margin: "0 auto",
            }}
          />
        </section>
        <section className="storybook-myteam-instructional-faculty">
          <Skeleton variant="rectangular" width={280} height={150} />
          <div>
            <Skeleton
              variant="text"
              sx={{ fontSize: "2.5rem", width: "50%" }}
            />
            {[...Array(4).keys()].map((index) => (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  width: "90%",
                }}
                key={index}
              />
            ))}
          </div>
        </section>
        <section className="storybook-myteam-peer-tutors">
          <div>
            <Skeleton
              variant="text"
              sx={{ fontSize: "2.5rem", width: "50%" }}
            />
            {[...Array(4).keys()].map((index) => (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  width: "90%",
                }}
                key={index}
              />
            ))}
          </div>
          <Skeleton variant="rectangular" width={280} height={150} />
        </section>
        <section className="storybook-myteam-academic-success-counselors">
          <Skeleton variant="rectangular" width={280} height={150} />
          <div>
            <Skeleton
              variant="text"
              sx={{ fontSize: "2.5rem", width: "50%" }}
            />
            {[...Array(4).keys()].map((index) => (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  width: "90%",
                }}
                key={index}
              />
            ))}
          </div>
        </section>
      </main>
    </article>
  );
};
