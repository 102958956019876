import React from "react";

/**
 * List of accordion items used in Wellness Services Page.
 */
export const CSQuestions = [
  {
    title: "How can Counseling and Career Services support me?",
    content: (
      <>
        <p>
          Counseling and Career Services can help you explore career options,
          make career decisions and put your plans into action. We’re here to:
        </p>
        <ul>
          <li>
            Create a plan with you to achieve your academic and career goals.
          </li>
          <li>
            Teach you skills for writing a resume and cover letter, creating a
            LinkedIn profile, searching for jobs and interviewing.
          </li>
          <li>
            Provide specialized group workshops and industry-specific events.
          </li>
          <li>
            Connect you with tools, resources and potential job and internship
            opportunities.
          </li>
        </ul>
      </>
    ),
    active: true,
  },
  {
    title:
      "For career-related questions, should I schedule an appointment with my Success Counselor or with a Career Services Specialist?",
    content: (
      <>
        <p>
          We recommend that you start with your assigned Success Counselor.
          Counselors can answer career-readiness questions, help you create your
          resume and connect you to additional Calbright resources. To make an
          appointment with your Success Counselor, go to the My Teams page on
          the Student Portal.
        </p>
        <p>
          After meeting with your counselor, we recommend that you attend our
          Career Services workshops.
        </p>
        <p>
          The best time to meet with a Career Services Specialist is when you’re
          getting ready to apply for jobs. Career Services Specialists are here
          to help you finalize your resume and support your job search.
        </p>
      </>
    ),
    active: false,
  },
  {
    title: "What is CareerConnect and why do I need to use it?",
    content: (
      <>
        <p>
          CareerConnect is Calbright’s online career resource center that
          empowers every Calbright student and alumnus to navigate their unique
          path to success. CareerConnect offers tools and resources for career
          exploration and growth, such as assessments, interviewing, networking,
          job and internship boards and so much more. It's also where you can
          learn about and register for Calbright Counseling and Career Services
          workshops and special events.
        </p>
      </>
    ),
    active: false,
  },
  {
    id: "know-more",
    title: "Why can't I access CareerConnect?",
    content: (
      <>
        <p>
          To access CareerConnect, you must have completed your first academic
          assignment. Within 72 hours of completing your first assignment,
          you’ll receive an email with instructions on how to access
          CareerConnect.
        </p>
      </>
    ),
    active: false,
  },
  {
    title: "How can I get help creating and finalizing my resume?",
    content: (
      <>
        <p>
          Step 1: Meet with your Success Counselor to start working on your
          resume. Your counselor will make sure you have access to the resume
          resources on CareerConnect. You can make an appointment with your
          counselor from the My Teams page on the Student Portal.
        </p>
        <p>Step 2: Attend a Career Services workshop.</p>
        <p>
          Step 3: Meet with a Career Services Specialist to finalize your
          resume.{" "}
        </p>
      </>
    ),
    active: false,
  },
  {
    title: "Can you help me find a job?",
    content: (
      <p>
        We can help you with your job or internship search. Visit the
        CareerConnect job board, create a profile, upload a resume, and search
        and apply for jobs.
      </p>
    ),
    active: false,
  },
  {
    title: "How can I contact Counseling and Career Services?",
    content: (
      <>
        <a href="mailto:careerservices@calbright.org">
          careerservices@calbright.org
        </a>
      </>
    ),
    active: false,
  },
];
