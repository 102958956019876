import React from "react";
import { WelcomeMessage as WelcomeStudent } from "@storybook/stories/WelcomeMessage/WelcomeMessage";

const WelcomeMessage = ({ user, dashboardData }) => {
  return (
    <>
      {/* TODO:Add Welcome msg lastlogin, and other props to userInfo data object. */}
      {(dashboardData.studentProgress.section === "milestone" ||
        dashboardData.studentProgress.section === "no-milestone") &&
      dashboardData.studentProgress.milestone ? (
        <WelcomeStudent
          currentTask={dashboardData.studentProgress.currentT}
          lastLogin={
            user?.lastLogin === "" ? "0 days" : `${user?.lastLogin} day(s)`
          }
          messageHead="Welcome Back"
          name={user.firstName}
          recentAssignment={
            user?.lastAssignmentCompletion === ""
              ? "0 days"
              : `${user?.lastAssignmentCompletion} day(s)`
          }
          isCompletion={true}
          lms={user.lms}
        />
      ) : (
        <WelcomeStudent
          currentTask={dashboardData.studentProgress.currentTask}
          lastLogin={null}
          messageHead="Hello"
          name={user?.firstName}
          recentAssignment={null}
          programName={user?.currentProgram}
        />
      )}
    </>
  );
};

export default WelcomeMessage;
