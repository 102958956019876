import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { preReqStepAction } from "../../redux/actions/preReqStepAction";

import { Header } from "@storybook/stories/Header/Header";
import { Card } from "@storybook/stories/Card/Card";
import { AppBadge } from "@storybook/stories/AppBadge/AppBadge";
import { TaskProgressBar } from "@storybook/stories/TaskProgressBar/TaskProgressBar";
import { ProgressBar } from "@storybook/stories/ProgressBar/ProgressBar";
import { Button } from "@storybook/stories/Button/Button";
import { Modal } from "@storybook/stories/Modal/Modal";
import { ModalLinkedIn } from "@storybook/stories/ModalLinkedIn/ModalLinkedIn";
import { SkeletonDashboard } from "../../components/Skeleton/SkeletonDashboard";
import { JoyrideNav } from "../../components/JoyrideNav/JoyrideNav";
import BroadcastMessage from "../../components/BroadcastMessage/BroadcastMessage";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import UserDashboardHandler from "../../components/UserDashboardHandler/UserDashboardHandler";
import { preReqMain } from "../../components/PreRequisite/PreReqMain";
import { preReqCongr } from "../../components/PreRequisite/Steps/PreReqCongr";
import { preReqDenied } from "../../components/PreRequisite/Steps/PreReqDenied";

import { appIcons } from "../../utils/helpers/appIcons";
import { helpIcons } from "../../utils/helpers/helpIcons";
import IconFirstAssignComplete from "../../assets/task/task-firstsaa.svg";
import LinkedInLogo from "../../assets/my-apps/linkedin-learning.svg";
import ArrowIcon from "../../assets/icon-arrow.svg";
import InfoIcon from "@mui/icons-material/Info";

import * as dashboardApi from "../../api/dashboardApi";

import isStudentEnrolled from "../../utils/isStudentEnrolled";
import * as tasksItems from "../../utils/helpers/tasks";
import {
  handleContinueProgram,
  handleCourseLink,
  dashboardError,
  setFirstSAAData,
} from "../../utils/helpers/dashboard";
import {
  learnerStatusLookup,
  studentProgramLookup,
  prereqStatusLookup,
} from "../../utils/helpers/data";

import "./dashboard.css";

const LINKEDIN_URL =
  "https://www.linkedin.com/signup/cold-join?session_redirect=%2Flearning&source=subs_learn_start&trk=learning_login_join_now";
const INSTRUCTIONS_URL =
  "https://drive.google.com/file/d/17rzC2Qza42YKFip7EoucLl8PdVhIefRC/view";

export const Dashboard = ({ user, saveAppointmentLinks, appointmentLinks }) => {
  const dispatch = useDispatch();
  const preReqStep = useSelector((state) => state.preReqStep.preReqStep);

  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showMyApps, setShowMyApps] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isModalLinkedInOpen, setIsModalLinkedInOpen] = useState(false);

  const firstSAAViewedLegacy = localStorage.getItem("firstSAAViewed") == "true";
  const firstSAAViewedData = JSON.parse(
    localStorage.getItem("firstSAAViewedList")
  );
  const firstSAAViewed =
    firstSAAViewedData && Array.isArray(firstSAAViewedData)
      ? firstSAAViewedData.includes(user?.cccId)
      : false;

  const history = useHistory();
  const handleProfileButtonClick = () => history.push("/profile");

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent({});
  };

  const handleLogin = () => {
    window.location.replace(process.env.AUTH_URL);
  };

  const handleLogout = () => {
    window.location.replace(process.env.LOGOUT_URI);
  };

  const showTaskInProgressModal = (modalContent) => {
    setModalContent(modalContent);
    setIsModalOpen(true);
    return;
  };

  let tasks = useUpdateTasks(user, tasksItems, showTaskInProgressModal);

  useEffect(() => {
    setLoading(true);
    if (user !== undefined) {
      const { appsEnabled, helpIconsEnabled } = dashboardApi.setEnabledApps(
        user.learnerStatus
      );

      const userProgress = dashboardApi.setDashboardTasks(user);

      const updatedHelpIcons = helpIcons.map((helpIcon) => {
        const isDisabled = !helpIconsEnabled.includes(helpIcon.name);
        return {
          ...helpIcon,
          link: isDisabled ? "" : helpIcon.link,
          disabled: isDisabled,
          disabledMsg: helpIcon.disabledMsg,
        };
      });

      const updatedAppIcons = appIcons.map((appIcon) => {
        const isDisabled = !appsEnabled.includes(appIcon.key);
        if (appIcon.key === "linkedIn") {
          appIcon.handleClick = () => setIsModalLinkedInOpen(true);
        }
        return {
          ...appIcon,
          link: isDisabled ? "" : appIcon.link,
          disabled: isDisabled,
          disabledMsg: appIcon.disabledMsg,
        };
      });

      saveAppointmentLinks(user.currentProgram);

      setDashboardData({
        ...dashboardData,
        studentProgress: userProgress,
        appList: updatedAppIcons,
        helpIcons: updatedHelpIcons,
        appointmentLinks,
      });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [user]);

  /* TODO: Handle milestone completion click event. */

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }, []);

  const handleShowMyApps = () => {
    setShowMyApps(!showMyApps);
  };

  useEffect(() => {
    if (
      dashboardData?.studentProgress.milestone.progress === 100 &&
      user.learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"] &&
      !firstSAAViewedLegacy &&
      !firstSAAViewed
    ) {
      setModalContent(firstSAAModalContent);

      setTimeout(() => {
        setIsModalOpen(true);
        if (modalContent?.title === "Congratulations!") {
          setFirstSAAData(user?.cccId);
        }
      }, 500);
    }

    if (firstSAAViewedLegacy && !firstSAAViewed) {
      setFirstSAAData(user?.cccId);
    }
  }, [user, dashboardData, modalContent]);

  const showEnrollmentDate = (learnerStatus, enrollmentDate, termEndDate) => {
    if (
      learnerStatus === learnerStatusLookup["APP_SUBMITTED"] ||
      learnerStatus === learnerStatusLookup["STARTED_ORIENTATION"]
    ) {
      return ["", ""];
    }
    return [enrollmentDate, termEndDate];
  };

  const [enrollmentDate, termEndDate] = showEnrollmentDate(
    user.learnerStatus,
    user.enrollmentDate,
    user.currentTermEndDate
  );

  useEffect(() => {
    setModalContent(preReqStep);
  }, [preReqStep]);

  const handleEsc = useCallback((event) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [handleEsc]);

  if (Object.keys(user).length > 0 && !user?.currentProgram) {
    return <UserDashboardHandler user={user} />;
  }

  return (
    <article className="storybook-dashboard" data-testid="dashboardContainer">
      <Header
        user={user}
        isLogoClickable={false}
        onLogin={handleLogin}
        onLogout={handleLogout}
        activeLink="home"
        avatarsize="medium"
      />
      <>
        {/* TODO: Remove additional loading logic from Dashboard (its handled by RequireAuth) */}
        {loading && !dashboardData ? (
          // <SkeletonDashboard />
          <h1>Loading ...</h1>
        ) : (
          <>
            {Object.keys(user).length > 0 && (
              <main>
                <JoyrideNav studentProgress={dashboardData.studentProgress} />
                <div className="container">
                  <div className="storybook-dashboard-inner">
                    <section className="storybook-dashboard-message no-desktop">
                      <BroadcastMessage />
                      <WelcomeMessage
                        user={user}
                        dashboardData={dashboardData}
                      />
                    </section>
                    <div
                      className="storybook-dashboard-leftcolumn"
                      data-testid="dashboardLeftcolumn"
                    >
                      <section
                        className="storybook-dashboard-usercard"
                        data-testid="dashboardUserCard"
                      >
                        <Card
                          cardType="student-profile"
                          dashboardContent={{
                            cccId: user.cccId,
                            enrollmentDate: enrollmentDate,
                            termEndDate: termEndDate,
                          }}
                          title={user.firstName + " " + user.lastName}
                          hasButton={true}
                          onClick={handleProfileButtonClick}
                          imageSrc={user.profileImageUrl}
                        />
                      </section>
                      <section
                        className={`storybook-dashboard-myapps ${
                          showMyApps ? "show" : ""
                        }`}
                        data-testid="dashboardMyApps"
                      >
                        <hr width="80%" />
                        <h2>My Apps</h2>
                        <div
                          className="storybook-myapps-container"
                          data-testid="myappsContainer"
                        >
                          {dashboardData?.appList.map((appIcon, index) => (
                            <AppBadge
                              key={index}
                              id={appIcon.name}
                              title={appIcon.title}
                              link={appIcon.link}
                              linkType="external"
                              icon={appIcon.icon}
                              disabled={appIcon.disabled}
                              disabledMsg={appIcon.disabledMsg}
                              data-testid={appIcon.key + "-badge"}
                              handleClick={
                                appIcon.handleClick &&
                                appIcon.title === "Program Access"
                                  ? (e) => handleContinueProgram(user)
                                  : appIcon.handleClick
                              }
                            />
                          ))}
                        </div>
                      </section>
                      <div
                        className="storybook-dashboard-myapps-show-more"
                        onClick={handleShowMyApps}
                      >
                        <img
                          src={ArrowIcon}
                          className={showMyApps ? "expand-rotate" : ""}
                        />
                      </div>
                    </div>
                    <div
                      className="storybook-dashboard-rightcolumn"
                      data-testid="dashboardRightcolumn"
                    >
                      <section
                        className="storybook-dashboard-message no-mobile"
                        data-testid="dashboardMessage"
                      >
                        <BroadcastMessage />
                        <WelcomeMessage
                          user={user}
                          dashboardData={dashboardData}
                        />
                      </section>
                      {dashboardData.studentProgress.section ===
                      "no-milestone" ? (
                        <section
                          className="storybook-dashboard-mytasks coursework white-container box-shadow"
                          data-testid="dashboardMyTasksCoursework"
                        >
                          <div className="storybook-dashboard-mytasks--wrapper">
                            <header>
                              <h2>{dashboardData.studentProgress.title}</h2>
                            </header>
                            <h3>{user.currentProgram}</h3>
                            {dashboardData.studentProgress.courses.map(
                              (course, index) => (
                                <ProgressBar
                                  key={index}
                                  label={`${course.deptName} ${course.deptNum} `}
                                  link={handleCourseLink(
                                    course.code,
                                    user?.programVersion
                                  )}
                                  description={course.courseName}
                                  progress={course.progress}
                                  percentLocation={course.percentLocation}
                                  showScale={true}
                                />
                              )
                            )}
                          </div>
                          <footer>
                            <Button
                              label="Continue Your Program"
                              onClick={() => handleContinueProgram(user)}
                            />
                            <p className="storybook-mytasks-info">
                              <InfoIcon /> Progress is{" "}
                              {user?.lms === "Canvas"
                                ? `calculated based on graded Summative Assessments.`
                                : `updated every 24 hours.`}
                            </p>
                          </footer>
                        </section>
                      ) : (
                        <>
                          <section
                            className="storybook-dashboard-mytasks white-container box-shadow"
                            data-testid="dashboardMyTasks"
                          >
                            <header>
                              <h2>{dashboardData.studentProgress.title}</h2>
                            </header>
                            <TaskProgressBar
                              stages={tasks}
                              date={dashboardData.studentProgress.date}
                              progress={
                                dashboardData.studentProgress.milestone.progress
                              }
                              section={dashboardData.studentProgress.section}
                              milestoneDetails={
                                dashboardData.studentProgress.milestone.details
                              }
                              handleCompleteFirstAssignment={() =>
                                handleContinueProgram(user)
                              }
                              progressStages={tasksItems.progressStages}
                            />
                          </section>
                        </>
                      )}
                      <section
                        className="storybook-dashboard-needhelp white-container"
                        data-testid="dashboardNeedHelp"
                      >
                        <h2>Quick Links</h2>
                        {dashboardData.helpIcons.map((helpIcon, index) => (
                          <AppBadge
                            key={index}
                            id={helpIcon.name}
                            icon={helpIcon.icon}
                            link={helpIcon.link}
                            title={helpIcon.title}
                            disabled={helpIcon.disabled}
                            disabledMsg={helpIcon.disabledMsg}
                            target={helpIcon.target}
                          />
                        ))}
                      </section>
                    </div>
                  </div>
                </div>
                {isModalOpen && modalContent !== undefined && (
                  <Modal
                    icon={modalContent.icon}
                    title={modalContent.title}
                    openDialog={modalContent.openDialog}
                    showCloseIcon={modalContent.showCloseIcon}
                    confirmText={modalContent.confirmText}
                    confirmClick={() => handleCloseModal()}
                    cancelClick={handleCloseModal}
                    size={modalContent.size}
                    extraClass={modalContent.extraClass}
                  >
                    {modalContent.children}
                  </Modal>
                )}
                {isModalLinkedInOpen && (
                  <ModalLinkedIn
                    icon={{
                      imgUrl: LinkedInLogo,
                      isImage: true,
                      name: "",
                      size: "96",
                    }}
                    openDialog={true}
                    title="LinkedIn Learning"
                    linkedInLoginURL={LINKEDIN_URL}
                    instructionsSignUp={INSTRUCTIONS_URL}
                    target="_blank"
                    cancelClick={() => setIsModalLinkedInOpen(false)}
                  >
                    You have free access to LinkedIn Learning as a Calbright
                    College student. With LinkedIn Learning, you can
                  </ModalLinkedIn>
                )}
              </main>
            )}
          </>
        )}
      </>
    </article>
  );
};

Dashboard.propTypes = {
  /**
   * User information used on the dashboard.
   */
  user: PropTypes.shape({}),
  /**
   * List of apps enabled for the user.
   */
  appsEnabled: PropTypes.array,
  /**
   * Student progress information.
   */
  userProgress: PropTypes.shape({
    title: PropTypes.string,
    progress: PropTypes.number,
    section: PropTypes.oneOf(["onboarding", "milestone", "no-milestone"]),
    milestone: PropTypes.shape({
      name: PropTypes.string,
      progress: PropTypes.number,
      type: PropTypes.oneOf(["onboarding", "milestone"]),
      completionDate: PropTypes.string,
      status: PropTypes.oneOf(["Ahead", "On Track", "Behind", "Way - Behind"]),
    }),
    date: PropTypes.string,
  }),
  /**
   * Help Icons enabled.
   */
  helpIconsEnabled: PropTypes.array,
  // apiData property types
  apiData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    profile_image_url: PropTypes.string,
    current_program: PropTypes.string,
    enrollment_date: PropTypes.string,
    current_term_end_date: PropTypes.string,
    learner_status: PropTypes.string,
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        completed: PropTypes.bool,
        name: PropTypes.string,
        progress: PropTypes.string,
      })
    ),
    lms: PropTypes.string,
    program_of_interest: PropTypes.string,
    program_version: PropTypes.string,
    salesforce_id: PropTypes.string,
  }),
  // token: PropTypes.object,
  // saveCode: PropTypes.func.isRequired,
  // loadToken: PropTypes.func.isRequired,
  // saveUser: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  user: null,
  appsEnabled: appIcons,
  userProgress: {
    title: "My Tasks",
    progress: 0,
    section: "onboarding",
    date: "",
  },
  helpIconsEnabled: helpIcons,
};

/**
 * Modal content template
 */
const modalContentTemplate = {
  icon: {
    color: "error",
    name: "ErrorOutlineOutlined",
    size: "75px",
  },
  title: "Title",
  children: <p>Content</p>,
  openDialog: true,
  showCloseIcon: false,
  confirmText: "Close",
  size: "large",
};

/**
 * Content for First SAA detected modal window.
 */
const firstSAAModalContent = {
  icon: {
    name: "",
    color: "",
    size: "100px",
    isImage: true,
    imgUrl: IconFirstAssignComplete,
  },
  title: "Congratulations!",
  openDialog: true,
  showCloseIcon: false,
  confirmText: "Continue",
  children: (
    <p className="center">
      Well done! You’ve completed all your orientation tasks. <br />
      Click Continue to return to your dashboard.
    </p>
  ),
};

/**
 * Content for Sign Enrollment Agreement modal window.
 */
const signEnrollAgreementContent = {
  ...modalContentTemplate,
  title: "You will Sign Enrollment Agreement during your Onboarding session",
  children: (
    <p>
      During your Onboarding session, you’ll officially enroll in your program
      of study by filling out and signing your enrollment agreement.
    </p>
  ),
};

const pendingReviewContent = {
  ...modalContentTemplate,
  title: "Your Pre-requisite Verification is Pending Review",
  children: (
    <p>
      Your pre-requisite check has been submitted and is being reviewed. You
      will hear from us in 2 business days. Check your Calbright email for
      updates.
    </p>
  ),
};

const waitingSupportContent = {
  ...modalContentTemplate,
  title: "Support Requested",
  children: (
    <p>
      Thank you for contacting us. The Success Team will reach out to you in 2-5
      business days. Check your Calbright email for updates.
    </p>
  ),
};

/**
 * Custom hook to change onboarding tasks based on learnerStatus and program.
 * @param {object} user
 * @param {array} onboardingTasks Pass it taskItems.studentOnboarding
 * @param {function} showTaskInProgressModal function that handles the task click event.
 * @param {string} targetTaskId
 * @returns {array} tasks
 */
const useUpdateTasks = (user, taskItems, showTaskInProgressModal) => {
  const [tasks, setTasks] = useState([]);
  let modalContent = null;

  useEffect(() => {
    const updateTasks = () => {
      // Determine which task to update
      let taskToUpdate = null;
      let updatedTasks = taskItems.studentOnboarding;
      let targetTaskId = "";

      if (user?.currentProgram === studentProgramLookup.CYBERSECURITY) {
        updatedTasks = [tasksItems.preRequisiteTask, ...updatedTasks];
      }

      switch (user?.learnerStatus) {
        case learnerStatusLookup.APP_SUBMITTED:
        case learnerStatusLookup.STARTED_ORIENTATION:
          targetTaskId = "prerequisite-verification";
          break;
        case learnerStatusLookup.COMPLETED_CSEP:
        case learnerStatusLookup.COMPLETED_ORIENTATION:
        case learnerStatusLookup.ENROLLED_IN_PROGRAM_PATHWAY:
          targetTaskId = "enrollment-agreement";
          break;
      }

      taskToUpdate = updatedTasks.find((task) => task.id === targetTaskId);

      if (taskToUpdate) {
        const updatedTask = { ...taskToUpdate };
        switch (true) {
          case user?.learnerStatus ===
            learnerStatusLookup.COMPLETED_ORIENTATION:
          case user?.learnerStatus === learnerStatusLookup.COMPLETED_CSEP:
            updatedTask.handleClick = () =>
              showTaskInProgressModal(signEnrollAgreementContent);
            break;
          case isStudentEnrolled(user?.learnerStatus):
            updatedTask.title = "Enrollment Agreement Signed";
            break;
        }

        if (
          user.currentProgram === studentProgramLookup["CYBERSECURITY"] &&
          targetTaskId === "prerequisite-verification"
        ) {
          switch (user?.prereqStatus) {
            case prereqStatusLookup.PENDING:
              updatedTask.statusLabel = "Pending Review";
              modalContent = pendingReviewContent;
              break;
            case prereqStatusLookup.WAITING_SUPPORT:
              updatedTask.statusLabel = "Waiting Support";
              modalContent = waitingSupportContent;
              break;
            case prereqStatusLookup.APPROVED:
              modalContent = preReqCongr;
              break;
            case prereqStatusLookup.DENIED:
              modalContent = preReqDenied;
              break;
            default:
              modalContent = preReqMain;
              break;
          }

          if (modalContent) {
            updatedTask.handleClick = () =>
              showTaskInProgressModal(modalContent);
          }
        }

        updatedTasks = updatedTasks.map((task) =>
          task.id === targetTaskId ? updatedTask : task
        );

        setTasks(updatedTasks);
      } else {
        setTasks(updatedTasks);
      }
    };

    updateTasks();
  }, [user, tasksItems]);

  return tasks;
};

export default Dashboard;
