import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Wellness } from "@storybook/stories/Wellness/Wellness";
import { Accordion } from "@storybook/stories/Accordion/Accordion";
import { Skeleton } from "@mui/material";

import { handleError } from "../../api/apiUtils";
import { learnerStatusLookup } from "../../utils/helpers/data";
import { updateImagePath } from "../../utils/updateImagePath";
import { convertContentToReactNode } from "../../utils/htmlToReactNode";
import { fetchStaticVirtualID } from "../../redux/actions/staticActions";

const VirtualID = ({ user }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [staticData, setStaticData] = useState(null);
  const virtualID = useSelector(
    (state) => state.staticContent?.virtualID
  );
  const enrolled =
    user.learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"];

  const handleCTA = () => {
    window.open(process.env.VIRTUAL_ID_URL, "_blank");
    setMessage("You have been successfully redirected to ID123.");
  };

  const closeMessage = () => {
    setMessage(null);
  };

  const convertFaqs = (items) => {
    return items.map((item) => ({
      ...item,
      content: convertContentToReactNode(item.content),
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const staticResponse =
          virtualID ||
          (await dispatch(
            fetchStaticVirtualID("/common/virtual-id.json")
          ));

        const processedData = {
          ...staticResponse,
          subTitle: convertContentToReactNode(staticResponse?.subTitle),
          cardItems: updateImagePath(staticResponse?.cardItems),
          cta: {
            ...staticResponse?.cta,
            body: staticResponse?.cta?.body,
            bodyNotEnrolled: staticResponse?.cta?.bodyNotEnrolled,
          },
          faq: convertFaqs(staticResponse?.faq),
        };

        setStaticData(processedData);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, virtualID]);

  if (isLoading || !staticData) {
    return (
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1.5rem",
          width: "calc(800px - 40vw)",
          maxWidth: "100%",
          margin: "10px",
        }}
      />
    );
  }

  return (
    <article className="virtual-id">
      <PortalHeader user={user} activeLink="benefits" />
      <main>
        <div className="container">
          <Wellness
            items={staticData?.cardItems}
            enrolled={enrolled}
            title={staticData?.title}
            subtitle={staticData?.subTitle}
            cardTitle={staticData?.cta.cardTitle}
            cardContent={staticData?.cta.body}
            notEnrCardContent={staticData?.cta.bodyNotEnrolled}
            btnLabel={staticData?.cta.btnLabel}
            btnSubmit={handleCTA}
            closeMessage={closeMessage}
            message={message}
          />
          <Accordion
            items={staticData?.faq}
            title="Frequently Asked Questions"
          />
        </div>
      </main>
    </article>
  );
};

export default VirtualID;
