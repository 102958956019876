import React from "react";
import PropTypes from "prop-types";
import { InlineWidget } from "react-calendly";

export const Calendly = ({
  title,
  url,
  prefill,
  settings,
  styles,
  utm
}) => {
  return (
    <div data-testid="calendlyWidget">
      <InlineWidget
        iframeTitle={title}
        prefill={prefill}
        pageSettings={settings}
        styles={styles}
        url={url}
        utm={utm}
      />
    </div>
  );
}

Calendly.propTypes = {
  /**
   * Calendly iframe title.
   */
  title: PropTypes.string,
  /**
   * Calendly url.
   */
  url: PropTypes.string.isRequired,
  /**
   * Calendly autocomplete values.
   */
  prefill: PropTypes.object,
  /**
   * Calendly customization settings.
   */
  settings: PropTypes.object,
  /**
   * Calendly custom styles.
   */
  styles: PropTypes.object,
  /**
   * Utm options.
   */
  utm: PropTypes.object,
};
