import React from "react";
import { NavLink } from "react-router-dom";
import PeerTutoringIcon from "../../assets/tutoringlearning/PeerTutoring.png";
import GetStartedIcon from "../../assets/tutoringlearning/GetStartedWorkshop.png";
import ReadingIcon from "../../assets/tutoringlearning/Reading.png";
import DigitalLiteracyIcon from "../../assets/tutoringlearning/DigitalLiteracy.png";
import LeadershipIcon from "../../assets/tutoringlearning/Leadership.png";

import { learnerStatusLookup as status } from "../../utils/helpers/data";

import { Button } from "@storybook/stories/Button/Button";

/**
 * Alternative content for the "Tutoring and Learning" page when the user is enrolled in a program pathway.
 * @type {React.ReactNode}
 */
export const contentEnabledSchedule = (
  <div className="storybook-learning-content">
    <p>
      Peer tutors are here to support you through your academic journey at
      Calbright College. To help you succeed in the Information Technology
      Support program, we offer individual appointments, recurring weekly
      appointments, drop-in support and asynchronous support. Peer tutors can
      also coordinate additional study groups and workshops as needed.
    </p>
    <h3>One-on-One Appointments</h3>
    <p>
      Schedule one-on-one time with a peer tutor to get help with your
      coursework — when you need it and at a time that fits your schedule.
    </p>
    <Button
      buttonType="basic"
      size="medium"
      onClick={() =>
        window.open(
          "https://calendly.com/calbright-tutoring-learning-support",
          "_blank"
        )
      }
      label="Schedule Now"
    />

    <h3>Drop-In</h3>
    <p>
      Meet with a peer tutor without a scheduled appointment. Drop-in sessions
      provide the support you need for your coursework in a welcoming
      environment.
    </p>
    <p>
      Download the Tutoring and Learning Center Calendar to discover available
      sessions.
    </p>
    <Button
      buttonType="basic"
      size="medium"
      onClick={() =>
        window.open(
          "https://calendar.google.com/calendar/u/0?cid=Y19jOTI2ZjA5NzA4NTg0M2Q2NTdlOTI2ZTU3MmNhNjVmZjQwZDFlYmZlM2I0MDQ5Mjg3MjdhNDkwMzllOGU5NTAwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20",
          "_blank"
        )
      }
      label="Download Now"
    />

    <h3>Peer Assisted Study Session (PASS)</h3>
    <p>
      Facilitated by peer tutors, these intimate and focused sessions provide a
      collaborative and supportive learning environment to ask questions and
      work on course assignments.
    </p>
    <p>
      Download the Tutoring and Learning Center Calendar to discover available
      sessions.
    </p>
    <Button
      id="btnPASS"
      buttonType="basic"
      size="medium"
      onClick={() =>
        window.open(
          "https://calendar.google.com/calendar/u/0?cid=Y19jOTI2ZjA5NzA4NTg0M2Q2NTdlOTI2ZTU3MmNhNjVmZjQwZDFlYmZlM2I0MDQ5Mjg3MjdhNDkwMzllOGU5NTAwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20",
          "_blank"
        )
      }
      label="Download Now"
    />

    <h3>Ask the TLC</h3>
    <p>
      Have a question but don’t have time to meet with someone? Submit your
      question to the Tutoring and Learning Center and get your answer within 24
      business hours.
    </p>
    <Button
      buttonType="basic"
      size="medium"
      onClick={() =>
        window.open(
          "https://docs.google.com/forms/d/e/1FAIpQLSfDm_s9JeCIOJfI3xxhiiXasy-7N2IJruyTb-AaH9hJ1QxCYw/viewform?usp=sf_link",
          "_blank"
        )
      }
      label="Ask Now"
    />
  </div>
);

/**
 * Learning resources categories, icon and content (as an array of objects).
 *  @type {Array.<{id: number, title: string, description: string, image: string, content: React.ReactNode}>}
 */
export const LearningResourcesData = [
  {
    id: 1,
    title: "Peer Tutoring",
    description:
      "If you need assistance with your coursework, we’ll work with you at a time that fits your schedule. Services include one-on-one appointments, drop-in peer tutoring, Ask the TLC and workshops.",
    image: PeerTutoringIcon,
    content: (
      <div className="storybook-learning-content">
        <p>
          Peer tutors are here to support you through your academic journey at
          Calbright College. To help you succeed in the Information Technology
          Support program, we offer individual appointments, recurring weekly
          appointments, drop-in support and asynchronous support. Peer tutors
          can also coordinate additional study groups and workshops as needed.
        </p>
        <h3>One-on-One Appointments</h3>
        <p>
          Schedule one-on-one time with a peer tutor to get help with your
          coursework — when you need it and at a time that fits your schedule.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          disabled={true}
          onClick={(e) => e.preventDefault()}
          label="Schedule Now"
        >
          <span className="sr-only">disabled</span>
        </Button>

        <h3>Drop-In</h3>
        <p>
          Meet with a peer tutor without a scheduled appointment. Drop-in
          sessions provide the support you need for your coursework in a
          welcoming environment.
        </p>
        <p>
          Download the Tutoring and Learning Center Calendar to discover
          available sessions.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendar.google.com/calendar/u/0?cid=Y19jOTI2ZjA5NzA4NTg0M2Q2NTdlOTI2ZTU3MmNhNjVmZjQwZDFlYmZlM2I0MDQ5Mjg3MjdhNDkwMzllOGU5NTAwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20",
              "_blank"
            )
          }
          label="Download Now"
        />

        <h3>Peer Assisted Study Session (PASS)</h3>
        <p>
          Facilitated by peer tutors, these intimate and focused sessions
          provide a collaborative and supportive learning environment to ask
          questions and work on course assignments.
        </p>
        <p>
          Download the Tutoring and Learning Center Calendar to discover
          available sessions.
        </p>
        <Button
          id="btnPASS"
          data-testid="btnPass"
          buttonType="basic"
          size="medium"
          onClick={(e) => e.preventDefault()}
          label="Download Now"
          disabled={true}
        ></Button>

        <h3>Ask the TLC</h3>
        <p>
          Have a question but don’t have time to meet with someone? Submit your
          question to the Tutoring and Learning Center and get your answer
          within 24 business hours.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSfDm_s9JeCIOJfI3xxhiiXasy-7N2IJruyTb-AaH9hJ1QxCYw/viewform?usp=sf_link",
              "_blank"
            )
          }
          label="Ask Now"
        />
      </div>
    ),
  },
  {
    id: 2,
    title: "Getting Started Workshops",
    description:
      "Getting Started workshops are a great way to get familiar with Calbright apps and programs, and prepare for becoming a Calbright student. Workshops include Navigate the Student Portal and Calbright Apps, Start Your First Assignment, and Read to Understand.",
    image: GetStartedIcon,
    content: (
      <div className="storybook-learning-content">
        <h3>Navigate the Student Portal and Calbright Apps</h3>
        <p>
          Expand your knowledge of Calbright apps like Slack, Google Workspace
          and the Student Portal.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendly.com/getting-started-at-calbright/navigating-student-portal-platforms-and-apps",
              "_blank"
            )
          }
          label="Schedule Now"
        />
        <h3>Start Your First Assignment</h3>
        <p>
          Let us walk you through your first module or course assignment to help
          you get comfortable with the learning platform.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendly.com/getting-started-at-calbright",
              "_blank"
            )
          }
          label="Schedule Now"
        />
        <h3>Reading to Understand</h3>
        <p>
          In small groups, a reading and writing tutor will introduce you to
          some reading strategies to help you understand your program material
          better.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendly.com/getting-started-at-calbright/reading-to-understand",
              "_blank"
            )
          }
          label="Schedule Now"
        />
      </div>
    ),
  },
  {
    id: 3,
    title: "Online Learning and Digital Literacy",
    description:
      "Need to expand your knowledge of Calbright tools, apps and software? Our team is available to help you learn about Google Workspace, the Student Portal and more. Services include one-on-one appointments, drop-in support, Ask the TLC and workshops.",
    image: DigitalLiteracyIcon,
    content: (
      <div className="storybook-learning-content">
        <p>
          Expand your knowledge of Calbright apps like Slack, Google Workspace
          and the Student Portal.
        </p>
        <h3>One-on-One Appointments</h3>
        <p>
          Schedule one-on-one time with a Student Support Specialist to get help
          navigating Calbright apps like Slack, Google Workspace and the Student
          Portal.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendly.com/tlc-online-learning-and-digital-literacy-support",
              "_blank"
            )
          }
          label="Schedule Now"
        />
        <h3>Drop-In</h3>
        <p>
          Meet with a Student Support Specialist without a scheduled appointment
          time. Drop-in sessions provide navigation support of Calbright apps
          and platforms.
        </p>
        <p>
          Download the Tutoring and Learning Center Calendar to discover
          available sessions.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendar.google.com/calendar/u/0?cid=Y19jOTI2ZjA5NzA4NTg0M2Q2NTdlOTI2ZTU3MmNhNjVmZjQwZDFlYmZlM2I0MDQ5Mjg3MjdhNDkwMzllOGU5NTAwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20",
              "_blank"
            )
          }
          label="Download Now"
        />
        <h3>Workshops and Study Sessions</h3>
        <p>
          Facilitated by Student Support Specialists, these intimate and focused
          sessions provide a collaborative and supportive learning environment.
        </p>
        <p>
          Download the Tutoring and Learning Center Calendar to discover
          available sessions.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendar.google.com/calendar/u/0?cid=Y19jOTI2ZjA5NzA4NTg0M2Q2NTdlOTI2ZTU3MmNhNjVmZjQwZDFlYmZlM2I0MDQ5Mjg3MjdhNDkwMzllOGU5NTAwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20",
              "_blank"
            )
          }
          label="Download Now"
        />
        <h3>Ask the TLC</h3>
        <p>
          Have a question about Calbright apps like Slack, Google Workspace and
          the Student Portal? Don’t have time to meet with someone? Submit your
          question to the Tutoring and Learning Center and get your answer
          within 24 business hours.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSfDm_s9JeCIOJfI3xxhiiXasy-7N2IJruyTb-AaH9hJ1QxCYw/viewform?usp=sf_link",
              "_blank"
            )
          }
          label="Ask Now"
        />
      </div>
    ),
  },
  {
    id: 4,
    title: "Reading, Writing, and English as a Second Language",
    description:
      "Want to increase your reading comprehension and writing skills? Or do you want to improve your English language skills? Meet with a tutor to develop strategies for reading more effectively, memorizing terms and communicating clearly through writing. Services include one-on-one appointments, drop-in support, Ask the TLC and workshops.",
    image: ReadingIcon,
    content: (
      <div className="storybook-learning-content">
        <h3>One-on-One Appointments</h3>
        <p>
          Schedule one-on-one time with a Reading and Writing Specialist to
          increase your speaking or writing confidence, reduce language errors
          and develop professional communication skills.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendly.com/tlc-reading-writing-and-english-language-support",
              "_blank"
            )
          }
          label="Schedule Now"
        />
        <h3>Drop-In</h3>
        <p>
          Meet with a Student Support Specialist without a scheduled appointment
          time. Drop-in sessions provide reading, writing, and English as a
          second language support.
        </p>
        <p>
          Download the Tutoring and Learning Center Calendar to discover
          available sessions.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendar.google.com/calendar/u/0?cid=Y19jOTI2ZjA5NzA4NTg0M2Q2NTdlOTI2ZTU3MmNhNjVmZjQwZDFlYmZlM2I0MDQ5Mjg3MjdhNDkwMzllOGU5NTAwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20",
              "_blank"
            )
          }
          label="Download Now"
        />
        <h3>Workshop and Study Sessions</h3>
        <p>
          Facilitated by Student Support Specialists, these intimate and focused
          sessions provide a collaborative and supportive learning environment.
        </p>
        <p>
          Download the Tutoring and Learning Center Calendar to discover
          available sessions.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://calendar.google.com/calendar/u/0?cid=Y19jOTI2ZjA5NzA4NTg0M2Q2NTdlOTI2ZTU3MmNhNjVmZjQwZDFlYmZlM2I0MDQ5Mjg3MjdhNDkwMzllOGU5NTAwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20",
              "_blank"
            )
          }
          label="Download Now"
        />
        <h3>Ask a Specialist</h3>
        <p>
          Have a question but don’t have time to meet with someone? Submit your
          question to a reading, writing and English as a Second Language
          Specialist.
        </p>
        <Button
          buttonType="basic"
          size="medium"
          onClick={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSfDm_s9JeCIOJfI3xxhiiXasy-7N2IJruyTb-AaH9hJ1QxCYw/viewform?usp=sf_link",
              "_blank"
            )
          }
          label="Ask Now"
        />
      </div>
    ),
  },
  {
    id: 5,
    title: "Experiential Learning and Leadership Internship",
    description:
      "The Experiential Learning and Leadership (ExLL) Program is a six month paid internship designed for current students and alumni. Its primary purpose is to provide valuable work experience and leadership opportunities.",
    image: LeadershipIcon,
    content: (
      <div className="storybook-learning-content">
        <h4 className="inset-20">What is the ExLL Internship</h4>
        <p className="inset-20">
          The Experiential Learning and Leadership (ExLL) Internship at
          Calbright College offers a unique chance to gain real-world experience
          while enhancing your professional skills, critical thinking, and
          leadership abilities. This program blends hands-on internships with
          reflective practice to foster growth in a supportive and collaborative
          environment.
        </p>
        <h4 className="inset-20">Who and When Can You Apply?</h4>
        <p className="inset-20">
          Applications are open in March for the July-December cycle and in
          September for the January-June cycle. Each cycle lasts six months:
          Cycle 1 runs from July 1st to December 31st, and Cycle 2 runs from
          January 1st to June 30th. Onboarding is in June and December,
          respectively.
        </p>
        <h4 className="inset-20">How to Apply</h4>
        <p className="inset-20">
          To apply, update your resume, write a cover letter, and complete your
          application online via Career Connect.
        </p>
        <br />
        <NavLink
          to={"/career-services"}
          disabled={
            new Date().getMonth() + 1 === 9 || new Date().getMonth() + 1 === 3
              ? false
              : true
          }
        >
          <Button
            buttonType="basic"
            size="medium"
            disabled={
              new Date().getMonth() + 1 === 9 || new Date().getMonth() + 1 === 3
                ? false
                : true
            }
            onClick={(e) => e.preventDefault()}
            label="Apply for ExLL Internship"
          ></Button>
        </NavLink>
        <h4 className="inset-20">Why Should You Join?</h4>
        <p className="inset-20">
          Earn $20/hour with flexible hours (5-15 hours/week) and receive
          bi-weekly pay. The internship includes real-world tasks, mentorship,
          skill development, and community engagement, making it an excellent
          opportunity for professional growth, problem solving, and networking.
        </p>
        <p>
          Stay tuned for more details on how to apply and upcoming information
          sessions. Ready to advance your professional journey with the ExLL
          Internship at Calbright College?
        </p>
        <p>
          If you have any questions, please email us at{" "}
          <a href="mailto:Tutoring@calbright.org">Tutoring@calbright.org</a>
        </p>
      </div>
    ),
  },
];

/**
 * Peer Assisted Study Session (PASS) links and their associated labels
 * @type {Object.<string, {label: string, currentProgram: string, link: string}>}
 */
export const PASSLinks = {
  IT_SUPPORT: {
    label: "Schedule IT Support PASS",
    currentProgram: "IT Support",
    link: "https://calendly.com/pass-workshops",
  },

  CRM: {
    label: "Schedule CRM PASS",
    currentProgram: "Customer Relationship Management",
    link: "https://calendly.com/pass-workshops-crm",
  },

  DATA_ANALYSIS: {
    label: "Schedule Data Analysis PASS",
    currentProgram: "Data Analysis",
    link: "https://calendly.com/pass-workshops-data-analysis",
  },
};

/**
 * List (array) of team members displayed on tutoring center page.
 * @type {Array.<{id: number, name: string, role: string, department: string, slack: string, email: string, photo_url: string}>}
 */
export const MeetYourTeamData = [
  {
    id: 1,
    name: "Ehboni Greer",
    role: "Student Support Specialist",
    department: "Technology and Online Learning",
    slack: "U021VPL6KT2",
    email: "egreer@calbright.org",
    photo_url:
      "https://static.calbrightcollege.org/assets/tutors/thumbnails/a4d26db8-2554-4bae-8891-3fcb3e548459.png",
  },
  {
    id: 2,
    name: "Greg Money",
    role: "Student Support Specialist",
    department: "Technology and Accessibility",
    slack: "U03BZ3CDW93",
    email: "greg.money@calbright.edu",
    photo_url:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/94db3d71-9345-7fd2-b234-912334f23423.png",
  },
  {
    id: 3,
    name: "Kathleen Hickey",
    role: "Reading, Writing and ESL Tutor",
    department: "",
    slack: "U0704JJU7LN",
    email: "Kathleen.Hickey@calbright.edu",
    photo_url:
      "https://static.calbrightcollege.org/assets/student-avatars/user_3.svg", //"https://placehold.co/64x64/FFF7E8/FFF7E8",
  },
  {
    id: 4,
    name: "Richard Garcia",
    role: "Coordinator",
    department: "",
    slack: "U04GZUFRHNW",
    email: "richard.garcia@calbright.edu",
    photo_url:
      "https://static.calbrightcollege.org/assets/tutors/thumbnails/2264c83e-fc34-4ad4-8478-a28109c3e5fc.png",
  },
  {
    id: 5,
    name: "Leena Her",
    role: "Dean of Learning Services",
    department: "",
    slack: "",
    email: "leena.her@calbright.edu",
    photo_url:
      "https://static.calbrightcollege.org/assets/tutors/thumbnails/a163f50a-61b5-4ef9-aa50-746cafaa3bc1.png",
  },
];

/**
 * Recursively traverses the React element tree and returns arrays based on React element types.
 * If current node matches the type, add it to the result array.
 * If current node is falsy/invalid React element, return null.
 * If current node has children, recursively traverse them.
 * @param {*} element
 * @param {*} type
 * @returns null || array
 */
export const findChildElements = (element, type) => {
  const result = [];

  function traverse(node) {
    if (!node || !React.isValidElement(node)) {
      return;
    }

    if (node.type === type) {
      result.push(node);
    }

    React.Children.forEach(node.props.children, traverse);
  }

  traverse(element);
  return result;
};

/**
 * Find the button element with id "btnPASS" and update its properties.
 * @param {*} element
 * @param {*} PASSLinkData
 * @returns content with updated button properties
 */
export const updateResourceContent = (content, PASSLinkData) => {
  let modifiedContent = content;
  if (React.isValidElement(content)) {
    modifiedContent = React.cloneElement(
      content.props.children,
      {},
      React.Children.map(content.props.children, (child) => {
        // Find btn Replace the button element with a new Button component
        if (React.isValidElement(child) && child.props.id === "btnPASS") {
          return {
            ...child,
            props: {
              ...child.props,
              onClick: () => window.open(PASSLinkData.link, "_blank"),
              label: PASSLinkData.label,
              disabled: false,
            },
          };
        }
        return child;
      })
    );
  }

  return {
    ...content,
    props: {
      ...content.props,
      children: modifiedContent.props.children,
    },
  };
};

/**
 * Update learning resource center PASS links based on currentProgram.
 * @param {*} user
 * @param {*} LearningResourcesData
 * @param {*} studentProgramLookup
 * @param {*} PASSLinks
 * @param {*} updateResourceContent
 * @returns
 */
export const getUpdatedContent = (
  user,
  LearningResourcesData,
  studentProgramLookup,
  PASSLinks,
  updateResourceContent
) => {
  if (!user || !user.currentProgram) {
    return LearningResourcesData;
  }

  const updatedData = [...LearningResourcesData];

  if (
    user.learnerStatus === status["ENROLLED_IN_PROGRAM_PATHWAY"] ||
    user.learnerStatus === status["STARTED_PROGRAM_PATHWAY"]
  ) {
    updatedData[0].content = contentEnabledSchedule;
  }
  switch (user.currentProgram) {
    case studentProgramLookup["IT_SUPPORT"]:
      updatedData[0].content = updateResourceContent(
        updatedData[0].content,
        PASSLinks["IT_SUPPORT"]
      );
      break;
    case studentProgramLookup["T2T_CRM_ADMIN"]:
      updatedData[0].content = updateResourceContent(
        updatedData[0].content,
        PASSLinks["CRM"]
      );
      break;
    case studentProgramLookup["DATA_ANALYSIS"]:
      updatedData[0].content = updateResourceContent(
        updatedData[0].content,
        PASSLinks["DATA_ANALYSIS"]
      );
      break;
    default:
      break;
  }
  return updatedData;
};

/**
 * Function returns true if September or March. All other times returns false
 */
export const isInternshipSeason = () => {
  const currentMonth = new Date().getMonth() + 1;
  console.log("currentMonth =", currentMonth);
  return currentMonth !== 9 || currentMonth === 3;
};
