import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import back from "../assets/back.svg";
import close from "../assets/close.svg";
import focusMode from "../assets/focus-mode.svg";
import focusModeActive from "../assets/focus-mode-active.svg";

import "./resources.css";

export const Resources = ({ resources }) => {
  const [scrollTop, setScrollTop] = useState();
  const [isActive, setIsActive] = useState(null);
  const [isInFocusMode, setIsInFocusMode] = useState(false);
  const [resource, setResource] = useState(null);
  const leftColumn = useRef(null);
  const rightColumn = useRef(null);
  const activeItem = useRef(null);
  const arrow = useRef(null);
  const focusModeImg = useRef(null);

  const handleToggle = (index) => {
    if (isInFocusMode) handleFocusMode();

    if (isActive === index) {
      leftColumn.current.scrollTop = 0;
      return setIsActive(null);
    }

    const selectedItem = resources.find((item) => item.id === index);
    setResource(selectedItem);
    setIsActive(index);
  };

  const handleFocusMode = () => {
    leftColumn.current.classList.toggle("hide");
    rightColumn.current.classList.toggle("max-width");

    const isInMaxWidth = rightColumn.current.classList.contains("max-width");
    setIsInFocusMode(isInMaxWidth);

    focusModeImg.current.src = isInMaxWidth ? focusModeActive : focusMode;
  };

  const handleScroll = () => {
    getArrowPosition();
    checkArrowPosition();
  };

  const getArrowPosition = () => {
    if (leftColumn.current && activeItem.current) {
      const parentTop = leftColumn.current.getBoundingClientRect().top;
      const childTop = activeItem.current.getBoundingClientRect().top;
      setScrollTop(childTop - parentTop);
    }
  };

  const checkArrowPosition = () => {
    if (!arrow.current) return;

    if (scrollTop < -45 || scrollTop > 635) {
      arrow.current.classList.add("remove-arrow");
    } else {
      arrow.current.classList.remove("remove-arrow");
    }
  };

  useEffect(() => {
    if (arrow.current) {
      arrow.current.classList.remove("remove-arrow");
    }

    getArrowPosition();
  }, [isActive]);

  return (
    <div className="resources">
      <div className="resources-wrap">
        <div className={`resources-left ${isActive ? "toggle" : ""}`}>
          <div
            className="resources-left--inner"
            onWheel={handleScroll}
            ref={leftColumn}
          >
            {resources &&
              resources.map((item, index) => (
                <div
                  className={`resources-row ${
                    isActive === item.id ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => handleToggle(item.id)}
                  data-testid={`resources-row-${item.id}`}
                  ref={isActive === item.id ? activeItem : null}
                >
                  {item?.cornerMsg && (
                    <p className="resource-msg" data-testid="resourceMsg">
                      {item?.cornerMsg}
                    </p>
                  )}
                  <img src={item.image} alt={item.title} />
                  <div>
                    <h3>{item.title}</h3>
                    {!isActive && <p>{item.description}</p>}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {isActive && resource && (
          <div
            className="resources-right"
            data-testid="resources-right"
            ref={rightColumn}
          >
            <span
              className="resources-arrow"
              ref={arrow}
              style={{ top: `${scrollTop}px` }}
            />
            <div className="resources-right--inner">
              {resource?.focusMode && (
                <>
                  <button
                    className="btn-focus"
                    onClick={() => handleFocusMode(null)}
                    data-testid="btnFocusMode"
                  >
                    <img
                      src={focusMode}
                      alt="focus mode"
                      className="focusMode"
                      ref={focusModeImg}
                    />
                  </button>
                  <span>Focus mode</span>
                </>
              )}
              <button className="btn-close" onClick={() => handleToggle(null)}>
                <img src={close} alt="close" />
                <span>
                  <img src={back} alt="back" />
                  Back
                </span>
              </button>
              <h2>{resource.title}</h2>
              <p>{resource.description}</p>
              <div className="resources-content">{resource.content}</div>
              <img
                className="resource-bg"
                src={resource.image}
                alt={resource.title}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Resources.propTypes = {
  /**
   * Resource titles and their content.
   */
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        cornerMsg: PropTypes.string,
      }),
    })
  ),
};
