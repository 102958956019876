import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "./../Button/Button";

import CheckIcon from "./../assets/check.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./fileupload.css";

export const FileUploadInput = ({
  name,
  placeholder,
  allowedExt,
  fileSize,
  optionalName,
  toggleFile,
  resetErr,
  btnLabel,
  type,
}) => {
  const inputRef = useRef();
  const isSetMaxFileSize = !!fileSize;
  const [file, setFile] = useState(null);
  const [optional, setOptional] = useState("");
  const [over, setOver] = useState(false);
  const [fileError, setFileError] = useState(null);
  const maxAllowedSize = fileSize * 1024 * 1024;

  const handleDragEnter = (event) => {
    event.preventDefault();
    setOver(true);
  };

  const handleDragLeave = (event) => {
    setOver(false);
  };

  const handleFile = (event) => {
    event.preventDefault();
    const getFile = event.dataTransfer
      ? event.dataTransfer.files[0]
      : event.target.files[0];

    setFileError(null);
    setOver(false);

    if (isSetMaxFileSize && maxAllowedSize < getFile.size) {
      setFileError(
        `Your file exceeds the maximum allowed size of: ${fileSize}MB`
      );
      return;
    }

    const ext = getFile?.name.split(".").pop().toLowerCase();

    if (!allowedExt.includes(ext)) {
      setFileError(
        `Invalid file extension. Please try to upload ${allowedExt}.`
      );
      return;
    }

    getFile.optional = optional ?? null;

    if (toggleFile) {
      // Remove old file from parent
      toggleFile(file, true);
      // Add new file to parent
      getFile.certName = name;
      toggleFile(getFile);
    }

    setFile(getFile);
  };

  const handleDeleteFile = (event) => {
    event.stopPropagation();
    if (file.optional) {
      setOptional("");
    }
    if (toggleFile) {
      toggleFile(file, true);
    }
    setFileError(null);
    setFile(null);
  };

  useEffect(() => {
    if (file) {
      const getFile = file;
      getFile.optional = optional ?? null;
      getFile.certName = name;
      setFile(getFile);
    }
  }, [optional, file, name]);

  useEffect(() => {
    if (resetErr) {
      setFileError(null);
    }
  }, [resetErr]);

  return (
    <>
      <div
        className={`upload-input ${over ? "over" : ""} ${
          file ? "attached" : ""
        }`}
        onDragOver={handleDragEnter}
        onDrop={(event) => handleFile(event)}
        onDragLeave={handleDragLeave}
      >
        {type === "list" && (
          <div className="upload-input-col">
            <span
              className="upload-input-status"
              onClick={file ? handleDeleteFile : null}
            >
              {file && <img src={CheckIcon} alt="Done" />}
            </span>
            <span className="upload-input-name">{name}</span>
          </div>
        )}

        <span className="upload-input-placeholder">
          <span className={`upload-input-type-${type}`}>
            {optionalName && (
              <input
                className="upload-optional"
                placeholder="Enter a name"
                name="optional"
                value={optional}
                onChange={(e) => setOptional(e.target.value)}
                data-testid="file-upload-optional"
              />
            )}
            {file ? (
              <>
                <span>{file.name}</span>
                <button className="upload-delete" onClick={handleDeleteFile}>
                  <CloseIcon />
                </button>
              </>
            ) : (
              <span>{placeholder}</span>
            )}
          </span>
        </span>
        <Button
          buttonType="basic"
          label={btnLabel}
          onClick={() => inputRef.current.click()}
          isLoading={false}
        />
        <input
          type="file"
          onChange={(event) => handleFile(event)}
          hidden
          name={name.toLowerCase()}
          id={name?.replace(" ", "")}
          ref={inputRef}
          accept={allowedExt}
          data-testid={`file-upload-${name}`}
        />
      </div>
      {fileError && <p className="error">{fileError}</p>}
    </>
  );
};

FileUploadInput.propTypes = {
  /**
   * Label of input field.
   */
  name: PropTypes.string.isRequired,
  /**
   * Placeholder of the input field.
   */
  placeholder: PropTypes.string,
  /**
   * Allowed file extensions.
   */
  allowedExt: PropTypes.string,
  /**
   * Max file size.
   */
  fileSize: PropTypes.number,
  /**
   * Optional description file title.
   */
  optionalName: PropTypes.bool,
  /**
   * Pass/remove file to the parent component.
   */
  toggleFile: PropTypes.func,
  /**
   * Reset field error.
   */
  resetErr: PropTypes.bool,
  /**
   * Button label.
   */
  btnLabel: PropTypes.string,
  /**
   * Select from list (default) or simple
   */
  type: PropTypes.oneOf(["list", "simple"]),
};

FileUploadInput.defaultProps = {
  placeholder: "Please attach your resume...",
  allowedExt: ".jpg,.jpeg,.png,.pdf,.doc,.docx,",
  fileSize: 10,
  optionalName: false,
  passFile: () => {},
  name: "file",
  btnLabel: "Attach",
  type: "list",
};
