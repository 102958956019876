import React from "react";
import PropTypes from "prop-types";
import {
  getFeatureFlag,
  FEATURE_FLAGS,
} from "../../utils/helpers/feature-flags";

/**
 * Component that renders children if the feature flag and environment settings.
 * @param {Object} props
 * @param {FlagName} props.flagName - Name of the feature flag
 * @param {Boolean} props.forceEnable - Override to force enable the feature
 * @param {Boolean} props.inverse - If true, render children when feature is disabled
 * @param {React.ReactNode} props.children - Content to render when feature is enabled
 * @returns {React.ReactNode|null}
 */
export const FeatureFlag = ({ flagName, forceEnable, inverse, children }) => {
  const isEnabled = forceEnable || getFeatureFlag(flagName);
  return inverse ? (!isEnabled ? children : null) : isEnabled ? children : null;
};

FeatureFlag.propTypes = {
  flagName: PropTypes.oneOf(Object.keys(FEATURE_FLAGS)).isRequired,
  forceEnable: PropTypes.bool,
  inverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FeatureFlag.defaultProps = {
  inverse: false,
  forceEnable: false,
};

export default FeatureFlag;
