import * as types from "./actionTypes";
import * as myteamAPI from "../../api/myteamApi";
import { handleError } from "../../api/apiUtils";
import { beginApiCall } from "./apiStatusActions.js";

export function fetchTeamDataSuccess(myTeam) {
  return { type: types.FETCH_TEAMDATA_SUCCESS, myTeam: myTeam };
}

export function fetchTeamData(currentProgram, token) {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      const myTeam = await myteamAPI.getMyTeamData(currentProgram, token);
      dispatch(fetchTeamDataSuccess(myTeam));
      return myTeam;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}
