import React from "react";
import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";
import { NavLink } from "react-router-dom";

/**
 * Converts html string to React node and changes internal links to NavLinks.
 * @param {string} htmlString
 * @returns
 */
export const convertContentToReactNode = (htmlString) => {
  if (!htmlString) return null;

  const sanitizedHtml = DOMPurify.sanitize(htmlString);

  const options = {
    replace: (domNode) => {
      if (domNode.type === "tag" && domNode.name === "a") {
        const href = domNode.attribs?.href;

        if (href?.startsWith("/")) {
          return (
            <NavLink to={href} className={domNode.attribs?.class || ""}>
              {domToReact(domNode.children, options)}
            </NavLink>
          );
        }

        return (
          <a
            href={href}
            className={domNode.attribs?.class || ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            {domToReact(domNode.children, options)}
          </a>
        );
      }
    },
  };

  return parse(sanitizedHtml, options);
};
