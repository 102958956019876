import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function arservicesReducer(
  state = initialState.arservices,
  action
) {
  switch (action.type) {
    case types.FETCH_ARSERVICES_SUCCESS:
      return action.arservices;
    default:
      return state;
  }
}

export function enrollmentVerificationReducer(
  state = initialState.enrollmentVerification,
  action
) {
  switch (action.type) {
    case types.POST_ENROLLMENTVERIFICATION_SUCCESS:
      return action.enrollmentVerification;
    default:
      return state;
  }
}

export function completedProgramsReducer(
  state = initialState.completedPrograms,
  action
) {
  switch (action.type) {
    case types.FETCH_COMPLETED_PROGRAMS_SUCCESS:
      return action.completedPrograms;
    default:
      return state;
  }
}
