import * as types from "./actionTypes";
import * as broadcastMsgApi from "../../api/broadcastMsgApi.js";
import { handleError } from "../../api/apiUtils";
import { beginApiCall } from "./apiStatusActions.js";

export function setBroadcastMsgSuccess(broadcastMsgs) {
  return { type: types.SET_BROADCAST_MESSAGES_SUCCESS, broadcastMsgs };
}

export function saveBroadcastMsgs(token) {
  return async function (dispatch, getState) {
    if (getState().apiCallsInProgress == 0) {
      dispatch(beginApiCall());
      try {
        const broadcastMsgs = await broadcastMsgApi.getBroadcastMsgs(token);
        dispatch(setBroadcastMsgSuccess(broadcastMsgs));
        return broadcastMsgs;
      } catch (error) {
        dispatch(handleError(error));
        throw error;
      }
    }
  };
}
