import ReactGA from "react-ga4";

if (process.env.GA_KEY) {
  ReactGA.initialize(process.env.GA_KEY);
}

export const gtagEventStudentInfo = ({
  currentProgram,
  learnerStatus
}) => {
  if (!ReactGA.isInitialized) return;

  ReactGA.gtag("event", "student_info", {
    "intended_program": currentProgram,
    "learner_status": learnerStatus
  });
};

export const gaTrackRoutes = () => {
  if (!ReactGA.isInitialized) return;

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: document.title
  });
}