import React from 'react';
import PropTypes from 'prop-types';
import { getInitials } from '../../utils/helpers';

import './avatar.css';

/**
 * Use an Avatar to associate a user with a page or piece of content.
 */
export const Avatar = ({ fullName, initials, size, hasPhoto, photoUrl, handleAvatarClick, ...props }) => {  
 
  if(initials === '') {
    initials = getInitials(fullName);
  }

  return (
    <div 
      className={['storybook-avatar', `storybook-avatar--${size}`, `${props.active ? 'active' : ''}`].join(" ")} 
      data-testid="avatar-container"
      onClick={handleAvatarClick}>

      {hasPhoto 
      ?
        <img src={photoUrl} alt={fullName} data-testid="avatar-image" />
      :
        <>
          {initials}
        </>
      }
    </div>
  );
};

Avatar.propTypes = {
  /**
   * Additional information that can be displayed in avatar tooltip.
   */
  fullName: PropTypes.string.isRequired,
  /**
   * Text displayed on the Avatar, up to 2 characters. Excess will be ignored. If not provided, defaults to the fullName parameter.
   */
  initials: PropTypes.string,
  /**
   * How large should the avatar be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  /**
   * Does the avatar have an image associated with it?
   */
  hasPhoto: PropTypes.bool,
  /**
   * If an image is associated, provide a url to it.
   */
  photoUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

Avatar.defaultProps = {
  size: "large",
};