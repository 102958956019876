import { hotjar } from 'react-hotjar';

export const identifyHotjarStudent = async ({
  cccId,
  currentProgram,
  learnerStatus
}) => {
  try {
    if (process.env.HOTJAR_ID && !hotjar.initialized()) {
      await hotjar.initialize({
        id: process.env.HOTJAR_ID,
        sv: 6
      });
    }

    if (window.hj) {
      hotjar.identify(cccId, {
        "intended_program": currentProgram,
        "learner_status": learnerStatus
      });
    } else {
      console.warn("Hotjar not available");
    }
  } catch (error) {
    console.error("Error initializing or identifying Hotjar:", error);
  }
};
