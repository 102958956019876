import { appIcons } from "../utils/helpers/appIcons";
import { helpIcons } from "../utils/helpers/helpIcons";
import { calendlyLinks as programLinks } from "../utils/helpers/dashboard";

import {
  studentProgramLookup,
  learnerStatusLookup,
  prereqStatusLookup as prereq,
} from "../utils/helpers/data";

/**
 * Sets array values for My Apps and Quick Links section of the portal based on learnerStatus.
 * @param {string} learnerStatus
 * @returns {object{ appsEnabled, helpIconsEnabled}}
 */
export const setEnabledApps = (learnerStatus, currentProgram) => {
  let appsEnabled = [];
  let helpIconsEnabled = ["IconMyAccomodate"];
  if (currentProgram === "T2T CRM Admin") {
    switch (learnerStatus) {
      case learnerStatusLookup["APP_SUBMITTED"]:
      case learnerStatusLookup["READY_FOR_ONBOARDING"]:
      case learnerStatusLookup["STARTED_ORIENTATION"]:
      case learnerStatusLookup["COMPLETED_ORIENTATION"]:
        appsEnabled = ["gmail", "googleDrive", "googleCalendar"];
        helpIconsEnabled = ["IconCU", "IconMyAccomodate"];
        break;
      case learnerStatusLookup["COMPLETED_CSEP"]:
        appsEnabled = ["slack", "gmail", "googleDrive", "googleCalendar"];
        helpIconsEnabled = ["IconMeetMyTeam", "IconCU", "IconMyAccomodate"];
        break;
      case learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"]:
      case learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE"]:
        appsEnabled = [
          "programAccess",
          "slack",
          "gmail",
          "googleDrive",
          "googleCalendar",
        ];
        helpIconsEnabled = [
          "IconMeetMyTeam",
          "IconPPT",
          "IconCU",
          "IconMyAccomodate",
        ];
        break;
      case learnerStatusLookup["STARTED_PROGRAM_PATHWAY"]:
        appsEnabled = [...appIcons.map((iconObject) => iconObject.key)];
        helpIconsEnabled = helpIcons.map((iconObject) => iconObject.name);
        break;
    }
  } else {
    switch (learnerStatus) {
      case learnerStatusLookup["APP_SUBMITTED"]:
      case learnerStatusLookup["STARTED_ORIENTATION"]:
      case learnerStatusLookup["COMPLETED_ORIENTATION"]:
      case learnerStatusLookup["READY_FOR_ONBOARDING"]:
      case learnerStatusLookup["COMPLETED_CSEP"]:
        appsEnabled = ["gmail", "googleDrive", "googleCalendar"];
        helpIconsEnabled = ["IconCU", "IconMyAccomodate"];
        break;
      case learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"]:
      case learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE"]:
        appsEnabled = [
          "programAccess",
          "slack",
          "gmail",
          "googleDrive",
          "googleCalendar",
        ];
        helpIconsEnabled = [
          "IconMeetMyTeam",
          "IconPPT",
          "IconCU",
          "IconMyAccomodate",
        ];
        break;
      case learnerStatusLookup["STARTED_PROGRAM_PATHWAY"]:
      case learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"]:
        appsEnabled = [...appIcons.map((iconObject) => iconObject.key)];
        helpIconsEnabled = helpIcons.map((iconObject) => iconObject.name);
        break;
    }
  }
  return { appsEnabled, helpIconsEnabled };
};

/**
 * Maps Dashboard tasks/course display information based on learnerStatus.
 * @param {string} learnerStatus
 * @param {array} courses
 * @returns {object} userProgress
 */
export const setDashboardTasks = ({
  learnerStatus = learnerStatusLookup["STARTED_ORIENTATION"],
  courses = [],
  currentProgram,
  scheduledKickoff,
  prereqStatus = null,
}) => {
  let userProgress;
  const hasFirstSAA = firstSAA(courses);
  if (currentProgram === "T2T CRM Admin") {
    if (learnerStatus === learnerStatusLookup["APP_SUBMITTED"]) {
      userProgress = setTask("Schedule Onboarding", []);
    } else if (
      learnerStatus === learnerStatusLookup["READY_FOR_ONBOARDING"] ||
      learnerStatus === learnerStatusLookup["STARTED_ORIENTATION"] ||
      learnerStatus === learnerStatusLookup["COMPLETED_ORIENTATION"]
    ) {
      userProgress = setTask("Enrollment Agreement Signed", []);
    } else if (
      (learnerStatus === learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"] &&
        !scheduledKickoff) ||
      learnerStatus === learnerStatusLookup["COMPLETED_CSEP"]
    ) {
      userProgress = setTask("Schedule Kick-off", []);
    } else if (
      learnerStatus ===
        learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE"] ||
      (learnerStatus === learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"] &&
        scheduledKickoff)
    ) {
      userProgress = setTask("Schedule Kick-off-completed", []);
    } else if (
      learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"] &&
      hasFirstSAA
    ) {
      userProgress = setTask("Complete 1st Assignment-FirstSAA", courses);
    } else if (
      learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"] ||
      learnerStatus === learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"]
    ) {
      userProgress = setTask("Active Coursework", courses);
    } else {
      userProgress = setTask("Schedule Onboarding", []);
    }
  } else {
    if (
      (currentProgram === studentProgramLookup.CYBERSECURITY ||
        currentProgram === studentProgramLookup.T2T_INTRO_NETWORKS) &&
      (learnerStatus === learnerStatusLookup["APP_SUBMITTED"] ||
        learnerStatus === learnerStatusLookup["STARTED_ORIENTATION"]) &&
      prereqStatus !== prereq.APPROVED
    ) {
      userProgress = setTask("Pre-requisite Check", [], currentProgram);

      if (
        prereqStatus === prereq.PENDING ||
        prereqStatus === prereq.WAITING_SUPPORT
      ) {
        userProgress = setTask("Pre-requisite Pending", [], currentProgram);
      }
    } else if (
      learnerStatus === learnerStatusLookup["APP_STARTED"] ||
      learnerStatus === learnerStatusLookup["STARTED_ORIENTATION"]
    ) {
      userProgress = setTask("Schedule Onboarding", [], currentProgram);
    } else if (
      learnerStatus === learnerStatusLookup["COMPLETED_ORIENTATION"] ||
      learnerStatus === learnerStatusLookup["COMPLETED_CSEP"]
    ) {
      userProgress = setTask("Enrollment Agreement Signed", [], currentProgram);
    } else if (
      learnerStatus === learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"] &&
      !scheduledKickoff
    ) {
      userProgress = setTask("Schedule Kick-off", [], currentProgram);
    } else if (
      learnerStatus ===
        learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE"] ||
      (learnerStatus === learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"] &&
        scheduledKickoff)
    ) {
      userProgress = setTask("Schedule Kick-off-completed", [], currentProgram);
    } else if (
      learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"] &&
      hasFirstSAA
    ) {
      userProgress = setTask(
        "Complete 1st Assignment-FirstSAA",
        courses,
        currentProgram
      );
    } else if (
      learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"] ||
      learnerStatus === learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"]
    ) {
      userProgress = setTask("Active Coursework", courses, currentProgram);
    } else {
      userProgress = setTask("Schedule Onboarding", [], currentProgram);
    }
  }

  return userProgress;
};

/**
 * Look at courses progress and see if all course fall between 3-5%. If so, return true else false.
 * @param {array} courses
 * @returns {boolean} hasSAA
 */
const firstSAA = (courses) => {
  let hasSAA = false;

  if (courses.length > 0) {
    const progressValues = courses.map((course) => course.progress);
    const isInRange = progressValues.some(
      (progress) => progress >= 3 && progress <= 5
    );
    const isNotOver5 = progressValues.every((course) => course.progress < 6);

    hasSAA = isInRange && isNotOver5;
  }

  return hasSAA;
};

/**
 * Sets up Appointment Links based on user.currentProgram.
 * NOTE: function shows sandbox-calendly links in NODE_ENV = development
 * @param {string} currentProgram
 * @returns {object} appointmentLinks
 */
export const setAppointmentLinks = (currentProgram) => {
  let appointmentLinks = programLinks.find(
    (programLink) => programLink.program === currentProgram
  );

  if (
    process.env.STAGE_FEATURE_ENABLED === "true" ||
    process.env.INT_FEATURE_ENABLED === "true"
  ) {
    appointmentLinks = programLinks.find(
      (programLink) => programLink.id === "sandbox-calendly"
    );
  }
  return appointmentLinks;
};

/**
 * Sets up the different task steps on the dashboard.
 * @param {string} task
 * @param {array} courses
 * @returns {object} taskProgress
 */
function setTask(task, courses, currentProgram = null) {
  let taskProgress = {
    title: "My Tasks",
    progress: 0,
    section: "onboarding",
    currentTask: "",
    program: "Data Analysis",
    lastLogin: "3 days",
    date: "",
    milestone: {
      name: "Onboarding",
      progress: 0,
      type: "onboarding",
    },
    courses: courses,
  };

  const coursesMapped = mapCourseData(courses);
  const isPrereq =
    currentProgram === studentProgramLookup.CYBERSECURITY ||
    currentProgram === studentProgramLookup.T2T_INTRO_NETWORKS;

  switch (task) {
    case "Pre-requisite Check":
      taskProgress = {
        ...taskProgress,
        currentTask: "Pre-requisite Check",
        milestone: {
          ...taskProgress.milestone,
          progress: 0,
        },
      };
      break;
    case "Pre-requisite Pending":
      taskProgress = {
        ...taskProgress,
        currentTask: "Pre-requisite Check",
        milestone: {
          ...taskProgress.milestone,
          statusLabel: "Pending Review",
          progress: 1,
        },
      };
      break;
    case "Schedule Onboarding":
      taskProgress = {
        ...taskProgress,
        currentTask: "Schedule Onboarding",
        milestone: {
          ...taskProgress.milestone,
          progress: isPrereq ? 15 : 0,
        },
      };
      break;
    case "Enrollment Agreement Signed":
      taskProgress = {
        ...taskProgress,
        currentTask: "Sign Enrollment Agreement",
        milestone: {
          ...taskProgress.milestone,
          progress: isPrereq ? 38 : 20,
        },
      };
      break;
    case "Schedule Kick-off":
      taskProgress = {
        ...taskProgress,
        currentTask: "Schedule Kick-off",
        milestone: {
          ...taskProgress.milestone,
          progress: isPrereq ? 63 : 50,
        },
      };
      break;
    case "Schedule Kick-off-completed": // intermediary task created for dashboard
      taskProgress = {
        ...taskProgress,
        currentTask: "Complete First Assignment",
        milestone: {
          ...taskProgress.milestone,
          progress: isPrereq ? 88 : 85,
        },
      };
      break;
    case "Complete 1st Assignment":
      taskProgress = {
        ...taskProgress,
        currentTask: "Complete 1st Assignment",
        milestone: {
          ...taskProgress.milestone,
          progress: isPrereq ? 88 : 85,
        },
      };
      break;
    case "Complete 1st Assignment-FirstSAA": // intermediary task created for dashboard
      taskProgress = {
        ...taskProgress,
        title: "Active Coursework",
        currentTask: "Completed First Assignment",
        milestone: {
          name: "Onboarding",
          progress: 100,
          type: "onboarding",
        },
        courses: coursesMapped,
      };
    case "Active Coursework":
      taskProgress = {
        title: "Active Coursework",
        progress: 0,
        section: "no-milestone",
        program: "Data Analysis",
        date: "",
        currentTask: "Active Coursework",
        milestone: {
          name: "Onboarding",
          progress: 100,
          type: "onboarding",
        },
        courses: coursesMapped,
      };
      break;
  }

  return taskProgress;
}

/**
 * Map course data to dashboard fields
 * @param {*} courses
 * @return {array} coursesMapped
 */
const mapCourseData = (courses) => {
  let coursesMapped = [];

  // TODO: Add a check for IT520 and IT525 add links to course.name based on version.
  if (courses.length > 0) {
    coursesMapped = courses.map((course) => {
      return {
        ...course,
        deptName: course.code,
        deptNum: "",
        courseName: course.name,
        progress: course.progress === null ? 0 : course.progress,
      };
    });
  }
  return coursesMapped;
};
