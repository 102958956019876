import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";

import "./checkbox.css";

export const Checkbox = ({
  label,
  name,
  showLabel,
  checked,
  required,
  readOnly,
  disabled,
  onChange,
  size,
  value,
}) => {
  const labelClass = ["storybook-checkbox-label"];
  const labelFormatted = label?.replace(" ", "-");
  const nameFormatted = name?.replace(" ", "-");
  const [isChecked, setIsChecked] = useState(checked || false);

  if (showLabel) {
    labelClass.push("storybook-checkbox-label--show");
  }

  switch (size) {
    case "small":
      size = "16px";
      break;
    case "medium":
      size = "24px";
      break;
    case "large":
      size = "32px";
      break;
    default:
      size = "24px";
  }

  const handleOnChange = (event) => {
    if (disabled || readOnly) return;
    onChange && onChange(event.target.checked);
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label className="storybook-checkbox-container">
      <input
        type="checkbox"
        name={nameFormatted || labelFormatted}
        id={nameFormatted || labelFormatted}
        aria-required={required}
        aria-readonly={readOnly}
        className="storybook-checkbox"
        checked={isChecked}
        onChange={handleOnChange}
        data-testid="checkboxField"
        disabled={disabled}
        readOnly={readOnly}
        value={value || nameFormatted}
      />
      {isChecked ? (
        <CheckBoxRoundedIcon className="storybook-checkbox-checked" />
      ) : (
        <CheckBoxOutlineBlankRoundedIcon className="storybook-checkbox-unchecked" />
      )}
      <span
        className={labelClass.join(" ")}
        htmlFor={nameFormatted || labelFormatted}
        data-testid="checkboxLabel"
      >
        {label}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  /**
   * Name of the checkbox
   */
  name: PropTypes.string.isRequired,
  /**
   * Label of the checkbox (visible form label)
   */
  label: PropTypes.string.isRequired,
  /**
   * Toggle label on or off.
   */
  showLabel: PropTypes.bool,
  /**
   * Add required property to checkbox
   */
  required: PropTypes.bool,
  /**
   * Add readonly property to checkbox
   */
  readOnly: PropTypes.bool,
  /**
   * Add checked property to checkbox
   */
  checked: PropTypes.bool,
  /**
   * Add disabled property to checkbox
   */
  disabled: PropTypes.bool,
  /**
   * Add size property to checkbox
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Add value property to checkbox
   */
  value: PropTypes.string,
  /**
   * Add onChange property to checkbox. Implemented by parent component.
   */
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  showLabel: true,
  required: false,
  readOnly: false,
  checked: false,
  disabled: false,
  onChange: undefined,
};
