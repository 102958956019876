import React from 'react';
import PropTypes from 'prop-types';

import './aside.css';

/**
 * Content holder used to accompany main content on a page.
 */
export const Aside = ({ title, children, cornerType, dropShadow}) => {
  return (
    <aside 
      className={`storybook-aside ${(cornerType !== 'none') ? 'border-radius-' + cornerType : ''} ${dropShadow && 'box-shadow'}`} 
      data-testid="asideContainer"
    >
      <h2>{title}</h2>
      {children}
    </aside>
  )
};

Aside.propTypes = {
  /**
   * Title of the aside 
   */
  title: PropTypes.string,
  /**
   * Children is any node (content) to fill the aside. Include a minimum element like a paragraph for a string of text.
   */
  children: PropTypes.node,
  /**
   * Add rounded corners to the container.
   */
  cornerType: PropTypes.oneOf(["all", "top", "bottom", "none"]).isRequired,
  /**
   * Add dropshadow to the container.
   */
  dropShadow: PropTypes.bool,
};

Aside.defaultProps = {
  cornerType: "none"
}