import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Button } from "@storybook/stories/Button/Button";
import { Modal } from "@storybook/stories/Modal/Modal";
import { updateProgram } from "../../api/intendedProgram";
import { enrollmentPrograms } from "../../utils/helpers/data";

import { CalbrightLogo } from "../../assets";

import "./modalintprog.css";

const PROGRAMS_URL = "https://www.calbright.edu/programs/";

const ModalIntProg = () => {
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);
  const [program, setProgram] = useState("");

  const handleSelectChange = (event) => {
    setProgram(event.target.value);
  };

  const submitProgram = async () => {
    setError(null);
    const response = await updateProgram(token, program);

    if (response.user_updated) {
      document.querySelector('.storybook-modal').remove();
      return location.reload();
    }

    setError(response?.message ?? response.error.message);
  }

  return (
    <Modal
      icon={{
        name: "",
        size: "300px",
        isImage: true,
        imgUrl: CalbrightLogo
      }}
      showCloseIcon={false}
      confirmText={null}
      openDialog={true}
      title="Welcome!"
      size="large"
      extraClass="modal-intended-program"
    >
      <div className="flex flex-col">
        <p>
          Go to {""}
          <a href={PROGRAMS_URL} target="_blank">
            Programs Page
          </a>
          {""} to view all the programs offered by Calbright College.
        </p>
        <label>Programs</label>
        <div className="select-program">
          <select value={program}
            onChange={handleSelectChange}
          >
            <option value="">Select a program</option>
            {enrollmentPrograms.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          {error &&
           (<p className="error">{error}</p>)
          }
        </div>
        <Button
          label="Continue to onboarding"
          disabled={!program}
          isLoading={false}
          onClick={() => submitProgram()}
        />
        <p>
          If you have any questions, please email us at &nbsp;
          <a href="mailto:success@calbright.org">
            success@calbright.org
          </a>.
        </p>
      </div>
    </Modal>
  )
}

export default ModalIntProg;