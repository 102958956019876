import { hotjar } from 'react-hotjar';

if (process.env.HOTJAR_ID) {
  hotjar.initialize({
    id: process.env.HOTJAR_ID,
    sv: 6
  });
}

export const identifyHotjarStudent = ({
  cccId,
  currentProgram,
  learnerStatus
}) => {
  if (!hotjar.initialized()) return;

  hotjar.identify(cccId, {
    "intended_program": currentProgram,
    "learner_status": learnerStatus
  });
}