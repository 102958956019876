import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Wellness } from "@storybook/stories/Wellness/Wellness";
import { CareerSidebar } from "@storybook/stories/CareerSidebar/CareerSidebar";
import { Accordion } from "@storybook/stories/Accordion/Accordion";
import { learnerStatusLookup } from "../../utils/helpers/data";
import { Skeleton } from "@mui/material";
import { fetchStaticCareerServices } from "../../redux/actions/staticActions";
import { handleError } from "../../api/apiUtils";

import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";
import { NavLink } from "react-router-dom";

import "./career-services.css";

const SPP = learnerStatusLookup["STARTED_PROGRAM_PATHWAY"];
const CPP = learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"];
const SSO = "https://shibboleth-calbright-csm.symplicity.com/sso/";

export const CareerServices = ({ user }) => {
  const dispatch = useDispatch();
  const careerServices = useSelector(
    (state) => state.staticContent?.careerServices
  );
  const enrolled = user.learnerStatus === SPP || user.learnerStatus === CPP;
  const ssoURL = enrolled ? SSO : "/";
  const calendlyParams = `?full_name=${user.firstName} ${user.lastName}
    &email=${user.email}`;
  const [staticData, setStaticData] = useState(null);
  const [processedStaticData, setProcessedStaticData] = useState(null);

  const [loading, setLoading] = useState(true);

  /**
   * Converts html string to React node and changes internal links to NavLinks.
   * @param {string} htmlString
   * @returns
   */
  const convertContentToReactNode = (htmlString) => {
    if (!htmlString) return null;

    const sanitizedHtml = DOMPurify.sanitize(htmlString);

    const options = {
      replace: (domNode) => {
        if (domNode.type === "tag" && domNode.name === "a") {
          const href = domNode.attribs?.href;

          if (href?.startsWith("/")) {
            return (
              <NavLink to={href} className={domNode.attribs?.class || ""}>
                {domToReact(domNode.children, options)}
              </NavLink>
            );
          }

          return (
            <a
              href={href}
              className={domNode.attribs?.class || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {domToReact(domNode.children, options)}
            </a>
          );
        }
      },
    };

    return parse(sanitizedHtml, options);
  };

  useEffect(() => {
    const convertFaqs = (data) => {
      return data.faqs.map((faq) => ({
        ...faq,
        content: convertContentToReactNode(faq.content),
      }));
    };
    const updateImage = (data) => {
      return data.careerPrep.map((item) => ({
        ...item,
        image: process.env.STATIC_S3_URL + item.image,
      }));
    };

    const fetchData = async () => {
      try {
        setLoading(true);

        if (careerServices) {
          const convertedFaqs = convertFaqs(careerServices);
          const updatedCareerPrep = updateImage(careerServices);

          setStaticData(careerServices);
          setProcessedStaticData({
            ...careerServices,
            faqs: convertedFaqs,
            careerPrep: updatedCareerPrep,
          });
          return;
        }

        const staticResponse = await dispatch(
          fetchStaticCareerServices("/common/career-services.json")
        );

        if (isValidData(staticResponse)) {
          const convertedFaqs = convertFaqs(careerServices);
          const updatedCareerPrep = updateImage(staticResponse);

          setStaticData(staticResponse);
          setProcessedStaticData({
            ...staticResponse,
            faq: convertedFaqs,
            careerPrep: updatedCareerPrep,
          });
        } else {
          console.error("Invalid or missing data");
          handleError(new Error("Invalid or missing data"));
        }
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    const isValidData = (data) => {
      return (
        data &&
        data.title &&
        data.subTitle &&
        data.careerPrep &&
        data.cta &&
        data.faqs
      );
    };

    fetchData();
  }, [dispatch, careerServices]);

  if (loading) {
    return (
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1.5rem",
          width: "calc(800px - 40vw)",
          maxWidth: "100%",
          margin: "10px",
        }}
      />
    );
  }

  if (!staticData) {
    return <div>No data available</div>;
  }

  return (
    <article className="career-services">
      <PortalHeader user={user} activeLink="services" />
      <main>
        <div className="container">
          <Wellness
            items={processedStaticData?.careerPrep}
            title={staticData?.title}
            subtitle={staticData?.subTitle}
            enrolled={enrolled}
            cardTitle={staticData?.cta.cardTitle}
            cardContent={staticData?.cta.body}
            notEnrCardContent={staticData?.cta.bodyNotEnrolled}
            btnLabel={staticData?.cta.btnLabel}
            btnSubmit={() => window.open(ssoURL, "_blank").focus()}
          />
          <div className="careers-row">
            <CareerSidebar
              title={staticData?.workshops.title}
              subtitle={staticData?.workshops.subTitle}
              workshops={staticData?.workshops.list}
              enrolled={enrolled}
              link={`https://calendly.com/career-service${calendlyParams}`}
            />
            <Accordion
              items={processedStaticData?.faqs}
              title="Frequently Asked Questions"
            />
          </div>
        </div>
      </main>
    </article>
  );
};

CareerServices.propTypes = {
  user: PropTypes.shape({}),
};

export default CareerServices;
