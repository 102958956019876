import React from "react";

export const TopicOfTheMonthData = [
  {
    year: "2024",
    month: "October",
    subtitle: "Breast Cancer Awareness",
    description:
      "October has been designated as Breast Cancer Awareness Month to focus attention on the impact of breast cancer and the importance of early detection.",
    content: (
      <>
        <p>
          This annual campaign, which began in 1985, aims to increase awareness,
          promote screening, and support those affected by the disease.
          Education plays a crucial role in protecting ourselves and others, as
          it empowers individuals to recognize potential symptoms, understand
          risk factors, and adopt preventive measures. Learn how to join your
          local community to raise awareness this month by clicking the link
          identified below:
        </p>
        <h3>
          <a
            href="https://www.norcalthinkpink.org/about-us/calendar/"
            target="_blank"
            rel="noreferrer"
          >
            Nor-Cal Think
          </a>
        </h3>
        <p>
          Pink Nor-Cal Think Pink aims to boost awareness of breast cancer, and
          enhance early detection through community collaboration.
        </p>
        <h3>
          <a
            href="https://www.komen.org/community/california/"
            target="_blank"
            rel="noreferrer"
          >
            Susan G. Koman: California Resources and Events
          </a>
        </h3>
        <p>
          Susan G. Koman’s mission is to save lives by meeting the most critical
          needs of our communities and investing in breakthrough research to
          prevent and cure breast cancer.
        </p>
        <h3>
          <a
            href="https://www.nationalbreastcancer.org/about-breast-cancer/early-detection/breast-cancer-resources/"
            target="_blank"
            rel="noreferrer"
          >
            National Breast Cancer Awareness Foundation
          </a>
        </h3>
        <p>
          Find free educational resources, including education guides, financial
          assistance, and early detection programs.
        </p>
      </>
    ),
  },
  {
    year: "2024",
    month: "November",
    subtitle: "National Native American Heritage Month",
    description:
      "This November, our Calbright College proudly recognizes National Native American Heritage Month, a time to celebrate and honor the rich history, diverse cultures, and significant contributions of Indigenous peoples in the United States. ",
    content: (
      <>
        <p>
          Established officially in 1990 but with roots dating back to 1916,
          this month-long observance offers us a valuable opportunity to deepen
          our understanding of Native Americans, Alaska Natives, Native
          Hawaiians, and affiliated Island communities through celebrating
          Indigenous authors and artists, championing cultural preservation
          efforts, and advocacy for important social and political issues
          affecting Indigenous communities. We encourage everyone to participate
          by learning about Native food sovereignty, supporting language
          preservation programs, engaging with Native-led initiatives, and
          reflecting on how we can better represent Indigenous stories and
          perspectives in our curricula and institutional practices. This month
          also provides a special moment to honor Native American veterans on
          Veterans Day, November 11th. By actively participating in National
          Native American Heritage Month, we contribute to a more inclusive and
          culturally aware community that recognizes and values the ongoing
          contributions of Indigenous peoples to our society and throughout
          higher education.
        </p>
        <h3>
          <a
            href="https://www.si.edu/events/native-american-heritage-month"
            target="_blank"
            rel="noreferrer"
          >
            Smithsonian Institution
          </a>
        </h3>
        <p>
          Visit the Smithsonian’s website to celebrate National Native American
          Heritage month with events, resources, exhibitions, and podcast
          episodes.
        </p>
        <h3>
          <a
            href="https://www.ala.org/aasl/advocacy/promo/NativeAmerican"
            target="_blank"
            rel="noreferrer"
          >
            American Association of School Libraries
          </a>
        </h3>
        <p>
          Visit the ALA to join online workshops and a variety of learning
          experiences, including exhibits, art, literature and other Indigenous
          collections, curated by the Library of Congress.
        </p>
        <h3>
          <a
            href="https://nahc.ca.gov/2024/05/save-the-date-57th-annual-native-american-day/"
            target="_blank"
            rel="noreferrer"
          >
            The State of California Native American Heritage Foundation
          </a>
        </h3>
        <p>
          Visit The California Native American Heritage Commission (NAHC or
          Commission), whose mission is to identify, catalog, and protect Native
          American cultural resources, among many other significant works to
          advocate for our Native communities.
        </p>
        <h3>
          <a
            href="https://www.nps.gov/subjects/npscelebrates/native-american-heritage-month.htm"
            target="_blank"
            rel="noreferrer"
          >
            National Parks Services
          </a>
        </h3>
        <p>
          Join the National Park Service this november to celebrate the rich
          traditions, languages, and contributions of Indigenous people. The NPS
          preserves cultural resources, such as buildings, landscapes,
          archeological sites, and museum collections in counties across
          California. Find a park and celebrate locally.
        </p>
      </>
    ),
  },
  {
    year: "2024",
    month: "December",
    subtitle: "Universal Human Rights Month",
    description:
      "This December, we encourage you to take a deeper dive with us, as we celebrate International Human Rights Day, recognized on December 10th of every year.",
    content: (
      <>
        <p>
          At Calbright, we honor human rights and advocate for them all year
          round. This December, we encourage you to take a deeper dive with us,
          as we celebrate International Human Rights Day, recognized on December
          10th of every year. This day is significant because it marks the
          adoption of the Universal Declaration of Human Rights (UDHR) by the
          United Nations General Assembly in 1948. The UDHR is a document that
          outlines the fundamental rights and freedoms that all people are
          entitled to, regardless of their race, color, religion, sex, language,
          or other status. On Human Rights Day, people from different
          backgrounds and cultures come together to reflect on the progress made
          in advancing human rights and to reaffirm their commitment to a common
          humanity.
        </p>
        <h3>Take Time to Celebrate Human Rights All Month:</h3>
        <p>
          Donate to or get involved with your favorite Human Rights charity
          like:
        </p>
        <p>
          <a
            href="https://www.amnesty.org/en/#:~:text=Amnesty%20International%20is%20a%20global,end%20abuses%20of%20human%20rights"
            target="_blank"
            rel="noreferrer"
          >
            Amnesty International
          </a>
          <br />
          <a href="https://www.hrc.org/" target="_blank" rel="noreferrer">
            Human Rights Campaign: HRC
          </a>{" "}
          <br />
          <a href="https://naacp.org/" target="_blank" rel="noreferrer">
            NAACP
          </a>{" "}
          <br />
          <a
            href="https://www.unwomen.org/en/what-we-do/humanitarian-action"
            target="_blank"
            rel="noreferrer"
          >
            UN Women
          </a>
          , and hundreds more.
        </p>
        <h3>Attend a human rights event.</h3>
        <p>
          <a
            href="https://www.un.org/en/academic-impact/2024-calendar-selected-united-nations-events"
            target="_blank"
            rel="noreferrer"
          >
            Check out the UN’s Global Events Calendar
          </a>
          <br />
          <a
            href="https://promiseinstitute.law.ucla.edu/"
            target="_blank"
            rel="noreferrer"
          >
            Check out UCLA’s The Promise Institute for Human Rights{" "}
          </a>
          <br />
          <a
            href="https://humanrights.stanford.edu/"
            target="_blank"
            rel="noreferrer"
          >
            Visit Stanford’s The Center for Human Rights and International
            Justice
          </a>
          <br />
          <a
            href="https://calhum.org/events/month/2024-12/?hide_subsequent_recurrences=1"
            target="_blank"
            rel="noreferrer"
          >
            Attend a free event at a local museum
          </a>
        </p>
        <h3>Show your support for human rights</h3>
        <p>
          <a
            href="https://www.npr.org/2023/01/23/1150457928/amazon-smiles-ethical-shopping-alternatives"
            target="_blank"
            rel="noreferrer"
          >
            Choose fair trade local, & ethically made gifts
          </a>
          <br />
          <a
            href="https://www.learningforjustice.org/classroom-resources/lessons/standing-up-against-discrimination"
            target="_blank"
            rel="noreferrer"
          >
            Stand up against discrimination
          </a>
          <br />
          <a
            href="https://www.hrw.org/news/2022/12/08/inspiring-stories-human-rights-day-2022"
            target="_blank"
            rel="noreferrer"
          >
            Listen to others' stories and fights for Human Rights
          </a>
          <br />
          <a
            href="https://www.humanrightscareers.com/courses/"
            target="_blank"
            rel="noreferrer"
          >
            Do your research and learn about the issues
          </a>
          <br />
        </p>
      </>
    ),
  },
];
