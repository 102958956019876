import React from "react";
import PropTypes from "prop-types";

import { FEATURE_FLAGS, ENV_FLAGS } from "../../utils/helpers/feature-flags";

/**
 * Component that renders children if the feature flag is enabled.
 * @param {Object} props
 * @param {FlagName} props.flagName
 * @param {Boolean} props.showIfEnvEnabled
 * @param {Boolean} props.showIfEnvDisabled
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode|null}
 */
export const FeatureFlag = ({
  flagName,
  showIfEnvEnabled,
  showIfEnvDisabled,
  children,
}) => {
  const isFlagEnabled = FEATURE_FLAGS[flagName];

  const isStageFeatureEnabled = process.env.STAGE_FEATURE_ENABLED === "true";
  const isStageFeatureEnabledMatch = showIfEnvEnabled === isStageFeatureEnabled;

  const shouldRender = isFlagEnabled && isStageFeatureEnabledMatch;

  const isStageFeatureDisabled = !isStageFeatureEnabled && showIfEnvDisabled;

  if (isFlagEnabled && isStageFeatureEnabledMatch) return children;

  if (isStageFeatureDisabled) return children;

  return shouldRender ? children : null;
};

FeatureFlag.propTypes = {
  flagName: PropTypes.oneOf(Object.keys(FEATURE_FLAGS)),
  showIfEnvEnabled: PropTypes.bool,
  showIfEnvDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FeatureFlag.defaultProps = {
  flagName: null,
  showIfEnvDisabled: false,
};
