import CatherineParker from "../stories/assets/myteam/catherine-parker.svg";
import ElliotFu from "../stories/assets/myteam/elliot-fu.svg";
import LailaJansen from "../stories/assets/myteam/laila-jansen.svg";
import EdisonCameron from "../stories/assets/myteam/edison-cameron.svg";
import PadminiJohannes from "../stories/assets/myteam/padmini-johannes.svg";
import BrittAllen from "../stories/assets/myteam/britt-allen.svg";
import JoachimNik from "../stories/assets/myteam/joachim-nik.svg";
import AntoinetteMagee from "../stories/assets/myteam/antoinette-magee.png";

export const menuData = [
  {
    id: "home",
    label: "Home",
    url: "/",
  },
  {
    id: "my-team",
    label: "My Team",
    url: "/my-team",
  },
  {
    id: "services",
    label: "Services",
    dropdownItems: [
      {
        id: "career-connect",
        label: "CareerConnect",
        url: "/career-services/",
      },
      {
        id: "wellness-services",
        label: "Wellness Services",
        url: "/wellness-services",
      },
      {
        id: "accessibility-services",
        label: "Accessibility Services",
        url: "/accessibility-services",
      },
      {
        id: "academic-support",
        label: "Tutoring and Learning Center",
        url: "/learning-center",
      },
      {
        id: "library-services",
        label: "Library Services",
        url: "/library-services",
      },
    ],
  },
  {
    id: "resources",
    label: "Resources",
    url: "/resources",
  },
];

export const EPP_MSG = "Will activate after Enrollment Agreement is signed";
export const SPP_MSG = "Will activate after first assignment is completed";

export const profileData = [
  {
    id: "my-profile",
    label: "My Profile",
    url: "/profile",
  },
  {
    id: "sign-out",
    label: "Sign Out",
    url: "/sign-out",
  },
];

/**
 * Array of strings used to identify top level menu items.
 * NOTE: Function pulling this same data doesn't work in Storybook.
 */
export const menuIds = [
  "home",
  "my-team",
  "services",
  "resources",
  "help",
  "profile",
];

/**
 * Learner Status used in determining steps in Dashboard task progress.
 * Some of the steps currently used are as follows. Map to the appropriate step in the Dashboard.
 * [ "enrollment-agreement", "schedule-kickoff", "kickoff", "onboarding", "enrolled", "graduated" ]
 */
export const learnerStatus = [
  "Expressed Interest",
  "App Started",
  "App Submitted",
  "Started Orientation",
  "Completed Orientation",
  "Completed CSEP",
  "Enrolled in Program Pathway",
  "Started Program Pathway",
  "Completed Program Pathway",
];

/**
 * List of instructors used for demo on the MyTeam page.
 */
export const Instructors = [
  {
    name: "Catherine Parker",
    course: "Workforce",
    courseNumber: "WF 500",
    email: "catherine.parker@calbright.org",
    slack: "@catherineparker",
    calendlyUrl: "https://calendly.com/catherineparker",
    phone: "555-555-5555",
    officeHours: "Mon, Wed, Fri",
    image: CatherineParker,
  },
  {
    name: "Elliot Fu",
    course: "Intro to IT",
    courseNumber: "IT 101",
    email: "elliot.fu@calbright.org",
    slack: "@elliotfu",
    calendlyUrl: "https://calendly.com/elliotfu",
    phone: "555-555-5555",
    officeHours: "Tue, Thu, Sat",
    image: ElliotFu,
  },
  {
    name: "Laila Jansen",
    course: "Intro to IT",
    courseNumber: "IT 500",
    email: "laila.jansen@calbright.org",
    slack: "@laila.jansen",
    calendlyUrl: "https://calendly.com/laila.jansen",
    phone: "555-555-5555",
    officeHours: "Mon, Wed, Fri",
    image: LailaJansen,
  },
  {
    name: "Edison Cameron",
    course: "Intro to IT",
    courseNumber: "IT 101",
    email: "edison.carmeron@calbright.org",
    slack: "@elliotfu",
    calendlyUrl: "https://calendly.com/edison.carmeron",
    phone: "555-555-5555",
    officeHours: "Tue, Thu, Sat",
    image: EdisonCameron,
  },
  {
    name: "Padmini Johannes",
    course: "Intro to IT",
    courseNumber: "IT 120",
    email: "padmini.johannes@calbright.org",
    slack: "@padmini.johannes",
    calendlyUrl: "https://calendly.com/padmini.johannes",
    phone: "555-555-5555",
    officeHours: "Mon, Wed, Fri",
    image: PadminiJohannes,
  },
  {
    name: "Britt Allen",
    course: "Workforce",
    courseNumber: "WF 101",
    email: "britt.allen@calbright.org",
    slack: "@britt.allen",
    calendlyUrl: "https://calendly.com/britt.allen",
    phone: "555-555-5555",
    officeHours: "Tue, Thu, Sat",
    image: BrittAllen,
  },
  {
    name: "Joachim Nik",
    course: "Workforce",
    courseNumber: "WF 251",
    email: "joachim.nik@calbright.org",
    slack: "@joachim.nik",
    calendlyUrl: "https://calendly.com/joachim.nik",
    phone: "555-555-5555",
    officeHours: "Tue, Thu, Sat",
    image: JoachimNik,
  },
];

/**
 * Counselor information used for demo on the MyTeam page.
 */
export const Counselor = {
  name: "Antoinette Magee",
  title: "Academic Success Counselor",
  memberType: "Counselor",
  email: "antoinette.magee@calbright.org",
  slack: "@antoinettemagee",
  calendlyUrl: "https://calendly.com/antoinettemagee",
  phone: "555-555-5555",
  image: AntoinetteMagee,
};
