import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../Button/Button';

import './tooltip.css'; 

export const Tooltip = ({ text, children, position }) => (
  <div 
    className="storybook-tooltip-container" 
    data-testid="tooltipContainer"
  >
    {children}
    <span className={["storybook-tooltip-text", `storybook-tooltip-text--${position}`].join(" ")} data-testid="tooltipText">{text}</span>
  </div>
);

Tooltip.propTypes = {
  /**
   * Text to include in the tooltip.
   */
  text: PropTypes.string.isRequired,
  /**
   * Element or node to apply the tooltip to.
   */
  children: PropTypes.node.isRequired,
  /**
   * Where to position the tooltip.
   */
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]).isRequired,
};

Tooltip.defaultProps = {
  text: "Demo tooltip text.",
  children: <Button buttonType="primary" label="Demo Tooltip Target" aria-label="Demo Tooltip target" />,
  position: "bottom",
};