import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import * as signoutActions from "../../redux/actions/signoutActions";

const SignOutPage = ({ signOut, user }) => {
  useEffect(() => {
    signOut();
  }, []);
  // remove localstorage items firstSAAViewed, schedul-kickoff, schedule-onboarding, previousUrl, userData
  localStorage.removeItem("schedule-kickoff");
  localStorage.removeItem("schedule-onboarding");
  localStorage.removeItem("previousUrl");
  localStorage.removeItem("userData");
  localStorage.removeItem("ProfileDismissedAlert");

  removeBannersForUser(user?.cccId);

  setTimeout(() => window.location.replace(process.env.LOGOUT_URI), 500);

  return <img src="https://accounts.google.com/logout" width="1" height="1" />;
};

SignOutPage.propTypes = {
  signOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispathToProps = {
  signOut: signoutActions.signOut,
};

export default connect(mapStateToProps, mapDispathToProps)(SignOutPage);

/**
 * Removes dismissed banners for a user from localStorage.
 * @param {string} cccId
 * @returns null
 */
const removeBannersForUser = (cccId) => {
  const banners = localStorage.getItem("dismissedBroadcastMsgs");

  if (!banners) {
    return;
  }

  try {
    const parsedBanners = JSON.parse(banners);

    if (typeof parsedBanners !== "object" || parsedBanners === null) {
      return;
    }

    if (!parsedBanners.hasOwnProperty(cccId)) {
      return;
    }

    delete parsedBanners[cccId];

    localStorage.setItem(
      "dismissedBroadcastMsgs",
      JSON.stringify(parsedBanners)
    );
  } catch (error) {
    console.error("Error processing banners", error);
  }
};
