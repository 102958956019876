/**
 * Handles API responses
 * @param {*} response
 */
export async function handleResponse(response) {
  if (response.status === 200) {
    return response.data;
  }
  const error = await response.statusText;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    throw new Error("Server error: ", error);
  }

  throw new Error("Network response was not ok.", error);
}

/**
 * Handle errors that occur during API calls.
 * @param {*} error
 */
export function handleError(error) {
  if (error === "No token in request") return; // This error is handled by ErrorPage
  let message = "Something went wrong. Please try again later.";

  if (error.response) {
    switch (error.response.status) {
      case 404:
        message = "The requested resource could not be found.";
        break;
      case 500:
        message =
          "A server error occurred. Please contact support if the problem persists.";
        break;
      case 403:
        message = "You do not have permission to access this resource.";
        break;
    }
  }

  return { error: { ...error, message } };
}
