import React from 'react';

import { SkeletonDashboard } from "@storybook/stories/Skeleton/SkeletonDashboard";
import ModalIntProg from './../ModalIntProg/ModalIntProg';
import ErrorPage from "../../pages/ErrorPage/ErrorPage";

const UserDashboardHandler = ({ user }) => {
  const dashboardError = user?.error;

  if (dashboardError) {
    return (
      <>
        <SkeletonDashboard />
        <ErrorPage error={dashboardError} />
      </>
    );
  }

  if (!user?.currentProgram) {
    return (
      <>
        <SkeletonDashboard />
        <ModalIntProg />
      </>
    );
  }

  return null;
};

export default UserDashboardHandler;