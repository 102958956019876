import axiosInstance from "../api/axios";
import { handleResponse, handleError } from "./apiUtils";

/**
 * Sends a post request to /withdraw endpoint to withdraw student from current program.
 * @param { string } token
 * @param { string } reason
 * @param { string } subReason
 * @param { string } comments
 * @param { bool } confirmed
 * @returns { array }
 */
export function withdraw(
  token,
  reason,
  subReason,
  comments,
  confirmed
) {
  return axiosInstance
    .post(
      "/program/drop-withdraw",
      {
        reason,
        subReason,
        comments,
        confirmed
      },
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}