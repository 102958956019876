import * as types from "./actionTypes";
import { beginApiCall } from "./apiStatusActions.js";

export function signOutSuccess() {
  return { type: types.SIGNOUT_SUCCESS };
}

export function signOut() {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(signOutSuccess());
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}
