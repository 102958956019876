import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "../api/axios";

/**
 * Retrieve broadcastMessages from /broadcast_messages endpoint.
 * @param { string } token
 * @returns {Array} banners[]
 */
export function getBroadcastMsgs(token) {
  return axiosInstance
    .get("/banners", {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then(handleResponse)
    .then((data) => {
      return data.banners;
    })
    .catch(handleError);
}
