import React from 'react';

import './skeleton.css';

export const Skeleton = ({ classes }) => {
  const classNames = `skeleton ${classes}`;
  
  return (
    <div className={classNames}></div>
  );
};
