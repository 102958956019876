import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { TopicOfTheMonthData } from "../../utils/helpers/topic-of-the-month";

export const TopicOfTheMonth = ({ type }) => {
  const [topic, setTopic] = useState(null);
  const getCurrentMonthYear = () => {
    return {
      month: new Date().toLocaleString("default", { month: "long" }),
      year: new Date().getFullYear().toString(),
    };
  };
  const { month: currentMonth, year: currentYear } = getCurrentMonthYear();

  useEffect(() => {
    const foundTopic = TopicOfTheMonthData.find(
      (topic) =>
        topic.year === currentYear.toString() && topic.month === currentMonth
    );

    if (foundTopic) {
      const { month, subtitle, description, content } = foundTopic;
      setTopic({ month, subtitle, description, content });
    }
  }, [currentMonth, currentYear]);

  if (!topic) {
    return <p>No topic for this month.</p>;
  }

  return type === "description" ? (
    <>{topic.description}</>
  ) : (
    <>
      <h2>
        {topic.month}: {topic.subtitle}
      </h2>
      {topic.content}
    </>
  );
};

TopicOfTheMonth.propTypes = {
  type: PropTypes.oneOf(["description", "content"]),
};
