import { SET_DEVICES_SUCCESS } from "../actions/actionTypes";

import initialState from "./initialState";

export function devicesReducer(
  state = initialState.devices,
  action
) {
  switch (action.type) {
    case SET_DEVICES_SUCCESS:
      return action.devices;
    default:
      return state;
  }
}
