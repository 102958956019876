import { SET_DEVICES_SUCCESS } from "./actionTypes";
import { getDevices } from "../../api/devicesApi.js";
import { handleError } from "../../api/apiUtils";
import { beginApiCall } from "./apiStatusActions.js";

export function fetchDevicesSuccess(devices) {
  return { type: SET_DEVICES_SUCCESS, devices };
}

export function fetchDevices(token) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const { devices } = await getDevices(token);
      dispatch(fetchDevicesSuccess(devices));
      return devices;
    } catch (error) {
      dispatch(apiCallError(error));
      dispatch(handleError(error));
      throw error;
    }
  };
}