import React from "react";

import {
  AccessibilityAbout,
  AccessibilityRegister,
  AccessibilityFAQ,
  AccessibilityStudentRights,
  AccessibilityStudentResources,
  MeetOurTeam
} from "../../assets";

import { Accordion } from "@storybook/stories/Accordion/Accordion";

const SurveyURL = "https://calbright-accommodate.symplicity.com/surveys/pre_enrollment_request";

export const CardContent = `My Accommodate prioritizes accessibility
    for students and fosters an inclusive educational environment
    by offering resources and tailored accommodations for students.
    It provides a simple, convenient platform where students can set up
    appointments with Accessibility Services, request accommodations for
    coursework, and easily track and manage their requests, ensuring
    equal access to learning opportunities for all.
  `;

export const NotEnrCardContent = `
    To request Accessibility Services, Please fill out the
    <a href="${SurveyURL}" target="_blank">Reasonable Accommodation Request</a> Form.
  `;

const AccessibilityFAQData = [
  {
    title:
      "1. Am I automatically eligible for accessibility services or do I have to apply?",
    content:
      <>
        Accessibility services are offered on a voluntary basis. You have
        the option to apply for and request these services; they are not
        provided automatically.
      </>,
  },
  {
    title: "2. When is the best time to apply for services?",
    content: (
      <>
        As soon as you enroll in a program at Calbright. However, if you require
        reasonable accommodations to participate in the Calbright onboarding
        session, please submit this<br/> {""}
        <a href="https://calbright-accommodate.symplicity.com/surveys/pre_enrollment_request" target="_blank">
          Pre-enrollment Accommodation Request Form
        </a>. We’ll coordinate necessary accommodations to facilitate your enrollment
        in a program pathway. Once enrolled, you’ll need to register for services
        by submitting the Accessibility Services Intake application, and providing
        disability verification documentation to request continued support and services.
      </>
    ),
  },
  {
    title: "3. Are services confidential?",
    content:
      <>
        All disability-related information, including documentation,
        accommodation letters, correspondence and consultations are
        considered confidential and managed in accordance with The Family
        Educational Rights and Privacy Act (FERPA) regulations for the
        purpose of administering services.
      </>,
  },
  {
    title:
      "4. What if I am not sure if my disability / medical condition would qualify me for services?",
    content:
      <>
        Talk to us about it, and we can help you determine if you qualify for
        services. Let us advise you since we may know of support and
        resources that can help you with your academic needs.
      </>,
  },
  {
    title:
      "5. Calbright’s competency-based / asynchronous model may address some of my disability related needs. Should I still apply?",
    content:
      <>
        Yes, students will need accommodations for third-party exams.
        Accessibility services provide a variety of support, including
        services, referrals, and advocacy, to help meet your needs both
        at Calbright and beyond.
      </>,
  },
  {
    title:
      "6. How do I request accommodations for my industry certification exams?",
    content: (
      <>
        We understand that requesting accommodations for your industry
        certification exam can feel overwhelming. While it is your
        responsibility to reach out to the third-party provider for
        these accommodations, please know that we are here to
        support you throughout the process. Calbright can offer guidance
        and information to help you navigate this, even though we are not
        able to arrange the accommodations directly. You're not alone
        in this journey.
      </>
    ),
  },
  {
    title:
      "7. Is there a form that my medical/ disability services provider can complete to serve as disability verification?",
    content:
      <>
         ​​If you do not have disability documentation but are working with a
         provider who could provide verification, ask them to complete this {""}
        <a href="https://drive.google.com/file/d/1-aW4XmCS8OLxIEb1dKeN-MaZmYThKyQy/view?usp=sharing"
          target="_blank"
        >
          disability verification form
        </a> for you to submit to us.
      </>,

  },
];

export const AccessibilityServicesData = [
  {
    id: 1,
    title: "Student Rights and Responsibilities",
    description: "We encourage all students with disabilities to review their Rights and Responsibilities.",
    image: AccessibilityStudentRights,
    content: (
      <div className="storybook-learning-content">
        <h3>Student Rights:</h3>
        <ul className="pl-15 mt-0">
          <li className="pl-10 pb-15">
            Participation in Accessibility Services is entirely voluntary and,
            as such, it is the student’s responsibility to identify as an
            individual with a disability, to request accommodations and/or
            support services, and to seek information, counsel, or assistance
            as desired.
          </li>
          <li className="pl-10 pb-15">
            Receiving Accessibility Services will not preclude a student from
            also participating in any other course, program, or activity
            offered by Calbright College.
          </li>
          <li className="pl-10 pb-15">
            All records maintained by Accessibility Services pertaining to
            students with disabilities will be protected from disclosure and
            will be handled in the same manner as required for all other
            student records.
          </li>
          <li className="pl-10 pb-15">
            Students have the right to file a grievance if they feel they have
            been discriminated against, by submitting the {""}
            <a href="https://www.calbright.edu/report-a-concern/" target="_blank">
              Report a Concern form
            </a> on the Calbright webpage. We encourage students to first discuss
            their concerns with their Accessibility Services Counselor or
            the Manager for Accessibility Services.
          </li>
        </ul>
        <p>
          NOTE: Authorities cited: Title 5 C.C.R Section 56000 et seq.
          Education Code sections 66701, 67301-37312, 70901, 84850.
        </p>
        <h3>Student Responsibilities:</h3>
        <p>By requesting accessibility services, students are agreeing to the following:</p>
        <ul className="pl-15 mt-0">
          <li className="pl-10 pb-15">
            To be eligible for academic adjustments, auxiliary aids, services
            and/or instruction provided through the Accessibility Services
            program, a student must have a disability that is verified by
            Accessibility Services. Students are responsible for providing
            the information/documentation necessary for verification.
            A students educational limitations must be identified by
            certificated staff and eligibility for each service provided
            must be directly related to an educational limitation.
          </li>
          <li className="pl-10 pb-15">
            A student receiving Accessibility Services must comply with the
            Student Code of Conduct adopted by Calbright College and all other
            applicable statutes and regulations related to student conduct.
          </li>
          <li className="pl-10 pb-15">
            Request reasonable accommodations, academic adjustments and
            auxiliary aides in accordance with Accessibility Services
            guidelines and timelines, and use any issued services, licenses,
            loaned equipment, and/or technology responsibly.
          </li>
          <li className="pl-10 pb-15">
            Notify Accessibility Services of any absence and/or need to cancel
            or reschedule appointments while using interpreters, real-time
            captioners and/or other service providers.
          </li>
          <li className="pl-10 pb-15">
            Enrolled students must meet the academic standards and progress
            established by Calbright College for all students in the student catalog.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 2,
    title: "Student Resources",
    description:
      <>
        Please check these resources that provide important information
        regarding requesting accommodations, legal references and employment.
      </>,
    image: AccessibilityStudentResources,
    content: (
      <div className="storybook-learning-content">
        <h3>Career and Employment Resources</h3>
        <ul>
          <li>
            <a href="https://www.dor.ca.gov/Home/WhatWeDo" target="_blank">
              California Department of Rehabilitation (DOR)
            </a>
          </li>
          <li>
            <a href="https://www.dor.ca.gov/Home/BlindFieldServices" target="_blank">
              DOR: Blind Field Services
            </a>
          </li>
          <li>
            <a href="https://www.nad.org" target="_blank">
              National Federation of the Deaf
            </a>
          </li>
          <li>
            <a href="https://askjan.org" target="_blank">
              Job Accommodation Network
            </a>
          </li>
          <li>
            <a href="https://www.wrp.gov/wrp?id=wrp_about_us" target="_blank">
              Federal recruitment of students with disabilities
            </a>
          </li>
        </ul>
        <h3>
          Requesting Accommodations for Industry Certification Exams
        </h3>
        <p>
          Students needing accommodations for any exam, course, or process
          handled by an organization other than Calbright (referred to as
          a "third-party") must contact the exam provider directly to request
          and arrange these accommodations. It is the student's responsibility
          to make this request. The following resources offer information on
          how to request accommodations for specific Calbright programs:
        </p>
        <ul>
          <li>
            <strong>Data Analysis: </strong>
            You must <a href="https://www.coursera.support/s/article/360036160591-How-to-contact-Coursera?language=en_US"
              target="_blank">contact Coursera</a>
            {""} directly for accommodations.
          </li>
          <li>
            <strong>CRM: </strong>
            <a href="https://trailhead.salesforce.com/help?article=Requesting-Accommodations-for-Proctored-Exams"
              target="_blank">
              Salesforce Trailhead request accommodations
            </a>
          </li>
          <li>
            <strong>IT Support/Networks/Cybersecurity: </strong>
            <a href="https://home.pearsonvue.com/Test-takers/Accommodations.aspx"
              target="_blank">
              visit Pearson VUE test-takers accommodations
            </a>
          </li>
        </ul>
        <h3>Legal References</h3>
        <ol>
          <li>
            <a href="https://www.dol.gov/agencies/oasam/centers-offices/civil-rights-center/statutes/section-504-rehabilitation-act-of-1973"
              target="_blank">
              Section 504 of the Rehabilitation act of 1973
            </a>
          </li>
          <li>
            <a href="https://www.ada.gov/2010_regs.htm"
              target="_blank">
              The Americans with Disabilities Act Amendments Act (ADAAA)
            </a>
          </li>
          <li>
            <a href="https://www.ada.gov/law-and-regs/ada"
              target="_blank">
              Family Educational Rights and Privacy Act (FERPA)
            </a>
          </li>
        </ol>
      </div>
    ),
  },
  {
    id: 3,
    title: "Frequently Asked Questions",
    description: (
      <>
        Please review these FAQs that may answer some of the questions you still
        have. If you need further assistance, please email us at {""}
        <a href="mailto:accessibilityservices@calbright.org">
          accessibilityservices@calbright.org
        </a>
        . We are happy to help.
      </>
    ),
    image: AccessibilityFAQ,
    content: (
      <div className="storybook-learning-content">
        <Accordion items={AccessibilityFAQData} />
      </div>
    ),
  },
];

export const MeetYourTeamData = [
  {
    id: 1,
    name: "Meet our Team Members",
    role: (
      <>
        <strong>Program Contact Information</strong>
      </>
    ),
    department: "Technology and Online Learning",
    phone: "",
    slack: "",
    email: "accessibilityservices@calbright.org",
    photo_url: MeetOurTeam,
  },
  {
    id: 2,
    name: "Carlos Cid",
    role: "Student Support Specialist",
    department: "",
    phone: "",
    slack: "",
    email: "carlos.cid@calbright.org",
    photo_url:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/e00262a1-8e61-4a03-bc5f-36e18a79bb3f.png",
  },
  {
    id: 3,
    name: "Errin Sullivan Arcos",
    role: "Success Counselor",
    department: "Accessibility Services",
    phone: "",
    slack: "",
    email: "Errin.SullivanArcos@calbright.org",
    photo_url:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/2653e14d-c7a4-42c8-a2b6-742ef5be3109.png",
  },
  {
    id: 4,
    name: "Dr. Ava Burns",
    role: "Dean of Student Journey",
    department: "",
    slack: "",
    email: "ava@calbright.org",
    photo_url:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/a732b13f-cdaa-4f4f-b722-25d66249711c.png",
  },
];
