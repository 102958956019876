import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Button } from "@storybook/stories/Button/Button";
import { Checkbox } from "@storybook/stories/Checkbox/Checkbox";
import { Input } from "@storybook/stories/Input/Input";
import { FileUploadInput } from "@storybook/stories/FileUploadInput/FileUploadInput";
import { Modal } from "@storybook/stories/Modal/Modal";
import { formatDate } from "@storybook/utils/helpers.js";

import AddIcon from "@mui/icons-material/AddRounded";
import RemoveIcon from "@mui/icons-material/RemoveRounded";
import { GreenCheck, IconSuccess, IconError } from "../../assets";

import {
  fetchARServices,
  postEnrollmentVerification,
} from "../../redux/actions/arservicesActions";

import "./enrollverificationform.css";

export const EnrollVerificationForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { verificationFormDateSubmitted } = useSelector(
    (state) => state.arservices
  );
  const token = useSelector((state) => state.token);
  const [recipientEmails, setRecipientEmails] = useState([0]);
  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const strictEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  const { isWithinFiveBusinessDays, futureFiveBusinessDays } =
    businessDayCalculator(verificationFormDateSubmitted, "both");

  const modalContentFormSubmission = {
    icon: {
      name: "",
      color: "",
      size: "96px",
      isImage: true,
      imgUrl: IconSuccess,
    },
    title:
      "Thank you for your submission! Your enrollment verification letter or submitted form will be emailed within 2 business days.",
    openDialog: true,
    showCloseIcon: true,
    confirmText: "Close",
    children: "",
    size: "extra-large",
    extraClass: "text-center ev-modal",
  };

  const modalContent5DaySubmission = {
    icon: {
      name: "",
      color: "",
      size: "96px",
      isImage: true,
      imgUrl: IconError,
    },
    title: `You submitted a request for transcript on ${formatDate(
      verificationFormDateSubmitted
    )}, please wait until ${formatDate(
      futureFiveBusinessDays
    )} to submit another request.`,
    children: "",
    openDialog: true,
    showCloseIcon: true,
    confirmText: "Close",
    size: "extra-large",
    extraClass: "text-center ev-modal",
  };

  useEffect(() => {
    if (isWithinFiveBusinessDays && !isFormSubmitted) {
      setModal({
        isOpen: true,
        content: modalContent5DaySubmission,
      });
    }
  }, [verificationFormDateSubmitted]);

  const handleCloseModal = () => {
    setModal({
      isOpen: false,
      content: null,
    });
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setFieldValue("verificationFileUpload", file);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      calbrightEmailCheck: true,
      calbrightEmail: user?.email,
      personalEmailCheck: true,
      personalEmail: user?.personalEmail,
      recipientEmails: [],
      verificationFileUpload: null,
      fileUpload: null,
    },
    validationSchema: Yup.object({
      recipientEmails: Yup.array().of(
        Yup.string()
          .trim()
          .matches(strictEmailRegex, "Invalid email address.")
          .test("required", "Email is required", function (value) {
            if (!value) return true;
            return Yup.string().email().isValidSync(value);
          })
          .test(
            "unique",
            "Duplicate email addresses are not allowed.",
            function (value) {
              if (!value) return true;
              return (
                values.recipientEmails.filter((email) => email === value)
                  .length <= 1
              );
            }
          )
          .test(
            "not-calbright-or-personal",
            "Cannot use Calbright or personal email.",
            function (value) {
              if (!value) return true;
              const { calbrightEmail, personalEmail } = values;
              return value !== calbrightEmail && value !== personalEmail;
            }
          )
      ),
    }),
    onSubmit: async (values, actions) => {
      const formData = new FormData();

      formData.append("calbrightEmail", values.calbrightEmail);
      formData.append("personalEmail", values.personalEmail);
      if (values.recipientEmails.length > 0) {
        formData.append(
          "recipientEmails",
          JSON.stringify(values.recipientEmails)
        );
      }
      if (values.verificationFileUpload) {
        formData.append(
          "verificationFileUpload",
          values.verificationFileUpload,
          values.verificationFileUpload.name
        );
      }

      try {
        await dispatch(postEnrollmentVerification(token, formData)).then(() => {
          dispatch(fetchARServices(token));
        });

        setIsFormSubmitted(true);

        setModal({
          isOpen: true,
          content: modalContentFormSubmission,
        });

        toast.success(
          <>
            <img
              className="green-check"
              src={GreenCheck}
              alt="green check icon"
            />
            Enrollment Verification Letter submitted successfully!
          </>,
          {
            toastId: "avatar-change-success",
            role: "alert",
            position: "top-right",
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            autoClose: 5000,
            delay: 3000,
          }
        );

        actions.resetForm({
          calbrightEmailCheck: true,
          calbrightEmail: user?.email,
          personalEmailCheck: true,
          personalEmail: user?.personalEmail,
          recipientEmails: [],
          verificationFileUpload: null,
          fileUpload: null,
        });
        // Clear file upload label plus trigger FileUploadInput component reset.
        document.querySelector("button.upload-delete").click();
      } catch (error) {
        throw new Error(
          "Enrollment Verification form submission failed",
          error
        );
      }
    },
  });

  const addRecipientEmail = () => {
    if (recipientEmails.length < 5) {
      const newIndex = recipientEmails.length;
      setRecipientEmails([...recipientEmails, newIndex]);
      setFieldValue("recipientEmails", [...values.recipientEmails, ""]);
    }
  };

  const removeRecipientEmail = (index) => {
    const updatedRecipients = recipientEmails.filter((_, i) => i !== index);
    setRecipientEmails(updatedRecipients);

    const updatedEmails = values.recipientEmails.filter((_, i) => i !== index);
    setFieldValue("recipientEmails", updatedEmails);
  };

  const renderError = (message) => <p className="error-message">{message}</p>;

  const handleBack = () => {
    document.querySelector("button.btn-close").click();
  };

  return (
    <section className="enroll-verification" test-id="enrollVerification">
      <ToastContainer className="storybook-profile-toast" />

      <hr />
      <p>
        Requesting an Enrollment Verification Letter: Complete the Enrollment
        Verification Form to receive your letter. Requests are typically
        processed within 2 business days.
      </p>
      <form onSubmit={handleSubmit} autoComplete="off">
        <section className="field-group" data-testid="calbrightEmailContainer">
          <Checkbox
            id="calbrightEmailCheck"
            label="Use Calbright Email"
            showLabel={false}
            name="calbrightEmailCheck"
            checked={values.calbrightEmailCheck}
            onChange={handleChange}
            readOnly
          />
          <Input
            placeholder="Calbright Email"
            showLabel={false}
            label="calbrightEmail"
            id="calbrightEmail"
            name="calbrightEmail"
            defaultValue={user?.email}
            type="email"
            onChange={handleChange}
            readOnly
          />
        </section>
        <section className="field-group" data-testid="personalEmailContainer">
          <Checkbox
            id="personalEmailCheck"
            label="Send to your personal email"
            showLabel={false}
            name="personalEmailCheck"
            checked={values.personalEmailCheck}
            onChange={handleChange}
            readOnly
          />
          <Input
            id="personalEmail"
            name="personalEmail"
            label="personal email"
            showLabel={false}
            placeholder="Personal email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={user?.personalEmail}
          />
        </section>
        <section className="field-group" data-testid="recipientEmailContainer">
          <label htmlFor="recipientEmail">
            (Optional) If you would like a copy of this letter sent directly to
            a recipient, please enter their email address (you can add up to 5
            email address using the “+” sign).
          </label>
          {recipientEmails.map((_, index) => (
            <div key={index} className="recipient-email-row">
              <Input
                id={`recipientEmails[${index}]`}
                name={`recipientEmails[${index}]`}
                label={`Recipient email ${index + 1}`}
                showLabel={false}
                placeholder="Recipient’s email address"
                defaultValue={values.recipientEmails[index] || ""}
                onChange={(e) => {
                  const newEmails = [...values.recipientEmails];
                  newEmails[index] = e.target.value;
                  setFieldValue("recipientEmails", newEmails);
                }}
                onBlur={handleBlur}
                size="large"
              />
              {index > 0 && index === recipientEmails.length - 1 && (
                <button
                  type="button"
                  onClick={() => removeRecipientEmail(index)}
                  className="remove-email-btn"
                >
                  <RemoveIcon fontSize="large" />
                </button>
              )}
              {recipientEmails.length < 5 &&
                index === recipientEmails.length - 1 && (
                  <button
                    type="button"
                    onClick={addRecipientEmail}
                    className="add-email-btn"
                  >
                    <AddIcon fontSize="large" />
                  </button>
                )}
              {errors?.recipientEmails &&
                errors?.recipientEmails[index] &&
                touched?.recipientEmails &&
                touched?.recipientEmails[index] &&
                renderError(errors?.recipientEmails[index])}
            </div>
          ))}
        </section>
        <section className="field-group" data-testid="fileUploadContainer">
          <label htmlFor="fileUpload">
            (Optional) If you would like us to fill out a verification form from
            a third party agency, then please upload here
          </label>

          <FileUploadInput
            btnLabel="Upload"
            id="verificationFileUpload"
            name="verificationFileUpload"
            placeholder="Upload a document"
            handleFileChange={handleFileChange}
            type="simple"
            allowedExt=".pdf,.doc,.docx,.png"
            fileSize={5}
          />
          <p className="file-limits">
            File size limits: 5MB with PDF, Word, JPG or PNG
          </p>
        </section>

        <Button
          buttonType="basic"
          size="medium"
          label="Back"
          onClick={handleBack}
        />
        <Button
          buttonType="default"
          size="medium"
          label="Submit"
          type="submit"
          disabled={isSubmitting || Object.keys(errors).length > 0}
        />
      </form>
      {modal.isOpen && modal.content !== undefined && (
        <Modal
          {...modal.content}
          confirmClick={handleCloseModal}
          cancelClick={handleCloseModal}
        >
          {modal.content.children}
        </Modal>
      )}
    </section>
  );
};

EnrollVerificationForm.propTypes = {
  user: PropTypes.shape({}),
};

/**
 * Checks if the submitted date is within 5 business days of the current date
 * @param {string} submittedDate date as string
 * @param {string} direction "before" or "after" or "both"
 * @returns
 */
function businessDayCalculator(submittedDate, direction = "before") {
  const dateSubmitted = new Date(submittedDate);
  const today = new Date();

  dateSubmitted.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const addBusinessDays = (startDate, days) => {
    const endDate = new Date(startDate);
    let businessDays = 0;

    while (businessDays < days) {
      endDate.setDate(endDate.getDate() + 1);
      const dayOfWeek = endDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        businessDays++;
      }
    }

    return endDate;
  };

  const countBusinessDaysBetween = (startDate, endDate) => {
    let count = 0;
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return count;
  };

  switch (direction) {
    case "before":
      // Check if within 5 business days before today
      return countBusinessDaysBetween(dateSubmitted, today) <= 5;
    case "after":
      // Calculate date 5 business days after submitted date
      return addBusinessDays(dateSubmitted, 5);
    case "both":
      // Return both the boolean check and the future date
      return {
        isWithinFiveBusinessDays:
          countBusinessDaysBetween(dateSubmitted, today) <= 5,
        futureFiveBusinessDays: addBusinessDays(dateSubmitted, 6),
      };
  }
}
