export const menuData = [
  {
    id: "home",
    label: "Home",
    url: "/",
  },
  {
    id: "my-team",
    label: "My Team",
    url: "/my-team",
  },
  {
    id: "services",
    label: "Services",
    dropdownItems: [
      {
        id: "career-connect",
        label: "Career Services",
        url: "/career-services/",
      },
      {
        id: "wellness-services",
        label: "Wellness Services",
        url: "/wellness-services",
      },
      {
        id: "accessibility-services",
        label: "Accessibility Services",
        url: "/accessibility-services",
      },
      {
        id: "academic-support",
        label: "Tutoring Services",
        url: "/learning-center",
      },
      {
        id: "library-services",
        label: "Library Services",
        url: "/library-services",
      },
      {
        id: "admissions-records-services",
        label: "Admissions and Records Services",
        url: "/admissions-records-services",
      },
      
    ],
  },
  {
    id: "resources",
    label: "Resources",
    url: "/resources",
  },
  {
    id: "benefits",
    label: "Benefits",
    dropdownItems: [
      {
        id: "virtual-id",
        label: "Virtual ID",
        url: "/virtual-id",
      },
    ]
  },
];

/**
 * Takes an array of menu items and an index reference of an item to remove and returns a new array of menu items.
 * @param {array} menuData
 * @param {string} submenuIdToRemove
 * @returns {array} updatedMenuData
 */
export const updateMenuData = (menuData, submenuIdToRemove) => {
  let updatedMenuData = menuData.map((item) => {
    if (item.id === "services") {
      const newDropdownItems = [...item.dropdownItems];
      const indexToRemove = newDropdownItems.findIndex(
        (dropdownItem) => dropdownItem.id === submenuIdToRemove
      );
      newDropdownItems.splice(indexToRemove, 1);
      return {
        ...item,
        dropdownItems: newDropdownItems,
      };
    }
    return item;
  });
  return updatedMenuData;
};

export const EPP_MSG = "Will activate after Enrollment Agreement is signed";
export const SPP_MSG = "Will activate after first assignment is completed";

/**
 * Array of strings used to identify top level menu items.
 * NOTE: Function pulling this same data doesn't work in Storybook.
 */
export const menuIds = ["home", "my-team", "calendar", "services", "resources"];

/**
 * Prerequisite statuses.
 */
export const prereqStatusLookup = {
  APPROVED: "approved",
  PENDING: "pending",
  DENIED: "denied",
  WAITING_SUPPORT: "waiting_support",
};

/**
 * Learner Status used in determining steps in Dashboard task progress.
 * Some of the steps currently used are as follows. Map to the appropriate step in the Dashboard.
 * [ "enrollment-agreement", "schedule-kickoff", "kickoff", "onboarding", "enrolled", "graduated" ]
 */
export const learnerStatus = [
  "Expressed Interest",
  "App Started",
  "App Submitted",
  "Started Orientation",
  "Completed Orientation",
  "Completed CSEP",
  "Enrolled in Program Pathway",
  "Started Program Pathway",
  "Completed Program Pathway",
];

export const learnerStatusLookup = {
  EXPRESSED_INTEREST: "Expressed Interest",
  APP_STARTED: "App Started",
  APP_SUBMITTED: "App Submitted",
  READY_FOR_ONBOARDING: "Ready for Onboarding",
  STARTED_ORIENTATION: "Started Orientation",
  COMPLETED_ORIENTATION: "Completed Orientation",
  COMPLETED_CSEP: "Completed CSEP",
  ENROLLED_IN_PROGRAM_PATHWAY: "Enrolled in Program Pathway",
  ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE:
    "Enrolled in Program Pathway-KickoffComplete", // TODO: Replace Placeholder status (KickoffComplete) added for Dashboard functionality.
  STARTED_PROGRAM_PATHWAY: "Started Program Pathway",
  COMPLETED_PROGRAM_PATHWAY: "Completed Program Pathway",
};

/**
 * Enrolled Learner Statuses
 */
export const enrolledStatus = [
  "Enrolled in Program Pathway",
  "Enrolled in Program Pathway-KickoffComplete",
  "Started Program Pathway",
  "Completed Program Pathway",
];

export const studentProgramLookup = {
  T2T_CRM_ADMIN: "Customer Relationship Management",
  T2T_INTRO_NETWORKS: "T2T Intro to Networks",
  MEDICAL_CODING: "Medical Coding",
  IT_SUPPORT: "IT Support",
  CYBERSECURITY: "Cybersecurity",
  CAREER_READINESS: "Career Readiness",
  DATA_ANALYSIS: "Data Analysis",
  PROJECT_MANAGEMENT: "Project Management",
  HC_DEI: "HC DEI",
  HR_LEARNING_DEVELOPMENT: "HR Learning and Development",
};

export const enrollmentPrograms = [
  "Cybersecurity",
  "IT Support",
  "Project Management",
  "Customer Relationship Management",
  "Data Analysis",
  "T2T Intro to Networks",
];
