import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "../api/axios";

/**
 * getTeamData - retrieve team data fro API.
 */
export const getMyTeamData = (currentProgram, token) => {
  return axiosInstance
    .get(`/my_team?program_name=${currentProgram}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then(handleResponse)
    .catch(handleError);
};
