import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function avatarsReducer(state = initialState.avatars, action) {
  switch (action.type) {
    case types.FETCH_AVATARS_SUCCESS:
      return action.avatars;
    default:
      return state;
  }
}

export default function avatarReducer(state = initialState.avatar, action) {
  switch (action.type) {
    case types.UPDATE_AVATAR_SUCCESS:
      return action.avatar;
    default:
      return state;
  }
}
