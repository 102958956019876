import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "./axios";

/**
 * Returns the date AR Services form was submitted to the admissions records.
 * @param token auth token
 * @returns object with requestTranscriptDateSubmitted and verificationFormDateSubmitted
 */
export const getAdmissionsRecords = (token) => {
  return axiosInstance
    .get("/admissions-records", {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const postEnrollmentVerification = (token, data) => {
  return axiosInstance
    .post("/admissions-records/enrollment", data, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

/**
 * Function retrieves the student completed programs from the /program/complete endpoint.
 * @param {JWT} token
 * @returns completedPrograms
 */
export const getCompletedPrograms = (token) => {
  return axiosInstance
    .get("/program/complete",
      { headers: { Authorization: `Bearer ${token?.idToken}` }
    })
    .then(handleResponse)
    .catch(handleError);
}
