import React from "react";

import IconCongratulations from '../../../assets/icon-congratulations.svg';

const PreReqCongr = () => {
  return null;
}

export const preReqCongr = {
  icon: {
    name: "",
    color: "",
    size: "96px",
    isImage: true,
    imgUrl: IconCongratulations,
  },
  title: `Congratulations!`,
  openDialog: true,
  confirmText: 'Continue',
  size: 'extra-large',
  showCloseIcon: true,
  extraClass: 'modal-prereq text-center',
  children: (
    <p>
      We have completed and approved all your Pre-requisite<br/>
      Check. Click Continue to return to your dashboard.
    </p>
  ),
};