/**
 * Converts images paths to the remote source.
 * @param {array} items
 * @returns {array}
 */
export const updateImagePath = (items) => {
  return items.map((item) => ({
    ...item,
    image: process.env.STATIC_S3_URL + item.image,
  }));
};