import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function appointmentReducer(
  state = initialState.appointment,
  action
) {
  switch (action.type) {
    case types.SET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        ...action.appointment,
      };
    default:
      return state;
  }
}

export function appointmentLinksReducer(
  state = initialState.appointmentLinks,
  action
) {
  switch (action.type) {
    case types.SET_APPOINTMENTLINKS_SUCCESS:
      return {
        ...state,
        ...action.appointmentLinks,
      };
    case types.LOAD_APPOINTMENTLINKS_SUCCESS:
      return action.appointmentLinks;
    default:
      return state;
  }
}
