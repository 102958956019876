import React from "react";

import { Modal } from "@storybook/stories/Modal/Modal";
import { IconError } from "./../../assets";

import "./modallogout.css";

export const ModalLogOut = () => {
  return (
    <Modal
      title="Something doesn’t look right!"
      openDialog={true}
      showCloseIcon={false}
      confirmText="Log Out"
      confirmClick={() => window.location.replace(process.env.LOGOUT_URI)}
      extraClass="modal-log-out"
      icon={{
        imgUrl: IconError,
        isImage: true,
        name: "",
        size: "52",
      }}
    >
      <p className="text-center">
        We can’t find a program linked to your account. Please contact Support
        at 1-833-956-0225 or email us at{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a>.
      </p>
    </Modal>
  );
};
