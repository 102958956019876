import React from 'react';
import PropTypes from 'prop-types';

import { ListItem } from '../ListItem/ListItem';

import './list.css';

export const List = ({ id, items, isdropdown, active, openDropdown, extraItem }) => {
  return (
    <ul className={isdropdown ? `storybook-list--dropdown ${openDropdown ? 'open' : ''}` : 'storybook-list'} data-testid={id}>
     {items.map((item, index) => (
        <ListItem
          parentId={id}
          key={index}
          item={item}
          active={active}
        />
      ))}
      {extraItem
        &&
        <li className="storybook-list-item storybook-list-item__extra">
          {extraItem}
        </li>
      }
    </ul>
  );
};

List.propTypes = {
  /**
   * Items is an array of list items.
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string,
      dropdownItems: PropTypes.array,
    }),
  })),
  /**
   * Is this list a dropdown list (i.e. a vertical list)?
   */
  isDropdown: PropTypes.bool,
  /**
   * Active determines what link in the menu is active.
   */
  active: PropTypes.string,
};