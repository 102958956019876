import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { preReqStepAction } from "../../../redux/actions/preReqStepAction";
import { contactSupport } from "../../../api/prereqApi";
import { updateUserPreReqAction } from "../../../redux/actions/userActions";
import { prereqStatusLookup } from "../../../utils/helpers/data";

import { Textarea } from "@storybook/stories/Textarea/Textarea";
import { preReqSuccess } from "./PreReqSuccess";

const PreReqSupport = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitSupport = async () => {
    setError(null);
    setLoading(true);
    const response = await contactSupport(message, token);
    setLoading(false);

    if (!response.success) {
      setError(response.message);
    } else {
      dispatch(updateUserPreReqAction(prereqStatusLookup.WAITING_SUPPORT));
      dispatch(preReqStepAction(preReqSuccess));
    }
  };

  return (
    <>
      <Textarea
        title="Please enter your question here. Someone from Success Team will reach out to you."
        placeholder="Type your question here"
        submitValue={() => submitSupport()}
        getValue={(value) => setMessage(value)}
        buttonLabel="Submit"
        btnLoading={loading}
        maxLength={5000}
        error={error}
      />
    </>
  );
};

export const preReqSupport = {
  icon: null,
  title: null,
  openDialog: true,
  showCloseIcon: false,
  confirmText: null,
  size: "extra-large",
  showCloseIcon: true,
  extraClass: "modal-prereq text-left",
  children: <PreReqSupport />,
};
