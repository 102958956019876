import React from "react";
import PropTypes from "prop-types";

import { Button } from "../Button/Button";

import AvatarFemale from "../assets/demo-images/Avatar_Female_Default.svg";

import "./card.css";
import { formatDate } from "../../utils/helpers.js";

export const Card = ({
  title,
  imageSrc,
  cardType,
  dashboardContent,
  children,
  hasButton,
  onClick,
  isProfileDisabled,
  ...props
}) => {
  dashboardContent = dashboardContent || null;

  return (
    <div className="storybook-card-container" data-testid="card-container">
      <div className="storybook-card-image" data-testid="card-image">
        <img src={imageSrc} alt="" />
      </div>

      <div className="storybook-card-content" data-testid="card-content">
        <h2 className="storybook-card-title" data-testid="card-title">
          {title}
        </h2>
        {cardType === "student-profile" && (
          <>
            <p className="cccid" data-testid="cccid">
              CCCID: {dashboardContent.cccId}
            </p>
            {dashboardContent?.enrollmentDate && (
              <p className="term-date" data-testid="enrollment-date">
                Enrollment Date
                <br />
                <span>{formatDate(dashboardContent?.enrollmentDate)}</span>
              </p>
            )}
            {dashboardContent?.termEndDate && (
              <p className="term-date" data-testid="term-end-date">
                End of Term Date
                <br />
                <span>{formatDate(dashboardContent?.termEndDate)}</span>
              </p>
            )}
            {dashboardContent?.dropDeadline && (
              <p className="term-date" data-testid="drop-deadline">
                Last Day to Drop without a "W"
                <br />
                <span>{formatDate(dashboardContent?.dropDeadline)}</span>
              </p>
            )}

            {dashboardContent?.withdrawDeadline && (
              <p className="term-date" data-testid="withdraw-deadline">
                Last day to withdraw with a "W"
                <br />
                <span>{formatDate(dashboardContent?.withdrawDeadline)}</span>
              </p>
            )}
            {dashboardContent.text}
          </>
        )}
        {children && <>{children}</>}
      </div>
      {hasButton && (
        <div className="storybook-card-buttons" data-testid="card-buttons">
          <Button
            buttonType="basic"
            label={props.btnLabel}
            onClick={onClick}
            disabled={isProfileDisabled}
          />
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  /**
   * Student's name.
   */
  title: PropTypes.string,
  /**
   * Student's image
   */
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Dashboard card type.
   */
  cardType: PropTypes.oneOf(["student-profile", "my-team", "other"]),
  /**
   * Card dashboard content.
   */
  dashboardContent: PropTypes.shape({
    cccId: PropTypes.string,
    enrollmentDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    termEndDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    dropDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    withdrawDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    completeFirstAssigment: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hasButton: PropTypes.bool,
  }),
  /*
   * Button text.
   */
  btnLabel: PropTypes.string,
  isProfileDisabled: PropTypes.bool,
  children: PropTypes.node,
};

Card.defaultProps = {
  title: "Jane Doe",
  imageSrc: AvatarFemale,
  dashboardContent: {},
  hasButton: false,
  btnLabel: "My Profile",
  isProfileDisabled: false,
};
