import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "./axios";

/**
 * Function retrieves devices info /devices endpoint.
 * @param {JWT} token
 * @returns devices
 */
export const getDevices = (token) => {
  return axiosInstance
    .get("/devices",
      { headers: { Authorization: `Bearer ${token?.idToken}` }
    })
    .then(handleResponse)
    .catch(handleError);
}
