import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { SkeletonDashboard } from "../../components/Skeleton/SkeletonDashboard";
import { PortalHeader } from "../../components/Header/PortalHeader";
import { Card } from "@storybook/stories/Card/Card";
import { AppBadge } from "@storybook/stories/AppBadge/AppBadge";
import { TaskProgressBar } from "@storybook/stories/TaskProgressBar/TaskProgressBar";
import { ProgressBar } from "@storybook/stories/ProgressBar/ProgressBar";
import { Button } from "@storybook/stories/Button/Button";
import { Modal } from "@storybook/stories/Modal/Modal";
import { ModalLinkedIn } from "@storybook/stories/ModalLinkedIn/ModalLinkedIn";
import { JoyrideNav } from "../../components/JoyrideNav/JoyrideNav";
import BroadcastMessage from "../../components/BroadcastMessage/BroadcastMessage";
import WelcomeMessage from "../../components/WelcomeMessage/WelcomeMessage";
import UserDashboardHandler from "../../components/UserDashboardHandler/UserDashboardHandler";
import { preReqMain } from "../../components/PreRequisite/PreReqMain";
import { preReqCongr } from "../../components/PreRequisite/Steps/PreReqCongr";
import { preReqDenied } from "../../components/PreRequisite/Steps/PreReqDenied";
import { preReqStepAction } from "../../redux/actions/preReqStepAction";
import { setFirstSAAViewed } from "../../redux/actions/userActions";

import { fetchStaticDashboard } from "../../redux/actions/staticActions";
import { handleError } from "../../api/apiUtils";

import { IconFirstAssignComplete, LinkedInLogo, IconArrow } from "../../assets";
import InfoIcon from "@mui/icons-material/Info";

import * as dashboardApi from "../../api/dashboardApi";
import * as tasksItems from "../../utils/helpers/tasks";
import {
  handleContinueProgram,
  handleCourseLink,
} from "../../utils/helpers/dashboard";
import {
  learnerStatusLookup,
  studentProgramLookup,
  prereqStatusLookup,
} from "../../utils/helpers/data";

import "./dashboard.css";

const LINKEDIN_URL =
  "https://www.linkedin.com/signup/cold-join?session_redirect=%2Flearning&source=subs_learn_start&trk=learning_login_join_now";
const INSTRUCTIONS_URL =
  "https://drive.google.com/file/d/17rzC2Qza42YKFip7EoucLl8PdVhIefRC/view";

export const Dashboard = ({ user, saveAppointmentLinks, appointmentLinks }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const preReqStep = useSelector((state) => state.preReqStep.preReqStep);
  const dashboard = useSelector((state) => state.staticContent?.dashboard);
  const [appIcons, setAppIcons] = useState(dashboard?.appIcons ?? []);
  const [helpIcons, setHelpIcons] = useState(dashboard?.helpIcons ?? []);
  const [studentOnboarding, setStudentOnboarding] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showMyApps, setShowMyApps] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isModalLinkedInOpen, setIsModalLinkedInOpen] = useState(false);

  const firstSAAViewedLegacy = localStorage.getItem("firstSAAViewed") == "true";
  const firstSAAViewedData = JSON.parse(
    localStorage.getItem("firstSAAViewedList")
  );
  const firstSAAViewed =
    firstSAAViewedData && Array.isArray(firstSAAViewedData)
      ? firstSAAViewedData.includes(user?.cccId)
      : false;

  const history = useHistory();
  const handleProfileButtonClick = () => history.push("/profile");

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent({});

    if (preReqStep) {
      dispatch(preReqStepAction({}));
    }
  };

  const showTaskInProgressModal = (modalContent) => {
    setModalContent(modalContent);
    setIsModalOpen(true);
    return;
  };

  const handleIconClick = (iconKey) => {
    if (iconKey === "linkedIn") {
      setIsModalLinkedInOpen(true);
    } else if (iconKey === "programAccess") {
      handleContinueProgram(user);
    }
  };

  const { staticData, isLoadingIcons } = useStaticData();

  let tasks = useUpdateTasks(
    user,
    tasksItems.studentOnboarding,
    showTaskInProgressModal
  );

  useEffect(() => {
    if (user?.currentProgram && user?.cccId) {
      saveAppointmentLinks(user.currentProgram, user?.cccId);
    }
  }, [user?.currentProgram, user?.cccId]);

  useEffect(() => {
    if (isLoadingIcons || !staticData) {
      return;
    }

    if (!appIcons.length || !helpIcons.length) {
      setAppIcons(staticData.appIcons);
      setHelpIcons(staticData.helpIcons);
      setStudentOnboarding(staticData.studentOnboarding);
    }
  }, [isLoadingIcons, staticData]);

  useEffect(() => {
    if (
      !user ||
      Object.keys(user).length === 0 ||
      !appIcons.length ||
      !helpIcons.length
    ) {
      return;
    }

    const { appsEnabled, helpIconsEnabled } = dashboardApi.setEnabledApps(
      user.learnerStatus
    );

    const updateIcons = (icons, enabledList, keyProp) => {
      return icons.map((icon) => {
        const isDisabled = !enabledList.includes(icon[keyProp]);
        return {
          ...icon,
          icon: icon.icon.includes(process.env.STATIC_S3_URL)
            ? icon.icon
            : `${process.env.STATIC_S3_URL}${icon.icon}`,
          link: isDisabled ? "" : icon.link,
          disabled: isDisabled,
          disabledMsg: icon.disabledMsg,
        };
      });
    };

    const updatedHelpIcons = updateIcons(helpIcons, helpIconsEnabled, "name");
    const updatedAppIcons = updateIcons(appIcons, appsEnabled, "key");
    const userProgress = dashboardApi.setDashboardTasks(user);

    setAppIcons(updatedAppIcons);
    setHelpIcons(updatedHelpIcons);

    setDashboardData((prevData) => ({
      ...dashboardData,
      studentProgress: userProgress,
      appList: updatedAppIcons,
      helpIcons: updatedHelpIcons,
      appointmentLinks,
    }));

    setLoading(false);
  }, [user, appointmentLinks]);

  const handleShowMyApps = () => {
    setShowMyApps(!showMyApps);
  };

  useEffect(() => {
    const showFirstSAA = user?.showFirstSAA;

    if (!firstSAAViewed && !firstSAAViewedLegacy && showFirstSAA) {
      setTimeout(() => {
        setModalContent(firstSAAModalContent);
        setIsModalOpen(true);
        dispatch(setFirstSAAViewed(token));
      }, 500);
    }

    if ((firstSAAViewed || firstSAAViewedLegacy) && showFirstSAA) {
      dispatch(setFirstSAAViewed(token));
    }
  }, [user?.showFirstSAA]);

  const [enrollmentDate, termEndDate, dropDeadline, withdrawDeadline] =
    useKeyDates(
      user.learnerStatus,
      user.enrollmentDate,
      user.currentTermEndDate,
      user.dropDeadline,
      user.withdrawDeadline
    );

  useEffect(() => {
    setModalContent(preReqStep);
  }, [preReqStep]);

  const handleEsc = useCallback((event) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [handleEsc]);

  if (Object.keys(user).length > 0 && !user?.currentProgram) {
    return <UserDashboardHandler user={user} />;
  }

  return (
    <article className="storybook-dashboard" data-testid="dashboardContainer">
      <PortalHeader user={user} activeLink="home" />
      <>
        {loading && !dashboardData ? (
          <SkeletonDashboard showHeader={false} />
        ) : (
          <>
            {Object.keys(user).length > 0 && (
              <main>
                <JoyrideNav
                  studentProgress={dashboardData.studentProgress}
                  showFTUX={user?.showFTUX}
                />
                <div className="container">
                  <div className="storybook-dashboard-inner">
                    <section className="storybook-dashboard-message no-desktop">
                      <BroadcastMessage />
                      <WelcomeMessage
                        user={user}
                        dashboardData={dashboardData}
                      />
                    </section>
                    <div
                      className="storybook-dashboard-leftcolumn"
                      data-testid="dashboardLeftcolumn"
                    >
                      <section
                        className="storybook-dashboard-usercard"
                        data-testid="dashboardUserCard"
                      >
                        <Card
                          cardType="student-profile"
                          dashboardContent={{
                            cccId: user.cccId,
                            enrollmentDate: enrollmentDate,
                            termEndDate: termEndDate,
                            dropDeadline: dropDeadline,
                            withdrawDeadline: withdrawDeadline,
                          }}
                          title={user.firstName + " " + user.lastName}
                          hasButton={true}
                          onClick={handleProfileButtonClick}
                          imageSrc={user.profileImageUrl}
                        />
                      </section>
                      <section
                        className={`storybook-dashboard-myapps ${
                          showMyApps ? "show" : ""
                        }`}
                        data-testid="dashboardMyApps"
                      >
                        <hr width="80%" />
                        <h2>My Apps</h2>
                        <div
                          className="storybook-myapps-container"
                          data-testid="myappsContainer"
                        >
                          {dashboardData?.appList.map((appIcon, index) => (
                            <AppBadge
                              key={index}
                              id={appIcon.name}
                              title={appIcon.title}
                              link={appIcon.link}
                              linkType="external"
                              icon={appIcon.icon}
                              disabled={appIcon.disabled}
                              disabledMsg={appIcon.disabledMsg}
                              data-testid={appIcon.key + "-badge"}
                              handleClick={() => handleIconClick(appIcon.key)}
                            />
                          ))}
                        </div>
                      </section>
                      <div
                        className="storybook-dashboard-myapps-show-more"
                        onClick={handleShowMyApps}
                      >
                        <img
                          src={IconArrow}
                          className={showMyApps ? "expand-rotate" : ""}
                        />
                      </div>
                    </div>
                    <div
                      className="storybook-dashboard-rightcolumn"
                      data-testid="dashboardRightcolumn"
                    >
                      <section
                        className="storybook-dashboard-message no-mobile"
                        data-testid="dashboardMessage"
                      >
                        <BroadcastMessage />
                        <WelcomeMessage
                          user={user}
                          dashboardData={dashboardData}
                        />
                      </section>
                      {dashboardData.studentProgress.section ===
                      "no-milestone" ? (
                        <section
                          className="storybook-dashboard-mytasks coursework white-container box-shadow"
                          data-testid="dashboardMyTasksCoursework"
                        >
                          <div className="storybook-dashboard-mytasks--wrapper">
                            <header>
                              <h2>{dashboardData.studentProgress.title}</h2>
                            </header>
                            <h3>{user.currentProgram}</h3>
                            {dashboardData.studentProgress.courses.map(
                              (course, index) => (
                                <ProgressBar
                                  key={index}
                                  label={`${course.deptName} ${course.deptNum} `}
                                  link={handleCourseLink(
                                    course.code,
                                    user?.programVersion
                                  )}
                                  description={course.courseName}
                                  progress={course.progress}
                                  percentLocation={course.percentLocation}
                                  showScale={true}
                                />
                              )
                            )}
                          </div>
                          <footer>
                            <Button
                              label="Continue Your Program"
                              onClick={() => handleContinueProgram(user)}
                            />
                            <p className="storybook-mytasks-info">
                              <InfoIcon /> Progress is{" "}
                              {user?.lms === "Canvas"
                                ? `calculated based on graded Summative Assessments.`
                                : `updated every 24 hours.`}
                            </p>
                          </footer>
                        </section>
                      ) : (
                        <>
                          <section
                            className="storybook-dashboard-mytasks white-container box-shadow"
                            data-testid="dashboardMyTasks"
                          >
                            <header>
                              <h2>{dashboardData.studentProgress.title}</h2>
                            </header>
                            <TaskProgressBar
                              stages={tasks}
                              date={dashboardData.studentProgress.date}
                              progress={
                                dashboardData.studentProgress.milestone.progress
                              }
                              section={dashboardData.studentProgress.section}
                              milestoneDetails={
                                dashboardData.studentProgress.milestone.details
                              }
                              handleCompleteFirstAssignment={() =>
                                handleContinueProgram(user)
                              }
                              progressStages={tasksItems.progressStages}
                            />
                          </section>
                        </>
                      )}
                      <section
                        className="storybook-dashboard-needhelp white-container"
                        data-testid="dashboardNeedHelp"
                      >
                        <h2>Quick Links</h2>
                        {dashboardData.helpIcons.map((helpIcon, index) => (
                          <AppBadge
                            key={index}
                            id={helpIcon.name}
                            icon={helpIcon.icon}
                            link={helpIcon.link}
                            title={helpIcon.title}
                            disabled={helpIcon.disabled}
                            disabledMsg={helpIcon.disabledMsg}
                            target={helpIcon.target}
                          />
                        ))}
                      </section>
                    </div>
                  </div>
                </div>
                {isModalOpen && modalContent !== undefined && (
                  <Modal
                    icon={modalContent.icon}
                    title={modalContent.title}
                    openDialog={modalContent.openDialog}
                    showCloseIcon={modalContent.showCloseIcon}
                    confirmText={modalContent.confirmText}
                    confirmClick={() => handleCloseModal()}
                    cancelClick={handleCloseModal}
                    size={modalContent.size}
                    extraClass={modalContent.extraClass}
                  >
                    {modalContent.children}
                  </Modal>
                )}
                {isModalLinkedInOpen && (
                  <ModalLinkedIn
                    icon={{
                      imgUrl: LinkedInLogo,
                      isImage: true,
                      name: "",
                      size: "96",
                    }}
                    openDialog={true}
                    title="LinkedIn Learning"
                    linkedInLoginURL={LINKEDIN_URL}
                    instructionsSignUp={INSTRUCTIONS_URL}
                    target="_blank"
                    cancelClick={() => setIsModalLinkedInOpen(false)}
                  >
                    You have free access to LinkedIn Learning as a Calbright
                    College student. With LinkedIn Learning, you can
                  </ModalLinkedIn>
                )}
              </main>
            )}
          </>
        )}
      </>
    </article>
  );
};

Dashboard.propTypes = {
  /**
   * User information used on the dashboard.
   */
  user: PropTypes.shape({}),
  /**
   * List of apps enabled for the user.
   */
  appsEnabled: PropTypes.array,
  /**
   * Student progress information.
   */
  userProgress: PropTypes.shape({
    title: PropTypes.string,
    progress: PropTypes.number,
    section: PropTypes.oneOf(["onboarding", "milestone", "no-milestone"]),
    milestone: PropTypes.shape({
      name: PropTypes.string,
      progress: PropTypes.number,
      type: PropTypes.oneOf(["onboarding", "milestone"]),
      completionDate: PropTypes.string,
      status: PropTypes.oneOf(["Ahead", "On Track", "Behind", "Way - Behind"]),
    }),
    date: PropTypes.string,
  }),
  /**
   * Help Icons enabled.
   */
  helpIconsEnabled: PropTypes.array,
  // apiData property types
  apiData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    profile_image_url: PropTypes.string,
    current_program: PropTypes.string,
    enrollment_date: PropTypes.string,
    current_term_end_date: PropTypes.string,
    learner_status: PropTypes.string,
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        completed: PropTypes.bool,
        name: PropTypes.string,
        progress: PropTypes.string,
      })
    ),
    lms: PropTypes.string,
    program_of_interest: PropTypes.string,
    program_version: PropTypes.string,
    salesforce_id: PropTypes.string,
  }),
};

Dashboard.defaultProps = {
  user: null,
  // appsEnabled: appIcons,
  userProgress: {
    title: "My Tasks",
    progress: 0,
    section: "onboarding",
    date: "",
  },
  // helpIconsEnabled: helpIcons,
};

/**
 * Modal content template
 */
const modalContentTemplate = {
  icon: {
    color: "error",
    name: "ErrorOutlineOutlined",
    size: "75px",
  },
  title: "Title",
  children: <p>Content</p>,
  openDialog: true,
  showCloseIcon: false,
  confirmText: "Close",
  size: "large",
};

/**
 * Content for First SAA detected modal window.
 */
const firstSAAModalContent = {
  icon: {
    name: "",
    color: "",
    size: "100px",
    isImage: true,
    imgUrl: IconFirstAssignComplete,
  },
  title: "Congratulations!",
  openDialog: true,
  showCloseIcon: false,
  confirmText: "Continue",
  children: (
    <p className="center">
      Well done! You’ve completed all your orientation tasks. <br />
      Click Continue to return to your dashboard.
    </p>
  ),
};

/**
 * Content for Sign Enrollment Agreement modal window.
 */
const signEnrollAgreementContent = {
  ...modalContentTemplate,
  title: "You will Sign Enrollment Agreement during your Onboarding session",
  children: (
    <p>
      During your Onboarding session, you’ll officially enroll in your program
      of study by filling out and signing your enrollment agreement.
    </p>
  ),
};

const pendingReviewContent = {
  ...modalContentTemplate,
  title: "Your Pre-requisite Verification is Pending Review",
  children: (
    <p>
      Your pre-requisite check has been submitted and is being reviewed. You
      will hear from us in 2 business days. Check your Calbright email for
      updates.
    </p>
  ),
};

const waitingSupportContent = {
  ...modalContentTemplate,
  title: "Support Requested",
  children: (
    <p>
      Thank you for contacting us. The Success Team will reach out to you in 2-5
      business days. Check your Calbright email for updates.
    </p>
  ),
};

/**
 * Custom hook to change onboarding tasks based on learnerStatus and program.
 * @param {object} user
 * @param {array} onboardingTasks Pass it taskItems.studentOnboarding
 * @param {function} showTaskInProgressModal function that handles the task click event.
 * @returns {array} tasks
 */
const useUpdateTasks = (user, studentOnboarding, showTaskInProgressModal) => {
  const [tasks, setTasks] = useState([]);

  /** Object Mapping of taskid to learnerStatus */
  const taskConfiguration = {
    "prerequisite-verification": {
      "Started Orientation": {
        handleClick: () =>
          showTaskInProgressModal(getModalContentForPrereq(user?.prereqStatus)),
      },
      "Completed Orientation": {
        title: "Pre-requisite Approved",
      },
      "Completed CSEP": {
        title: "Pre-requisite Approved",
      },
      "Enrolled in Program Pathway": {
        title: "Pre-requisite Approved",
      },
      "Started Program Pathway": {
        title: "Pre-requisite Approved",
      },
    },
    "schedule-onboarding": {
      "Completed Orientation": {
        title: "Onboarding Scheduled",
      },
      "Completed CSEP": {
        title: "Onboarding Scheduled",
      },
      "Enrolled in Program Pathway": {
        title: "Onboarding Scheduled",
      },
      "Started Program Pathway": {
        title: "Onboarding Scheduled",
      },
    },
    "enrollment-agreement": {
      "Completed Orientation": {
        handleClick: () => showTaskInProgressModal(signEnrollAgreementContent),
      },
      "Completed CSEP": {
        handleClick: () => showTaskInProgressModal(signEnrollAgreementContent),
      },
      "Enrolled in Program Pathway": {
        title: "Enrollment Agreement Signed",
      },
    },
    "schedule-kickoff": {
      "Enrolled in Program Pathway": {
        title: "Kickoff Scheduled",
      },
    },
    "complete-assignment": {
      "Enrolled in Program Pathway": {
        title: "Complete First Assignment by " + user?.dropDeadline,
      },
      "Started Program Pathway": {
        title: "Complete First Assignment by " + user?.dropDeadline,
      },
    },
  };

  const getModalContentForPrereq = (prereqStatus) => {
    const modalContentMap = {
      [prereqStatusLookup.PENDING]: pendingReviewContent,
      [prereqStatusLookup.WAITING_SUPPORT]: waitingSupportContent,
      [prereqStatusLookup.APPROVED]: preReqCongr,
      [prereqStatusLookup.DENIED]: preReqDenied,
      default: preReqMain,
    };
    return modalContentMap[prereqStatus] || modalContentMap.default;
  };

  const getTargetTaskId = (learnerStatus) => {
    const taskIdMap = {
      [learnerStatusLookup.APP_SUBMITTED]: "prerequisite-verification",
      [learnerStatusLookup.STARTED_ORIENTATION]: "prerequisite-verification",
    };
    return taskIdMap[learnerStatus] || "";
  };

  useEffect(() => {
    /** Map tasks based on current learner status. */
    const updateTasks = () => {
      let updatedTasks = studentOnboarding;
      let targetTaskId = getTargetTaskId(user?.learnerStatus);

      const isPrereqProgram = [
        studentProgramLookup.CYBERSECURITY,
        studentProgramLookup.T2T_INTRO_NETWORKS,
      ].includes(user?.currentProgram);

      if (isPrereqProgram) {
        updatedTasks = [tasksItems.preRequisiteTask, ...updatedTasks];
      }

      updatedTasks = updatedTasks.map((task) => {
        if (
          task.id === "schedule-kickoff" &&
          user?.learnerStatus ===
            learnerStatusLookup.ENROLLED_IN_PROGRAM_PATHWAY &&
          !user?.scheduledKickoff
        ) {
          return task;
        }

        const taskConfig = taskConfiguration[task.id]?.[user?.learnerStatus];
        return taskConfig ? { ...task, ...taskConfig } : task;
      });

      if (targetTaskId) {
        updatedTasks = updatedTasks.map((task) => {
          if (task.id !== targetTaskId) return task;

          const updatedTask = { ...task };

          if (targetTaskId === "prerequisite-verification" && isPrereqProgram) {
            const modalContent = getModalContentForPrereq(user?.prereqStatus);
            updatedTask.title =
              user?.prereqStatus === prereqStatusLookup.APPROVED
                ? "Pre-requisite Approved"
                : "Pre-requisite Check";
            updatedTask.statusLabel =
              user?.prereqStatus === prereqStatusLookup.PENDING
                ? "Pending Review"
                : user?.prereqStatus === prereqStatusLookup.WAITING_SUPPORT
                ? "Waiting Support"
                : updatedTask.statusLabel;

            if (modalContent) {
              updatedTask.handleClick = () =>
                showTaskInProgressModal(modalContent);
            }
          }
          return updatedTask;
        });
      }
      setTasks(updatedTasks);
    };

    updateTasks();
  }, [user, tasksItems]);

  return tasks;
};

/*
 * Custom hook to decide which dates to show in student profile card
 * @param {string} learnerStatus
 * @param {string} enrollmentDate
 * @param {string} termEndDate
 * @param {string} dropDeadline
 * @param {string} withdrawDeadline
 * @returns {array} keyDates [enrollmentDate, termEndDate, dropDeadline, withdrawDeadline]
 */
const useKeyDates = (
  learnerStatus,
  enrollmentDate,
  termEndDate,
  dropDeadline,
  withdrawDeadline
) => {
  const todaysDate = new Date();
  const dropDeadlineDate = new Date(dropDeadline);
  const withdrawDeadlineDate = new Date(withdrawDeadline);

  switch (learnerStatus) {
    case learnerStatusLookup.ENROLLED_IN_PROGRAM_PATHWAY:
    case learnerStatusLookup.STARTED_PROGRAM_PATHWAY:
      if (todaysDate <= dropDeadlineDate) {
        return ["", "", dropDeadline, ""];
      }
      if (todaysDate <= withdrawDeadlineDate) {
        return ["", "", "", withdrawDeadline];
      }
      return ["", termEndDate, "", ""];
    default:
      return ["", "", "", ""];
  }
};

/**
 * Custom hook to retrieve static data.
 */
const useStaticData = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.staticContent?.dashboard);
  const [staticData, setStaticData] = useState(dashboard ?? null);
  const [isLoadingIcons, setIsLoadingIcons] = useState(!dashboard);

  useEffect(() => {
    if (dashboard) {
      setStaticData({
        appIcons: dashboard.appIcons,
        helpIcons: dashboard.helpIcons,
        studentOnboarding: dashboard.studentOnboarding,
      });
      setIsLoadingIcons(false);
      return;
    }

    const fetchStaticData = async () => {
      try {
        const response = await dispatch(fetchStaticDashboard());
        setStaticData({
          appIcons: response.appIcons,
          helpIcons: response.helpIcons,
          studentOnboarding: response.studentOnboarding,
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoadingIcons(false);
      }
    };

    fetchStaticData();
  }, [dashboard, dispatch]);

  return { staticData, isLoadingIcons };
};

export default Dashboard;
