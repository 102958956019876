import React from "react";
import PropTypes from "prop-types";

import { progress as progressStatus } from "../../constants";

import "./taskprogressitem.css";

export const TaskProgressItem = ({
  title,
  subtitle,
  section,
  progress,
  statusLabel,
  onClick,
}) => {
  const currentItem = progressStatus.find((item) => item.name === progress);
  const icon = currentItem.icon;
  return (
    <div
      className={`storybook-task-item storybook-task-item--${progress} ${section}`}
      data-testid="progressBarItem"
      onClick={onClick}
    >
      {statusLabel &&
        (progress === "started" || progress === "in-progress") && (
          <div className="item-pending">({statusLabel})</div>
        )}
      <div className="item-icon">
        {icon && <img src={icon} alt={`Task ${title}`} />}
        {progress === "started" && (
          <span className="hover-tip">Action needed here!</span>
        )}
      </div>
      <div className="item-title">
        {title && <p>{title}</p>}
        {subtitle && <p>{subtitle}</p>}
      </div>
    </div>
  );
};

TaskProgressItem.propTypes = {
  /**
   * Task title
   */
  title: PropTypes.string,
  /**
   * Task subtitle
   */
  subtitle: PropTypes.string,
  /**
   * Course section
   */
  section: PropTypes.oneOf(["onboarding", "milestone"]),
  /**
   * Progress icon
   */
  icon: PropTypes.string,
  /**
   * Status of task progress
   */
  progress: PropTypes.oneOf([
    "default",
    "started",
    "started-hover",
    "passed",
    "in-progress",
    "done",
    "success",
    "assignment-1",
    "milestone-1",
    "milestone-2",
  ]),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};
