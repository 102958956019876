import * as types from "./actionTypes";
import * as myteamAPI from "../../api/myteamApi";
import { handleError } from "../../api/apiUtils";
import { beginApiCall } from "./apiStatusActions.js";

export function fetchTeamDataRequest(teamData) {
  return { type: types.FETCH_TEAMDATA_REQUEST, teamData };
}

export function fetchTeamDataSuccess(teamData) {
  return { type: types.FETCH_TEAMDATA_SUCCESS, teamData };
}

export function fetchTeamDataFailure(error) {
  return { type: types.FETCH_TEAMDATA_FAILURE, error };
}

export function fetchTeamData(currentProgram) {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      console.log("fetchTeamData triggered", currentProgram);
      const teamData = await myteamAPI.getTeamData(currentProgram);
      dispatch(fetchTeamDataSuccess(teamData));
      return teamData;
    } catch (error) {
      dispatch(handleError(error));
      dispatch(fetchTeamDataFailure(error));
      throw error;
    }
  };
}

// dataActions.js

// export const fetchData = () => {
//   return async (dispatch) => {
//     dispatch({ type: 'FETCH_DATA_REQUEST' });

//     try {
//       const response = await axios.get('https://api.example.com/data');
//       dispatch({ type: 'FETCH_DATA_SUCCESS', payload: response.data });
//     } catch (error) {
//       dispatch({ type: 'FETCH_DATA_FAILURE', payload: error.message });
//     }
//   };
// };
