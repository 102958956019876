import React from 'react';

import { Skeleton } from './Skeleton';

// import './skeleton.css';

export const SkeletonDashboard = () => {
  return (
    <div className='storybook-dashboard'>
      <main className='skeleton-container'>
        <section className='storybook-dashboard-message flex-direction-column align-left'>
          <Skeleton classes="title width-40 margin-top-2x" />
          <Skeleton classes="text width-100" />
        </section>
        <div className='storybook-dashboard-leftcolumn'>
          <section className='storybook-dashboard-usercard'>
            <div className='storybook-card-container'>
              <Skeleton classes="profile-rectangle center" />
              <Skeleton classes="title width-50 center" />
              <Skeleton classes="text width-40 center" />
              <Skeleton classes="text width-40 center" />
              <Skeleton classes="text width-75 center" />
              <Skeleton classes="text width-50 oneX center" />
            </div>
          </section>
          <section className='storybook-dashboard-myapps'>
            <Skeleton classes="title width-75 center" />
            <div className='storybook-myapps-container'>
              {[...Array(6).keys()].map((index) => (
                <div className='storybook-app-badge' key={index}>
                  <Skeleton classes="profile-rectangle--small width-40 center" />
                  <Skeleton classes="text width-40 center" />
                </div>)
              )}
            </div>
          </section>
        </div>
        <div className='storybook-dashboard-rightcolumn'>
          
          <section className='storybook-dashboard-mytasks white-container box-shadow margin-1x'>
            <Skeleton classes="title width-40" />
            <Skeleton classes="text" />
            <Skeleton classes="text height-2x" />
            <Skeleton classes="title width-40 center" />
          </section>
          <section className='storybook-dashboard-mytasks white-container'>
            <Skeleton classes="text width-40" />    
            <Skeleton classes="text width-100" />            
          </section>
          <section className='storybook-dashboard-needhelp white-container'>
            
              {[...Array(5).keys()].map((index) => (
                <div className="storybook-app-badge flex-basis-15 margin-1x" key={index}>
                  
                  <Skeleton classes="profile-rectangle--small center" />
                  <Skeleton classes="text width-75 center" />
                  <Skeleton classes="text width-40 center" />
                </div>)
              )}
          </section>
        </div>
      </main>
    </div>
  )
};
