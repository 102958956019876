import React from "react";
import PropTypes from "prop-types";

import { Card } from "../Card/Card";

import EmailIcon from "../assets/icon-email.svg";
import SlackLogo from "../assets/icon-slack.svg";
import PhoneIcon from "../assets/icon-phone.svg";

import "./meetyourteam.css";

/**
 * Component used by MeetYourTeam to organize component pieces.
 * @param {*} member
 */
const TeamMember = ({ member }) => (
  <>
    <p className="storybook-teammember-role">{member.role}</p>
    {member.department && (
      <p className="storybook-teammember-dept">{member.department}</p>
    )}
    <div className="storybook-teammember--contacts">
      {member.phone && (
        <a href={`phone:${member.phone}`}>
          <img src={PhoneIcon} className="phone-logo" alt="" /> {member.phone}
        </a>
      )}
      {member.slack && (
        <a
          href={`https://calbright-students.slack.com/team/${member.slack}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={SlackLogo} className="slack-logo" alt="" /> Slack
        </a>
      )}
      {member.email && (
        <a href={`mailto:${member.email}`}>
          <img src={EmailIcon} className="email-logo" alt="" /> Email
        </a>
      )}
    </div>
  </>
);

TeamMember.propTypes = {
  member: PropTypes.shape({
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    department: PropTypes.string,
    slack: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

/**
 * Component used to display team members.
 * @param {teammembers} param0
 * @returns
 */
export const MeetYourTeam = ({ teammembers }) => {
  return (
    <section
      className="storybook-team white-container"
      data-testid="teamContainer"
    >
      <h2>Meet Your Team</h2>
      <div className="storybook-team--cards">
        {teammembers.map((member, index) => (
          <Card
            key={member.id}
            title={member.name}
            imageSrc={member.photo_url}
            cardType="other"
            children={<TeamMember member={member} />}
            hasButton={false}
            isProfileDisabled={false}
          />
        ))}
      </div>
    </section>
  );
};

MeetYourTeam.propTypes = {
  /**
   * Array of TeamMember component(s).
   */
  teammembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photo_url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      role: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      department: PropTypes.string,
      slack: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};
