import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const myTeamReducer = (state = initialState.teamData, action) => {
  switch (action.type) {
    case types.FETCH_TEAMDATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_TEAMDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        teamData: action.payload,
      };
    case types.FETCH_TEAMDATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default myTeamReducer;
