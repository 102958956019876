import React from "react";
import PropTypes from "prop-types";

import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import accessCourses from "../assets/modal-linkedin/access-courses.svg";
import exploreTopics from "../assets/modal-linkedin/explore-topics.svg";
import accessAll from "../assets/modal-linkedin/access-all.svg";

import "./modal-linkedin.css";

/**
 * LinkedIn Learning Modal Box
 */
export const ModalLinkedIn = ({
  title,
  icon,
  children,
  size,
  linkedInLoginURL,
  instructionsSignUp,
  openDialog,
  target,
  cancelClick,
  onLinkedInLogin,
}) => {
  return (
    <Modal
      title={title}
      icon={icon}
      openDialog={openDialog}
      showCloseIcon={true}
      size={size}
      confirmText={""}
      extraClass="in-modal"
      cancelClick={cancelClick}
    >
      <div className="in-content">
        {children}
        <div className="in-learning-row">
          <div className="in-learning-col">
            <img src={accessCourses} alt="" />
            <span>Access courses instructors may mark as assignments</span>
          </div>
          <div className="in-learning-col">
            <img src={exploreTopics} alt="" />
            <span>
              Explore topics of interest and learn from industry experts
            </span>
          </div>
          <div className="in-learning-col">
            <img src={accessAll} alt="" />
            <span>Access all courses within LinkedIn Learning for free</span>
          </div>
        </div>
        <b>Please select one of the two options below. </b>
        <p>
          For logging in, click “Continue with Google” and use your Calbright
          College account to sign in
        </p>
        <div className="in-learning-links">
          <div>
            <p>
              I already have a LinkedIn Learning Account with Calbright College
              Email
            </p>
            <Button
              buttonType="default"
              label="Continue with Google"
              size="medium"
              onClick={() => {
                onLinkedInLogin();
                window.open(linkedInLoginURL, target);
              }}
            />
          </div>
          <div>
            <p>
              I don’t have a LinkedIn Learning Account with Calbright College
              Email
            </p>
            <Button
              buttonType="basic"
              label="View Instructions to Sign Up"
              size="medium"
              onClick={() => {
                window.open(instructionsSignUp, target);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

Modal.propTypes = {
  /**
   * Title of the modal window.
   */
  title: PropTypes.string,
  /**
   * Contents of the modal window.
   */
  children: PropTypes.node.isRequired,
  /**
   * How large should the modal be?
   */
  size: PropTypes.oneOf(["small", "medium", "large", "extra-large"]).isRequired,
  /**
   * Icon to include at the top of the modal. This is the MUI Icon name e.g. AccessAlarmIcon.
   * The icon name is required but a color and size can also be included in the object passed.
   */
  icon: PropTypes.shape({
    /** Icon name. */
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    isImage: PropTypes.bool,
    imgUrl: PropTypes.string,
  }),
  /**
   * LinkedIn Login URL
   */
  linkedInLoginURL: PropTypes.string,
  /**
   * LinkedIn Sign Up URL
   */
  instructionsSignUp: PropTypes.string,
  /**
   * Link target
   */
  target: PropTypes.string,
  /**
   * On LinkedIn Learning login
   */
  onLinkedInLogin: PropTypes.func,
};

ModalLinkedIn.defaultProps = {
  size: "extra-large",
  linkedInLoginURL: "#",
  instructionsSignUp: "#",
  target: "_self",
  openDialog: false,
  onLinkedInLogin: () => {},
};
