const defaultState = {
  buttonDisabled: false,
}

const SET_DISABLED = "SET_DISABLED";

export const buttonChatReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DISABLED:
      return { ...state, buttonDisabled: action.payload }
    default:
      return state;
  }
}

export const setDisabledAction = (payload) => ({type: SET_DISABLED, payload});