import AvatarFemale from "../../assets/demo-images/Avatar_Female_Default.svg";

import CatherineParker from "../../assets/myteam/catherine-parker.svg";
import ElliotFu from "../../assets/myteam/elliot-fu.svg";
import LailaJansen from "../../assets/myteam/laila-jansen.svg";
import EdisonCameron from "../../assets/myteam/edison-cameron.svg";
import PadminiJohannes from "../../assets/myteam/padmini-johannes.svg";
import BrittAllen from "../../assets/myteam/britt-allen.svg";
import JoachimNik from "../../assets/myteam/joachim-nik.svg";
import ShemilaJohnson from "../../assets/myteam/shemila-johnson.png";

/**
 * Mock user for testing.
 */
export const mockUser = {
  firstName: "Mariah",
  lastName: "Villalobos",
  name: "Mariah Villalobos",
  email: "mariah.villalobos@calbright.org",
  cccId: "CALBRDEMO01",
  hasPhoto: true,
  photoUrl: AvatarFemale,
  profileImageUrl: AvatarFemale,
  enrollmentDate: "07/01/2023",
  termEndDate: "12/31/2023",
  learningProgress: "schedule-onboarding",
  learnerStatus: "Started Program Pathway",
  enrolled: true,
};

export const mockUserDashboardData = {
  cccId: "CALBRDEMO01",
  firstName: "Mariah",
  lastName: "Villalobos",
  email: "Mariah.Villalobos@calbright.org",
  courses: [
    {
      code: "IT500",
      completed: false,
      name: "Introduction to Information Technology Support (A+)",
      progress: 3,
    },
    {
      code: "WF500",
      completed: false,
      name: "College and Career Essential Skills",
      progress: 0,
    },
  ],
  currentProgram: "IT Support",
  currentTermEndDate: "09/02/2024",
  enrollmentDate: "12/18/2023",
  learnerStatus: "Completed CSEP",
  enrolled: true,
  lms: "Canvas",
  phoneNumber: "4254203360",
  profileImageUrl:
    "https://static.calbrightcollege.org/assets/student-avatars/user_1.svg",
  hasPhoto: true,
  programOfInterest: "IT Support;Cybersecurity",
  programVersion: "IT500 - v5.0, WF500 - v5.0",
  salesforceId: "0037f00000rMkYWAA0",
  address: "123 Main Street Sacramento, CA, 95213",
  dropDeadline: "2023-08-17",
  withdrawDeadline: "2023-10-24",
  lastAssignmentCompletion: "",
  lastLogin: "",
  scheduledKickoff: true,
  prereqStatus: null, // approved, pending, denied, waiting_support,
  prereqCertSubmissionAllowed: true,
  prereqDeniedReason: "Transcript Issue",
  prereqDeniedResponse: "Your transcript submitted was insufficient....",
  prereqExpProofSubmissionAllowed: true,
  prereqLastAnswerExpProof: null,
  prerequisiteAttempts: 1,
  showFTUX: true,
  showFirstSAA: false,
  prereqRecommendedProgram: "T2T Intro to Networks",
};

export const mockDashboardAPIError = {
  error:
    'Failed to fetch user with email Mariah.Villalobos@calbright.org from Salesforce: RequestFailed (status 401): [{"message":"Session expired or invalid","errorCode":"INVALID_SESSION_ID"}]',
};

/**
 * List of instructors used for demo on the MyTeam page.
 */
export const Instructors = [
  {
    name: "Catherine Parker",
    course: "Workforce",
    courseNumber: "WF 500",
    department_name: "WF",
    department_number: "500",
    email: "catherine.parker@calbright.org",
    slack: "@catherineparker",
    calendlyUrl: "https://calendly.com/catherineparker",
    phone: "555-555-5555",
    availability: ["mon", "wed", "fri"],
    profile_image: CatherineParker,
  },
  {
    name: "Elliot Fu",
    course: "Intro to IT",
    courseNumber: "IT 101",
    department_name: "IT",
    department_number: "101",
    email: "elliot.fu@calbright.org",
    slack: "@elliotfu",
    calendlyUrl: "https://calendly.com/elliotfu",
    phone: "555-555-5555",
    availability: ["tue", "thu", "sat"],
    profile_image: ElliotFu,
  },
  {
    name: "Laila Jansen",
    course: "Intro to IT",
    department_name: "IT",
    department_number: "500",
    email: "laila.jansen@calbright.org",
    slack: "@laila.jansen",
    calendlyUrl: "https://calendly.com/laila.jansen",
    phone: "555-555-5555",
    availability: ["mon", "wed", "fri"],
    profile_image: LailaJansen,
  },
  {
    name: "Edison Cameron",
    course: "Intro to IT",
    courseNumber: "IT 101",
    department_name: "IT",
    department_number: "101",
    email: "edison.carmeron@calbright.org",
    slack: "@elliotfu",
    calendlyUrl: "https://calendly.com/edison.carmeron",
    phone: "555-555-5555",
    availability: ["tue", "thu", "sat"],
    profile_image: EdisonCameron,
  },
  {
    name: "Padmini Johannes",
    course: "Intro to IT",
    courseNumber: "IT 120",
    department_name: "IT",
    department_number: "120",
    email: "padmini.johannes@calbright.org",
    slack: "@padmini.johannes",
    calendlyUrl: "https://calendly.com/padmini.johannes",
    phone: "555-555-5555",
    availability: ["mon", "wed", "fri"],
    profile_image: PadminiJohannes,
  },
  {
    name: "Britt Allen",
    course: "Workforce",
    department_name: "WF",
    department_number: "101",
    email: "britt.allen@calbright.org",
    slack: "@britt.allen",
    calendlyUrl: "https://calendly.com/britt.allen",
    phone: "555-555-5555",
    availability: ["tue", "thu", "sat"],
    profile_image: BrittAllen,
  },
  {
    name: "Joachim Nik",
    course: "Workforce",
    courseNumber: "WF 251",
    department_name: "WF",
    department_number: "251",
    email: "joachim.nik@calbright.org",
    slack: "@joachim.nik",
    calendlyUrl: "https://calendly.com/joachim.nik",
    phone: "555-555-5555",
    officeHours: "Tue, Thu, Sat",
    availability: ["tue", "thu", "sat"],
    profile_image: JoachimNik,
  },
  {
    availability: ["sat", "sun"],
    calendly_link: "https://calendly.com/daniel-juarez-calbright",
    course: "Intro. to Networks",
    department_name: "IT",
    department_number: "532",
    email: "daniel.juarez@calbright.org",
    name: "Dan Juarez",
    profile_image:
      "https://drive.google.com/thumbnail?id=1kN5VgFUC5VPYIVsojxWrEgdMP8BwnSjy",
    slack: "https://calbright-college.slack.com/team/U05SLHWD3PU",
  },
  {
    availability: ["mon", "wed"],
    calendly_link: "https://calendly.com/laurence_butler",
    course: "Intro. to Networks",
    department_name: "IT",
    department_number: "532",
    email: "laurence.butler@calbright.org",
    name: "Laurence Butler",
    profile_image:
      "https://drive.google.com/thumbnail?id=11UBid6jQMfIZpkj61ifv0oUvJCR7x1og",
    slack: "https://calbright-college.slack.com/team/U05S0CNDL2K",
  },
];

/**
 * Counselor information used for demo on the MyTeam page.
 */
export const Counselor = {
  calendly_link:
    "https://calendly.com/calbright-academic-success-counselors/crm-program-individual-student-meeting-15min",
  email: "shemila@calbright.org",
  name: "Shemila Johnson",
  profile_image: ShemilaJohnson,
  role: "Academic Success Counselor",
  slack: "https://calbright-students.slack.com/archives/C01S6RVTSTU",
  text: "(510) 474-0551",
};

// Sample Data from API /my_team endpoint
export const RealData = {
  counselor: {
    calendly_link:
      "https://calendly.com/calbright-academic-success-counselors/crm-program-individual-student-meeting-15min",
    email: "shemila@calbright.org",
    name: "Shemila Johnson",
    profile_image:
      "https://drive.google.com/file/d/1H22nIq24aSPuNKmJnrWdOxWTFuZ8Tgyp/view?usp=sharing",
    role: "Academic Success Counselor",
    slack: "https://calbright-students.slack.com/archives/C01S6RVTSTU",
    text: "(510) 474-0551",
  },
  instructor: [
    {
      availability: ["sat", "sun"],
      calendly_link: "https://calendly.com/daniel-juarez-calbright",
      course: "Introduction to Networks",
      department_name: "IT",
      department_number: "532",
      email: "daniel.juarez@calbright.org",
      name: "Dan Juarez",
      profile_image:
        "https://drive.google.com/uc?id=1kN5VgFUC5VPYIVsojxWrEgdMP8BwnSjy",
      slack: "https://calbright-college.slack.com/team/U05SLHWD3PU",
    },
    {
      availability: ["mon", "wed"],
      calendly_link: "https://calendly.com/laurence_butler",
      course: "Introduction to Networks",
      department_name: "IT",
      department_number: "532",
      email: "laurence.butler@calbright.org",
      name: "Laurence Butler",
      profile_image:
        "https://drive.google.com/uc?id=11UBid6jQMfIZpkj61ifv0oUvJCR7x1og",
      slack: "https://calbright-college.slack.com/team/U05S0CNDL2K",
    },
  ],
};

/**
 * My Team mock data.
 * endpoint: /my_team
 * method: GET
 * Status: 200
 * Response: { counselor: {}, instructors: [{...}, {...}]}
 * Res
 */
export const mockTeamData = {
  counselor: {
    calendly_link:
      "https://calendly.com/calbright-academic-success-counselors/crm-program-individual-student-meeting",
    email: "shemila@calbright.org",
    name: "Shemila Johnson",
    phone: "(510) 474-0551",
    profile_image:
      "https://static.calbrightcollege.org/assets/counselors/thumbnails/3b500c08-1750-4053-a8e2-249ec4ecec79.png",
    role: "Success Counselor",
    slack: "https://calbright-students.slack.com/archives/C01S6RVTSTU",
  },
  instructors: [
    {
      availability: ["mon", "tue"],
      calendly_link: "https://calendly.com/mstewart-itsupport",
      course: "Technology Support (A+)",
      department_name: null,
      department_number: null,
      email: "michael@calbright.org",
      name: "Michael Stewart",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/050b9120-8729-4f29-b4e0-94db3d7af581.png",
      slack: "https://calbright-students.slack.com/team/U0215R9BGSW",
    },
    {
      availability: ["mon", "tue"],
      calendly_link: "https://calendly.com/odellcalbright/office-hours",
      course: "21st Century Skills",
      department_name: null,
      department_number: null,
      email: "ashley@calbright.org",
      name: "Ashley Odell",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/0d4738dd-8240-4cb5-a1d2-1abcce68bb2e.png",
      slack: "https://calbright-students.slack.com/team/U020R4LS97Z",
    },
    {
      availability: ["mon", "tue", "thu"],
      calendly_link: "https://calendly.com/michael-dudas",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "michael.dudas@calbright.org",
      name: "Michael Dudas",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/c3710549-405e-43a2-bf23-85c8567b10dc.png",
      slack: "https://calbright-students.slack.com/team/U0484DPGZ3J",
    },
    {
      availability: ["wed", "fri", "sun"],
      calendly_link: "https://calendly.com/cornelius-masonii",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "cornelius.masonii@calbright.org",
      name: "Cornelius Mason II",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/53cd5eb1-3493-4de9-822d-021f6c08bafb.png",
      slack: "https://calbright-students.slack.com/team/U047ENLB1C2",
    },
    {
      availability: ["mon", "tue"],
      calendly_link: "https://calendly.com/bringgenberg",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "bringgenberg@calbright.org",
      name: "Benjamin Ringgenberg",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/df6f03b8-2f65-4ef7-b0cb-86bad3719d48.png",
      slack: "https://calbright-students.slack.com/archives/D052JH2KTF0",
    },
    {
      availability: ["mon", "tue", "wed", "thu"],
      calendly_link: "https://calendly.com/mfranklin-calbright",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "michael.franklin@calbright.org",
      name: "Michael Franklin",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/164b59e4-b46a-4afb-b8cd-fc028ff73da3.png",
      slack: "https://calbright-students.slack.com/team/U05V3047G13",
    },
    {
      availability: ["sat", "mon", "wed"],
      calendly_link: "https://calendly.com/ola-moore/",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "oladele.moore@calbright.org",
      name: "Oladele Moore",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/3355ae6c-6f35-40f3-afb8-573cdc738729.png",
      slack: "https://calbright-students.slack.com/team/U04JTL9S8QL",
    },
    {
      availability: ["mon", "tue", "wed", "thu", "fri"],
      calendly_link: "https://calendly.com/lauren-van-talia",
      course: "IT Support",
      department_name: null,
      department_number: null,
      email: "lauren-van.talia@calbright.org",
      name: "Lauren VanTalia",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/b0468087-4336-41bd-92dc-a27f61f44c66.png",
      slack: "https://calbright-students.slack.com/team/U06L126J8DC",
    },
    {
      availability: ["sun", "mon", "thu", "fri"],
      calendly_link: "https://calendly.com/tasha-johnson-office-hours/",
      course: "21st Century Skills",
      department_name: null,
      department_number: null,
      email: "tasha.johnson@calbright.org",
      name: "Tasha Johnson",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/aa7cd6c6-91dd-416c-a71c-a2c63c3666e5.png",
      slack: "https://calbright-students.slack.com/team/U0694TKTH38",
    },
    {
      availability: ["sat", "sun", "tue", "wed"],
      calendly_link: "https://calendly.com/luisjr-munguiarivera",
      course: "21st Century Skills",
      department_name: null,
      department_number: null,
      email: "luisjr.munguiarivera@calbright.org",
      name: "Luis Munguia Rivera",
      profile_image:
        "https://static.calbrightcollege.org/assets/instructors/thumbnails/8cea3c70-c7d0-4f92-a357-3eb60d8a2641.png",
      slack: "https://calbright-students.slack.com/team/U05V33NCLA1",
    },
  ],
};

/**
 * Mock data for Schedule Kickoff step of onboarding.
 * endpoint: /scheduled_kickoff
 * method: POST
 * Status: 200
 * Response: {message: "Kickoff scheduled updated successfully for <mock.user@calbright.org>", user_updated: true}
 */
export const mockKickoffScheduled_Response = {
  message:
    "Kickoff scheduled updated successfully for <mock.user@calbright.org>",
  user_updated: true,
};

/**
 * Mock data for broadcast messages.
 * endpoint: /banners
 * method: GET
 * status: 200
 * Response: {banners: [{...}, {...}]}
 */
export const mockBroadcastMessage_Response = {
  banners: [
    {
      id: "6a25267d-2782-4141-b5b8-d9438b9e7c67",
      body: "my test banner1",
      dismissible: true,
      title: "test1",
      type: "warning",
    },
    {
      id: "4474c7f5-10b0-4217-b866-54d90877231b",
      body: "Everyone needs to see this! bannertest",
      dismissible: false,
      title: "Important Test",
      type: "info",
    },
    {
      id: "40a8eed9-575e-44e8-921c-332fc78abea9",
      body: "Another bannertest",
      dismissible: true,
      title: "Date Test",
      type: "success",
    },
    {
      id: "5add980c-aaef-4da8-b048-c6d786b3b6e3",
      body: "Another bannertest",
      dismissible: true,
      title: "Date Test",
      type: "success",
    },
    {
      id: "73982b95-1f79-47ba-83a7-83c89cc24ddd",
      body: "No title bannertest",
      dismissible: true,
      title: null,
      type: "info",
    },
  ],
};

export const mockBroadcastMessage_Response02 = {
  banners: [
    {
      id: 1,
      title: "Important Test",
      body: "Everyone needs to see this! bannertest",
      dismissible: false,
      type: "info",
    },
    {
      id: 2,
      title: "Date Test",
      body: "Another bannertest",
      dismissible: true,
      type: "success",
    },
    {
      id: 3,
      title: "Date Test",
      body: "Another bannertest",
      dismissible: true,
      type: "success",
    },
  ],
};

/**
 * Mock data for avatars.
 * endpoint: /avatars
 * method: GET
 * status: 200
 * Response: {avatars: [...]}
 */
export const mockAvatarsAPI = {
  avatars: [
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/female_brown.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/female_pink.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/female_white.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/male_brown.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/male_pink.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/male_white.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/user_1.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/user_2.svg",
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/student-avatars/assets/student-avatars/user_3.svg",
  ],
};

/**
 * Mock data for avatar.
 * endpoint: /avatar
 * method: POT
 * status: 200
 * Response: {avatar_link: "..."}
 */
export const mockUpdatedAvatarAPI_Request = {
  avatar_link:
    "https://s3.us-west-2.amazonaws.com/static.calbrightcollege.org/assets/students/thumbnails/User_Avatar_Default.png",
};

/**
 * Mock data for avatar.
 * endpoint: /avatar
 * method: POST
 * status: 200
 * Response: {avatar_updated: true, message: "Avatar updated successfully for <user-email-address>"}
 */
export const mockUpdatedAvatarAPI_Response = {
  avatar_updated: true,
  message: "Avatar updated successfully for <user-email-address>",
};

export const mockFTUXViewed_Response = {
  message: "Student portal event ftux_viewed saved",
};

export const mockFirstSAAViewed_Response = {
  message: "Student portal event first_saa_viewed saved",
};

// Onboard - Demo data
export const userProgress = {
  title: "My Tasks",
  progress: 0,
  section: "onboarding",
  program: "Data Analysis",
  date: "",
  milestone: {
    name: "Onboarding",
    progress: 0,
    type: "onboarding",
  },
  courses: [],
};

// Default apps enabled
export const appsEnabled = ["slack", "gmail", "googleDrive", "googleCalendar"];
export const helpIconsEnabled = ["IconCU"];
