import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from "./../Button/Button";

import './textarea.css';

export const Textarea = ({
  title,
  placeholder,
  value,
  rows,
  notice,
  submitValue,
  getValue,
  buttonLabel,
  maxLength,
  error,
  btnLoading
}) => {
  const [text, setText] = useState(value || '');
  const [textError, setTextError] = useState(error);

  const handleText = (e) => {
    setTextError(null);

    if (e.target.value.length > maxLength) {
      setTextError(`Your text exceeds the maximum allowed length of ${maxLength} chars.`);
      return;
    }

    const updatedText = e.target.value;
    setText(updatedText);

    if (getValue) {
      getValue(updatedText);
    }
  }

  useEffect(() => {
    setTextError(error);
  }, [error])

  return (
    <div className="storybook-textarea">
      {title && (
        <h2 className="head-thin">{title}</h2>
      )}
      <textarea
        placeholder={placeholder}
        rows={rows}
        onChange={handleText}
        value={text}
        data-testid="textarea"
      ></textarea>
      <p className="text-right">{text.length}/{maxLength} Characters</p>
      {notice && <p className="storybook-notice">{notice}</p>}
      {textError && <p className="error text-center">{textError}</p>}
      <div className="storybook-textarea-btn-area">
        {(textError || text.length < 3)
          ?
            <Button
              buttonType="basic"
              disabled
              label={buttonLabel}
            />
          :
            <Button
              label={buttonLabel}
              onClick={submitValue}
              isLoading={btnLoading}
            />
        }
      </div>
    </div>
  );
};

Textarea.propTypes = {
  /**
   * Textarea component title.
   */
  title: PropTypes.string.isRequired,
  /**
   * Textarea component placeholder.
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Textarea component default value.
   */
  value: PropTypes.string,
  /**
   * Textarea component height value.
   */
  rows: PropTypes.string,
  /**
   * Notice message.
   */
  notice: PropTypes.string,
  /**
   * Submit textarea value.
   */
  submitValue: PropTypes.func,
  /**
   * Return textarea value to parent.
   */
  getValue: PropTypes.func,
  /**
   * Button label.
   */
  buttonLabel: PropTypes.string,
  /**
   * Max length of text
   */
  maxLength: PropTypes.number,
  /**
   * Validation error message
   */
  error: PropTypes.string,
  /**
   * Button loading state
   */
  btnLoading: PropTypes.bool,
};

Textarea.defaultProps = {
  title: "Please enter your question here. Someone from Success Team will reach out to you",
  placeholder: "Type your question here",
  value: "",
  rows: "6",
  buttonLabel: "Next",
  maxLength: 3000,
  btnLoading: false
};