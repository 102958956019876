import React from "react";
import PropTypes from "prop-types";
import { getCurrentDay } from "../../utils/helpers";

import "./welcome-message.css";

export const WelcomeMessage = ({
  name,
  introMsg,
  messageHead,
  currentTask,
  isCompletion,
  lastLogin,
  recentAssignment,
  programName,
  lms,
}) => {
  const currentDay = getCurrentDay();

  const onboardingMsg = `${introMsg} ${currentTask}.`;
  let customCourseWorkMsg;

  switch (lms) {
    case "Strut":
      customCourseWorkMsg = `${currentDay}. It’s been ${recentAssignment} since you completed an assignment.`;
      break;
    case "Trailhead":
      customCourseWorkMsg = `${currentDay}. It’s been ${recentAssignment} since your last Badge completion.`;
      break;
    default:
      // default message (includes Canvas)
      customCourseWorkMsg = `${currentDay}. It’s been ${lastLogin} since your last login, and ${recentAssignment} since your most recent ${lms} assignment completion.`;
      break;
  }

  return (
    <div className="storybook-welcome-message">
      <div className="page-title">
        <h1>
          {messageHead}, {name}!
          {!isCompletion &&
            programName &&
            ` Welcome to the ${programName} program.`}
        </h1>
        <p data-testid="welcomeMsg">
          {isCompletion ? customCourseWorkMsg : onboardingMsg}
        </p>
      </div>
    </div>
  );
};

WelcomeMessage.propTypes = {
  /**
   * Welcome message
   */
  introMsg: PropTypes.string,
  /**
   * Program name
   */
  programName: PropTypes.string,
  /**
   * Student name
   */
  name: PropTypes.string,
  /**
   * Hello message
   */
  messageHead: PropTypes.string,
  /**
   * Current task title
   */
  currentTask: PropTypes.string,
  /**
   * Current task title
   */
  isCompletion: PropTypes.bool,
  /**
   * Last login value
   */
  lastLogin: PropTypes.string,
  /**
   * Recent assignment value
   */
  recentAssignment: PropTypes.string,
  /**
   * Learning management system value
   */
  lms: PropTypes.string,
};

WelcomeMessage.defaultProps = {
  introMsg:
    "Continue your student journey at Calbright by finishing this task:",
};
