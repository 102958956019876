import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * useAuthentication customm hook handles authentication logic
 * @param {*} param0
 * @returns
 */
export const useAuthentication = ({
  requireAuth,
  token,
  user,
  saveToken,
  saveUser,
}) => {
  const [authState, setAuthState] = useState({
    isLoading: true,
    authError: null,
    isAuthenticated: false,
  });
  const history = useHistory();

  const setPreviousUrl = () => {
    const previousUrl = window.location.href.includes("appointment?type")
      ? window.location.origin
      : window.location.href;
    localStorage.setItem("previousUrl", previousUrl);
  };

  const getPreviousUrl = () => {
    if (localStorage.getItem("previousUrl") === null) {
      return "/";
    }
    const prevURL = new URL(localStorage.getItem("previousUrl"));
    let prevURLPath = prevURL.pathname;
    let prevURLSearch = prevURL.search !== null ? prevURL.search : "";
    return prevURLPath !== null ? prevURLPath + prevURLSearch : "/";
  };

  useEffect(() => {
    const hash = new URLSearchParams(location.hash);
    const accessToken = hash.get("#access_token");

    if (
      requireAuth &&
      accessToken === null &&
      Object.keys(token).length === 0
    ) {
      setPreviousUrl();
      window.location.replace(process.env.AUTH_URL);
      return;
    }

    if (requireAuth && Object.keys(token).length === 0) {
      try {
        const currentToken = saveToken();
        saveUser(currentToken)
          .then((userData) => {
            if (
              !userData ||
              Object.keys(userData).length === 0 ||
              userData?.error
            ) {
              const error = {
                name: "AuthenticationError",
                message:
                  userData?.error?.response?.data?.error ||
                  userData?.error?.message ||
                  "Invalid user data received",
                stack: new Error().stack,
              };
              setAuthState({
                isLoading: true,
                authError: error,
                isAuthenticated: false,
              });
              return;
            }
            setAuthState({
              isLoading: false,
              authError: error,
              isAuthenticated: true,
            });
            const prevURL = getPreviousUrl();
            history.push(prevURL);
          })
          .catch((error) => {
            const errorObj = {
              name: "AuthenticationError",
              message: error.message || "Failed to authenticate user",
              stack: error.stack,
            };
            setAuthState({
              isLoading: true,
              authError: errorObj,
              isAuthenticated: false,
            });
          });
      } catch (error) {
        const errorObj = {
          name: "AuthenticationError",
          message: error.message || "Unable to save token or user",
          stack: error.stack,
        };
        setAuthState({
          isLoading: true,
          authError: errorObj,
          isAuthenticated: false,
        });
      }
    }
  }, [user, requireAuth, token, saveToken, saveUser]);

  return { authState };
};
