/**
 * Handles API responses
 * @param {*} response
 */
export async function handleResponse(response) {
  if (response.status === 200) {
    return response.data;
  }
  const error = await response.statusText;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    throw new Error("Server error: ", error);
  }

  throw new Error("Network response was not ok.", error);
}

/**
 * Handle errors that occur during API calls.
 * @param {*} error
 */
export function handleError(error) {
  if (error === "No token in request") return; // This error is handled by ErrorPage
  let message =
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    "Something went wrong. Please try again later.";

  if (error.response) {
    switch (error.response.status) {
      case 400:
        message =
          message || "Invalid request. Please check your input and try again.";
        break;
      case 401:
        message = message || "Unauthorized. Please log in again.";
        break;
      case 403:
        message =
          message || "You do not have permission to access this resource.";
        break;
      case 404:
        message = message || "The requested resource could not be found.";
        break;
      case 408:
        message = message || "Request timeout. Please try again.";
        break;
      case 429:
        message = "Too many requests. Please wait and try again later.";
        break;
      case 500:
      case 502:
      case 503:
      case 504:
        message =
          message ||
          "A server error occurred. Please contact support if the problem persists.";
        break;

      default:
        message = "An unexpected error occurred. Please try again later.";
    }
  }

  return { error: { ...error, message } };
}
