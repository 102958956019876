import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "../api/axios";

/**
 * Retrieve avatars from /avatars endpoint.
 * @param { string } token
 * @returns {avatars[]} avatars
 */
export function fetchAvatars(token) {
  return axiosInstance
    .get("/avatars", {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then(handleResponse)
    .then((data) => {
      return data.avatars.map((avatar, index) => ({
        icon: updateAvatarUrl(avatar),
        id: `avatar-${index + 1}`,
      }));
    })
    .catch(handleError);
}

/**
 * Sends a post request to /avatar endpoint to update avatar.
 * @param {string} profileImageUrl
 * @param { string } token
 * @returns
 */
export function updateAvatar(token, profileImageUrl) {
  return axiosInstance
    .post(
      "/avatar",
      { avatar_link: profileImageUrl },
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Removes AWS and url format to correct static url.
 * @param {string} avatar
 * @returns
 */
export const updateAvatarUrl = (avatar) => {
  return avatar
    .replace(
      "/assets/student-avatars/assets/student-avatars/",
      "/assets/student-avatars/"
    )
    .replace("s3.us-west-2.amazonaws.com/", "");
};
