import React, { useState } from "react";
import PropTypes from "prop-types";

import MenuIcon from "@mui/icons-material/Menu";
import IconClose from "@mui/icons-material/Close";
import CalbrightLogo from "../assets/calbright_logo.svg";
import { List } from "../List/List";

import { menuData, menuIds } from "../../utils/data";

import "./menu.css";

/**
 * Menu holds links used for navigation.
 */
export const Menu = ({
  items,
  isDropdown,
  active,
  name,
  openDropdown,
  children,
}) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const openSideNav = () => {
    setIsSideNavOpen(true);
  };

  const closeSideNav = () => {
    setIsSideNavOpen(false);
  };

  return (
    <div
      className="storybook-menu-container"
      data-testid={`${name}-container`}
      id={`${name}Container`}
    >
      <div className="storybook-menu-toggle" data-testid="menu-toggle">
        <MenuIcon onClick={openSideNav} />
        <div
          className={`storybook-sidenav ${isSideNavOpen ? "open" : ""}`}
          id="sidenav"
        >
          <header>
            <button
              className="storybook-sidenav-close"
              data-testid="closeSideNav"
              onClick={closeSideNav}
            >
              <IconClose />
              <span className="sr-only">Close Menu</span>
            </button>
            <a href="https://www.calbright.edu" className="storybook-logo">
              <img src={CalbrightLogo} alt="Calbright College" />
            </a>
          </header>
          <List id="menu-mobile" items={items} />
        </div>
      </div>
      <List
        id="menu-desktop"
        items={items}
        isdropdown={isDropdown}
        active={active}
        openDropdown={openDropdown}
        extraItem={children}
      />
    </div>
  );
};

Menu.propTypes = {
  /**
   * Items is an array of objects that represent a menu item.
   */
  items: PropTypes.array.isRequired,
  /**
   * Indicate if this menu is a dropdown menu.
   */
  isDropdown: PropTypes.bool,
  /**
   * Determines which link in the main menu is active.
   */
  // active: PropTypes.oneOf([ "home", "my-team", "calendar", "services", "resources" ]).isRequired,
  active: PropTypes.oneOf(menuIds).isRequired,
};

Menu.defaultProps = {
  items: menuData,
  isDropdown: false,
  active: "home",
};
