import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";

import App from "./App";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

import "@storybook/styles/global.css";
import "./index.css";

const store = configureStore();

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // this.state.error = error;
    return { hasError: true, error: error, errorInfo: errorInfo };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage error={this.state} />;
    }

    return this.props.children;
  }
}

render(
  <ErrorBoundary FallbackComponent={<ErrorPage />}>
    <ReduxProvider store={store}>
      <Router>
        <App />
      </Router>
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById("app")
);
