import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { switchProgramPrereq } from "../../../api/prereqApi";
import { preReqStepAction } from "../../../redux/actions/preReqStepAction";
import { updateUserProgramAction } from "../../../redux/actions/userActions";
import { studentProgramLookup } from "../../../utils/helpers/data";

import { Button } from "@storybook/stories/Button/Button";
import { preReqCongr } from "./PreReqCongr";
import { preReqSupport } from "./PreReqSupport";
import { preReqMain } from "./../PreReqMain";

const PreReqDenied = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const recommendedProgram =
    user.prereqRecommendedProgram || studentProgramLookup.IT_SUPPORT;
  const isSubmAllowed =
    user?.prereqCertSubmissionAllowed || user?.prereqExpProofSubmissionAllowed;

  const preReqEnroll = {
    ...preReqCongr,
    confirmText: null,
    showCloseIcon: false,
    children: <EnrolledMsg />,
  };

  const submitSwitchProgram = async () => {
    setError(null);
    setLoading(true);
    const response = await switchProgramPrereq(token);
    setLoading(false);

    if (!response.success) {
      setError(response.message);
    } else {
      dispatch(updateUserProgramAction(recommendedProgram));
      dispatch(preReqStepAction(preReqEnroll));
    }
  };

  return (
    <>
      <h2 className="head-thin">
        {isSubmAllowed ? (
          <>
            Unfortunately, you do not meet the requirements for this program.
            However, we can enroll you in the {recommendedProgram} program to acquire the
            necessary experience. Would you like to be enrolled in the {""}
            {recommendedProgram} program?
          </>
        ) : (
          <>
            Unfortunately, you do not qualified for this program. You will have
            30 days to re-submit your application. However, would you like to be
            enrolled in the {recommendedProgram} program?
          </>
        )}
      </h2>
      <div className="flex flex-col pre-req-btns">
        <Button
          buttonType="basic"
          size="medium"
          label={`Yes, enroll me in the ${recommendedProgram} Program`}
          onClick={() => submitSwitchProgram()}
          isLoading={loading}
        />
        <Button
          buttonType="basic"
          size="medium"
          label="No, but I want to contact Student Support"
          onClick={() => dispatch(preReqStepAction(preReqSupport))}
        />
        <Button
          buttonType="basic"
          size="medium"
          label="No, I want to try again"
          disabled={!isSubmAllowed}
          onClick={() =>
            isSubmAllowed ? dispatch(preReqStepAction(preReqMain)) : null
          }
        />
      </div>
      {error && <p className="error text-center">{error}</p>}
    </>
  );
};

const EnrolledMsg = () => {
  const user = useSelector((state) => state.user);
  const recommendedProgram =
    user.prereqRecommendedProgram || studentProgramLookup.IT_SUPPORT;

  return (
    <>
      <p>
        Great choice! We have enrolled you in {recommendedProgram} program.
        <br />
        Click Continue to return to your dashboard.
      </p>
      <div className="lato-regular mt-30">
        <Button
          buttonType="default"
          size="medium"
          label="Continue"
          onClick={() => location.reload()}
        />
      </div>
    </>
  );
};

export const preReqDenied = {
  icon: null,
  title: null,
  openDialog: true,
  showCloseIcon: false,
  confirmText: null,
  size: "extra-large",
  showCloseIcon: true,
  extraClass: "modal-prereq text-left",
  children: <PreReqDenied />,
};
