import * as types from "./actionTypes";
import * as avatarApi from "../../api/avatarApi";
import { handleError } from "../../api/apiUtils";
import { beginApiCall, apiCallError } from "./apiStatusActions.js";

export function fetchAvatarsSuccess(avatars) {
  return { type: types.FETCH_AVATARS_SUCCESS, avatars };
}

export function updateAvatarSuccess(avatar) {
  return { type: types.UPDATE_AVATAR_SUCCESS, avatar };
}

export function fetchAvatars(token) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const avatars = await avatarApi.fetchAvatars(token);
      dispatch(fetchAvatarsSuccess(avatars));
      return avatars;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function updateAvatar(token, profileImageUrl) {
  return async function (dispatch, getState) {
    dispatch(beginApiCall());
    try {
      avatarApi.updateAvatar(token, profileImageUrl);
      dispatch(updateAvatarSuccess(profileImageUrl));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
