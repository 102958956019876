import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './avatarlist.css';

/**
 * List of Avatars or icons and ability to select one from the list.
 * Use/implement triggerAvatarUpdate prop to retrieve selected avatar in parent component.
 * @param {*} Avatars, triggerAvatarUpdate 
 * @returns selected item
 */
export const AvatarList = ({ avatars, triggerAvatarUpdate }) => {
  const [activeAvatarId, setActiveAvatarId] = useState();

  return (
    <div className="storybook-avatar-list" data-testid="avatarlistContainer">
      {avatars.map((avatar) => (
          <img 
            src={avatar.icon} 
            alt={avatar.id} 
            key={avatar.id} 
            data-testid={`avatar-${avatar.id}`}
            onClick={()=> {triggerAvatarUpdate(avatar); setActiveAvatarId(avatar.id) }} 
            className={`avatar${(activeAvatarId === avatar.id) ? ' active' : ''}`}
          />
        )
      )}                                
    </div> 
  );
}

AvatarList.propTypes = {
  /**
   * avatars is an array of objects.
   * id unique identifier used to select an avatar.
   * icon can be a string or object usually the src of the image/avatar.
   */
  avatars: PropTypes.array,  
  /**
   * Function used to bubble up selected avatar to parent component.
   * Implement this function in the parent component.
   */
  triggerAvatarUpdate: PropTypes.func,
};