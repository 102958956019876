import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";
import { NavLink } from "react-router-dom";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Wellness } from "@storybook/stories/Wellness/Wellness";
import { Accordion } from "@storybook/stories/Accordion/Accordion";
import { Skeleton } from "@mui/material";

import { learnerStatusLookup } from "../../utils/helpers/data";

import { fetchStaticWellness } from "../../redux/actions/staticActions";

import "./services.css";
import { handleError } from "../../api/apiUtils";

export const Services = ({ user }) => {
  if (Object.keys(user).length === 0) {
    return <Redirect to={process.env.AUTH_REDIRECT_URL} />;
  }
  const dispatch = useDispatch();
  const wellnessServices = useSelector(
    (state) => state.staticContent?.wellnessServices
  );
  const [staticData, setStaticData] = useState(null);
  const enrolled =
    user.learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"];
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleAuth = () => {
    setIsLoading(true);
    window.open(process.env.AUTH_TIMELY_CARE, "_blank");
    setIsLoading(false);
    setMessage(
      "You have been successfully redirected to TimelyCare authentication."
    );
  };

  /**
   * Converts html string to React node and changes internal links to NavLinks.
   * @param {string} htmlString
   * @returns
   */
  const convertContentToReactNode = (htmlString) => {
    if (!htmlString) return null;

    const sanitizedHtml = DOMPurify.sanitize(htmlString);

    const options = {
      replace: (domNode) => {
        if (domNode.type === "tag" && domNode.name === "a") {
          const href = domNode.attribs?.href;

          if (href?.startsWith("/")) {
            return (
              <NavLink to={href} className={domNode.attribs?.class || ""}>
                {domToReact(domNode.children, options)}
              </NavLink>
            );
          }

          return (
            <a
              href={href}
              className={domNode.attribs?.class || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {domToReact(domNode.children, options)}
            </a>
          );
        }
      },
    };

    return parse(sanitizedHtml, options);
  };

  const closeMessage = () => {
    setMessage(null);
  };

  const convertFaqs = (items) => {
    return items.map((item) => ({
      ...item,
      content: convertContentToReactNode(item.content),
    }));
  };
  const updateImagePath = (items) => {
    return items.map((item) => ({
      ...item,
      image: process.env.STATIC_S3_URL + item.image,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const staticResponse =
          wellnessServices ||
          (await dispatch(
            fetchStaticWellness("/common/wellness-services.json")
          ));

        const processedData = {
          ...staticResponse,
          subTitle: convertContentToReactNode(staticResponse?.subTitle),
          wellnessItems: updateImagePath(staticResponse?.wellnessItems),
          cta: {
            ...staticResponse?.cta,
            body: staticResponse?.cta?.body,
            bodyNotEnrolled: staticResponse?.cta?.bodyNotEnrolled,
          },
          faq: convertFaqs(staticResponse?.faq),
        };

        setStaticData(processedData);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, wellnessServices]);

  if (isLoading || !staticData) {
    return (
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1.5rem",
          width: "calc(800px - 40vw)",
          maxWidth: "100%",
          margin: "10px",
        }}
      />
    );
  }

  return (
    <article className="services">
      <PortalHeader user={user} activeLink="services" />
      <main>
        <div className="container">
          <Wellness
            items={staticData?.wellnessItems}
            enrolled={enrolled}
            title={staticData?.title}
            subtitle={staticData?.subTitle}
            cardTitle={staticData?.cta.cardTitle}
            cardContent={staticData?.cta.body}
            notEnrCardContent={staticData?.cta.bodyNotEnrolled}
            btnLabel={staticData?.cta?.btnLabel}
            btnSubmit={handleAuth}
            isLoading={isLoading}
            closeMessage={closeMessage}
            message={message}
          />
          <Accordion
            items={staticData?.faq}
            title="Frequently Asked Questions"
          />
        </div>
      </main>
    </article>
  );
};

Services.propTypes = {
  user: PropTypes.shape({}),
};

export default Services;
