import React from "react";
import PropTypes from "prop-types";
import { TaskProgressItem } from "../TaskProgressItem/TaskProgressItem";
import { useHistory } from "react-router-dom";

import {
  Milestone1,
  Milestone1b,
  Milestone1c,
  Milestone2,
  progressStages
} from "../../utils/tasks";

import "./taskprogressbar.css";

export const TaskProgressBar = ({
  date,
  progress,
  section,
  milestoneDetails,
  stages,
  handleCompleteFirstAssignment,
  progressStages
}) => {
  let studentStageType = stages;
  const history = useHistory();

  if (section === "milestone") {
    switch (milestoneDetails) {
      case "Milestone1":
        studentStageType = Milestone1.courses;
        break;
      case "Milestone1b":
        studentStageType = Milestone1b.courses;
        break;
      case "Milestone1c":
        studentStageType = Milestone1c.courses;
        break;
      case "Milestone2":
        studentStageType = Milestone2.courses;
        break;
      default:
        studentStageType = stages;
    }
  }

  let taskStep = 1;
  if (section === "onboarding") {
    studentStageType.forEach((studentTask, index) => {
      studentTask.progress = "default";

      for (const stage of progressStages) {
        if (progress === stage.progress) {
          taskStep = stage.step;
          if (index < stage.status.length) {
            studentTask.progress = stage.status[index];
          }
          break;
        }
      }

      if (progress === 100 && studentStageType.length === 4) {
        taskStep = 4;
        if (index < 3) {
          studentTask.progress = "passed";
        } else {
          studentTask.progress = "assignment-1";
        }
      }

      return studentTask;
    });
  }

  const handleTaskClick = (studentTask) => {
    const linkUrl = studentTask.linkUrl;
    const progress = studentTask.progress;

    if (studentTask.handleClick) {
      studentTask.handleClick();
      return;
    }

    if (progress === "started") {
      if (linkUrl.includes("http")) {
        window.open(linkUrl, "_blank");
      } else {
        if (linkUrl.includes("complete-first-assignment")) {
          handleCompleteFirstAssignment();
        } else {
          history.push(linkUrl);
        }
      }
    }
  };

  return (
    <>
      <div
        className={`storybook-task-progress-wrapper storybook-task-progress-wrapper--${section}`}
        data-testid="progressbarProgressWrapper"
      >
        <div className="storybook-task-progress-line__default">
          <div
            className="storybook-task-progress-line__progress"
            data-testid="progressbarProgress"
            style={{ width: `${progress}%` }}
          />
        </div>

        <div className="storybook-task-progress-bar">
          {studentStageType.map((studentTask, index) => (
            <TaskProgressItem
              key={index}
              progress={studentTask.progress}
              statusLabel={studentTask.statusLabel}
              subtitle={studentTask.subtitle}
              title={studentTask.title}
              section={section}
              onClick={() => handleTaskClick(studentTask)}
            />
          ))}
        </div>
        <strong data-testid="progressLabel">{date}</strong>
      </div>

      {section === "onboarding" && (
        <>
          <hr />
          <div className="storybook-task-progress-desc">
            <h2>Current Task Information</h2>
            <h3>
              Step {taskStep}: {studentStageType[taskStep - 1].title}
            </h3>
            <p>{studentStageType[taskStep - 1].description}</p>
          </div>
        </>
      )}
    </>
  );
};

TaskProgressBar.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.number,
  section: PropTypes.string,
};

TaskProgressBar.defaultProps = {
  progressStages
};
