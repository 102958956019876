/**
 * List of app features and whether they are enabled in staging only.
 * envOverrideName - use in case we want to override the feature flag in specific env.
 */
const FEATURES = {
  AR_SERVICES: {
    enabled: true,
    stagingOnly: false,
    envOverideName: process.env.FEATURE_AR_SERVICES,
  },
  CALENDLY_LINKS_SANDBOX: {
    enabled: true,
    stagingOnly: true,
    envOverideName: process.env.FEATURE_CALENDLY_LINKS_SANDBOX,
  },
};

/**
 * If it's a staging-only feature and we're in production, disable it.
 * If stage features are enabled, return the feature's enabled status.
 * Check for specific environment override in env
 * @param {*} flagName
 * @returns
 */
export const getFeatureFlag = (flagName) => {
  const isStageEnabled =
    process.env.STAGE_FEATURE_ENABLED === "true" ||
    process.env.INT_FEATURE_ENABLED === "true";

  if (!(flagName in FEATURES)) {
    return false;
  }

  const isProduction = process.env.NODE_ENV === "production";

  const feature = FEATURES[flagName];
  if (feature.stagingOnly && isProduction) {
    return false;
  }

  const envOverride = FEATURES[flagName].envOverideName;

  if (envOverride !== undefined) {
    return envOverride === "true";
  }

  if (isStageEnabled) {
    return feature.enabled;
  }

  return false;
};

export const FEATURE_FLAGS = Object.keys(FEATURES).reduce((acc, key) => {
  acc[key] = FEATURES[key].enabled;
  return acc;
}, {});
