export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const SET_USER_SUCCESS = "SET_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const SAVE_CODE_SUCCESS = "SAVE_CODE_SUCCESS";

export const LOAD_TOKEN_SUCCESS = "LOAD_TOKEN_SUCCESS";
export const SAVE_TOKEN_SUCCESS = "SAVE_TOKEN_SUCCESS";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";

export const LOAD_APPOINTMENT_SUCCESS = "LOAD_APPOINTMENT_SUCCESS";
export const SET_APPOINTMENT_SUCCESS = "SET_APPOINTMENT_SUCCESS";

export const LOAD_APPOINTMENTLINKS_SUCCESS = "LOAD_APPOINTMENTLINKS_SUCCESS";
export const SET_APPOINTMENTLINKS_SUCCESS = "SET_APPOINTMENTLINKS_SUCCESS";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";

export const FETCH_TEAMDATA_SUCCESS = "FETCH_TEAMDATA_SUCCESS";

export const FETCH_AVATARS_SUCCESS = "FETCH_AVATARS_SUCCESS";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";

export const KICKOFF_SCHEDULED_SUCCESS = "KICKOFF_SCHEDULED_SUCCESS";

export const PRE_REQ_MOVE_STEP = "PRE_REQ_MOVE_STEP";
export const UPDATE_USER_PREREQ = "UPDATE_USER_PREREQ";
export const UPDATE_USER_PROGRAM = "UPDATE_USER_PROGRAM";

export const SHOW_LOADING = "SHOW_LOADING";

export const SET_BROADCAST_MESSAGES_SUCCESS = "SET_BROADCAST_MESSAGES_SUCCESS";

export const FIRSTSAA_VIEWED_SUCCESS = "FIRSTSAA_VIEWED_SUCCESS";
export const FTUX_VIEWED_SUCCESS = "FTUX_VIEWED_SUCCESS";
export const FTUX_RESET_SUCCESS = "FTUX_RESET_SUCCESS";

export const SET_TOPIC_OF_MONTH_SUCCESS = "TOPIC_OF_MONTH_SUCCESS";

export const SET_DEVICES_SUCCESS = "SET_DEVICES_SUCCESS";

export const FETCH_ARSERVICES_SUCCESS = "FETCH_ARSERVICES_SUCCESS";

export const POST_ENROLLMENTVERIFICATION_SUCCESS =
  "POST_ENROLLMENTVERIFICATION_SUCCESS";

export const FETCH_COMPLETED_PROGRAMS_SUCCESS =
  "FETCH_COMPLETED_PROGRAMS_SUCCESS";

export const TOGGLE_THEME = "TOGGLE_THEME";

export const FETCH_STATIC_DASH_SUCCESS = "FETCH_STATIC_DASH_SUCCESS";
export const FETCH_STATIC_MYTEAM_SUCCESS = "FETCH_STATIC_MYTEAM_SUCCESS";
export const FETCH_STATIC_CAREERSERV_SUCCESS =
  "FETCH_STATIC_CAREERSERV_SUCCESS";
export const FETCH_VIRTUAL_ID_SUCCESS = "FETCH_VIRTUAL_ID_SUCCESS";
export const FETCH_STATIC_WELLNESS_SUCCESS = "FETCH_STATIC_WELLNESS_SUCCESS";
