import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./button.css";

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  buttonType,
  backgroundColor,
  size,
  label,
  disabled,
  onClick,
  isLoading,
  children,
  ...props
}) => {

  const [loading, setLoading] = useState(isLoading || false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClick = async () => {
    if (onClick) {
      setLoading(true); // Set loading to true when the button is clicked

      try {
        // Simulate a delay of 1 sec. Remove if onClick() is a true API call.
        await new Promise((resolve) => setTimeout(resolve, 500));

        await onClick();
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }
  };

  const btnClass = [
    "storybook-button",
    `storybook-button--${size}`,
    `${loading ? "btn-loading transparent" : ""}`
  ];

  switch (buttonType) {
    case "primary":
      btnClass.push("storybook-button--primary");
      break;
    case "secondary":
      btnClass.push("storybook-button--secondary");
      break;
    case "basic":
      btnClass.push("storybook-button--basic");
      break;
    default:
      btnClass.push("storybook-button--default");
  }

  return (
    <button
      type="button"
      className={btnClass.join(" ")}
      style={backgroundColor && { backgroundColor }}
      {...props}
      disabled={disabled}
      onClick={!loading ? handleClick : undefined}
    >
      {label}
      {children}
      {loading && (
        <span className="loading">...</span>
      )}
    </button>
  );
};

Button.propTypes = {
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * Type of button (default, basic, primary, secondary)
   */
  buttonType: PropTypes.oneOf(["default", "basic", "primary", "secondary"]),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["tiny","small", "medium", "large"]),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,  
  /**
   * Sets the button state to disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Controls the loading state of the button.
   */
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  label: "Button Text",
  buttonType: "default",
  backgroundColor: null,
  size: "medium",
  onClick: undefined,
  disabled: false,
  isLoading: false,
};
