import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "../api/axios";

/**
 * Function retrieves user data from the /user endpoint.
 * @param {JWT} token
 * @returns user
 */
export function getUser(token) {
  return axiosInstance
    .get("/user", { headers: { Authorization: `Bearer ${token?.idToken}` } })
    .then(handleResponse)
    .then((user) => {
      return { ...user, photoUrl: user.profileImageUrl, hasPhoto: true };
    })
    .catch(handleError);
}

/**
 * Function updates user profile info.
 * @param {*} user
 * @returns
 */
export function updateUser(user) {
  return axiosInstance
    .post("/user", { user })
    .then(handleResponse)
    .catch(handleError);
}

/**
 * setKickOffScheduled - Sets kickoff scheduled to intended program.
 */
export const setKickOffScheduled = (token) => {
  return axiosInstance
    .post(
      `/scheduled_kickoff`,
      {},
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then(handleResponse)
    .catch(handleError);
};
