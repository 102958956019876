import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './notification.css';

export const Notification = ({ display, message, type, duration, onClick, onClose }) => {
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const notificationTimeout = duration * 1000;

  useEffect(() => {
    if (display) {
      setOpen(true);

      const setOpenTimer = setTimeout(() => {
        setIsVisible(true);
      }, 100);

      if (duration < 0) return;
      // Set timeout for auto hiding the notification
      const setVisibleTimer = setTimeout(() => {
        // Remove the animation class before closing
        setIsVisible(false);
        if (onClose) onClose();
      }, notificationTimeout);

      return () => {
        clearTimeout(setOpenTimer);
        clearTimeout(setVisibleTimer);
      };
    } else {
      setIsVisible(false);
    }
  }, [display, duration, onClose, notificationTimeout, setIsVisible]);

  useEffect(() => {
    if (isVisible) return;

    const timer = setTimeout(() => {
      setOpen(false);
    }, 800);

    return () => clearTimeout(timer);
  }, [isVisible, setOpen, notificationTimeout]);

  if (!open) return null;

  return (
    <div
      className={`storybook-notification ${isVisible ? 'storybook-notification--show' : ''}`}
      onClick={onClick}
      data-testid="notification"
    >
      {type !== "default" && (
        <i className={`icon-notification icon-${type}`}></i>
      )}
      <span>{message}</span>
    </div>
  );
};

Notification.propTypes = {
  /**
   * Display notification popup.
   */
  display: PropTypes.bool,
  /**
   * Notification text message.
   */
  message: PropTypes.string,
  /**
   * Specify notification type.
   */
  type: PropTypes.oneOf(["info", "success", "warning", "error", "default"]),
  /**
   * Speficy the duration of displaying notification.
   * Set -1 to disable timeout
   */
  duration: PropTypes.number,
  /**
   * Speficy a function to be called when notification is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Speficy a function to be called when notification is closed.
   */
  onClose: PropTypes.func
};

Notification.defaultProps = {
  display: true,
  message: "You have successfully Submitted Request!",
  type: "default",
  duration: 5,
  onClick: () => {},
  onClose: () => {},
}