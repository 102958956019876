import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";

import {
  styles,
  stepsOnboarding,
  stepsCoursework,
} from "../../utils/helpers/steps";

import "./joyridenav.css";
import { setFTUXViewed } from "../../redux/actions/userActions";

export const JoyrideNav = ({ studentProgress, showFTUX }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const joyrideWrap = document.getElementById("react-joyride-portal");
  const [joyrideStep, setJoyrideStep] = useState(0);

  const steps =
    studentProgress.section === "no-milestone"
      ? stepsCoursework
      : stepsOnboarding;
  const [tutorialPassed, setTutorialPassed] = useState(!showFTUX);

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    setJoyrideStep(index);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setFTUXViewed(token));
      setTutorialPassed(true);
    }
  };

  useEffect(() => {
    if (joyrideWrap) {
      joyrideWrap.classList.remove(`joyride-step-${joyrideStep - 1}`);
      joyrideWrap.classList.add(`joyride-step-${joyrideStep}`);
    }
  }, [joyrideWrap, joyrideStep]);

  useEffect(() => {
    if (joyrideWrap && studentProgress.section === "no-milestone") {
      joyrideWrap.classList.add(`joyride-step-no-milestone`);
    }
  }, [joyrideWrap]);

  useEffect(() => {
    const originalConsoleWarn = console.warn;
    console.warn = () => {};
    return () => {
      console.warn = originalConsoleWarn;
    };
  }, []);

  if (tutorialPassed) return null;

  return (
    <Joyride
      continuous
      steps={steps}
      callback={handleJoyrideCallback}
      showSkipButton
      showBackButton={false}
      disableOverlayClose
      disableCloseOnEsc
      hideCloseButton
      hideBackButton
      showProgress
      spotlightPadding={0}
      styles={styles}
      locale={{
        skip: "Do not show this again",
        last: "Start My Student Journey at Calbright!",
      }}
    />
  );
};
