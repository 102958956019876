/**
 * Walkthrough steps
 */
const isDesktop = window.innerWidth > 767 ? ".no-mobile" : ".no-desktop";

let steps = [
  {
    disableBeacon: true,
    target: `${isDesktop} .storybook-welcome-message .page-title`,
    content: `Welcome to your new Calbright student portal!
        This walkthrough highlights key areas of the portal and their purpose. 
        Click Next to continue.`,
  },
  {
    target: ".storybook-menu-container",
    content: `This is your top navigation bar from where you
        can access your homepage, My Team information, 
        important Calbright services and resources.`,
  },
  {
    target: ".storybook-dashboard-leftcolumn",
    placement: "right",
    content: `Your profile area highlights key term, drop/
        withdrawal dates, and provides quick access to
        important apps, like the Learning Management System (LMS).`,
  },
  {
    target: ".storybook-dashboard-needhelp",
    content: `Use these links for quick access to important areas in the portal.`,
  },
  {
    target: ".storybook-dashboard-mytasks",
    placement: "bottom",
    content: `This is your main coursework area where you can
        track your course progress and access the LMS.`,
  },
  {
    target: ".storybook-icon-help",
    content: `For help and any frequently asked questions, click
        on this button, also you can start this tour again from here.`,
  },
];

export const stepsCoursework = steps.map((item) =>
  item.target === ".storybook-dashboard-mytasks"
    ? { ...item, target: `.storybook-dashboard-mytasks--wrapper` }
    : item
);

export const stepsOnboarding = steps.map((item) =>
  item.target === ".storybook-dashboard-mytasks"
    ? {
        ...item,
        content: `This is your tasks area where you can track your progress at Calbright College.`,
      }
    : item
);

export const styles = {
  buttonSkip: {
    color: "#747474",
    paddingLeft: "0",
  },
  buttonNext: {
    backgroundColor: "#d34508",
    borderRadius: "0",
    borderRadius: "4px",
    fontSize: "14px",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "22px",
    paddingRight: "22px",
  },
  tooltip: {
    padding: "30px",
  },
  tooltipContainer: {
    color: "#333333",
    fontSize: "14px",
    textAlign: "left",
    lineHeight: "24px",
  },
  tooltipContent: {
    paddingTop: "0",
    paddingBottom: "20px",
    paddingLeft: "0",
    paddingRight: "0",
  },
  tooltipFooter: {
    marginTop: "0",
  },
};
