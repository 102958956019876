const STATIC_S3_URL = process.env.STATIC_S3_URL;

const buildAssetURL = (url) => `${STATIC_S3_URL}/common/assets/${url}`;

export const IconError = buildAssetURL("icon-error.svg");
export const IconSuccess = buildAssetURL("icon-success.svg");
export const IconPhone = buildAssetURL("icon-phone.svg");
export const IconEmail = buildAssetURL("icon-email.svg");
export const IconSlack = buildAssetURL("icon-slack.svg");
export const IconCongratulations = buildAssetURL("icon-congratulations.svg");
export const IconArrow = buildAssetURL("icon-arrow.svg");
export const IconBack = buildAssetURL("icon-back.svg");
export const IconMinus = buildAssetURL("icon-minus.svg");
export const IconPlus = buildAssetURL("icon-plus.svg");
export const GreenCheck = buildAssetURL("profile/green-check.svg");
export const CalbrightLogo = buildAssetURL("calbright_logo.svg");

export const IconFirstAssignComplete = buildAssetURL("task/task-firstsaa.svg");

export const GoogleCalendarLogo = buildAssetURL("my-apps/google-calendar.svg");
export const LinkedInLogo = buildAssetURL("my-apps/linkedin-learning.svg");
export const SlackLogo = buildAssetURL("my-apps/slack.svg");
export const GmailLogo = buildAssetURL("my-apps/gmail.svg");
export const GoogleDriveLogo = buildAssetURL("my-apps/google-drive.svg");
export const ProgramAccessLogo = buildAssetURL("my-apps/program-access-white.svg");

export const AvatarFemale = buildAssetURL("demo-images/Avatar_Female_Default.svg");

export const CounselorPreepp = buildAssetURL("myteam/counselor-preepp.png");
export const InstructorPreepp = buildAssetURL("myteam/instructor-preepp.png");
export const AcademicSuccessCounselors = buildAssetURL("myteam/academic-success-counselors.svg");
export const PeerTutors = buildAssetURL("myteam/peer-tutors.svg");
export const InstructionalFaculty = buildAssetURL("myteam/instructional-faculty.svg");
export const CatherineParker = buildAssetURL("myteam/catherine-parker.svg");
export const ElliotFu = buildAssetURL("myteam/elliot-fu.svg");
export const LailaJansen = buildAssetURL("myteam/laila-jansen.svg");
export const EdisonCameron = buildAssetURL("myteam/edison-cameron.svg");
export const PadminiJohannes = buildAssetURL("myteam/padmini-johannes.svg");
export const BrittAllen = buildAssetURL("myteam/britt-allen.svg");
export const JoachimNik = buildAssetURL("myteam/joachim-nik.svg");
export const ShemilaJohnson = buildAssetURL("myteam/shemila-johnson.png");


export const AccessibilityAbout = buildAssetURL("accessibility-services/accessibility-about.svg");
export const AccessibilityRegister = buildAssetURL("accessibility-services/accessibility-register.svg");
export const AccessibilityFAQ = buildAssetURL("accessibility-services/accessibility-faq.svg");
export const AccessibilityStudentRights = buildAssetURL("accessibility-services/accessibility-student-rights.svg");
export const AccessibilityStudentResources = buildAssetURL("accessibility-services/accessibility-student-resources.svg");
export const MeetOurTeam = buildAssetURL("accessibility-services/meet-our-team.svg");

export const RequestTranscript = buildAssetURL("ar-services/request-transcript.svg");
export const AccessingCertificatesCompetency = buildAssetURL("ar-services/accessing-certificates-competency.svg");
export const WithdrawingFromCalbright = buildAssetURL("ar-services/withdrawing-from-calbright.svg");
export const RequestingEnrollmentVerificationLetter = buildAssetURL("ar-services/requesting-enrollment-verification-letter.svg");

export const FemGreen = buildAssetURL("avatars/fem-green.svg");
export const FemTan = buildAssetURL("avatars/fem-tan.svg");
export const FemYellow = buildAssetURL("avatars/fem-yellow.svg");
export const GentGreen = buildAssetURL("avatars/gent-green.svg");
export const GentTan = buildAssetURL("avatars/gent-tan.svg");
export const GentYellow = buildAssetURL("avatars/gent-yellow.svg");
export const UserFilledIn = buildAssetURL("avatars/user-filled-in.svg");
export const UserInverted = buildAssetURL("avatars/user-inverted.svg");
export const UserOutline = buildAssetURL("avatars/user-outline.svg");

export const Resume = buildAssetURL("career-services/resume-cover-letter.svg");
export const JobSearch = buildAssetURL("career-services/job-search-strategies.svg");
export const Interview = buildAssetURL("career-services/interview-salary.svg");

export const AdmissionsIcon = buildAssetURL("help/admissions-records.svg");
export const CourseworkIcon = buildAssetURL("help/coursework.svg");
export const GeneralIcon = buildAssetURL("help/general.svg");
export const TechIcon = buildAssetURL("help/tech.svg");
export const CanvasIcon = buildAssetURL("help/canvas.svg");
export const LiveChatIcon = buildAssetURL("help/icon-live-chat.svg");
export const MailSupportIcon = buildAssetURL("help/icon-mail-support.svg");
export const PhoneSupportIcon = buildAssetURL("help/icon-phone-support.svg");
export const RocketIcon = buildAssetURL("help/icon-rocket.svg");
export const RocketBlueIcon = buildAssetURL("help/icon-rocket-blue.svg");

export const IconPPT = buildAssetURL("app-badges/icon-ppt.svg");
export const IconMA = buildAssetURL("app-badges/icon-ma.svg");
export const IconFWS = buildAssetURL("app-badges/icon-fws.svg");
export const IconCU = buildAssetURL("app-badges/icon-cu.svg");
export const IconMeetMyTeam = buildAssetURL("app-badges/icon-meet-my-team.svg");
export const IconCareerHub = buildAssetURL("app-badges/icon-career-hub.svg");

export const CitingSources = buildAssetURL("library-services/CitingSources.png");
export const FrequentlyAskedQuestions = buildAssetURL("library-services/FrequentlyAskedQuestions.png");
export const LeveragingAI = buildAssetURL("library-services/LeveragingAI.png");
export const ResearchGuide = buildAssetURL("library-services/ResearchGuide.png");
export const TopicsofTheMonth = buildAssetURL("library-services/TopicsofTheMonth.png");

export const Food = buildAssetURL("resources/Food.svg");
export const HousingHomelessness = buildAssetURL("resources/HousingHomelessness.svg");
export const LegalAssistance = buildAssetURL("resources/LegalAssistance.svg");
export const MentalHealth = buildAssetURL("resources/MentalHealth.svg");
export const TaxCredits = buildAssetURL("resources/TaxCredits.svg");
export const Undocumented = buildAssetURL("resources/Undocumented.svg");
export const VoterResources = buildAssetURL("resources/VoterResources.svg");

export const PeerTutoringIcon = buildAssetURL("tutoringlearning/PeerTutoring.png");
export const GetStartedIcon = buildAssetURL("tutoringlearning/GetStartedWorkshop.png");
export const ReadingIcon = buildAssetURL("tutoringlearning/Reading.png");
export const DigitalLiteracyIcon = buildAssetURL("tutoringlearning/DigitalLiteracy.png");
export const LeadershipIcon = buildAssetURL("tutoringlearning/Leadership.png");

export const Medical = buildAssetURL("wellness/medical.svg");
export const Counseling = buildAssetURL("wellness/counseling.svg");
export const LifeCoaching = buildAssetURL("wellness/life-coaching.svg");

export const Discounts = buildAssetURL("virtual-id/discounts.svg");
export const Security = buildAssetURL("virtual-id/security.svg");
export const Resources = buildAssetURL("virtual-id/resources.svg");
