/**
 * Wellness services items used in Wellness Services Page.
 */
export const workshops = [
  {
    title: "Job Search Skills",
    link: "#",
    color: 'red'
  },
  {
    title: "Workshop: Rock That Interview",
    link: "#",
    color: 'yellow'
  },
  {
    title: "Workshop: Crafting Your Resume",
    link: "#",
    color: 'blue'
  },
  {
    title: "What's a Recruiter",
    link: "#",
    color: 'green'
  }
];