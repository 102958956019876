// Helper functions

/**
 * getInitials is a function that takes a fullName parameter and
 * returns the two initials from the first and last name.
 * @param {string} fullName - Name to be converted to initials.
 * @returns {string} initials - Initials from the fullName.
 */
export const getInitials = (fullName) => {
  const nameParts = fullName.trim().split(" ");
  let initials = "";

  if (nameParts.length >= 2) {
    const firstNameInitial = nameParts[0][0];
    const lastNameInitial = nameParts[nameParts.length - 1][0];
    initials = `${firstNameInitial}${lastNameInitial}`;
  } else if (nameParts.length === 1 && nameParts[0][0] !== undefined) {
    initials = nameParts[0][0];
    if (!isNaN(initials) || initials === undefined) {
      initials = "Invalid";
    }
  } else {
    initials = "Invalid";
  }

  return initials;
};

/**
 * Takes an array of objects and returns an array of the ids of each object.
 * @param {array} menuItems
 * @returns {array} menuIds
 */
export const getMenuIds = (menuItems) => {
  return menuItems.map((menuItem) => menuItem.id);
};

/**
 * Get current day
 * @returns {string}
 */
export const getCurrentDay = () => {
  const currentDate = new Date();
  const options = {
    month: "short",
    day: "numeric",
    weekday: "long",
  };

  return currentDate.toLocaleDateString("en-US", options);
};

/**
 * Format input recieved as a date in dd/mm/yyyy format
 * @input : string, number or date object
 * @return: string date
 */
export const formatDate = (input) => {
  let date = new Date(input);

  if (isNaN(date.getTime())) {
    return "-";
  }

  // Get the dd/mm/yyyy from date obj.
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};
