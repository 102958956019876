/**
 * Checks if today's date is within the startDate and endDate parameter range.
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns true if within range, false otherwise
 * @throws {Error} if startDate or endDate is not a valid Date object
 */
const isWithinDateRange = (dateToTest = new Date(), startDate, endDate) => {
  if (!(startDate instanceof Date) || isNaN(startDate.getTime())) {
    throw new Error("Invalid startDate");
  }

  if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
    throw new Error("Invalid endDate");
  }

  return dateToTest >= startDate && dateToTest <= endDate;
};

export default isWithinDateRange;

/**
 * Functions returns date in current locale
 * @param {*} date date to be converted
 * @param {*} separator date separator like - or /
 * @param {*} format YYYY-MM-DD or MM-DD-YYYY
 * @returns
 */
export const getDate = (date, separator = "/", format = "MM-DD-YYYY") => {
  const d = date || new Date();
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");

  switch (format) {
    case "YYYY-MM-DD":
      return `${year}${separator}${month}${separator}${day}`;
    default: // "MM-DD-YYYY":
      return `${month}${separator}${day}${separator}${year}`;
  }
};
