import React from "react";
import PropTypes from "prop-types";

import "./input.css";

export const Input = ({
  type,
  name,
  label,
  showLabel,
  placeholder,
  defaultValue,
  helptext,
  icon,
  required,
  maxlength,
  minlength,
  size,
  disabled,
  autofocus,
  readonly,
  autocomplete,
  onChange,
  onBlur,
}) => {
  const labelClass = ["storybook-input-label"];
  const nameFormatted = name?.replace(" ", "-");

  if (showLabel) {
    labelClass.push("storybook-input-label--show");
  }

  const handleOnChange = (e) => {
    onChange && onChange(e);
  };

  const handleOnBlur = (e) => {
    onBlur && onBlur(e);
  };

  return (
    <>
      <label
        className={labelClass.join(" ")}
        htmlFor={nameFormatted}
        data-testid="inputLabel"
      >
        {label}
      </label>

      <input
        type={type}
        id={nameFormatted}
        name={nameFormatted}
        className={`storybook-input storybook-input--${size}`}
        placeholder={placeholder}
        size={size}
        required={required}
        disabled={disabled}
        autoFocus={autofocus}
        readOnly={readonly}
        autoComplete={autocomplete}
        onChange={(e) => handleOnChange(e)}
        onBlur={(e) => handleOnBlur(e)}
        maxLength={maxlength}
        minLength={minlength}
        value={defaultValue}
        data-testid="inputField"
      />
      {helptext && <span className="storybook-input-help">{helptext}</span>}
    </>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "password",
    "email",
    "number",
    "tel",
    "url",
    "search",
    "date",
    "time",
    "datetime",
    "datetime-local",
    "month",
    "week",
    "color",
  ]).isRequired,
  /**
   * Label of the input
   */
  label: PropTypes.string.isRequired,
  /**
   * Toggle label on or off.
   */
  showLabel: PropTypes.bool,
  /**
   * Placeholder text for the input
   */
  placeholder: PropTypes.string,
  /**
   * Default value for the input
   */
  defaultValue: PropTypes.string,
  /**
   * Help text for the input
   */
  helptext: PropTypes.string,
  /**
   * Icon for the input (not implemented yet)
   */
  icon: PropTypes.string,
  /**
   * Add required property to input
   */
  required: PropTypes.bool,
  /**
   * Add maxlength property to input
   */
  maxlength: PropTypes.number,
  /**
   * Add minlength property to input
   */
  minlength: PropTypes.number,
  /**
   * Add size property to input
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Add disabled property to input
   */
  disabled: PropTypes.bool,
  /**
   * Add autofocus property to input
   */
  autofocus: PropTypes.bool,
  /**
   * Add readonly property to input
   */
  readonly: PropTypes.bool,
  /**
   * Add autocomplete property to input
   */
  autocomplete: PropTypes.oneOf(["on", "off"]),
  /**
   * Add onChange property to input. Implemented by parent component.
   */
  onChange: PropTypes.func,
  /**
   * Add onBlur property to input. Implemented by parent component.
   */
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  label: "Name",
  showLabel: true,
  type: "text",
  size: "medium",
  required: false,
  disabled: false,
  autofocus: false,
  readonly: false,
  autocomplete: "off",
  onChange: undefined,
  onBlur: undefined,
};
