export default {
  apiCallsInProgress: 0,
  loading: true,
  token: {},
  user: {},
  broadcastMsgs: null,
  appointment: null,
  appointmentLinks: {},
  signOut: false,
  myTeam: null,
  preReqStep: {},
  avatar: null,
  avatars: null,
  topicOfMonth: null,
  arservices: null,
  enrollmentVerification: null,
  completedPrograms: null,
  devices: null,
  staticContent: {},
  theme: JSON.parse(localStorage.getItem("student-portal-theme")) || false,
};
