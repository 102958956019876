import React from "react";
import { useDispatch } from "react-redux";

import { Header } from "@storybook/stories/Header/Header";
import { menuData, updateMenuData } from "../../utils/helpers/data";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { toggleTheme } from "../../redux/actions/themeActions";

/**
 * Header component for the Storybook UI customized for student portal.
 * @param {object} user
 * @param {string} activeLink
 * @returns
 */
export const PortalHeader = ({ user, activeLink }) => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    window.location.replace(process.env.AUTH_URL);
  };
  const handleLogout = () => {
    window.location.replace(process.env.LOGOUT_URI);
  };
  const onToggleTheme = (theme) => dispatch(toggleTheme(theme));

  const isARServicesEnabled = useFeatureFlag("AR_SERVICES");

  const updatedMenu = isARServicesEnabled
    ? menuData
    : updateMenuData(menuData, "admissions-records-services");

  return (
    <>
      <Header
        user={user}
        onLogin={handleLogin}
        onLogout={handleLogout}
        activeLink={activeLink}
        avatarsize="medium"
        menuData={updatedMenu}
        onToggleTheme={onToggleTheme}
      />
    </>
  );
};
