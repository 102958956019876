import React, { useState } from "react";
import PropTypes from "prop-types";
import IconDown from "../assets/accordion/icon-chevron-down.svg";

import "./accordion.css";

export const Accordion = ({ title, items }) => {
  const [isActive, setIsActive] = useState(null);

  const handleToggle = (index) => {
    if (isActive === index) return setIsActive(null);

    setIsActive(index);
  };

  return (
    <div className="accordion" data-testid="accordion">
      {title && <h4>{title}</h4>}
      {items.map((item, index) => (
        <div
          className={`accordion-item ${isActive === index ? "active" : ""}`}
          key={index}
          data-testid={`accordion-item-${index}`}
        >
          <button
            id={item.id}
            className="accordion-title"
            onClick={() => handleToggle(index)}
            data-testid={`accordion-title-${index}`}
            aria-expanded={isActive === index}
            aria-controls={`accordion-content-${index}`}
          >
            <h4>{item.title}</h4>
            <img src={IconDown} alt="" />
          </button>
          <div
            className="accordion-content"
            id={`accordion-content-${index}`}
            data-testid={`accordion-content-${index}`}
            aria-hidden={!(isActive === index)}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
};

Accordion.propTypes = {
  /**
   * Accordion items is an array of items.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        active: PropTypes.bool,
      }),
    })
  ),
};
