import React, { Fragment, useState, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import useLiveChat from "./../../hooks/live-chat";

import { Header } from "@storybook/stories/Header/Header";
import { SearchBar } from "@storybook/stories/Search/Search";
import { AppBadge } from "@storybook/stories/AppBadge/AppBadge";
import { Button } from "@storybook/stories/Button/Button";
import { Accordion } from "@storybook/stories/Accordion/Accordion";

import { Faqs, Categories, FAQIcons, QuickFAQs } from "../../utils/helpers/faq";
import BackIcon from "../../assets/icon-back.svg";

import "./help.css";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";

const BackArrow = ({ resetSearch }) => (
  <p className="storybook-help-search-results-back" onClick={resetSearch}>
    <img src={BackIcon} /> Back
  </p>
);

export const Help = ({ user }) => {
  const { openChat } = useLiveChat();
  const buttonDisabled = useSelector(
    (state) => state.buttonChatDisabled.buttonDisabled
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchClickedAndBlank, setSearchClickedAndBlank] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [accordionActive, setAccordionActive] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const quickLinksRef = useRef(null);
  const suggestionsRef = useRef(null);
  const history = useHistory();

  const handleLogin = () => {
    window.location.replace(process.env.AUTH_URL);
  };

  const handleLogout = () => {
    window.location.replace(process.env.LOGOUT_URI);
  };

  useLayoutEffect(() => {
    if (quickLinksRef.current) {
      if (isSearchFocused && searchInput.length === 0) {
        // Show quick links section
        quickLinksRef.current.style.display = "block";
      } else {
        // Hide quick links section
        quickLinksRef.current.style.display = "none";
      }
    }

    if (suggestionsRef.current) {
      if (isSearchFocused && searchInput.length > 0) {
        // Show suggestions section
        suggestionsRef.current.style.display = "block";
      } else {
        // Hide suggestions section
        suggestionsRef.current.style.display = "none";
      }
    }
  }, [isSearchFocused, searchInput, searchClickedAndBlank]);

  useLayoutEffect(() => {
    if (searchSubmitted && searchResults.length === 0) {
      setSelectedCategory("");

      if (searchInput.length === 0) {
        quickLinksRef.current.style.display = "none";
      }
    }
  }, [searchSubmitted, searchResults]);

  const searchFaqs = (faqs, searchTerm) => {
    const search = searchTerm.toLowerCase();

    const filteredFaqs = faqs.filter((faq) => {
      return (
        faq.title.toLowerCase().includes(search) ||
        faq.content.props.children.includes(search)
      );
    });

    const sortedFaqs = filteredFaqs.sort((a, b) => {
      if (a.category < b.category) {
        return -1;
      }
      if (a.category > b.category) {
        return 1;
      }
      return 0;
    });

    return sortedFaqs;
  };

  const searchResults = searchInput ? searchFaqs(Faqs, searchInput) : "";
  const searchResultsCategories = searchResults
    ? [...new Set(searchResults.map((result) => result.category))]
    : [];

  const handleSearch = (event) => {
    setSearchInput(event);
  };

  // Triggered when a quick link is clicked.
  const quickLinkSearch = (faqTitle) => {
    setSearchSubmitted(true);
    handleSearch(faqTitle);
    setAccordionActive(true);
  };

  // Triggered when the search bar is clicked.
  const handleSearchSuggestions = (query) => {
    // If search is clicked and blank, show search suggestions
    if (query.length === 0) {
      setSearchClickedAndBlank(true);
      resetSearch();
    } else {
      setSearchInput(query);
      setSearchClickedAndBlank(false);
    }
  };

  useLayoutEffect(() => {
    const accordionItems = document.querySelectorAll(".accordion-item");
    if (accordionItems.length > 0) {
      if (accordionActive) {
        accordionItems[0].classList.add("active");
      } else {
        accordionItems[0].classList.remove("active");
      }
    }
  }, [accordionActive]);

  const resetBadges = () => {
    document.querySelectorAll(".storybook-app-badge").forEach((appBadge) => {
      appBadge.classList.remove("active-sibling");
    });
  };

  const resetSearch = () => {
    setSearchInput("");
    setSearchSubmitted(false);
    setSelectedCategory("");
    resetBadges();
    prev();
  };

  const handleAppBadgeClick = (id, category, e) => {
    e.preventDefault();
    const categoryPure = category.replace(" Questions", "");

    categoryPure == "Canvas" && window.screen.availWidth > 991 && next();

    if (selectedCategory === categoryPure) {
      setSelectedCategory("");
      resetBadges();
      prev();
      return;
    }
    setSelectedCategory(categoryPure);

    document
      .querySelectorAll('.storybook-app-badge[active="false"]')
      .forEach((appBadge) => {
        appBadge.classList.add("active-sibling");
      });
  };

  // Reset Joyride and redirect to dashboard page
  const handleWalkthroughClick = (e) => {
    e.preventDefault();
    localStorage.removeItem("tutorialPassed");
    history.push("/");
  };

  const renderAppBadge = (category, index, prefix) => (
    <AppBadge
      key={prefix + index}
      title={category}
      icon={FAQIcons[category]}
      link=""
      active={selectedCategory === category}
      disabled={false}
      target="_self"
      handleClick={handleAppBadgeClick}
    />
  );

  // Wrap text from search bar in results
  const getWrappedResult = (title) => {
    const regex = new RegExp(searchInput, "gi");
    return title.replace(regex, (match) => `<span>${match}</span>`);
  };

  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) =>
        prevState == Categories.length - 1 ? 0 : prevState + 1
      );
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) =>
        prevState === 0 ? Categories.length - 1 : prevState - 1
      );
    }
  };

  return (
    <article className="storybook-help" data-testid="helpContainer">
      <Header
        user={user}
        onLogin={handleLogin}
        onLogout={handleLogout}
        activeLink="help"
        avatarsize="medium"
      />
      <main>
        <div className="container">
          <div className="storybook-help-content">
            <div>
              <div className="page-title" data-testid="helpIntro">
                <h1>How can we help?</h1>
                <p>
                  Learn more by reviewing our frequently asked questions. If you
                  can’t find the answer you’re looking for, please reach out to
                  our support teams by email, text or phone.
                </p>
              </div>
              <section
                className="storybook-help-search"
                data-testid="helpSearch"
              >
                <div
                  className={`storybook-help-search-wrapper ${
                    isSearchFocused ? "focused" : ""
                  }`}
                >
                  <h2>Frequently Asked Questions</h2>
                  <SearchBar
                    onChange={handleSearchSuggestions}
                    onSearch={() => {
                      setSearchSubmitted(true);
                      setIsSearchFocused(true);
                    }}
                    onClick={handleSearchSuggestions}
                    placeholder={"Type Keywords to find answers"}
                    initialQuery={searchInput}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() =>
                      setTimeout(() => setIsSearchFocused(false), 350)
                    }
                  />
                </div>
                {/*  Quicklinks */}
                <div
                  ref={quickLinksRef}
                  className="storybook-help-search-quicklinks"
                  data-testid="helpSearchQuicklinks"
                >
                  <h3>QUICK LINKS</h3>
                  {QuickFAQs.map((faq, index) => (
                    <p
                      key={"quicklinks" + faq.id || "quicklinks" + index}
                      className="search-quicklinks"
                      onClick={() => quickLinkSearch(faq.title)}
                    >
                      {faq.title}
                    </p>
                  ))}
                </div>
                {/*  Suggestions */}
                {searchResultsCategories.length > 0 && (
                  <div
                    ref={suggestionsRef}
                    className="storybook-help-search-suggestions"
                  >
                    <h3>SUGGESTIONS</h3>
                    {searchResultsCategories.map((category, index) => (
                      <div
                        className="storybook-help-search-suggestion-item"
                        key={"searchcategories" + index}
                      >
                        <h4 className="storybook-help-search-suggestion-category">
                          {category}
                        </h4>
                        <div className="storybook-help-search-suggestion-questions">
                          {searchResults
                            .filter((result) => result.category === category)
                            .map((faq, innerIndex) => (
                              <p
                                key={"suggestions" + innerIndex}
                                className="storybook-help-search-suggestion-question"
                                onClick={() => quickLinkSearch(faq.title)}
                              >
                                {parse(getWrappedResult(faq.title))}
                              </p>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className={`storybook-help-search-results box-${
                    selectedCategory && !searchSubmitted ? "inner" : "out"
                  } ${
                    searchSubmitted && searchResults.length === 0
                      ? "no-results"
                      : ""
                  }`}
                  data-testid="helpSearchResults"
                >
                  {searchSubmitted ? (
                    <div className="storybook-help-search-searchInputresults">
                      {/* TODO: Display accordion list based on search keywords. 
                      Loop through search result and show accordions by category  */}

                      {searchResults && searchResults.length > 0 ? (
                        <>
                          <BackArrow resetSearch={resetSearch} />
                          <p className="storybook-help-search-results-items">
                            We found {searchResults.length} results.
                          </p>
                          {searchResultsCategories.map((category, index) => (
                            <Accordion
                              key={"searchresults" + index}
                              title={category}
                              items={searchResults.filter(
                                (result) => result.category === category
                              )}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          <BackArrow resetSearch={resetSearch} />
                          <div className="storybook-help-search-noresults">
                            <h2>Oops!</h2>
                            <p>
                              Sorry, we couldn't find any results, but we've got
                              you covered with a variety of categories to
                              explore.
                              <br />
                              Click on one of the available categories below to
                              browse through our FAQs.
                            </p>
                          </div>
                          <div
                            className="storybook-help-search-noresults-categories"
                            data-testid="helpSearchNoresults"
                          >
                            {Categories.map((category, index) => {
                              return (
                                <Fragment key={index}>
                                  <AppBadge
                                    key={"noresults" + index}
                                    id={index}
                                    title={category}
                                    icon={FAQIcons[category]}
                                    active={false}
                                    disabled={false}
                                    target="_self"
                                    handleClick={handleAppBadgeClick}
                                  />
                                  {selectedCategory === category && (
                                    <Accordion
                                      key={"allresults" + index}
                                      title={selectedCategory + " Questions"}
                                      items={Faqs.filter(
                                        (faq) =>
                                          faq.category === selectedCategory
                                      )}
                                    />
                                  )}
                                </Fragment>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      className="storybook-help-search-categories"
                      data-testid="helpSearchCategories"
                    >
                      {selectedCategory && (
                        <BackArrow resetSearch={resetSearch} />
                      )}

                      <div className="storybook-help-categories">
                        <button
                          onClick={prev}
                          className={`left-arrow ${
                            currentIndex === 0 ? "arrow-disabled" : ""
                          }`}
                        >
                          <KeyboardArrowLeftOutlined />
                        </button>
                        <div className="storybook-help-categories--wrapper">
                          <div
                            className="storybook-help-categories--inner"
                            style={{
                              transform: `translateX(-${
                                (currentIndex * 100) / 4
                              }%)`,
                            }}
                          >
                            {Categories.map((category, index) => (
                              <Fragment key={index}>
                                <AppBadge
                                  key={"search" + index}
                                  id={index}
                                  title={category}
                                  icon={FAQIcons[category]}
                                  link=""
                                  active={selectedCategory === category}
                                  disabled={false}
                                  target="_self"
                                  handleClick={handleAppBadgeClick}
                                />
                              </Fragment>
                            ))}
                          </div>
                        </div>
                        <button
                          onClick={next}
                          className={`right-arrow ${
                            currentIndex === 1 ? "arrow-disabled" : ""
                          }`}
                        >
                          <KeyboardArrowRightOutlined />
                        </button>
                      </div>

                      {selectedCategory && (
                        <Accordion
                          key={"allresults-" + selectedCategory}
                          title={selectedCategory + " Questions"}
                          items={Faqs.filter(
                            (faq) => faq.category === selectedCategory
                          )}
                        />
                      )}
                    </div>
                  )}
                </div>
              </section>
            </div>

            <aside className="storybook-help-aside" data-testid="helpAside">
              <a
                href="/"
                className="walkthrough-link"
                onClick={handleWalkthroughClick}
              >
                <i className="icon-rocket"></i>
                <b>Launch Walkthrough Tour</b>
              </a>

              <hr />

              <div className="storybook-help-aside-block storybook-help-aside-block--contact-us">
                <h2>Contact Us</h2>
                <p>
                  Support Specialists are available by live chat, email and
                  phone.
                </p>
                <p>Mon-Fri: 9 a.m. to 8 p.m.</p>
                <p>Sat: 8 a.m. to 5 p.m.</p>
              </div>

              <div className="storybook-help-aside-block">
                <i className="icon-live-chat"></i>
                <h4>Live Chat</h4>
                <p>Chat live with a Support Specialist now.</p>
                <Button
                  label="Live Chat"
                  onClick={openChat}
                  disabled={buttonDisabled}
                  isLoading={buttonDisabled}
                  buttonType="basic"
                  size="medium"
                />
              </div>

              <div className="storybook-help-aside-block">
                <i className="icon-mail-support"></i>
                <h4>Email Support</h4>
                <p>
                  <a href="mailto:success@calbright.org">
                    <b>success@calbright.org</b>
                  </a>
                </p>
              </div>

              <div className="storybook-help-aside-block">
                <i className="icon-phone-support"></i>
                <h4>Phone Support</h4>
                <p>1-833-956-0225</p>
              </div>
            </aside>
          </div>
        </div>
      </main>
    </article>
  );
};

Help.propTypes = {
  /**
   * User information.
   */
  user: PropTypes.shape({}),
};

Help.defaultProps = {};

export default Help;
