import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from "@storybook/stories/Button/Button";
import { Modal } from "@storybook/stories/Modal/Modal";
import { Tooltip } from "@storybook/stories/Tooltip/Tooltip";
import { getDate } from "../../../utils/isWithinDateRange";
import { withdraw } from "../../../api/withdrawApi";

import { IconSuccess, IconError } from "./../../../assets";
import "../ar-styles.css";

const countDaysFromStartDate = (enrollmentDate) => {
  const today = new Date();
  const enrollment = new Date(enrollmentDate);
  const timeDiff = today - enrollment;
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff;
}

export const Withdrawing = () => {
  const today = getDate();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [completedAndGraded, setCompletedAndGraded] = useState(false);
  const [completedNotGraded, setCompletedNotGraded] = useState(false);
  const {
    enrollmentDate,
    dropDeadline,
    withdrawDeadline,
    currentTermEndDate,
  } = user;

  const dropDeadlineDate = getDate(new Date(dropDeadline));
  const isBeforeDropDeadline = today <= dropDeadlineDate;

  useEffect(() => {
    if (user.courses.length > 0) {
      const hasCompletedCourse = user.courses
        .some(course => course.completed === true);

      const hasPGrade = user.courses
        .some(course => course.grade === "P");

      setCompletedAndGraded(hasCompletedCourse && hasPGrade);
      setCompletedNotGraded(hasCompletedCourse && !hasPGrade);
    }
  }, [user?.courses, setCompletedAndGraded, setCompletedNotGraded]);

  const handleSubmitForm = (values) => {
    setError(null);

    if (!values.reason) {
      setError("Please choose a main reason.");
      return;
    }

    if (values.reason !== "transfer_program" && !values.subReason) {
      setError("Please choose a reason.");
      return;
    }

    setWarningModal(true);
  };

  const submitForm = async (values) => {
    const response = await withdraw(
      token,
      values.reason,
      values.subReason,
      values.comments,
      values.confirm
    );

    setWarningModal(false);

    if (!response.error) {
      setSuccessModal(true);

      setTimeout(() => {
        window.location.replace(process.env.LOGOUT_URI)
      }, 3000);
      return;
    }

    setError(response?.error?.message);
  }

  const handleCloseModal = () => {
    setWarningModal(false);
  }

  const handleReasonChange = (setFieldValue, reason) => {
    setFieldValue("reason", reason);
    setFieldValue("subReason", "");
  };

  const validateComments = (values) => {
    const errors = {};
    if (values?.comments.length >= 500) {
      errors.comments = "Field must not exceed 500 characters.";
    }
    return errors;
  };

  return (
    <div className="ar-content ar-content--withdraw">
      <h2>{isBeforeDropDeadline ? 'Drop' : 'Withdraw'} Form</h2>

      <WithdrawStatus enrollmentDate={enrollmentDate} />

      <div className="ar-content--withdraw-dates">
        {enrollmentDate && (
          <div>
            <span>Enrollment Date</span>
            <strong>{enrollmentDate}</strong>
          </div>
        )}

        {dropDeadline && (
          <div>
            <span>Drop Deadline</span>
            <strong>{dropDeadline}</strong>
          </div>
        )}

        {withdrawDeadline && (
          <div>
            <span>Withdraw Deadline</span>
            <strong>{withdrawDeadline}</strong>
          </div>
        )}

        {currentTermEndDate && (
          <div>
            <span>End of Term Date</span>
            <strong>{currentTermEndDate}</strong>
          </div>
        )}
      </div>

      {countDaysFromStartDate(enrollmentDate) >= 99 ? (
        <div className="ar-warning-message">
          <img src={IconError} alt="Warning" />
          <h3>
            You cannot withdraw at this stage. Students who are inactive will be
            awarded {""}
            <Tooltip
              position="top"
              text="NP stands for an Academic progress mark of No Pass"
            >
              <span className="underline">an NP</span>
              <sup>?</sup>
            </Tooltip>
            grade and be withdrawn automatically from their Program. {""}
            For questions, please contact {""}
            <a href="mailto:success@calbright.org">success@calbright.org</a>.
          </h3>
        </div>
      ) : completedNotGraded ? (
        <>
          <div className="ar-warning-message">
            <img src={IconError} alt="Warning" />
            <h3>
              You have completed at least one of the courses for this term.
              The course needs to be graded before you can submit a withdrawal
              form. Please check back in 2-3 business days.
            </h3>
          </div>
          <NotGradedWarningModal />
        </>
      ) : (
        <>
          <p>
            If you have any questions on drop or withdraw deadlines or {""}
            definitions, please refer to the College Academic Catalog: {""}
            <a href="https://www.calbright.edu/academic-catalogs/" target="_blank">
              https://www.calbright.edu/academic-catalogs/
            </a>
          </p>

          <Formik
            initialValues={{
              reason: "",
              subReason: "",
              comments: "",
              confirm: false,
            }}
            validate={validateComments}
            onSubmit={handleSubmitForm}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className="ar-form">
                <p>
                  If you wish to proceed please fill out the form below:
                </p>
                <h3>
                  Program to be dropped from: {user.currentProgram}
                </h3>
                <h3>
                  Please select the main reason for deciding to drop from your program:
                </h3>
                <div className="ar-form--row">
                  <label>
                    <Field type="radio"
                      name="reason"
                      value="personal"
                      onChange={(e) => handleReasonChange(setFieldValue, e.target.value)}
                    />
                    1. Personal and/or other reasons
                  </label>
                  {values.reason === 'personal' && (
                    <div className="ar-form--row-inner">
                      <label>
                        <Field type="radio" name="subReason" value="Personal reasons" />
                        1). Personal reasons
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Financial reasons" />
                        2). Financial reasons
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Health related constraints" />
                        3). Health-related constraints
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Military service" />
                        4). Military service
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Time constraints" />
                        5). Time constraints
                      </label>
                    </div>
                  )}
                </div>

                <div className="ar-form--row">
                  <label>
                    <Field type="radio"
                      name="reason"
                      value="expectations"
                      onChange={(e) => handleReasonChange(setFieldValue, e.target.value)}
                    />
                    2. Program did not meet my expectations
                  </label>
                  {values.reason === 'expectations' && (
                    <div className="ar-form--row-inner">
                      <label>
                        <Field type="radio" name="subReason" value="Content was difficult to understand" />
                        1). Content was difficult to understand
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Program was not a good fit for my professional goals" />
                        2). Program was not a good fit for my professional goals
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Program was not advanced enough" />
                        3). Program was not advanced enough
                      </label>
                    </div>
                  )}
                </div>

                <div className="ar-form--row">
                  <label>
                    <Field type="radio"
                      name="reason"
                      value="support"
                      onChange={(e) => handleReasonChange(setFieldValue, e.target.value)}
                    />
                    3. Lack of support or resources
                  </label>
                  {values.reason === 'support' && (
                    <div className="ar-form--row-inner">
                      <label>
                        <Field type="radio" name="subReason" value="Technical issues" />
                        1). Technical Issues
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Insufficient academic guidance and support" />
                        2). Insufficient academic guidance and support
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Language barriers" />
                        3). Language barriers
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Community support issues" />
                        4). Community support issues
                      </label>
                    </div>
                  )}
                </div>

                <div className="ar-form--row">
                  <label>
                    <Field type="radio"
                      name="reason"
                      value="program"
                      onChange={(e) => handleReasonChange(setFieldValue, e.target.value)}
                    />
                    4. Transferred to another program within Calbright
                  </label>
                </div>

                <div className="ar-form--row">
                  <label>
                    <Field type="radio"
                      name="reason"
                      value="transfer_institution"
                      onChange={(e) => handleReasonChange(setFieldValue, e.target.value)}
                    />
                    5. Transferred to another institution
                  </label>
                  {values.reason === 'transfer_institution' && (
                    <div className="ar-form--row-inner">
                      <label>
                        <Field type="radio" name="subReason" value="2-year college" />
                        1). 2-year college
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="4-year college" />
                        2). 4-year university
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Post-graduate University" />
                        3). Post-graduate university
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Professional Certification" />
                        4). Professional certification
                      </label>
                    </div>
                  )}
                </div>

                <div className="ar-form--row">
                  <label>
                    <Field type="radio"
                      name="reason"
                      value="employment"
                      onChange={(e) => handleReasonChange(setFieldValue, e.target.value)}
                    />
                    6. Employment opportunities
                  </label>
                  {values.reason === 'employment' && (
                    <div className="ar-form--row-inner">
                      <label>
                        <Field type="radio" name="subReason" value="Employment related to the program" />
                        1). Employment related to the program
                      </label>
                      <label>
                        <Field type="radio" name="subReason" value="Employment not related to the program" />
                        2). Employment not related to the program
                      </label>
                    </div>
                  )}
                </div>

                <div className="ar-form--row">
                  <label></label>
                  <strong>
                    (Optional) Please tell us anything you would like to add
                    regarding your experience with Calbright:
                  </strong>
                  <Field
                    as="textarea"
                    name="comments"
                    placeholder="Your answer"
                    maxLength={500}
                  />
                  <ErrorMessage name="comments" component="div" className="error" />
                </div>

                <div className="ar-form--row ar-form--row-confirmation">
                  <div>
                    <h3>Confirmation</h3>
                    <p>
                      By submitting this form, I confirm that I want to drop the
                      course indicated above and I understand this drop will be
                      effective immediately.
                    </p>
                  </div>
                  <label>
                    <Field type="checkbox" name="confirm" />
                    I agree
                  </label>
                </div>

                {error && (<p className="error">{error}</p>)}

                <div className="ar-form--buttons">
                  <Button
                    label="Back"
                    buttonType="basic"
                    onClick={() => {}}
                  />
                  <Button
                    label="Submit"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                      !values.confirm ||
                      !values.reason ||
                      (values.reason !== "transfer_program" && !values.subReason)
                    }
                  />
                </div>

                {warningModal && (
                  <WithdrawingWarningModal
                    values={values}
                    submitForm={submitForm}
                    handleCloseModal={handleCloseModal}
                  >
                    {completedAndGraded ? (
                      <h2 className="storybook-modal-title">
                        You have completed at least one of the courses for this
                        term and have received {""}
                        <Tooltip
                          position="top"
                          text="P stands for an Academic progress mark of Pass"
                        >
                          <span className="underline">a P</span>
                          <sup>?</sup>
                        </Tooltip> {""}
                        grade.<br/> Do you still wish to withdraw?
                      </h2>
                    ) : (
                      <h2 className="storybook-modal-title">
                        Are you sure you wish to withdraw? Your access will {""}
                        be immediately suspended and we will send details to {""}
                        your personal email.
                      </h2>
                    )}
                  </WithdrawingWarningModal>
                )}
              </Form>
            )}
          </Formik>
          {successModal && (
            <WithdrawingSuccessModal />
          )}
        </>
      )}
    </div>
  );
};

const WithdrawStatus = ({ enrollmentDate }) => {
  const daysDiff = countDaysFromStartDate(enrollmentDate);

  if (daysDiff >= 0 && daysDiff <= 30) {
    return (
      <div className="ar-content--withdraw-status">
        <img src={IconError} width="20" alt="Warning" />
        <strong>
          Since you are within the first 30 days from term start date,
          you can drop without {""}
          <Tooltip
            position="top"
            text="W stands for an Academic progress mark of Withdraw"
          >
            <span className="underline">a W</span>
            <sup>?</sup>
          </Tooltip>
        </strong>
      </div>
    );
  } else if (daysDiff >= 31 && daysDiff <= 98) {
    return (
      <div className="ar-content--withdraw-status">
        <img src={IconError} width="20" alt="Warning" />
        <strong>
          Since you are within 31 - 98 days from term start date,
          you can withdraw with {""}
          <Tooltip
            position="top"
            text="W stands for an Academic progress mark of Withdraw"
          >
            <span className="underline">a W</span>
            <sup>?</sup>
          </Tooltip>
        </strong>
      </div>
    );
  }

  return null;
};

const WithdrawingSuccessModal = () => {
  return (
    <>
      <Modal
        icon={{
          name: "",
          color: "",
          size: "96px",
          isImage: true,
          imgUrl: IconSuccess
        }}
        title="Your withdrawal request has been processed!"
        openDialog={true}
        confirmText="Close"
        confirmClick={
          () => window.location.replace(process.env.LOGOUT_URI)
        }
        cancelText=""
        size="extra-large"
        showCloseIcon={false}
        extraClass={"text-center ar-modal"}
        closeOnEsc={false}
        children=""
      />
    </>
  );
}

const WithdrawingWarningModal = ({
  values,
  submitForm,
  handleCloseModal,
  children
}) => {
  return (
    <>
      <Modal
        icon={{
          name: "",
          color: "",
          size: "96px",
          isImage: true,
          imgUrl: IconError
        }}
        title=""
        openDialog={true}
        showCloseIcon={true}
        confirmText="Yes"
        confirmClick={() => submitForm(values)}
        cancelText="No"
        cancelClick={() => {
          handleCloseModal();
          location.replace('/');
        }}
        size="extra-large"
        showCloseIcon={true}
        extraClass={"text-center ar-modal"}
        children={children}
      />
    </>
  );
};

const NotGradedWarningModal = () => {
  return (
    <>
      <Modal
        icon={{
          name: "",
          color: "",
          size: "96px",
          isImage: true,
          imgUrl: IconError
        }}
        title={
          "You have completed at least one of the courses for this term. The course needs to be graded before you can submit a withdrawal form. Please check back in 2-3 business days."
        }
        openDialog={true}
        showCloseIcon={true}
        confirmText="Close"
        cancelText=""
        size="extra-large"
        showCloseIcon={true}
        extraClass={"text-center ar-modal"}
        children=""
      />
    </>
  );
};
