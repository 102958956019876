import React from "react";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";

import { Modal } from "@storybook/stories/Modal/Modal";
import { IconError } from "./../../assets";
import {
  ErrorMessage,
  contentNotCalbrightEmail,
  contentRefreshPortal,
} from "./ErrorMessage";

import "./error.css";

/**
 * Displays a custom error message in a modal based on the type of error provided.
 * @param {string} error
 * @returns Modal with error message
 */
const ErrorPage = ({ error }) => {
  const history = useHistory();
  let content = contentRefreshPortal;
  let detailedError = "";
  let errorStatus = 0;
  localStorage.removeItem("previousUrl");

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Object]";
  };

  // console.log("ErrorPage error =", error);
  // console.log("Error name =", error?.name);
  // console.log("ErrorPage errortype =", typeof error);
  // console.log("ErrorPage isObject? =", isObject(error));
  // console.log("Error message =", error?.message);
  // console.log("Error.error.message  =", error?.error?.message);
  // console.log("Error obj =", error);

  if (isObject(error)) {
    if (
      error?.name === "AxiosError" ||
      error?.name === "Error" ||
      error?.error?.name === "AuthenticationError"
    ) {
      detailedError =
        error?.message || error?.response?.data?.error || error?.error?.message;
      errorStatus = error?.response?.status;
      if (
        detailedError.includes("No token in request") ||
        detailedError.includes("InvalidTokenError")
      ) {
        return <></>;
      } else if (detailedError.includes("not found in Salesforce")) {
        content = {
          ...contentNotCalbrightEmail,
          confirmClick: () => history.push("/sign-out"),
        };
      }
    }
  } else if (typeof error === "string") {
    if (
      error.includes("No token in request") ||
      error.includes("Unauthorized")
    ) {
      return <></>;
    } else if (error.includes("Token has expired")) {
      content = {
        ...contentRefreshPortal,
        title: "Your session has expired",
        intro: "Refresh the page to reload/re-login the student portal.",
      };
    }
  }

  return (
    <Modal
      title={content.title}
      openDialog={true}
      showCloseIcon={false}
      confirmText={content.confirmText}
      confirmClick={content.confirmClick}
      extraClass="modal-log-out"
      icon={content.icon || IconError}
    >
      <ErrorMessage copy={content} />
    </Modal>
  );
};

ErrorPage.defaultProps = {
  error: "",
};

ErrorPage.propTypes = {
  /**
   * Error contains the error message.
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ErrorPage;
