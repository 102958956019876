import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import { saveTopicOfMonth } from "../../redux/actions/tomActions";
import { convertContentToReactNode } from "../../utils/htmlToReactNode";

export const TopicOfTheMonth = ({ type }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const topicOfMonth = useSelector((state) => state.topicOfMonth);
  const [isLoading, setIsLoading] = useState(false);

  const processedContent = useMemo(() => {
    if (!topicOfMonth?.content) return null;
    return convertContentToReactNode(topicOfMonth.content);
  }, [topicOfMonth?.content]);

  const renderContent = useMemo(() => {
    if (!topicOfMonth) return null;

    if (type === "description") {
      return <>{topicOfMonth.description}</>;
    }

    const topicOfMonthTitle = topicOfMonth.title
      .toLowerCase()
      .includes(topicOfMonth.month.toLowerCase())
      ? topicOfMonth.title
      : `${topicOfMonth.month}: ` + topicOfMonth.title;

    return (
      <>
        <h2>{topicOfMonthTitle}</h2>
        {processedContent}
      </>
    );
  }, [type, topicOfMonth, processedContent]);

  useEffect(() => {
    let mounted = true;

    const fetchTopic = async () => {
      if (topicOfMonth === null) {
        setIsLoading(true);
        try {
          await dispatch(saveTopicOfMonth(token));
        } finally {
          if (mounted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchTopic();

    return () => {
      mounted = false;
    };
  }, [dispatch, token, topicOfMonth]);

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!topicOfMonth) {
    return <>Topic of the month is currently unavailable.</>;
  }

  return renderContent;
};

const LoadingSkeleton = React.memo(() => (
  <Skeleton
    variant="text"
    sx={{
      fontSize: "1.5rem",
      width: "calc(800px - 40vw)",
      maxWidth: "100%",
      margin: "10px",
    }}
  />
));

TopicOfTheMonth.propTypes = {
  type: PropTypes.oneOf(["description", "content"]),
};

export default React.memo(TopicOfTheMonth);
