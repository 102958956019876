import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function broadcastMsgsReducer(
  state = initialState.broadcastMsgs,
  action
) {
  switch (action.type) {
    case types.SET_BROADCAST_MESSAGES_SUCCESS:
      return action.broadcastMsgs;
    default:
      return state;
  }
}
