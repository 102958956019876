import { enrolledStatus } from "./helpers/data"

/**
 * Method for determining whether current user is enrolled
 * Returning true if the learnerStatus are EPP, SPP or CPP.
 */
const isStudentEnrolled = (learnerStatus) => {
  return enrolledStatus.includes(learnerStatus);
}

export default isStudentEnrolled;