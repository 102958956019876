import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { mockUserDashboardData } from "../utils/helpers/user";
import {
  mockTeamData,
  mockKickoffScheduled_Response,
  mockBroadcastMessage_Response,
  mockAvatarsAPI,
  mockUpdatedAvatarAPI_Response,
} from "../utils/helpers/mock-data";

// const baseUrl = "http://localhost:3001"; // Local api for testing
const baseUrl = process.env.API_URL;

axios.defaults.baseURL = baseUrl;

const axiosInstance = axios.create({
  baseUrl,
  headers: { "Content-Type": "application/json" },
});

/**
 * Interceptor to check if token is expired, if not, refresh then
 * attach new token to authorization header.
 */
axiosInstance.interceptors.request.use(async (req) => {
  const tokenOnly = req.headers.Authorization.split(" ")[1];
  let idTokenDecoded;
  let isExpired = true;

  if (tokenOnly === "null" || tokenOnly === undefined) {
    return Promise.reject("No token in request");
  } else {
    idTokenDecoded = jwtDecode(req.headers.Authorization);
    isExpired = dayjs.unix(idTokenDecoded.exp).isBefore(dayjs());
  }

  if (isExpired) {
    return Promise.reject("Token has expired");
  }

  return req;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return Promise.reject("Unauthorized");
    }
    return Promise.reject(error);
  }
);

export const axiosPrivate = axios.create({
  baseUrl,
  headers: { "Content-Type": "application/json" },
  headers: { Authorization: `Bearer TOKEN HERE}` },
  withCredentials: true,
});

/**
 * Mock Adapter - This allows for local testing without actually making the api call.
 * Add specific API calls below to mock the response.
 * API calls should have mockData returned that matches the API.
 */

// Check if it requires to use Mock Adapter or it's Test Mode.
if (process.env.USE_MOCK_ADAPTER || process.env.JEST_WORKER_ID !== undefined) {
  const adapter = new MockAdapter(axiosInstance, { delayResponse: 1000 });

  // Mock API call for user dashboard data.
  adapter.onGet("/user").reply(200, mockUserDashboardData);

  // Mock API call for myTeam API using IT Support program as an example.
  adapter.onGet("/my_team?program_name=IT Support").reply(200, mockTeamData);

  //Mock API call for avatars.
  adapter.onGet("/avatars").reply(200, mockAvatarsAPI);

  // Mock API call to update user profile image (9)POST).
  adapter.onPost("/avatar").reply(200, mockUpdatedAvatarAPI_Response);

  // Mock API call to get broadcast messages.
  adapter.onGet("/banners").reply(200, mockBroadcastMessage_Response);

  // Mock API call to set kickoff scheduled
  adapter
    .onPost("/scheduled_kickoff")
    .reply(200, mockKickoffScheduled_Response);
}

export default axiosInstance;
