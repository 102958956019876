import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Intro } from "@storybook/stories/Intro/Intro";
import { Resources } from "@storybook/stories/Resources/Resources";
import { ARServicesData } from "../../utils/helpers/ar-services";
import { useARFormSubmmissions } from "../../hooks/useARFormSubmissions";

import "./admissions-records-services.css";

export const AdmissionsRecordServices = ({ user }) => {
  const updatedARData = useARFormSubmmissions(ARServicesData);

  return (
    <article className="ar-services" data-testid="arServices">
      <PortalHeader user={user} activeLink="services" />
      <main>
        <div className="container">
          {user && (
            <>
              <Intro
                title="Admissions and Records Services"
                content={
                  <>
                    To update your personal information, please contact our
                    Enrollment Services team at {""}
                    <a href="mailto:admissions@calbright.org">
                      admissions@calbright.org
                    </a>
                    .
                  </>
                }
              />
              <Resources resources={updatedARData} />
            </>
          )}
        </div>
      </main>
    </article>
  );
};

AdmissionsRecordServices.propTypes = {
  user: PropTypes.shape({}),
};

export default AdmissionsRecordServices;
