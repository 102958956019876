import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "../api/axios";
import { updateUser } from "../redux/actions/userActions";

export const PROF_EXPERIENCE_FILE_NAME = "professional_experience.txt";

/**
 * Sends a POST request to verify prerequisite certificates.
 *
 * @param {Array} certificateFiles - Array of certificate files to be uploaded.
 * @param {object} token - The authentication token containing the ID token.
 * @returns {Promise<object>} - Returns a promise that resolves to an object indicating success or failure.
 */
export const verifyPrereqCertificate = async (certificateFiles, token) => {
  if (!certificateFiles || certificateFiles.length === 0) {
    return {
      success: false,
      message: "No certificates provided."
    };
  }

  if (!token || !token.idToken) {
    return {
      success: false,
      message: "Invalid authentication token."
    };
  }

  const formData = createCertificateFormData(certificateFiles);

  return await axiosInstance.post(
    "/verify_prereq_certificate",
    formData,
    {
      headers: {
        Authorization: `Bearer ${token.idToken}`,
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      if (response.status === 200) {
        return { success: true };
      }

      const errorMessage = response?.error?.response?.data?.error
        || response?.error?.message;

      return { success: false, message: errorMessage };
    }).catch(({response}) => {
      return {
        success: false,
        message: response?.data?.error || "Something went wrong! Please try again or contact our support."
      };
    });
};

/**
 * Sends a support request with a question.
 *
 * @param {string} text - The question or issue to be sent to support.
 * @param {object} token - The authentication token containing the ID token.
 * @returns {Promise<object>} - Returns a promise that resolves to an object indicating success or failure.
 */
export const contactSupport = async (text, token) => {
  return await axiosInstance.post(
    "/prereq_student_support",
    { question: text },
    {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        return { success: true };
      }

      const message = response?.error?.response?.data?.error
        || response?.error?.message;

      return { success: false, message };
    }).catch(({response}) => {
      return {
        success: false,
        message: response?.data?.error || "Something went wrong! Please try again or contact our support."
      };
    });
};

/**
 * Switches the student's program after the prerequisite is declined.
 *
 * @param {object} token - The authentication token containing the ID token.
 * @returns {Promise<object>} - Returns a promise that resolves to an object indicating success or failure.
 */
export const switchProgramPrereq = async (token) => {
  if (!token || !token.idToken) {
    return {
      success: false,
      message: "Invalid authentication token.",
    };
  }

  return await axiosInstance.post(
    "/switch_program_prereq",
    {},
    {
      headers: {
        Authorization: `Bearer ${token.idToken}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        return { success: true };
      }

      const message = response?.error?.response?.data?.error
        || response?.error?.message;

      return { success: false, message };
    }).catch(({response}) => {
      return {
        success: false,
        message: response?.data?.error || "Something went wrong! Please try again or contact our support."
      };
    });
};

/**
 * Creates a FormData object containing the certificate files.
 *
 * @param {Array} certificateFiles - Array of certificate files to be uploaded.
 * @returns {FormData} - FormData object containing the renamed certificate files.
 */
const createCertificateFormData = (certificateFiles) => {
  const formData = new FormData();
  certificateFiles.forEach((file) => {
    if (!file) return;
    const newFileName = addCertCustomName(file);
    formData.append('certificate', file, newFileName);
  });

  return formData;
};

/**
 * Adds a custom name to the certificate file if applicable.
 *
 * @param {File} file - The original certificate file.
 * @returns {File} - A new File object with the custom name if applicable.
 */
const addCertCustomName = (file) => {
  if (!file || !file.name) {
    throw new Error("Invalid file object");
  }

  const { name, type, lastModified, optional, certName } = file;
  const ext = name.split('.').pop();

  if (name === PROF_EXPERIENCE_FILE_NAME) {
    return PROF_EXPERIENCE_FILE_NAME;
  }

  const newFileName = optional
    ? `cert_${optional}.${ext}`
    : `cert_${certName}.${ext}`;

  return newFileName;
};
