import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const myTeamReducer = (state = initialState.myTeam, action) => {
  switch (action.type) {
    case types.FETCH_TEAMDATA_SUCCESS:
      return action.myTeam;
    default:
      return state;
  }
};

export default myTeamReducer;
