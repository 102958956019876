import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox } from '@storybook/stories/Checkbox/Checkbox';
import { Input } from '@storybook/stories/Input/Input';
import { Button } from '@storybook/stories/Button/Button';
import { Modal } from '@storybook/stories/Modal/Modal';
import { Notification } from '@storybook/stories/Notification/Notification';
import { formatDate } from "@storybook/utils/helpers.js";
import { fetchARServices } from "./../../../redux/actions/arservicesActions";
import { transcriptRequest } from './../../../api/transcriptApi';

import { IconSuccess, IconError, IconMinus, IconPlus } from './../../../assets';

import './../ar-styles.css';

const MAX_RECIPIENT_ADDRESSES = 5;

export const RequestTranscriptForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const token = useSelector(state => state.token);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const pushNotificationProps = { display: false };
  const [pushNotification, setPushNotification] = useState(pushNotificationProps);
  const strictEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  const { requestTranscriptDateSubmitted } = useSelector(
    (state) => state.arservices
  );

  const formik = useFormik({
    initialValues: {
      unofficialTranscripts: false,
      calbrightEmailCheck: true,
      calbrightEmail: user.email || '',
      personalEmailCheck: false,
      personalEmail: '',
      officialTranscripts: false,
      recipientEmails: [{ id: Date.now(), email: '' }]
    },
    validationSchema: Yup.object({
      personalEmail: Yup.string()
        .trim()
        .matches(strictEmailRegex, "Invalid email address."),
      recipientEmails: Yup.array().of(
        Yup.object({
          email: Yup.string()
            .trim()
            .matches(strictEmailRegex, "Invalid email address.")
            .required("Email is required.")
            .test(
              "unique",
              "Duplicate email addresses are not allowed.",
              (value, { options: { context } }) =>
                !value ||
                context.recipientEmails.filter((item) => item.email === value).length <= 1
            )
            .test(
              "not-calbright-or-personal",
              "Cannot use Calbright or personal email.",
              (value, { options: { context } }) =>
                !value ||
                (value !== context.calbrightEmail && value !== context.personalEmail)
            )
            .when("$officialTranscripts", {
              is: true,
              then: (schema) => schema.required("Email is required."),
              otherwise: (schema) => schema.notRequired(),
            }),
        })
      ),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();

      setLoading(true);

      if (values.unofficialTranscripts) {
        formData.append("calbrightEmail", values.calbrightEmail);

        if (values.personalEmailCheck) {
          formData.append("personalEmail", values.personalEmail);
        }
      }

      if (values.officialTranscripts) {
        const recipientEmails = values.recipientEmails.map(item => item.email);
        formData.append(
          "recipientEmails",
          JSON.stringify(recipientEmails)
        );
      }

      setFormSubmitted(true);
      await transcriptRequest(token, formData)
        .then((response) => {
          if (response?.error) {
            setLoading(false);
            showNotification(response.error.message);
          } else {
            setSuccessModalOpen(true);
            dispatch(fetchARServices(token));
          }
        })
        .catch((error) => {
          setLoading(false);
          showNotification(
            error?.message ||
            'An unexpected error occurred.'
          );
        });
    },
  });

  const handleNotificationClose = () => {
    setPushNotification(pushNotificationProps);
    document.querySelector('.btn-close').click();
  };

  const showNotification = (message, type = 'error') => {
    setPushNotification({
      display: true,
      message,
      type,
    });
  };

  const { values, setFieldValue, handleChange, handleSubmit, errors, touched } = formik;

  const isSubmitDisabled = () => {
    const {
      unofficialTranscripts,
      officialTranscripts,
      calbrightEmail,
      personalEmailCheck,
      personalEmail,
      recipientEmails
    } = values;

    // Disable button when personalEmail empty or not validated
    // When at least one recipientEmails are not validated
    if (
      (officialTranscripts && errors.recipientEmails) ||
      (
        personalEmailCheck &&
        (errors.personalEmail || !personalEmail)
      )
    ) {
      return true;
    }

    // Both checkbox transcripts are unchecked
    if (!unofficialTranscripts && !officialTranscripts) {
      return true;
    }

    // Official checked but no recipient emails
    if (officialTranscripts) {
      return recipientEmails.every((recipient) => recipient.email === '');
    }

     // Unofficial checked, Official unchecked, but no main email
    if (unofficialTranscripts && !officialTranscripts) {
      return calbrightEmail === '';
    }

    return false;
  };

  useEffect(() => {
    values.recipientEmails.forEach((recipient, index) => {
      const emailError = Yup.string()
        .email('Invalid email address.')
        .isValidSync(recipient.email)
        ? ''
        : 'Invalid email address.';
      if (emailError && !errors.recipientEmails?.[index]) {
        setFieldValue(`errors.recipientEmails.${index}`, emailError);
      }
    });
  }, [values.recipientEmails, setFieldValue, errors.recipientEmails]);

  const handleAddRecipient = () => {
    if (values.recipientEmails.length < MAX_RECIPIENT_ADDRESSES) {
      setFieldValue('recipientEmails', [
        ...values.recipientEmails,
        { id: Date.now(), email: '' }
      ]);
    }
  };

  const handleRemoveRecipient = (id) => {
    const updatedEmails = values.recipientEmails.filter(email => email.id !== id);
    setFieldValue('recipientEmails', updatedEmails);
  };

  const handleCloseModal = () => {
    setSuccessModalOpen(false);
    showNotification(
      'You have successfully Submitted Request Transcript Form!',
      'success'
    );
  }

  return (
    <div className='ar-content'>
      <h2>Transcript Request Form</h2>
      <p>
        Complete the Transcript Request Form to receive your Calbright
        transcript. Requests are typically processed within 2 business days.
      </p>

      <form onSubmit={handleSubmit} className='transcript-form' autoComplete="off">
        <h3>What type of transcript are you requesting?</h3>

        <div className='transcript-form--inner'>
          <div className='transcript-form--inputs-row'>
            <Checkbox
              id='unofficialTranscripts'
              name='unofficialTranscripts'
              label='Unofficial (Unofficial Transcripts will be emailed to you)'
              showLabel={false}
              checked={values.unofficialTranscripts}
              onChange={() => setFieldValue('unofficialTranscripts', !values.unofficialTranscripts)}
            />
            <span onClick={() => setFieldValue('unofficialTranscripts', !values.unofficialTranscripts)}>
              Unofficial (Unofficial Transcripts will be emailed to you)
            </span>
          </div>
          {values.unofficialTranscripts && (
            <div className='transcript-form--inputs'>
              <div className='transcript-form--inputs-row'>
                <Checkbox
                  id='calbrightEmailCheck'
                  name='calbrightEmailCheck'
                  label='Use Calbright Email'
                  disabled={true}
                  showLabel={false}
                  checked={values.calbrightEmailCheck}
                  readOnly
                />
                <Input
                  id='calbrightEmail'
                  name='calbrightEmail'
                  type='email'
                  placeholder='Please enter your email address'
                  showLabel={false}
                  disabled={true}
                  defaultValue={values.calbrightEmail}
                  onBlur={formik.handleBlur}
                  readOnly
                />
              </div>
              <div className='transcript-form--inputs-row'>
                <Checkbox
                  id='personalEmailCheck'
                  name='personalEmailCheck'
                  label='Additional Email'
                  showLabel={false}
                  checked={values.personalEmailCheck}
                  onChange={
                    () => setFieldValue('personalEmailCheck', !values.personalEmailCheck)
                  }
                />
                <Input
                  id='personalEmail'
                  name='personalEmail'
                  type='email'
                  placeholder='Please enter your email address'
                  showLabel={false}
                  defaultValue={values.personalEmail}
                  onBlur={formik.handleBlur}
                  onChange={handleChange}
                />
                {errors.personalEmail &&
                  touched.personalEmail &&
                (
                  <div className='error-message'>
                    {errors.personalEmail}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='transcript-form--inner'>
          <div className='transcript-form--inputs-row'>
            <Checkbox
              id='officialTranscripts'
              name='officialTranscripts'
              label='Official (Official Transcripts will be sent directly to the receiving institution)'
              showLabel={false}
              checked={values.officialTranscripts}
              onChange={() => setFieldValue('officialTranscripts', !values.officialTranscripts)}
            />
            <span onClick={() => setFieldValue('officialTranscripts', !values.officialTranscripts)}>
              Official (Official Transcripts will be sent directly to the receiving institution)
            </span>
          </div>
          {values.officialTranscripts && (
            <div className='transcript-form--inputs'>
              <h3>
                Recipient’s email address (you can add up to {MAX_RECIPIENT_ADDRESSES} email addresses)
              </h3>
              {values.recipientEmails.map((recipient, index) => (
                <div key={recipient.id} className='transcript-form--inputs-row'>
                  <Input
                    id={`officialTranscriptsEmail${index}`}
                    name={`recipientEmails.${index}.email`}
                    type='email'
                    placeholder={`Please enter recipient email ${index + 1}`}
                    showLabel={false}
                    defaultValue={recipient.email}
                    onBlur={formik.handleBlur}
                    onChange={handleChange}
                  />
                  {index > 0 && (
                    <span
                      className='icon-minus'
                      style={{
                        backgroundImage: `url("${IconMinus}")`
                      }}
                      onClick={() => handleRemoveRecipient(recipient.id)}
                    >
                    </span>
                  )}
                  {index === values.recipientEmails.length - 1 &&
                    values.recipientEmails.length < MAX_RECIPIENT_ADDRESSES &&
                  (
                    <span
                      className='icon-plus'
                      style={{
                        backgroundImage: `url("${IconPlus}")`
                      }}
                      onClick={handleAddRecipient}
                    >
                    </span>
                  )}
                  {errors.recipientEmails &&
                    errors.recipientEmails[index] &&
                    touched.recipientEmails &&
                    touched.recipientEmails[index] &&
                  (
                    <div className='error-message'>
                      {errors.recipientEmails[index].email}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className='transcript-form--buttons'>
          <Button
            label='Back'
            buttonType='basic'
            onClick={() => { window.location.href = "/" }}
          />
          <Button
            label='Submit'
            onClick={handleSubmit}
            disabled={loading || isSubmitDisabled()}
          />
        </div>
      </form>

      {successModalOpen && (
        <Modal
          icon={{
            name: '',
            color: '',
            size: '96px',
            isImage: true,
            imgUrl: IconSuccess
          }}
          title={
            `Thank you for your submission! Your transcripts will be emailed within 2 business days.`
          }
          openDialog={true}
          confirmText='Continue'
          size='extra-large'
          showCloseIcon={true}
          extraClass={'text-center'}
          confirmClick={() => handleCloseModal()}
          cancelClick={() => handleCloseModal()}
          children=''
        />
      )}

      {(!formSubmitted && requestTranscriptDateSubmitted) && (
        <TranscriptWarningModal
          requestTranscriptDate={requestTranscriptDateSubmitted}
        />
      )}

      <Notification
        display={pushNotification.display}
        message={pushNotification.message}
        type={pushNotification.type}
        onClose={handleNotificationClose}
      />
    </div>
  );
};

const TranscriptWarningModal = ({ requestTranscriptDate }) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const fixedHolidays = ['01-01', '06-19', '07-04', '11-11', '12-25'];
  const returnDate = getReturnDate(requestTranscriptDate, fixedHolidays);

  useEffect(() => {
    if (isDateInBusinessDaysRange(requestTranscriptDate, fixedHolidays)) {
      setWarningModalOpen(true);
    }
  }, [requestTranscriptDate, fixedHolidays]);

  return (
    <>
      {warningModalOpen && (
        <Modal
          icon={{
            name: '',
            color: '',
            size: '96px',
            isImage: true,
            imgUrl: IconError
          }}
          title={
            `You submitted a request for transcript on ${formatDate(requestTranscriptDate)}, please wait until ${formatDate(returnDate)} to submit another request.`
          }
          openDialog={true}
          confirmText='Close'
          size='extra-large'
          showCloseIcon={true}
          extraClass={'text-center ar-modal'}
          children=''
        />
      )}
    </>
  );
};

const parseDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const getMonthDay = (date) => {
  const d = new Date(date);
  return `${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
};

const addBusinessDays = (startDate, days, holidays) => {
  const holidaySet = new Set(
    holidays.map((holiday) => {
      const [day, month] = holiday.split('-').map(Number);
      return `${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    })
  );

  let currentDate = new Date(startDate);
  let addedDays = 0;

  while (addedDays < Math.abs(days)) {
    currentDate.setDate(currentDate.getDate() + Math.sign(days));
    const dayOfWeek = currentDate.getDay();
    const currentDateMonthDay = getMonthDay(currentDate);

    // Skip weekends and fixed holidays
    if (dayOfWeek !== 0 && dayOfWeek !== 6 && !holidaySet.has(currentDateMonthDay)) {
      addedDays++;
    }
  }

  return currentDate;
};

const isDateInBusinessDaysRange = (dateValue, holidays = []) => {
  const today = new Date();
  const inputDate = parseDate(dateValue);

  const fiveBusinessDaysAgo = addBusinessDays(today, -5, holidays);

  return inputDate >= fiveBusinessDaysAgo && inputDate <= today;
};

const getReturnDate = (dateValue, holidays = []) => {
  const inputDate = parseDate(dateValue);
  return addBusinessDays(inputDate, 5, holidays);
};
