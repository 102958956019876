import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";

import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Card } from "@storybook/stories/Card/Card";
import { Button } from "@storybook/stories/Button/Button";

import { ModalLogOut } from "../../components/ModalLogOut/ModalLogOut";
import { SkeletonMyTeams } from "../../components/Skeleton/SkeletonMyTeams";
import { MyTeamMemberDetails } from "../../components/MyTeamMemberDetails/MyTeamMemberDetails";
import { fetchStaticMyTeam } from "../../redux/actions/staticActions";
import { fetchTeamData } from "../../redux/actions/myteamActions";
// import { convertContentToReactNode } from "../../utils/htmlToReactNode";

import {
  InstructionalFaculty,
  PeerTutors,
  AcademicSuccessCounselors,
  InstructorPreepp,
  CounselorPreepp,
  IconArrow,
} from "../../assets";

import isStudentEnrolled from "./../../utils/isStudentEnrolled";
import { handleError } from "../../api/apiUtils";
import { learnerStatusLookup } from "../../utils/helpers/data";

import "./myteam.css";

export const MyTeam = ({ user, token }) => {
  const dispatch = useDispatch();
  const myTeam = useSelector((state) => state?.myTeam);
  const myteamContent = useSelector((state) => state.staticContent?.myteam);
  const [teamData, setTeamData] = useState(null);
  const [staticData, setStaticData] = useState(null);
  const [processedStaticData, setProcessedStaticData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInstructors, setShowInstructors] = useState(false);
  const preEPPState = checkPreEPPState(user);
  let courseColors = [];

  function checkPreEPPState(user) {
    if (!user?.currentProgram) return false;
    const state = !isStudentEnrolled(user.learnerStatus);
    return state;
  }

  // TODO: Move convertContentToReactNode() to external file .
  /**
   * Converts html string to React node and changes internal links to NavLinks.
   * @param {string} htmlString
   * @returns
   */
  const convertContentToReactNode = (htmlString) => {
    if (!htmlString) return null;

    const sanitizedHtml = DOMPurify.sanitize(htmlString);

    const options = {
      replace: (domNode) => {
        if (domNode.type === "tag" && domNode.name === "a") {
          const href = domNode.attribs?.href;

          if (href?.startsWith("/")) {
            return (
              <NavLink to={href} className={domNode.attribs?.class || ""}>
                {domToReact(domNode.children, options)}
              </NavLink>
            );
          }

          return (
            <a
              href={href}
              className={domNode.attribs?.class || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {domToReact(domNode.children, options)}
            </a>
          );
        }
      },
    };

    return parse(sanitizedHtml, options);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.currentProgram) {
          return <ModalLogOut />;
        }

        const staticResponse =
          myteamContent || (await dispatch(fetchStaticMyTeam()));

        const processedContent = {
          intro: convertContentToReactNode(staticResponse.intro),
          faculty: {
            title: staticResponse.faculty.title,
            description: convertContentToReactNode(
              staticResponse.faculty.description
            ),
          },
          tutors: {
            title: staticResponse.tutors.title,
            description: convertContentToReactNode(
              staticResponse.tutors.description
            ),
          },
          counselors: {
            title: staticResponse.counselors.title,
            description: convertContentToReactNode(
              staticResponse.counselors.description
            ),
          },
          footer: convertContentToReactNode(staticResponse.footer),
        };

        setStaticData(staticResponse);
        setProcessedStaticData(processedContent);

        if (preEPPState) {
          setTeamData([]);
          setLoading(false);
          return;
        }

        const myteamResponse =
          myTeam || (await dispatch(fetchTeamData(user.currentProgram, token)));

        if (myteamResponse?.length === 0) {
          setLoading(false);
          return;
        }
        setTeamData(myteamResponse);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };

    fetchData();
  }, [user?.currentProgram, preEPPState, token?.idToken]);

  const handleShowInstructors = useCallback(() => {
    setShowInstructors(!showInstructors);
  }, [showInstructors]);

  const handleClickOutsideInstructorList = useCallback(
    (event) => {
      const inside = event.target.closest(".storybook-myteam-instructors-list");

      if (showInstructors && inside == null) {
        handleShowInstructors();
      }
    },
    [showInstructors, handleShowInstructors]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideInstructorList);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideInstructorList
      );
    };
  }, [handleClickOutsideInstructorList]);

  const instructorCourseColors = (instructors) => {
    let globalAltColor = false;
    const courseColorsArray = instructors.map((instructor, index) => {
      if (index === 0) {
        return {
          altColor: false,
          course: instructor.course,
        };
      } else {
        if (
          instructor.course === instructors[index - 1].course &&
          !globalAltColor
        ) {
          return {
            altColor: false,
            course: instructor.course,
          };
        } else {
          globalAltColor = true;
          return {
            altColor: true,
            course: instructor.course,
          };
        }
      }
    });
    return courseColorsArray;
  };

  const handleAltCourseColor = (course, courseColors) => {
    let altColor = "false";
    courseColors.map((courseColor) => {
      if (courseColor.course === course) {
        altColor = courseColor.altColor;
      }
    });
    return altColor;
  };

  if (teamData && teamData?.instructors) {
    courseColors = instructorCourseColors(teamData.instructors);
  }

  return (
    <article className="storybook-myteam" data-testid="myteamContainer">
      <PortalHeader user={user} activeLink="my-team" />
      {loading ? (
        <SkeletonMyTeams />
      ) : (
        user &&
        teamData &&
        staticData && (
          <main>
            <h1>My Team</h1>
            <div className="storybook-myteam-members">
              <section className="storybook-myteam-instructors">
                <h2>{staticData.meetInstructors.title}</h2>
                <p>{staticData.meetInstructors.description}</p>
                {preEPPState === true ||
                teamData.instructors?.error ||
                user.learnerStatus ===
                  learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"] ||
                (Object.hasOwn(teamData, "instructors") &&
                  teamData?.instructors.length < 1) ||
                !Object.hasOwn(teamData, "instructors") ? (
                  <div className="storybook-myteam-preepp white-container">
                    <section
                      className="storybook-myteam-counselor-list-item"
                      data-testid="counselor-list-content"
                    >
                      <Card
                        title=""
                        children={
                          preEPPState === true ? (
                            <PreEppMessage
                              message="Your instructors are assigned when you enroll. Sign up for an onboarding session and enroll today."
                              signUpUrl=""
                              btnLabel=""
                            />
                          ) : (
                            <PreEppMessage
                              message="Looks like your Instructors have not been assigned. Reach out to us so we can help"
                              signUpUrl={"mailto:success@calbright.org"}
                              btnLabel="Contact Support"
                            />
                          )
                        }
                        imageSrc={InstructorPreepp}
                        dashboardContent={{}}
                      />
                    </section>
                  </div>
                ) : (
                  <div className="storybook-myteam-instructors-list white-container">
                    <section
                      className={`storybook-myteam-instructors-list-item ${
                        showInstructors && "show"
                      }`}
                      data-testid="instructor-list-content"
                    >
                      {teamData.instructors?.map((instructor, index) => (
                        <Card
                          key={index}
                          title={instructor.name}
                          children={
                            <MyTeamMemberDetails
                              user={user}
                              applyAltColor={handleAltCourseColor(
                                instructor?.course,
                                courseColors
                              )}
                              {...instructor}
                            />
                          }
                          imageSrc={instructor.profile_image}
                        />
                      ))}
                    </section>
                    {teamData.instructors?.length > 2 && (
                      <div
                        className="storybook-myteam-instructors-show-more"
                        onClick={handleShowInstructors}
                      >
                        <img
                          src={IconArrow}
                          className={showInstructors ? "expand-rotate" : ""}
                        />
                      </div>
                    )}
                  </div>
                )}
              </section>
              <section className="storybook-myteam-counselors">
                <h2>{staticData.meetCounselor.title}</h2>
                <p>{staticData.meetCounselor.description}</p>
                {preEPPState === true ||
                teamData.counselor?.error ||
                user.learnerStatus ===
                  learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"] ||
                (Object.hasOwn(teamData, "counselor") &&
                  Object.keys(teamData?.counselor).length === 0) ||
                !Object.hasOwn(teamData, "counselor") ? (
                  <div className="storybook-myteam-preepp white-container">
                    <section
                      className="storybook-myteam-counselor-list-item"
                      data-testid="counselor-list-content"
                    >
                      <Card
                        title=""
                        children={
                          preEPPState === true ? (
                            <PreEppMessage
                              message="Your Success Counselors are assigned when you enroll. Sign up for an onboarding session and enroll today."
                              signUpUrl=""
                              btnLabel=""
                            />
                          ) : (
                            <PreEppMessage
                              message="Looks like your Success Counselor has not been assigned. Reach out to us so we can help"
                              signUpUrl={"mailto:success@calbright.org"}
                              btnLabel="Contact Support"
                            />
                          )
                        }
                        imageSrc={CounselorPreepp}
                        dashboardContent={{}}
                      />
                    </section>
                  </div>
                ) : (
                  <div className="storybook-myteam-counselors-list white-container">
                    <section
                      className="storybook-myteam-counselor-list-item"
                      data-testid="counselor-list-content"
                    >
                      <Card
                        title={teamData.counselor?.name}
                        children={
                          <MyTeamMemberDetails
                            user={user}
                            memberType="Counselor"
                            {...teamData?.counselor}
                            applyAltColor={false}
                          />
                        }
                        imageSrc={teamData.counselor?.profile_image}
                        dashboardContent={{}}
                      />
                    </section>
                  </div>
                )}
              </section>
            </div>
            <p className="storybook-myteam-copy">{staticData.intro}</p>

            <section className="storybook-myteam-instructional-faculty">
              <img src={InstructionalFaculty} alt="" />
              <div>
                <h2>{processedStaticData?.faculty.title}</h2>
                <p>{processedStaticData?.faculty.description}</p>
              </div>
            </section>
            <section className="storybook-myteam-peer-tutors">
              <div>
                <h2>{processedStaticData?.tutors.title}</h2>
                <p>{processedStaticData?.tutors.description}</p>
              </div>
              <img src={PeerTutors} alt="" />
            </section>
            <section className="storybook-myteam-academic-success-counselors">
              <img src={AcademicSuccessCounselors} alt="" />
              <div>
                <h2>{processedStaticData?.counselors.title}</h2>
                <p>{processedStaticData?.counselors.description}</p>
              </div>
            </section>

            <p className="storybook-myteam-assistance">
              {processedStaticData?.footer}
            </p>
          </main>
        )
      )}
    </article>
  );
};

MyTeam.propTypes = {
  user: PropTypes.shape({}),
  instructors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      course: PropTypes.string,
      department_name: PropTypes.string,
      department_number: PropTypes.string,
      email: PropTypes.string,
      slack: PropTypes.string,
      calendlyU_link: PropTypes.string,
      phone: PropTypes.string,
      availability: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      profile_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  counselor: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    slack: PropTypes.string,
    calendly_link: PropTypes.string,
    phone: PropTypes.string,
    officeHours: PropTypes.string,
    profile_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

MyTeam.defaultProps = {};

/**
 * PreEppMessage component
 * @returns generic notification
 */
const PreEppMessage = ({ message, signUpUrl, btnLabel }) => {
  const history = useHistory();
  const handleClick = () => {
    if (signUpUrl.includes("mailto")) {
      window.location.href = signUpUrl;
    } else {
      history.push(signUpUrl);
    }
  };
  return (
    <div className="storybook-myteam-member">
      <p>{message}</p>
      {signUpUrl && (
        <Button
          buttonType="basic"
          size="medium"
          label={btnLabel}
          onClick={() => handleClick(signUpUrl)}
        />
      )}
    </div>
  );
};

export default MyTeam;
