import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import * as response from "../utils/helpers/mock-data";
import * as responseContent from "../utils/helpers/mock-data-content.json";

const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  headers: { "Content-Type": "application/json" },
});

/**
 * Interceptor to check if token is expired, if not, refresh then
 * attach new token to authorization header.
 */
axiosInstance.interceptors.request.use(async (req) => {
  const tokenOnly = req.headers.Authorization.split(" ")[1];
  let idTokenDecoded;
  let isExpired = true;

  if (tokenOnly === "null" || tokenOnly === undefined) {
    return Promise.reject("No token in request");
  } else {
    idTokenDecoded = jwtDecode(req.headers.Authorization);
    isExpired = dayjs.unix(idTokenDecoded.exp).isBefore(dayjs());
  }

  if (isExpired) {
    return Promise.reject("Token has expired");
  }

  return req;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return Promise.reject("Unauthorized");
    }
    return Promise.reject(error);
  }
);

/**
 * Axios instance for static content. No authorization is required.
 */
export const axiosContent = axios.create({
  baseURL: process.env.STATIC_S3_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosContent.interceptors.request.use(
  async (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Mock Adapter - This allows for local testing without actually making the api call.
 * Add specific API calls below to mock the response.
 * API calls should have mockData returned that matches the API.
 */

// Check if it requires to use Mock Adapter or it's Test Mode.
if (process.env.USE_MOCK_ADAPTER || process.env.JEST_WORKER_ID !== undefined) {
  const adapter = new MockAdapter(axiosInstance, { delayResponse: 1000 });
  const adapterContent = new MockAdapter(axiosContent, {
    delayResponse: 500,
  });

  // Mock API call for user dashboard data.
  adapter.onGet("/user").reply(200, response.mockUserDashboardData);

  // Mock API call for myTeam API using IT Support program as an example.
  adapter
    .onGet("/my_team?program_name=IT Support")
    .reply(200, response.mockTeamData);

  //Mock API call for avatars.
  adapter.onGet("/avatars").reply(200, response.mockAvatarsAPI);

  // Mock API call to update user profile image (9)POST).
  adapter.onPost("/avatar").reply(200, response.mockUpdatedAvatarAPI_Response);

  // Mock API call to get broadcast messages.
  adapter.onGet("/banners").reply(200, response.mockBroadcastMessage_Response);

  // Mock API call to set kickoff scheduled
  adapter
    .onPost("/scheduled_kickoff")
    .reply(200, response.mockKickoffScheduled_Response);

  // Mock api to update showFirstSAA to false
  adapter
    .onPost("/event", { event_type: "first_saa_viewed" })
    .reply(200, response.mockFirstSAAViewed_Response);

  // Mock api to update showFTUX to false
  adapter
    .onPost("/event", { event_type: "ftux_viewed" })
    .reply(200, response.mockFTUXViewed_Response);

  // Mock api to retrieve Library Services - Topic of the Month
  adapter.onGet("/library_topic").reply(200, response.mockTopicOfTheMonth);

  // Mock api to retrieve Library Services - All Topics of the month
  adapter
    .onGet("/library_topic?all=true")
    .reply(200, response.mockTopicsOfTheMonth);

  // Mock api to retrieve student Completed Programs
  adapter.onGet("/program/complete").reply(200, response.mockCompletedPrograms);

  adapter
    .onGet("/library_topic?all=true")
    .reply(200, response.mockTopicsOfTheMonth);

  // Mock api to retrieve AR Services page submitted data
  adapter.onGet("/admissions-records").reply(200, response.mockARServices);

  // Mock api to retrieve the devices info
  adapter.onGet("/devices").reply(200, response.mockDevices);

  // Mock api to post AR Services Enrollment Verification form
  adapter
    .onPost("/admissions-records/enrollment")
    .reply(200, response.mockEnrollmentVerification);

  // adapter.onPost("/admissions-records/enrollment").reply(500, {
  //   error:
  //     "UnexpectedError: admissions_records_request() missing 1 required positional argument: 'sf'",
  // });

  /**
   * Static content
   */

  adapterContent
    .onGet("/common/dashboard.json")
    .reply(200, responseContent.default.dashboard);

  adapterContent
    .onGet("/common/wellness-services.json")
    .reply(200, responseContent.default.wellnessServices);

  adapterContent
    .onGet("/common/my-team.json")
    .reply(200, responseContent.default.myTeam);

  adapterContent
    .onGet("/common/career-services.json")
    .reply(200, responseContent.default.careerServices);

  adapterContent
    .onGet("/common/virtual-id.json")
    .reply(200, responseContent.default.virtualID);
}

export default axiosInstance;
