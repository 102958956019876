import { handleResponse, handleError } from "./apiUtils";
import { axiosContent } from "./axios";

/**
 * Function retrieves static content for dashboard.
 * @returns {object} {appicons, helpIcons, studentOnboarding }
 */
export const getStaticDashboard = () => {
  return axiosContent
    .get("/common/dashboard.json")
    .then(handleResponse)
    .catch(handleError);
};

/**
 * Function retrieves static content for my team.
 * @returns {object} { meet-instructors, meet-counselor, intro, faculty, tutors, counselors }
 */
export const getStaticMyTeam = () => {
  return axiosContent
    .get("/common/my-team.json")
    .then(handleResponse)
    .catch(handleError);
};

/**
 * Function retrieves static content for a given url.
 * @param {string} requestURL
 * @returns {object} { content }
 */
export const getStaticContent = (requestURL) => {
  return axiosContent.get(requestURL).then(handleResponse).catch(handleError);
};
