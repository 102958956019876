import * as types from "./actionTypes";
import * as tokenAPI from "../../api/tokenAPI";
import { handleError } from "../../api/apiUtils";
import { beginApiCall, apiCallError } from "./apiStatusActions.js";

export function loadTokenSuccess(token) {
  return { type: types.LOAD_TOKEN_SUCCESS, token };
}

export function saveCodeSuccess(code) {
  return { type: types.SAVE_CODE_SUCCESS, code };
}

export function saveTokenSuccess(token) {
  return { type: types.SAVE_TOKEN_SUCCESS, token };
}

export function refreshTokenSuccess(token) {
  return { type: types.REFRESH_TOKEN_SUCCESS, token };
}

export function saveCode() {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      const code = tokenAPI.getCode();
      dispatch(saveCodeSuccess(code));
      return code;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function loadToken() {
  return function (dispatch, getState) {
    dispatch(beginApiCall());
    try {
      const token = getState().token;
      dispatch(loadTokenSuccess(token));
      return token;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function saveToken() {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      const token = tokenAPI.getToken();
      dispatch(saveTokenSuccess(token));
      return token;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function refreshToken() {
  return function (dispatch) {
    return tokenAPI
      .refreshToken()
      .then((token) => {
        dispatch(refreshTokenSuccess(token));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
