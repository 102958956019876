import React, { useState } from "react";
import PropTypes from "prop-types";

import ArrowDownwardIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/KeyboardArrowUp";

import "./intro.css";

export const Intro = ({ title, content }) => {
  const [showIntro, setShowIntro] = useState(false);
  const handleShowIntro = () => {
    setShowIntro(!showIntro);
  };

  return (
    <section className="storybook-introsection">
      <div className="page-title">
        <h1>{title}</h1>
        <p className={`page-intro ${showIntro ? "show" : "hide"}`}>{content}</p>
      </div>
      <div className="page-intro-showmore" onClick={handleShowIntro}>
        {showIntro ? (
          <p>
            View Less <ArrowUpwardIcon />
          </p>
        ) : (
          <p>
            View More <ArrowDownwardIcon />
          </p>
        )}
      </div>
    </section>
  );
};

Intro.propTypes = {
  /**
   * Page title.
   */
  title: PropTypes.string,
  /**
   * Page intro paragraph/content.
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
