import React from 'react';
import PropTypes from 'prop-types';

import './career-sidebar.css';

export const CareerSidebar = ({ title, subtitle, workshops, enrolled, link }) => {
  return (
    <aside className="career-sidebar">
      <h3>{title}</h3>
      <p>{subtitle}</p>
      <hr/>
      <h4>Upcoming Workshops</h4>
      <div className="career-workshops">
      {workshops && workshops.slice(0, 4).map((item, index) => (
        <p key={index} className={`career-workshops__item ${enrolled ? "active" : ''}`}>
          <i className={`career-color ${enrolled ? item.color : ''}`}></i>
          {item.title}
        </p>
      ))}
      </div>
      {(enrolled ?
        <a href={link}>View All Workshops</a>
        :
        <b>Please Complete Enrollment to Register for Workshops</b>
      )}
    </aside>
  );
};

CareerSidebar.propTypes = {
  /**
   * Career title.
   */
  title: PropTypes.string,
  /**
   * Career subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Career Upcoming Workshops.
   */
  workshops: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  })),
  /**
   * Is student enrolled.
   */
  enrolled: PropTypes.bool,
  /**
   * Career sidebar link.
   */
  link: PropTypes.string,
};