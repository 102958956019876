import React from "react";
import { Skeleton } from "@mui/material";

import "./skeletonalert.css";

export const SkeletonAlert = () => {
  return (
    <section className="storybook-alert skeleton-container">
      <Skeleton
        variant="text"
        sx={{ fontSize: "1.5rem", width: "50%", margin: "10px" }}
      />
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1rem",
          width: "90%",
          margin: "10px",
        }}
      />
    </section>
  );
};
