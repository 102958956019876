import React from "react";
import PropTypes from "prop-types";
import "./progressbar.css";

export const ProgressBar = ({
  progress,
  label,
  link,
  description,
  barType,
  labelLocation,
  percentLocation,
  showScale,
}) => {
  var barTypeCss = barType;
  var showProgressLabel = true;
  if (["tiny", "small"].includes(barType)) {
    showProgressLabel = false;
  }
  return (
    <div className="storybook-progressbar">
      {labelLocation === "top" &&
        (link?.length > 0 ? (
          <a href={link} target="_blank" rel="noreferrer">
            <div className="storybook-progressbar-label">
              <span>{label}</span>
            </div>
            <div className="storybook-progressbar-desc">
              {description && description}
            </div>
          </a>
        ) : (
          <>
            <div className="storybook-progressbar-label">
              <span>{label}</span>
            </div>
            <div className="storybook-progressbar-desc">
              {description && description}
            </div>
          </>
        ))}

      <div
        className={[
          "storybook-progressbar-bckgrnd-bar",
          `storybook-progressbar-outer-${barTypeCss}`,
        ].join(" ")}
      >
        {showScale && (
          <>
            {progress < 21 && (
              <div className="scale-25" data-label="25%" aria-hidden="true">
                | 25%
              </div>
            )}
            {progress < 41 && (
              <div className="scale-50" data-label="50%" aria-hidden="true">
                | 50%
              </div>
            )}
            {progress < 71 && (
              <div className="scale-75" data-label="75%" aria-hidden="true">
                | 75%
              </div>
            )}
          </>
        )}
        <div
          data-testid="progressbarProgress"
          className="storybook-progressbar-progress"
          style={{ width: progress + "%" }}
          data-progress={progress}
        >
          {showProgressLabel && (
            <span
              data-testid="progressLabel"
              className={`"storybook-progressbar-progresslabel-${barTypeCss} ${
                percentLocation !== "undefined" && percentLocation
              }`}
            >
              {progress}%
            </span>
          )}
        </div>
      </div>
      {labelLocation === "bottom" && (
        <span className="storybook-progressbar-label">{label}</span>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  /**
   * Percent Progress Complete
   */
  progress: PropTypes.number.isRequired,
  /**
   * Label to be displayed under the progress bar (can be left empty)
   */
  label: PropTypes.string,
  /**
   * Optional link can be added to surround label and description.
   */
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * Label location
   */
  labelLocation: PropTypes.oneOf(["top", "bottom"]),
  /**
   * Percent location
   */
  percentLocation: PropTypes.oneOf(["inside", "outside"]),
  /**
   * Bar type to be displayed. Options(tiny, small, standard, large, big)
   */
  barType: PropTypes.string,
  /**
   * Labe description is additional copy to include along side the label.
   */
  description: PropTypes.string,
  /**
   * Show scale of 25, 50 and 75% inside the progress indicator?
   */
  showScale: PropTypes.bool,
};

ProgressBar.defaultProps = {
  progress: 0,
  label: "Demo Label",
  barType: "standard",
  labelLocation: "top",
};
