import * as types from "../actions/actionTypes";

const initialState = {};

export default function signOutReducer(state = initialState, action) {
  switch (action.type) {
    case types.SIGNOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
