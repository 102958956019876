import * as types from "./actionTypes.js";
import { handleError } from "../../api/apiUtils.js";
import { beginApiCall } from "./apiStatusActions.js";
import * as dashboardApi from "../../api/dashboardApi.js";

export function setAppointmentSuccess(appointment) {
  return { type: types.SET_APPOINTMENT_SUCCESS, appointment };
}

export function setAppointmentLinksSuccess(appointmentLinks) {
  return { type: types.SET_APPOINTMENTLINKS_SUCCESS, appointmentLinks };
}

export function saveAppointment(appointment) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      dispatch(setAppointmentSuccess(appointment));
      return appointment;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function saveAppointmentLinks(currentProgram) {
  return function (dispatch) {
    dispatch(beginApiCall());
    try {
      const apptLinks = dashboardApi.setAppointmentLinks(currentProgram);
      dispatch(setAppointmentLinksSuccess(apptLinks));
      return apptLinks;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}
