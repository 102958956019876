import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function staticReducer(state = initialState.staticContent, action) {
  switch (action.type) {
    case types.FETCH_STATIC_DASH_SUCCESS:
      return { ...state, dashboard: action.dashboard };
    case types.FETCH_STATIC_MYTEAM_SUCCESS:
      return { ...state, myteam: action.myteam };
    case types.FETCH_STATIC_CAREERSERV_SUCCESS:
      return { ...state, careerServices: action.careerServices };
    case types.FETCH_VIRTUAL_ID_SUCCESS:
      return { ...state, virtualID: action.virtualID };
    case types.FETCH_STATIC_WELLNESS_SUCCESS:
      return { ...state, wellnessServices: action.wellnessServices };
    default:
      return state;
  }
}
