import React from "react";

import { IconSuccess } from '../../../assets';

const PreReqSuccess = () => {
  return null;
}

export const preReqSuccess = {
  icon: {
    name: "",
    color: "",
    size: "96px",
    isImage: true,
    imgUrl: IconSuccess,
  },
  title: `Thank you for your submission!
    You will hear from us in 2-5 business days.`,
  openDialog: true,
  showCloseIcon: false,
  confirmText: 'Continue',
  size: 'extra-large',
  showCloseIcon: true,
  extraClass: 'modal-prereq text-center',
  children: '',
};