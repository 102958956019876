/**
 * Student Onboarding tasks displayed in My Tasks section of dashboard
 */
export let studentOnboarding = [
  {
    id: "schedule-onboarding",
    title: "Schedule Onboarding",
    subtitle: "",
    description:
      "Welcome to Calbright! Your first important task is to sign up for and attend a virtual Onboarding session. During Onboarding, you’ll meet fellow students and the enrollment team, and you’ll find out how to be successful at Calbright. Most importantly, you’ll officially enroll in your program.",
    progress: "default",
    statusLabel: "",
    url: "#",
    linkUrl: "/appointment?type=schedule-onboarding",
  },
  {
    id: "enrollment-agreement",
    title: "Sign Enrollment Agreement",
    subtitle: "",
    description:
      "During your Onboarding session, you’ll officially enroll in your program of study by filling out and signing your enrollment agreement. This task will get checked off automatically after you complete it during Onboarding.",
    progress: "default",
    tooltip: "You will receive an email to Sign Enrollment Agreement",
    linkUrl: '#'
  },
  {
    id: "schedule-kickoff",
    title: "Schedule Kickoff",
    subtitle: "",
    description:
      "The second part of orientation at Calbright is the Program Kickoff. This session is led by your instructor who’ll provide more details about your academic program and how to navigate the learning platform where you’ll complete your work. Make sure to sign up for and attend the kickoff to ensure a strong academic start.",
    progress: "default",
    linkUrl: "/appointment?type=schedule-kickoff",
  },
  {
    id: "complete-assignment",
    title: "Complete First Assignment",
    subtitle: "",
    description:
      "To become an actively enrolled student at Calbright, you’ll need to complete your first assignment within 30 days of your Onboarding session. During your Program Kickoff, the instructor will show you how to complete your first assignment. If you need more help with the assignment, contact Peer Tutoring using the link below.",
    progress: "default",
    linkUrl: "/complete-first-assignment",
  },
];

export const preRequisiteTask = {
  id: "prerequisite-verification",
  title: "Pre-requisite Check",
  subtitle: "",
  description:
    "The prerequisite check for Cybersecurity or Network+ programs at Calbright College typically involves assessing your foundational knowledge in areas relevant to these fields. This process often helps you to ensure you are prepared for the program’s demands and can excel in your studies.",
  progress: "default",
  linkUrl: ""
};

export const progressStages = [
  { progress: 0, step: 1, status: ["started"] },
  { progress: 1, step: 1, status: ["in-progress"] },
  { progress: 15, step: 2, status: ["passed", "started"] },
  { progress: 20, step: 2, status: ["passed", "in-progress"] },
  { progress: 38, step: 3, status: ["passed", "passed", "in-progress"] },
  { progress: 50, step: 3, status: ["passed", "passed", "started"] },
  { progress: 63, step: 4, status: ["passed", "passed", "passed", "started"] },
  { progress: 85, step: 4, status: ["passed", "passed", "passed", "started"] },
  { progress: 88, step: 5, status: ["passed", "passed", "passed", "passed", "started"] },
  { progress: 100, step: 5, status: ["passed", "passed", "passed", "passed", "assignment-1"] }
];
