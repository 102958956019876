import React, { useState } from "react";
import PropTypes from "prop-types";

import "./popover.css";

/**
 * A popoover displays contents on top of other content.
 */
export const Popover = ({
  content,
  children,
  isPadded,
  withArrow,
  openOnHover,
  size,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const popoverOpen = () => {
    setIsOpen(true);
  };

  const popoverClose = () => {
    setIsOpen(false);
  };

  const handleOnClick = () => {
    if (!openOnHover) {
      togglePopover();
    }
  };

  const handleOnMouseOver = () => {
    if (openOnHover) {
      popoverOpen();
    }
  };
  const handleOnMouseLeave = () => {
    if (openOnHover) {
      popoverClose();
    }
  };

  let popoverClasses = [
    "storybook-popover-content",
    `storybook-popover--${size}`,
  ];

  if (isPadded) {
    popoverClasses.push("storybook-popover--padded");
  }
  if (withArrow) {
    popoverClasses.push("storybook-popover--witharrow");
  }
  if (openOnHover) {
    popoverClasses.push("storybook-popover-container--mouseover");
  } else {
    popoverClasses = popoverClasses.filter(
      (item) => item !== "storybook-popover-container--mouseover"
    );
  }

  return (
    <>
      <span
        className="storybook-popover-trigger"
        onClick={handleOnClick}
        onMouseOver={handleOnMouseOver}
        onMouseLeave={handleOnMouseLeave}
        data-testid="popoverTrigger"
      >
        {children}
      </span>
      <span
        className="storybook-popover-container"
        data-testid="popoverContainer"
      >
        {isOpen && (
          <span
            className={popoverClasses.join(" ")}
            data-testid="popoverContent"
          >
            {content}
          </span>
        )}
      </span>
    </>
  );
};

Popover.propTypes = {
  /**
   * Contents of the Popover.
   */
  content: PropTypes.node.isRequired,
  /**
   * Component or target that the Popover is applied to.
   */
  children: PropTypes.node.isRequired,
  /**
   * Add padding to the Popover.
   */
  isPadded: PropTypes.bool,
  /**
   * Display an arrow pointing to the target.
   */
  withArrow: PropTypes.bool,
  /**
   * Display popover on mouseover? (If false it will display onClick)
   */
  openOnHover: PropTypes.bool,
  /**
   * How large should the Popover be?
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]).isRequired,
};

Popover.defaultProps = {
  content: "Popover Text",
  children: <div>Content to Popover</div>,
  isPadded: true,
  withArrow: false,
  openOnHover: false,
  size: "medium",
};
