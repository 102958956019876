import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as tokenActions from "../../redux/actions/tokenActions";
import * as userActions from "../../redux/actions/userActions";
import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as myteamActions from "../../redux/actions/myteamActions";
import * as avatarActions from "../../redux/actions/avatarActions";
import * as broadcastMsgActions from "../../redux/actions/broadcastMsgActions";
import { useHistory } from "react-router-dom";
import { handleError } from "../../api/apiUtils";
import { identifyHotjarStudent } from "../../utils/hotjar";
import { gtagEventStudentInfo } from "../../utils/gtag";
import { SkeletonDashboard } from "../Skeleton/SkeletonDashboard";
import useLiveChat from "./../../hooks/live-chat";

const RequireAuth = ({
  children,
  token,
  user,
  requireAuth = true,
  saveCode,
  saveToken,
  saveUser,
  appointment,
  appointmentLinks,
  saveAppointment,
  saveAppointmentLinks,
  teamData,
  fetchTeamData,
  loading,
  avatars,
  fetchAvatars,
  updateAvatar,
  setKickOffScheduled,
  ...propToPass
}) => {
  const { liveChat } = useLiveChat();
  const setPreviousUrl = () => {
    const previousUrl = window.location.href;
    localStorage.setItem("previousUrl", previousUrl);
    return;
  };

  const getPreviousUrl = () => {
    if (localStorage.getItem("previousUrl") === null) {
      return "/";
    }
    const prevURL = new URL(localStorage.getItem("previousUrl"));
    let prevURLPath = prevURL.pathname;
    let prevURLSearch = prevURL.search !== null ? prevURL.search : "";
    return prevURLPath !== null ? prevURLPath + prevURLSearch : "/";
  };

  const clearPreviousUrl = () => {
    localStorage.removeItem("previousUrl");
  };

  const history = useHistory();

  // Check if authenticated
  useEffect(() => {
    const hash = new URLSearchParams(location.hash);
    const accessToken = hash.get("#access_token");

    if (
      requireAuth &&
      accessToken === null &&
      Object.keys(token).length === 0
    ) {
      setPreviousUrl();
      window.location.replace(process.env.AUTH_URL);
    }

    if (requireAuth && Object.keys(token).length === 0) {
      try {
        const currentToken = saveToken();
        saveUser(currentToken)
          .then(() => {
            const prevURL = getPreviousUrl();
            history.push(prevURL);
          })
          .catch(handleError);
      } catch (error) {
        throw Error("Unable to save token or user", error.msg);
      }
    }
  }, [user]);

  useEffect(() => {
    liveChat();

    if (user?.currentProgram && user?.learnerStatus) {
      gtagEventStudentInfo(user);
      identifyHotjarStudent(user);
    }
  }, [user]);

  if (loading) {
    return <SkeletonDashboard />;
  }

  if (Object.keys(user).length > 0 && !user?.currentProgram) {
    const url = new URLSearchParams(location);
    if (
      url.get("pathname") !== "/" &&
      !url.get("hash").includes("access_token")
    ) {
      history.push("/");
    }
  }

  // If authenticated, render the wrapped component with props
  const WrappedComponentWithProps = React.cloneElement(children, {
    user,
    saveUser,
    appointment,
    appointmentLinks,
    saveAppointment,
    saveAppointmentLinks,
    teamData,
    fetchTeamData,
    loading,
    token,
    avatars,
    fetchAvatars,
    updateAvatar,
    setKickOffScheduled,
    ...propToPass,
  });

  return WrappedComponentWithProps;
};

const mapStateToProps = (state) => ({
  token: state.token,
  user: state.user,
  appointment: state.appointment,
  appointmentLinks: state.appointmentLinks,
  teamData: state.teamData,
  loading: state.loading,
  avatars: state.avatars,
});

const mapDispathToProps = {
  saveCode: tokenActions.saveCode,
  saveToken: tokenActions.saveToken,
  saveUser: userActions.saveUser,
  updateUser: userActions.updateUser,
  saveAppointment: appointmentActions.saveAppointment,
  saveAppointmentLinks: appointmentActions.saveAppointmentLinks,
  fetchTeamData: myteamActions.fetchTeamData,
  fetchAvatars: avatarActions.fetchAvatars,
  updateAvatar: avatarActions.updateAvatar,
  setKickOffScheduled: userActions.setKickOffScheduled,
};

export default connect(mapStateToProps, mapDispathToProps)(RequireAuth);
