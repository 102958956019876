import * as types from "./actionTypes";
import * as staticApi from "../../api/staticApi.js";
import { handleError } from "../../api/apiUtils";
import { beginApiCall } from "./apiStatusActions.js";

export function fetchStaticDashSuccess(dashboard) {
  return { type: types.FETCH_STATIC_DASH_SUCCESS, dashboard };
}

export function fetchStaticMyTeamSuccess(myteam) {
  return { type: types.FETCH_STATIC_MYTEAM_SUCCESS, myteam };
}

export function fetchStaticCareerServicesSuccess(careerServices) {
  return { type: types.FETCH_STATIC_CAREERSERV_SUCCESS, careerServices };
}

export function fetchStaticVirtualIDSuccess(virtualID) {
  return { type: types.FETCH_VIRTUAL_ID_SUCCESS, virtualID };
}

export function fetchStaticWellnessSuccess(wellnessServices) {
  return { type: types.FETCH_STATIC_WELLNESS_SUCCESS, wellnessServices };
}

export function fetchStaticDashboard() {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const dashboard = await staticApi.getStaticDashboard();
      dispatch(fetchStaticDashSuccess(dashboard));
      return dashboard;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function fetchStaticMyTeam() {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const myteam = await staticApi.getStaticMyTeam();
      dispatch(fetchStaticMyTeamSuccess(myteam));
      return myteam;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function fetchStaticCareerServices(requestURL) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const careerServices = await staticApi.getStaticContent(requestURL);
      dispatch(fetchStaticCareerServicesSuccess(careerServices));
      return careerServices;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function fetchStaticVirtualID(requestURL) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const virtualID = await staticApi.getStaticContent(requestURL);
      dispatch(fetchStaticVirtualIDSuccess(virtualID));
      return virtualID;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function fetchStaticWellness(requestURL) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const wellnessServices = await staticApi.getStaticContent(requestURL);
      dispatch(fetchStaticWellnessSuccess(wellnessServices));
      return wellnessServices;
    } catch (error) {
      dispatch(handleError(error));
      throw error;
    }
  };
}
