import { useSelector, useDispatch } from "react-redux";
import { setDisabledAction } from '../redux/reducers/helpReducer';

export default function useLiveChat () {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const liveChat = () => {
    if (Object.keys(user).length === 0) {
      // initESW(null);
      return;
    };

    if (!window.embedded_svc) {
      const s = document.createElement("script");
      s.setAttribute("src", process.env.SF_EMBEDDED_SERVICE);
      s.onload = function () {
        initESW(null);
      };
      document.body.appendChild(s);
    }
    // else {
    //   initESW(process.env.SF_URL);
    // }

    // observe();
  }

  // const isTargetNode = (node, target) => {
  //   return node.classList && node.classList.contains(target);
  // }
  // 
  // const handleMutation = (mutations, observer) => {
  //   for (const mutation of mutations) {
  //     if (mutation.type === "childList") {
  //       Array.from(mutation.addedNodes).forEach((node) => {
  //         if (isTargetNode(node, "featureBody")) {
  //           const suppliedName = node.querySelector(".SuppliedName");
  //           const suppliedEmail = node.querySelector(".SuppliedEmail");
  // 
  //           if (suppliedName && suppliedEmail) {
  //             suppliedName.value = `${user.firstName} ${user.lastName}`;
  //             suppliedEmail.value = user.email;
  //           }
  //         }
  //       });
  //     }
  //   }
  // }
  // 
  // const observe = () => {
  //   if ("MutationObserver" in window) {
  //     const observable = document.querySelector("body");
  //     const observer = new MutationObserver(handleMutation);
  //     observer.observe(observable, {
  //       attributes: false,
  //       childList: true,
  //       subtree: true
  //     });
  //   }
  // }

  const initESW = (gslbBaseURL) => {
    embedded_svc.settings.displayHelpButton = true;
    embedded_svc.settings.language = "";
    embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    embedded_svc.settings.entryFeature = "LiveAgent";

    embedded_svc.settings.prepopulatedPrechatFields = {
      // Name: `${user.firstName} ${user.lastName}`,
      FirstName: user.firstName,
      LastName: user.LastName,
      Email: user.email
    }

    embedded_svc.init(
      process.env.SF_MY_URL,
      process.env.SF_LIVE_AGENT,
      gslbBaseURL,
      process.env.SF_KEY,
      "Calbright_College_Learner_Support",
      {
        baseLiveAgentContentURL: process.env.SF_BASE_LIVE_AGENT_CONTENT_URL,
        deploymentId: process.env.SF_DEPLOYMENT_ID,
        buttonId: process.env.SF_BUTTON_ID,
        baseLiveAgentURL: process.env.SF_BASE_LIVE_AGENT_URL,
        eswLiveAgentDevName: "Calbright_College_Learner_Support",
        isOfflineSupportEnabled: true
      }
    );

    addChatEventListeners();
  };

  const openChat = () => {
    document.querySelector('.helpButtonEnabled')?.click();
    document.querySelector('.helpButton .content')?.click();
  }

  const addChatEventListeners = () => {
    window?.embedded_svc.addEventHandler("onHelpButtonClick", function(data) {
      dispatch(setDisabledAction(true));
    });

    window?.embedded_svc.addEventHandler("afterMaximize", function(data) {
      dispatch(setDisabledAction(true));
    });

    window?.embedded_svc.addEventHandler("afterMinimize", function(data) {
      dispatch(setDisabledAction(false));
    });

    window?.embedded_svc.addEventHandler("afterDestroy", function(data) {
      dispatch(setDisabledAction(false));
    });
  }

  return { liveChat, openChat };
}