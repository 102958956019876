import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "./../Button/Button";

import AddFile from "./../assets/file-upload/add-file.svg";
import Uploaded from "./../assets/file-upload/uploaded.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./fileupload.css";

export const FileUpload = ({
  title,
  name,
  allowedExt,
  fileSize,
  notice,
  submitFile,
  error,
  getFile,
  btnLoading
}) => {
  const [file, setFile] = useState(null);
  const [over, setOver] = useState(false);
  const [fileError, setFileError] = useState(error);
  const inputRef = useRef();

  const handleDragEnter = (event) => {
    event.preventDefault();
    setOver(true);
  }

  const handleDragLeave = (event) => {
    setOver(false);
  }

  const handleFile = (event) => {
    event.preventDefault();
    const maxAllowedSize = (fileSize * 1024 * 1024);
    const inputFile = event.dataTransfer
      ? event.dataTransfer.files[0] : event.target.files[0];

    setFileError(null);
    setOver(false);

    if (maxAllowedSize < inputFile?.size) {
      setFileError("Your file exceeds the maximum allowed size.");
      return;
    }

    const ext = inputFile?.name.split('.').pop().toLowerCase();

    if (!allowedExt.includes(ext)) {
      setFileError(`Invalid file extension. Please try to upload ${allowedExt}.`);
    }

    inputFile.certName = name;
    setFile(inputFile);
  }

  const handleDeleteFile = (event) => {
    event.stopPropagation();
    setFileError(null);
    setFile(null);
  }

  const handleSubmitFile = async (event) => {
    if (submitFile) {
      await submitFile(file);
      setFileError(null);
      setFile(null);
    }
  }

  useEffect(() => {
    if (getFile) {
      getFile(file);
    }
  }, [file, getFile]);

  useEffect(() => {
    setFileError(error);
  }, [error])

  return (
    <div className="file-upload" data-testid="file-upload">
      {title && (
        <h2 className="head-thin">{title}</h2>
      )}
      <div
        className={`file-dropzone ${over ? "over" : ""}`}
        onDragOver={handleDragEnter}
        onDrop={(event) => handleFile(event)}
        onDragLeave={handleDragLeave}
        onClick={() => inputRef.current.click()}
      >
        {!file
          ?
            <>
              <img src={AddFile} className="file-icon" alt="" />
              <p className="file-drag-here">
                <b>Choose file</b>
                <span> or </span>
                <strong>drag here</strong>
              </p>
              <p><strong>Size limited: {fileSize}MB</strong></p>
            </>
          :
            <>
              <img src={Uploaded} className="file-icon" alt="" />
              <p className="file-name">
                <strong>{file.name}</strong>
                <button className="file-delete" onClick={handleDeleteFile}>
                <CloseIcon />
              </button>
              </p>
            </>
        }
        <input
          type="file"
          name={name}
          onChange={(event) => handleFile(event)}
          hidden
          ref={inputRef}
          accept={allowedExt}
          data-testid="file-input"
        />
        {fileError && <p className="error">{fileError}</p>}
      </div>
      {notice && <p className="storybook-notice">{notice}</p>}
      {(fileError || !file)
        ?
          <Button
            buttonType="basic"
            disabled
            label="Submit"
            isLoading={btnLoading}
          />
        :
          <Button
            label="Submit"
            onClick={handleSubmitFile}
          />
      }
    </div>
  )
}

FileUpload.propTypes = {
  /**
   * Title of the dashlet.
   */
  title: PropTypes.string,
  /**
   * Input name.
   */
  name: PropTypes.string,
  /**
   * Allowed file extensions.
   */
  allowedExt: PropTypes.string,
  /**
   * Max file size.
   */
  fileSize: PropTypes.number,
  /**
   * Notice message.
   */
  notice: PropTypes.string,
  /**
   * Submit form function.
   */
  submitFile: PropTypes.func,
  /**
   * Error submit file.
   */
  error: PropTypes.string,
  /**
   * Button loading state
   */
  btnLoading: PropTypes.bool,
}

FileUpload.defaultProps = {
  allowedExt: ".jpg,.jpeg,.png,.pdf,.doc,.docx",
  fileSize: 25,
  notice: "*You will hear back from us in 2 business days",
  submitFile: () => console.log('Submit file!'),
  name: 'file',
  btnLoading: false
}
