import React, { useState } from "react";
import PropTypes from "prop-types";
import { useCalendlyEventListener } from "react-calendly";
import { useHistory } from "react-router-dom";

import { Header } from "@storybook/stories/Header/Header";
import { Calendly } from "@storybook/stories/Calendly/Calendly";
import { Button } from "@storybook/stories/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  studentProgramLookup,
  learnerStatusLookup,
  menuData,
} from "../../utils/helpers/data";

import "./schedule.css";

export const Schedule = ({
  user,
  updateUser,
  appointment,
  saveAppointment,
  appointmentLinks,
  setKickOffScheduled,
  token,
}) => {
  const [isOnboardingScheduled, setIsOnboardingScheduled] = useState();
  const [isKickOffScheduled, setIsKickOffScheduled] = useState();
  const params = new URLSearchParams(window.location.search);
  const appointmentType = params.get("type");
  let appointmentLink = "";
  let rescheduleLink = "";
  if (appointmentLinks == null || Object.keys(appointmentLinks).length === 0) {
    return (
      <Alert type="error">
        <header>Unable to find available calendar</header>
        <p>Please contact support to request an appointment link.</p>
      </Alert>
    );
  } else {
    if (appointmentType.length > 0) {
      appointmentLink = setAppointmentLink(appointmentType, appointmentLinks);
    }
  }

  const handleLogin = () => {
    window.location.replace(process.env.AUTH_URL);
  };

  const handleLogout = () => {
    window.location.replace(process.env.LOGOUT_URI);
  };

  const handleAppointmentUpdate = (updatedValues) => {
    saveAppointment(updatedValues);

    if (updatedValues?.eventScheduled) {
      rescheduleLink = createRescheduleLink(updatedValues);
    }

    if (
      updatedValues?.eventScheduled &&
      user.learnerStatus === learnerStatusLookup["APP_SUBMITTED"] &&
      user.currentProgram === studentProgramLookup["T2T_CRM_ADMIN"]
    ) {
      updateUser({
        ...user,
        learnerStatus: learnerStatusLookup["READY_FOR_ONBOARDING"],
      });
    } else if (
      updatedValues?.eventScheduled &&
      (user.learnerStatus === learnerStatusLookup["APP_SUBMITTED"] ||
        user.learnerStatus === learnerStatusLookup["STARTED_ORIENTATION"])
    ) {
      updateUser({
        ...user,
        learnerStatus: learnerStatusLookup["COMPLETED_ORIENTATION"],
      });
    } else if (
      updatedValues?.eventScheduled &&
      user.learnerStatus === learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"]
    ) {
      updateUser({
        ...user,
        learnerStatus:
          learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE"],
      });
      // TODO: Make API call to set Kickoff Scheduled
      setKickOffScheduled(token);
    }

    if (updatedValues?.eventScheduled) {
      handleEventScheduled();
    }
  };

  const handleEventScheduled = () => {
    setTimeout(() => {
      history.push("/");
    }, 3000);
    return;
  };

  useCalendlyEventListener({
    onProfilePageViewed: () =>
      handleAppointmentUpdate({ profilePageViewed: true }),
    onDateAndTimeSelected: () =>
      handleAppointmentUpdate({ dateTimeSelected: true }),
    onEventTypeViewed: () => handleAppointmentUpdate({ eventTypeViewed: true }),
    onEventScheduled: (e) => {
      switch (user.learnerStatus) {
        case learnerStatusLookup["APP_SUBMITTED"]:
        case learnerStatusLookup["STARTED_ORIENTATION"]:
          setIsOnboardingScheduled(true);
          break;
        case learnerStatusLookup["ENROLLED_IN_PROGRAM_PATHWAY"]:
        case learnerStatusLookup[
          "ENROLLED_IN_PROGRAM_PATHWAY_KICKOFF_COMPLETE"
        ]:
          setIsKickOffScheduled(true);
        default:
          break;
      }

      return handleAppointmentUpdate({
        eventScheduled: true,
        eventData: e.data.payload,
      });
    },
  });

  const history = useHistory();
  const handleBackToDashboard = () => history.push("/");

  if (
    (appointmentType === "schedule-onboarding" && isOnboardingScheduled) ||
    (appointmentType === "schedule-kickoff" && isKickOffScheduled)
  ) {
    handleEventScheduled();
  }

  return (
    <article className="storybook-schedule" data-testid="scheduleContainer">
      <Header
        user={user}
        onLogin={handleLogin}
        onLogout={handleLogout}
        activeLink="home"
        avatarsize="medium"
        menuData={menuData}
      />
      <>
        {user && (
          <main>
            {(appointmentType === "schedule-onboarding" &&
              isOnboardingScheduled) ||
            (appointmentType === "schedule-kickoff" && isKickOffScheduled) ? (
              <Alert type="success">
                <header>Appointment Scheduled</header>
                <p>
                  Your appointment has been scheduled. You will receive an email
                  with a link to your appointment. You will be redirected to the
                  dashboard in 5 seconds.
                </p>
                <Button
                  label="Return to Dashboard"
                  onClick={handleBackToDashboard}
                />
              </Alert>
            ) : (
              <Calendly
                prefill={{
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  name: `${user.firstName} ${user.lastName}`,
                  smsReminderNumber: user.phoneNumber,
                }}
                settings={{
                  backgroundColor: "ffffff",
                  hideEventTypeDetails: false,
                  hideGdprBanner: false,
                  hideLandingPageDetails: false,
                  primaryColor: "D34508",
                  textColor: "333333",
                }}
                styles={{
                  height: "800px",
                }}
                title={appointmentLinks.program}
                url={appointmentLink}
                utm={{
                  utmCampaign: "Test",
                  utmContent: user?.email,
                  utmMedium: "Test",
                  utmSource: "Test",
                  utmTerm: "Test",
                }}
              />
            )}
          </main>
        )}
      </>
    </article>
  );
};

Schedule.propTypes = {
  user: PropTypes.shape({}),
  updateUser: PropTypes.func,
  appointment: PropTypes.shape({
    profilePageViewed: PropTypes.bool,
    dateTimeSelected: PropTypes.bool,
    eventTypeViewed: PropTypes.bool,
    eventScheduled: PropTypes.bool,
  }),
  saveAppointment: PropTypes.func,
  appointmentLinks: PropTypes.shape({
    id: PropTypes.string,
    program: PropTypes.string,
    onboardingUrl: PropTypes.string,
    kickoffUrl: PropTypes.string,
  }),
  saveAppointmentLinks: PropTypes.func,
  setKickOffScheduled: PropTypes.func,
};

const createRescheduleLink = (calendlyData) => {
  return calendlyData.eventData?.invitee.uri.replace(
    "https://api.calendly.com/scheduled_events/",
    "https://calendly.com/reschedulings/"
  );
};

/**
 * Gets the schedule link from Redux or reschedule link from localStorage.
 * @param {string} appointmentLink
 */
const setAppointmentLink = (appointmentType, appointmentLinks) => {
  let apptLink = "";
  const rescheduleLinks = "";
  let appointmentData = "";
  if (localStorage.getItem(appointmentType) !== null) {
    appointmentData = JSON.parse(localStorage.getItem(appointmentType));
  }

  if (appointmentData?.rescheduleLink !== undefined) {
    apptLink = appointmentData.rescheduleLink;
  } else {
    if (appointmentType === "schedule-onboarding") {
      apptLink = appointmentLinks.onboardingUrl;
    } else {
      apptLink = appointmentLinks.kickoffUrl;
    }
  }
  return apptLink;
};

const Alert = ({ children, type }) => {
  const alertClass = ["storybook-alert"];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  switch (type) {
    case "error":
      alertClass.push("storybook-alert--error");
      break;
    case "success":
      alertClass.push("storybook-alert--success");
      break;
    case "warning":
      alertClass.push("storybook-alert--warning");
      break;
    default:
      alertClass.push("storybook-alert--info");
  }
  return (
    <section className={alertClass.join(" ")} data-testid="alertContainer">
      <button
        className="storybook-alert-close"
        data-testid="closeAlert"
        onClick={handleClose}
      >
        <CloseIcon />
        <span className="sr-only">Close Alert</span>
      </button>
      {children}
    </section>
  );
};

export default Schedule;
