import React from "react";

import CitingSources from "../../assets/library-services/CitingSources.png";
import FrequentlyAskedQuestions from "../../assets/library-services/FrequentlyAskedQuestions.png";
import LeveragingAI from "../../assets/library-services/LeveragingAI.png";
import ResearchGuide from "../../assets/library-services/ResearchGuide.png";
import TopicsofTheMonth from "../../assets/library-services/TopicsofTheMonth.png";

import { Accordion } from "@storybook/stories/Accordion/Accordion";
import { TopicOfTheMonth } from "../../components/TopicOfTheMonth/TopicOfTheMonth";

const LibraryFAQData = [
  {
    title: "Is it ethical to use AI in higher education?",
    content:
      "Yes, using AI in higher education can be ethical when done appropriately. At Calbright, your instructors will teach you how to use AI tools responsibly, ways to develop critical information literacy skills, and appropriate applications of AI that align with academic integrity. These skills will not only benefit your education but also prepare you for future career opportunities where AI use is increasingly common.",
  },
  {
    title: "How Do I Write Effective AI Prompts?",
    content: (
      <>
        Five tips to generate an effective AI Prompt:
        <ol>
          <li>
            Be Specific: Provide specific and detailed prompts that include
            goals, tone, format, and thematic focus.
          </li>
          <li>
            Give Context: Provide background information, role, and including
            examples or descriptions.
          </li>
          <li>
            Consider Audience: Specify the intended audience, including
            demographics, knowledge, and expectations.
          </li>
          <li>
            Provide Feedback: AI learns from your prompts. Give room to refine
            your results by telling AI what you do and don’t want. Try as many
            tones as needed by changing the wording or adding more context.
          </li>
          <li>
            Scan for Biases: Look for over-generalizations and stereotypes.
            Evaluate if output is supported by factual evidence. You are
            responsible for accuracy.
          </li>
        </ol>
        <p>*And ALWAYS remember to cite AI as a source</p>
        <p>
          <strong>References</strong>
        </p>
        <p>
          Derived from Harvard University, Information Technology. (2024).
          Getting started with prompts for text-based Generative AI tools.
          Retrieved July 30, 2024 from{" "}
          <a
            href="https://huit.harvard.edu/news/ai-prompts"
            target="_blank"
            rel="noreferrer"
          >
            https://huit.harvard.edu/news/ai-prompts
          </a>
        </p>
        <p>
          Derived from The University of Texas Rio Grande Valley, Center for
          Online Learning and Teaching Technology. (2024). Writing Effective
          Prompts for Generative AI. Retrieved July 30, 2024 from{" "}
          <a
            href="https://www.utrgv.edu/online/teaching-online/elearning-topics/edutech-ai/prompts/index.htm"
            target="_blank"
            rel="noreferrer"
          >
            https://www.utrgv.edu/online/teaching-online/elearning-topics/edutech-ai/prompts/index.htm
          </a>
          .
        </p>
      </>
    ),
  },
  {
    title: "What Should I Do If I Need Help With My Coursework?",
    content: (
      <>
        We have faculty and staff who are excited to help you succeed. Please
        reach out to your instructor, a tutor, or student services for
        assistance.
        <p>
          Take a look at the{" "}
          <a
            href="https://drive.google.com/file/d/1VpQ6cSBFrv7ZIkwELrXUdejqjivt77Gg/view"
            target="_blank"
            rel="noreferrer"
          >
            Tutoring and Learning Center poster
          </a>
          , which outlines all of the wonderful services from our Tutoring
          Center. Or,{" "}
          <a
            href="https://www.calbright.edu/student-life/support-counseling/"
            target="_blank"
            rel="noreferrer"
          >
            book an appointment with one of our tutors
          </a>
          .
        </p>
      </>
    ),
  },
  {
    title: "Do I receive any paid subscriptions as a student?",
    content:
      "Yes! Using your student account to sign into EBSCOhost (see button at the top of our Library Services page), you can access over 30 different databases, hundreds of e-books, and thousands of full text articles from magazines, newspapers, and academic journals (including peer-reviewed).",
  },
];

export const LibraryServicesData = [
  {
    id: 1,
    title: "Topics of the Month",
    description: <TopicOfTheMonth type="description" />,
    image: TopicsofTheMonth,
    content: (
      <div className="storybook-learning-content">
        <TopicOfTheMonth type="content" />
      </div>
    ),
  },
  {
    id: 2,
    title: "Leveraging AI",
    description:
      "Use AI tools ethically to enhance coursework. By integrating AI responsibly, students can improve their learning outcomes and gain valuable insights.",
    image: LeveragingAI,
    content: (
      <div className="storybook-learning-content">
        <p>
          Artificial Intelligence (AI) has recently seen widespread adoption,
          including in higher education, for personalized learning, predictive
          analytics, and research assistance. Calbright College is committed to
          integrating AI into its curriculum, emphasizing information literacy
          and ethical use. This includes teaching critical evaluation of
          AI-generated information, responsible AI use, and societal impacts of
          AI. Calbright aims to produce graduates proficient in AI use and
          capable of navigating its ethical landscape, preparing them for AI
          integrated workplaces and broader societal implications.
        </p>
        <h3>The Importance of AI Prompts</h3>
        <p>
          Creating effective prompts is crucial for optimal AI results. Clear
          and detailed prompts guide the AI to generate accurate and relevant
          responses. Investing time in precise prompts enhances AI content
          quality, reduces errors, and aligns output with the intended purpose.
          This approach maximizes AI potential, saves time, and improves user
          experience.
        </p>
        <h3>Bias and Hallucinations</h3>
        <p>
          In AI, biases and hallucinations are critical issues impacting
          reliability and fairness. Biases arise when training data reflect
          historical inequalities, leading to skewed outcomes. Hallucinations
          occur when AI generates inaccurate outputs presented as facts. These
          challenges require ongoing research and ethical considerations to
          ensure AI technologies are equitable and trustworthy.
        </p>
        <h3>Need Help Using AI in an Assignment?</h3>
        <p>
          Our tutors are skilled in using AI ethically, responsibly, and
          effectively to assist with your assignments.{" "}
          <a
            href="https://www.calbright.edu/student-life/support-counseling/"
            target="_blank"
            rel="noreferrer"
          >
            Go to Counseling Services to learn more.
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: "Research Guide",
    description:
      "Search engines like Google are convenient, but it's crucial to evaluate sources for reliability.",
    image: ResearchGuide,
    content: (
      <div className="storybook-learning-content">
        <p>
          While search engines like Google offer convenience, it's crucial to
          evaluate sources for reliability. Traditional publications, such as
          books and peer-reviewed articles, provide verified, in-depth knowledge
          and expert insights. Our library offers access to a comprehensive
          collection of books, periodicals, and archival materials through our
          online repositories.
        </p>

        <h3>Basic Steps for Conducting Effective Project Research</h3>
        <ol>
          <li>
            Understand the prompt by thoroughly reading instructor instructions
            and identifying all parts to address.
          </li>
          <li>
            Choose an original topic or angle to ensure relevance and
            originality.
          </li>
          <li>
            Use credible, non-biased sources from our academic database,
            EBSCOhost, or other respected sources.
          </li>
          <li>
            Cite your sources according to the format and requirements outlined
            in your syllabus.
          </li>
        </ol>
        <h3>Using a database</h3>
        <p>
          An academic database is a digital repository of scholarly resources,
          including journals, research articles, and e-books. Use these
          platforms to conduct in-depth research, access credible academic
          sources, explore diverse perspectives, and cite authoritative
          references in your work.
        </p>
        <p>
          How to use{" "}
          <a
            href="https://www.youtube.com/watch?v=vT833d5F2yI&t=79s"
            target="_blank"
            rel="noreferrer"
          >
            Ebsco Host
          </a>{" "}
          for basic research.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    title: "Citing Sources",
    description:
      "Master citations to avoid plagiarism, enhance credibility, and strengthen your work by giving proper credit to original sources.",
    image: CitingSources,
    content: (
      <div className="storybook-learning-content">
        <p>
          We are dedicated to shaping a future where education is limitless,
          ensuring that every individual has the opportunity to reach their full
          potential. Our commitment lies in breaking down barriers to learning,
          empowering people from all walks of life to achieve their dreams and
          contribute meaningfully to the world.
        </p>
        <h3>Why do we cite our work?</h3>
        <p>Citing sources in academic work is essential because it:</p>
        <ul>
          <li>
            Strengthens your arguments by backing them with credible sources.
          </li>
          <li>
            Prevents plagiarism by giving proper credit to original ideas.
          </li>
          <li>Upholds academic integrity by respecting the work of others.</li>
          <li>
            Helps readers find and assess your sources, promoting further
            research.
          </li>
          <li>
            Enhances your credibility as a well-researched and knowledgeable
            scholar.
          </li>
        </ul>
        <h3>American Psychological Association (APA)</h3>
        <p>
          Calbright values academic research, creativity, and citing the work of
          original authors while we learn through the valuable work of others.
          American Psychological Association (APA) citation style is used to
          avoid plagiarism and give credit to others for their work. APA (7th
          edition) requires:
        </p>
        <ul>
          <li>
            In-Text Citations for each source when paraphrasing and quoting.
            <a
              href="https://www.youtube.com/watch?v=-yi6GXPhybs&list=PL8F43A67F38DE3D5D&index=9"
              target="_blank"
              rel="noreferrer"
            >
              Check out this Purdue OWL (2020) video
            </a>{" "}
            for some context and examples.
          </li>
          <li>
            References page with full citation details for each source used.
            Here is another{" "}
            <a
              href="https://www.youtube.com/watch?v=HpAOi8-WUY4&list=PL8F43A67F38DE3D5D&index=4"
              target="_blank"
              rel="noreferrer"
            >
              Purdue OWL video
            </a>{" "}
            to help explain some Reference Page basics.
          </li>
          <li>
            For more details, please visit{" "}
            <a
              href="https://owl.purdue.edu/owl/research_and_citation/apa_style/apa_formatting_and_style_guide/general_format.html"
              target="_blank"
              rel="noreferrer"
            >
              Purdue's Online Writing Lab (OWL)
            </a>
            .
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 5,
    title: "Frequently Asked Questions",
    description: (
      <>
        Please review the FAQs that may answer some of the questions you have.
        If you need further assistance, please email us at{" "}
        <a href="mailto:libraryservices@calbright.org">
          libraryservices@calbright.org
        </a>
        . We are happy to help.
      </>
    ),
    image: FrequentlyAskedQuestions,
    content: (
      <div className="storybook-learning-content">
        <Accordion items={LibraryFAQData} />
      </div>
    ),
  },
];

export const LibraryCTAContent = `EBSCOhost is an academic database providing access to free scholarly content across numerous disciplines. Its intuitive interface, to easily find and manage relevant information.`;

export const LibraryNotEnrolledCTAContent = `To access EBSCOhost, you’ll need to complete
  your first academic assignment in your program. <br />Please contact the success
  team at <a href="mailto:success@calbright.org">success@calbright.org</a> for
  additional assistance.`;
