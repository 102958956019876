import React from "react";
import PropTypes from "prop-types";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Intro } from "@storybook/stories/Intro/Intro";
import { CallToAction } from "@storybook/stories/CallToAction/CallToAction";
import { Resources } from "@storybook/stories/Resources/Resources";
import { MeetYourTeam } from "@storybook/stories/MeetYourTeam/MeetYourTeam";
import isStudentEnrolled from "../../utils/isStudentEnrolled";
import {
  AccessibilityServicesData,
  MeetYourTeamData,
  CardContent,
  NotEnrCardContent,
} from "../../utils/helpers/accessibility-services";

import "./accessibilityservices.css";

const AccomodateSSO =
  "https://shibboleth-calbright-accommodate.symplicity.com/sso/";

export const AccessibilityServices = ({ user }) => {
  const enrolled = isStudentEnrolled(user.learnerStatus);

  const handleCTA = () => {
    window.open(AccomodateSSO, "_blank").focus();
  };

  return (
    <article
      className="storybook-accessibility"
      data-testid="accessibilityContainer"
    >
      <PortalHeader user={user} activeLink="services" />
      <main>
        <div className="container">
          {user && (
            <>
              <Intro
                title="Accessibility Services"
                content={
                  <>
                    Accessibility Services (AS) is Calbright’s disability
                    support program for students. It is a voluntary and
                    confidential program serving eligible students with
                    disabilities who register for services. For information and
                    assistance with registering, please email {""}
                    <a href="mailto:accessibilityservices@calbright.org">
                      accessibilityservices@calbright.org
                    </a>
                    .
                  </>
                }
              />
              <CallToAction
                props={{
                  cardTitle: "My Accommodate",
                  enrolled: enrolled,
                  cardContent: CardContent,
                  notEnrCardContent: NotEnrCardContent,
                  btnLabel: "Go to My Accommodate",
                  btnSubmit: handleCTA,
                  message: "",
                  closeMessage: "",
                }}
              />
              <Resources resources={AccessibilityServicesData} />
              <MeetYourTeam teammembers={MeetYourTeamData} />
            </>
          )}
        </div>
      </main>
    </article>
  );
};

AccessibilityServices.propTypes = {
  user: PropTypes.shape({}),
};

export default AccessibilityServices;
