import React from "react";
import { Modal } from "@storybook/stories/Modal/Modal";
import { IconError } from "./../../assets";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <Modal
      title="Something doesn’t look right!"
      openDialog={true}
      showCloseIcon={false}
      confirmText="Go to Dashboard"
      confirmClick={() => history.push("/")}
      extraClass="modal-log-out"
      icon={{
        imgUrl: IconError,
        isImage: true,
        name: "",
        size: "52",
      }}
    >
      <p className="text-center">
        We can’t find the page your are looking for.
      </p>
      <p className="text-center">
        Try navigating from the Dashboard or contact Support at 1-833-956-0225
        or email us at{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a>.
      </p>
    </Modal>
  );
};

export default PageNotFound;
