import React from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";

import { IconError } from "../../assets";

/**
 * Component that displays text in two paragraphs.
 * @param {Object} props.copy - The copy object containing intro and additional properties.
 * @param {string} props.copy.intro - The intro text to display.
 * @param {React.ReactNode} props.copy.additional - The additional content to display.
 */
export const ErrorMessage = ({ copy }) => {
  const history = useHistory();
  return (
    <>
      <p className="text-center">{copy?.intro}</p>
      <p className="text-center">{copy?.additional}</p>
    </>
  );
};

ErrorMessage.propTypes = {
  copy: PropTypes.shape({
    intro: PropTypes.string.isRequired,
    additional: PropTypes.node.isRequired,
  }),
};

/**
 * Default error icon.
 */
const errorIconDefault = {
  imgUrl: IconError,
  isImage: false,
  name: "",
  size: "82",
};

/**
 * Default content for the ErrorMessage component.
 * @type {Object}
 */
export const contentNotCalbrightEmail = {
  icon: errorIconDefault,
  title: "Something doesn't look right!",
  confirmText: "Let's try that again!",
  confirmClick: () => console.log("/sign-out"), // click event set in ErrorPage.jsx
  intro:
    "Please switch your Google account to your Calbright College email account.",
  additional: (
    <>
      You can always contact Support at 1-833-956-0225 or write to us at{" "}
      <a href="mailto:success@calbright.org">success@calbright.org</a> if you
      need additional help.
    </>
  ),
};

/**
 * Refresh content for the ErrorMessage component.
 * @type {Object}
 */
export const contentRefreshPortal = {
  icon: errorIconDefault,
  title: "Something doesn't look right!",
  confirmText: "Go to Dashboard",
  confirmClick: () => window.location.replace(process.env.AUTH_URL),
  intro: "Refresh the page to reload the student portal.",
  additional: (
    <>
      Or contact support at 1-833-956-0225 or email us at{" "}
      <a href="mailto:success@calbright.org">success@calbright.org</a>.
    </>
  ),
};
