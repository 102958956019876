import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "./../Button/Button";
import { FileUploadInput } from "./../FileUploadInput/FileUploadInput";

import "./multifile-upload.css";

export const MultiFileUpload = ({
  title,
  subtitle,
  notice,
  error,
  allowedExt,
  getFiles,
  submitValue,
  btnLoading,
  fileSize,
  maxAllowedSize
}) => {
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(error);
  const maxFilesSize = (maxAllowedSize * 1024 * 1024);
  const getTotalFilesSize = files.reduce((total, file) => total + file.size, 0);
  const exceedAllowedSize = getTotalFilesSize > maxFilesSize;

  const toggleFile = (file, remove = false) => {
    setFileError(null);

    if (!remove) {
      setFiles(oldArray => [...oldArray, file]);
    } else {
      setFiles(oldArray => {
        return oldArray.filter(item => item !== file)
      });
    }
  };

  useEffect(() => {
    setFileError(error);
  }, [error]);

  useEffect(() => {
    if (getFiles) {
      getFiles(files);
    }

    if (exceedAllowedSize) {
      setFileError(`Your files exceeds the maximum allowed size of: ${maxAllowedSize}MB`);
    }
  }, [files, getFiles, exceedAllowedSize, maxAllowedSize])

  return (
    <div className="multi-file" data-testid="multi-file-area">
      {title && (
        <h2 className="head-thin">{title}</h2>
      )}
      <p>{subtitle}</p>
      <FileUploadInput
        name="resume"
        placeholder="Please attach your resume..."
        toggleFile={toggleFile}
        allowedExt={allowedExt}
        fileSize={fileSize}
        resetErr={btnLoading}
      />
      <FileUploadInput
        name="certificate"
        placeholder="Please attach your certificate..."
        toggleFile={toggleFile}
        allowedExt={allowedExt}
        fileSize={fileSize}
        resetErr={btnLoading}
      />
      <FileUploadInput
        name="transcript"
        placeholder="Please attach your transcript..."
        toggleFile={toggleFile}
        allowedExt={allowedExt}
        fileSize={fileSize}
        resetErr={btnLoading}
      />
      <FileUploadInput
        name="other"
        placeholder="Please attach your other document..."
        toggleFile={toggleFile}
        optionalName={true}
        allowedExt={allowedExt}
        fileSize={fileSize}
        resetErr={btnLoading}
      />
      {fileError && <p className="error text-center">{fileError}</p>}
      {notice && <p className="storybook-notice">{notice}</p>}
      <div className="multi-file-btn-area">
        {(!files.length || exceedAllowedSize)
          ?
            <Button
              buttonType="basic"
              disabled
              label="Submit"
            />
          :
            <Button
              label="Submit"
              onClick={submitValue}
              isLoading={btnLoading}
            />
          }
        </div>
    </div>
  );
}

MultiFileUpload.propTypes = {
  /**
   * Multi file component title.
   */
  title: PropTypes.string.isRequired,
  /**
   * Additional subtitle message.
   */
  subtitle: PropTypes.string,
  /**
   * Notice message.
   */
  notice: PropTypes.string,
  /**
   * Allowed file extensions.
   */
  allowedExt: PropTypes.string,
  /**
   * Return files to parent.
   */
  getFiles: PropTypes.func,
  /**
   * Submit textarea value.
   */
  submitValue: PropTypes.func,
  /**
   * Button loading state
   */
  btnLoading: PropTypes.bool,
  /**
   * Error submit file.
   */
  error: PropTypes.string,
  /**
   * Max size per file.
   */
  fileSize: PropTypes.number,
  /**
   * Total allowed files size.
   */
  maxAllowedSize: PropTypes.number,
}

MultiFileUpload.defaultProps = {
  title: "Please attach supporting documentation such as resume, certificate, transcripts, and other document to verifying 1 year of prior IT experience.",
  subtitle: "The total size of all uploaded files is limited to 5MB.",
  notice: "*You will hear back from us in 2 - 5 business days",
  btnLoading: false,
  allowedExt: ".jpg,.jpeg,.png,.pdf,.doc,.docx,",
  fileSize: 5,
  maxAllowedSize: 25
}