import React from "react";
import PropTypes from "prop-types";

import { Button } from "../Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";

import "./calltoaction.css";

export const CallToAction = ({ props }) => {
  const {
    cardTitle,
    cardContent,
    notEnrCardContent,
    enrolled,
    btnLabel,
    btnSubmit,
    message,
    closeMessage,
  } = props;
  return (
    <section className="storybook-calltoaction card" data-testid="calltoaction">
      <h2>{cardTitle}</h2>
      {cardContent && <p>{parse(cardContent)}</p>}
      {!enrolled && (
        <p data-testid="card-content">{parse(notEnrCardContent)}</p>
      )}
      <Button
        label={btnLabel}
        buttonType="default"
        disabled={!enrolled}
        onClick={btnSubmit}
        data-testid="ctaBtn"
      />
      {message && (
        <p className="wellness-message">
          {parse(message)}
          <CloseIcon onClick={closeMessage} />
        </p>
      )}
    </section>
  );
};

CallToAction.propTypes = {
  /**
   * Card title.
   */
  cardTitle: PropTypes.string,
  /**
   * Card content.
   */
  cardContent: PropTypes.string,
  /**
   * Card Pre Enrolled content.
   */
  notEnrCardContent: PropTypes.string,
  /**
   * Card Button label.
   */
  btnLabel: PropTypes.string,
  /**
   * Set button submit action.
   */
  btnSubmit: PropTypes.func,

  /**
   * Is student enrolled.
   */
  enrolled: PropTypes.bool,
  /**
   * Request message response.
   */
  message: PropTypes.string,
  /**
   * Hide message.
   */
  closeMessage: PropTypes.func,
};
