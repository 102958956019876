import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  styles,
  stepsOnboarding,
  stepsCoursework
} from "../../utils/helpers/steps";

import "./joyridenav.css";

export const JoyrideNav = ({ studentProgress }) => {
  const joyrideWrap = document.getElementById("react-joyride-portal");
  const [joyrideStep, setJoyrideStep] = useState(0);

  const steps = (studentProgress.section === "no-milestone")
    ? stepsCoursework : stepsOnboarding;
  const [tutorialPassed, setTutorialPassed] = useState(
    localStorage.getItem("tutorialPassed") === "true"
  );

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    setJoyrideStep(index);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      window.localStorage.setItem("tutorialPassed", "true");
      setTutorialPassed(true);
    }
  };

  useEffect(() => {
    if (joyrideWrap) {
      joyrideWrap.classList.remove(`joyride-step-${joyrideStep - 1}`);
      joyrideWrap.classList.add(`joyride-step-${joyrideStep}`);
    }
  }, [joyrideWrap, joyrideStep])

  useEffect(() => {
    if (joyrideWrap && studentProgress.section === "no-milestone") {
      joyrideWrap.classList.add(`joyride-step-no-milestone`);
    }
  }, [joyrideWrap])

  useEffect(() => {
    const originalConsoleWarn = console.warn;
    console.warn = () => {};
    return () => {
      console.warn = originalConsoleWarn;
    };
  }, []);

  if (tutorialPassed) return (null);

  return (
    <Joyride
      continuous
      steps={steps}
      callback={handleJoyrideCallback}
      showSkipButton
      showBackButton={false}
      disableOverlayClose
      disableCloseOnEsc
      hideCloseButton
      hideBackButton
      showProgress
      spotlightPadding={0}
      styles={styles}
      locale={{
        skip: "Do not show this again",
        last: "Start My Student Journey at Calbright!",
      }}
    />
  );
};
