import React from "react";
import PropTypes from "prop-types";

import { Header } from "@storybook/stories/Header/Header";
import { Wellness } from "@storybook/stories/Wellness/Wellness";
import { CareerSidebar } from "@storybook/stories/CareerSidebar/CareerSidebar";
import { Accordion } from "@storybook/stories/Accordion/Accordion";
import { CSQuestions } from "../../utils/helpers/career-services-questions";
import { workshops } from "../../utils/helpers/workshops";
import { learnerStatusLookup } from "../../utils/helpers/data";

import {
  careerServices,
  careerContent,
  notEnrCardContent,
} from "../../utils/helpers/career-services";

import "./career-services.css";

const SPP = learnerStatusLookup["STARTED_PROGRAM_PATHWAY"];
const CPP = learnerStatusLookup["COMPLETED_PROGRAM_PATHWAY"];
const SSO = "https://shibboleth-calbright-csm.symplicity.com/sso/";

export const CareerServices = ({ user }) => {
  const handleLogin = () => {
    window.location.replace(process.env.AUTH_URL);
  };

  const handleLogout = () => {
    window.location.replace(process.env.LOGOUT_URI);
  };

  const enrolled = user.learnerStatus === SPP || user.learnerStatus === CPP;
  const ssoURL = enrolled ? SSO : "/";
  const calendlyParams = `?full_name=${user.firstName} ${user.lastName}
    &email=${user.email}`;

  return (
    <article className="career-services">
      <Header
        user={user}
        onLogin={handleLogin}
        onLogout={handleLogout}
        activeLink="services"
        avatarsize="medium"
      />
      <main>
        <div className="container">
          <Wellness
            items={careerServices}
            title="CareerConnect"
            subtitle="We help prepare you for your new career."
            enrolled={enrolled}
            cardTitle="The Easy Way To Find Jobs"
            cardContent={careerContent}
            notEnrCardContent={notEnrCardContent}
            btnLabel="Find Jobs on CareerConnect"
            btnSubmit={() => window.open(ssoURL, "_blank").focus()}
          />
          <div className="careers-row">
            <CareerSidebar
              title="Attend Workshops"
              subtitle="We offer career workshops, one-on-one coaching and a speaker series with experts sharing insights on career opportunities in a range of industries."
              workshops={workshops}
              enrolled={enrolled}
              link={`https://calendly.com/career-service${calendlyParams}`}
            />
            <Accordion items={CSQuestions} title="Frequently Asked Questions" />
          </div>
        </div>
      </main>
    </article>
  );
};

CareerServices.propTypes = {
  user: PropTypes.shape({}),
};

export default CareerServices;
