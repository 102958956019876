import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SkeletonAlert } from "../../components/Skeleton/SkeletonAlert";
import { Alert } from "@storybook/stories/Alert/Alert";
import { saveBroadcastMsgs } from "../../redux/actions/broadcastMsgActions";

import "./broadcast-message.css";

const BroadcastMessage = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const broadcastMsgs = useSelector((state) => state.broadcastMsgs);
  const [isLoading, setIsLoading] = useState(false);
  const [dismissedMsgs, setDismissedMsgs] = useState([]);

  useEffect(() => {
    const storedDismissedMsgs = localStorage.getItem("dismissedBroadcastMsgs");

    if (storedDismissedMsgs) {
      const parsedDismissedMsgs = JSON.parse(storedDismissedMsgs);
      if (
        typeof parsedDismissedMsgs === "object" &&
        parsedDismissedMsgs !== null
      ) {
        const userDismissedMsgs = parsedDismissedMsgs[user.cccId] || [];
        setDismissedMsgs(userDismissedMsgs);
      }
    }
  }, []);

  useEffect(() => {
    if (!Array.isArray(broadcastMsgs)) {
      setIsLoading(true);
      dispatch(saveBroadcastMsgs(token)).then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
    }
  }, [dispatch, token, broadcastMsgs]);

  const handleDismiss = (messageId) => {
    if (!messageId) return null;

    setDismissedMsgs((prevDismissedMsgs) => {
      const updatedDismissedMsgs = [...prevDismissedMsgs, messageId];
      const storedDismissedMsgs = localStorage.getItem(
        "dismissedBroadcastMsgs",
        JSON.stringify(updatedDismissedMsgs)
      );
      let allDismissedMsgs = {};
      if (storedDismissedMsgs) {
        allDismissedMsgs = JSON.parse(storedDismissedMsgs);
      }
      allDismissedMsgs[user.cccId] = updatedDismissedMsgs;
      localStorage.setItem(
        "dismissedBroadcastMsgs",
        JSON.stringify(allDismissedMsgs)
      );
      return updatedDismissedMsgs;
    });
  };

  const sortedBroadcastMsgs = useMemo(() => {
    if (!Array.isArray(broadcastMsgs)) return null;

    const typeOrder = ["error", "warning", "info", "success"];

    return [...broadcastMsgs]
      .filter((msg) => !dismissedMsgs.includes(msg.id))
      .sort((a, b) => {
        const aIndex = typeOrder.indexOf(a.type);
        const bIndex = typeOrder.indexOf(b.type);

        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;

        return aIndex - bIndex;
      });
  }, [broadcastMsgs, dismissedMsgs]);

  if (isLoading) return <SkeletonAlert />;

  if (!Array.isArray(broadcastMsgs) || broadcastMsgs.length === 0) {
    return null;
  }

  return (
    <>
      {sortedBroadcastMsgs.map((message) => (
        <Alert
          key={message.id}
          type={message?.type}
          dismissable={message?.dismissible}
          onDismiss={() => handleDismiss(message.id)}
        >
          {message.title && <h3>{message?.title}</h3>}
          <p>{message?.body}</p>
          {message?.message && <p>{message?.message}</p>}
        </Alert>
      ))}
    </>
  );
};

export default BroadcastMessage;
