import axiosInstance from "../api/axios";
import { handleResponse, handleError } from "./apiUtils";

/**
 * Sends a post request to /admissions-records/transcript.
 * @param {string} token
 * @param { array } data
 * @returns
 */
export function transcriptRequest(token, data) {
  return axiosInstance
    .post(
      "/admissions-records/transcript",
      data,
      {
        headers:
        {
          Authorization: `Bearer ${token?.idToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}