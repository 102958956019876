import Resume from "../../assets/career-services/resume-cover-letter.svg";
import JobSearch from "../../assets/career-services/job-search-strategies.svg";
import Interview from "../../assets/career-services/interview-salary.svg";

/**
 * Career services items used in Careers Services Page.
 */
export const careerServices = [
  {
    title: "Resume and Cover Letter",
    subtitle: "Showcase your skills with professional docs.",
    image: Resume,
  },
  {
    title: "Job Search Strategies",
    subtitle: "Match your skills to great jobs.",
    image: JobSearch,
  },
  {
    title: "Interview Preparation",
    subtitle: "Prepare for interview success.",
    image: Interview,
  },
];

export const careerContent = `Counseling and Career Services provides individual
  support and workshops to help you navigate your educational journey,
  build career-readiness skills and find and launch a meaningful career.
  CareerConnect is Calbright’s portal for all career services and resources.`;

/**
 * Career Services pre enrolled content.
 */
export const notEnrCardContent = `To access CareerConnect, you’ll need to complete
  your first academic assignment in your program. <br />You’ll then have access to individual 
  support and workshops to help you navigate your educational journey, build career-readiness skills and find and launch a meaningful career. 
  <br />Please contact the success
  team at <a href="mailto:success@calbright.org">success@calbright.org</a> for
  additional help or check the FAQs below to learn more.`;
