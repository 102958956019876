import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ReactSwitch from "react-switch";
import { NavLink } from "react-router-dom";

import { Menu } from "../Menu/Menu";
import { Button } from "../Button/Button";
import { Avatar } from "../Avatar/Avatar";

import CalbrightLogo from "../assets/calbright_logo.svg";

import { menuData, menuIds, profileData } from "../../utils/data";

import "./header.css";

const STUDENT_THEME = "student-portal-theme";

/**
 * Logged in child view of the header component.
 */
const LoggedInView = ({ getTheme, setTheme, ...props }) => {
  const [openDropdownStatus, setOpenDropdownStatus] = useState(false);
  const [checked, setChecked] = useState(getTheme);
  const profileMenuRef = useRef(null);

  const toggleTheme = () => {
    setChecked(!checked);
  };

  const handleProfileMenu = () => {
    setOpenDropdownStatus(!openDropdownStatus);
  };

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target) &&
      event.target.tagName !== "SPAN"
    ) {
      setOpenDropdownStatus(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setTheme(checked);
  }, [setTheme, checked]);

  return (
    <>
      <Menu
        items={props.menuData}
        isDropdown={false}
        active={props.activeLink}
        name="topmenu"
      />
      <div className="storybook-icon-container" ref={profileMenuRef}>
        <NavLink className="storybook-icon-help" to="/help">
          <i className="icon-help" />
        </NavLink>

        <Avatar
          size={props.avatarsize}
          fullName={`${props.user.firstName} ${props.user.lastName}`}
          hasPhoto={props.user.hasPhoto}
          photoUrl={props.user.profileImageUrl}
          handleAvatarClick={handleProfileMenu}
          active={props.activeLink === "profile"}
        />
        <Menu
          items={props.profileData}
          isDropdown={true}
          openDropdown={openDropdownStatus}
          active={props.activeLink}
          anchor="avatar-container"
          name="profilemenu"
        >
          <>
            <b>High Contrast Mode</b>
            <ReactSwitch
              onChange={toggleTheme}
              checked={checked ?? false}
              uncheckedIcon={false}
              checkedIcon={false}
              width={40}
              height={20}
              onColor="#065E84"
            />
            <p>Enhances the color contrast of text, buttons, etc.</p>
          </>
        </Menu>
      </div>
    </>
  );
};

/**
 * Logged out child view of the header component.
 */
const LoggedOutView = ({ onLogin }) => {
  return (
    <>
      <div className="storybook-icon-container">
        <NavLink className="storybook-icon-help" to="/help">
          <i className="icon-help" />
        </NavLink>
        <Button
          size="medium"
          onClick={onLogin}
          label="Log in"
          data-testid="login-btn"
        />
      </div>
    </>
  );
};

/**
 * Header area or app bar area for the website/application.
 */
export const Header = ({
  user,
  isLogoClickable,
  avatarsize,
  menuData,
  profileData,
  activeLink,
  onLogin,
  onLogout,
  getTheme,
  setTheme,
}) => {
  const LoggedInProps = {
    user,
    isLogoClickable,
    avatarsize,
    activeLink,
    onLogout,
    menuData,
    profileData,
    getTheme,
    setTheme,
  };
  const LoggedOutProps = { avatarsize, onLogin };

  const headerClass = ["storybook-header-inner"];
  !user && headerClass.push("storybook-header--loggedout");

  return (
    <header className="storybook-header">
      <div className="container">
        <div className={headerClass.join(" ")} data-testid="header-container">
          {isLogoClickable ?
              <NavLink to="/" className="storybook-logo">
                <img src={CalbrightLogo} alt="Calbright College" />
              </NavLink>
            :
              <div to="/" className="storybook-logo">
                <img src={CalbrightLogo} alt="Calbright College" />
              </div>
          }
          <>
            {user ? (
              <LoggedInView {...LoggedInProps} />
            ) : (
              <LoggedOutView {...LoggedOutProps} />
            )}
          </>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  /**
   * User holds user information used on the page.
   */
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  /**
   * Set logo clickable.
   */
  isLogoClickable: PropTypes.bool,
  /**
   * Menu data.
   */
  menuData: PropTypes.array.isRequired,
  /**
   * Login process(es) trigged after user logs in.
   */
  onLogin: PropTypes.func.isRequired,
  /**
   * Login process(es) triggered once use clicks log out.
   */
  onLogout: PropTypes.func.isRequired,
  /**
   * Avatar information used to populate the user avatar.
   */
  avatarsize: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Active determines what link in the menu is active.
   */
  // activeLink: PropTypes.oneOf(['home', 'my-team', 'calendar', 'services', 'resources', 'help', 'profile']),
  activeLink: PropTypes.oneOf(menuIds),
  /**
   * Get selected dashboard theme.
   */
  getTheme: PropTypes.func,
  /**
   * Set dashboard theme.
   */
  setTheme: PropTypes.func,
};

Header.defaultProps = {
  user: null,
  isLogoClickable: false,
  activeLink: "",
  menuData: menuData,
  profileData: profileData,
  getTheme: () => {
    const theme = localStorage.getItem(STUDENT_THEME);
    return JSON.parse(theme);
  },
  setTheme: (checked) => {
    localStorage.setItem(STUDENT_THEME, checked);
    document.body.classList.toggle("theme-cold", checked);
  },
};
