import Medical from "../../assets/wellness/medical.svg";
import Counseling from "../../assets/wellness/counseling.svg";
import LifeCoaching from "../../assets/wellness/life-coaching.svg";

/**
 * Wellness services items used in Wellness Services Page.
 */
export const wellnessItems = [
  {
    title: "Medical",
    subtitle: "On-demand access to a medical provider",
    image: Medical,
  },
  {
    title: "Counseling",
    subtitle:
      "On-demand crisis management, emotional support, and up to 12 annual counseling sessions with a mental health professional.",
    image: Counseling,
  },
  {
    title: "Life Coaching",
    subtitle:
      "Time management | Goal setting | Student life | Career development | Saving and budgeting",
    image: LifeCoaching,
  },
];

/**
 * Services content.
 */
export const servicesContent = `TimelyCare is Calbright’s wellness services 
  provider. TimelyCare provides the virtual wellness services through phone, 
  chat and video conference FREE OF CHARGE to all actively enrolled students.`;

/**
 * Services pre enrolled content.
 */
export const notEnrCardContent = `To access TimelyCare, you need to complete 
  an academic assignment in your program of study. If you need additional 
  support, please contact us at<br/>
  <a href="mailto:success@calbright.org">success@calbright.org</a>`;
