import React from "react";
import { Route, Switch } from "react-router-dom";

import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Dashboard from "./pages/Dashboard/Dashboard";
import MyTeam from "./pages/MyTeam/MyTeam";
import Services from "./pages/Services/Services";
import CareerServices from "./pages/CareerServices/CareerServices";
import Resources from "./pages/Resources/Resources";
import TutoringLearning from "./pages/TutoringLearning/TutoringLearning";
import Help from "./pages/Help/Help";
import Profile from "./pages/Profile/Profile";
import AccessibilityServices from "./pages/AccessibilityServices/AccessibilityServices";
import SignOutPage from "./pages/SignOut/SignOut";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import Schedule from "./pages/Schedule/Schedule";
import LibraryServices from "./pages/LibraryServices/LibraryServices";

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        </Route>
        <Route exact path="/my-team">
          <RequireAuth>
            <MyTeam />
          </RequireAuth>
        </Route>
        <Route exact path="/career-services">
          <RequireAuth>
            <CareerServices />
          </RequireAuth>
        </Route>
        <Route exact path="/wellness-services">
          <RequireAuth>
            <Services />
          </RequireAuth>
        </Route>
        <Route exact path="/accessibility-services">
          <RequireAuth>
            <AccessibilityServices />
          </RequireAuth>
        </Route>
        <Route exact path="/learning-center">
          <RequireAuth>
            <TutoringLearning />
          </RequireAuth>
        </Route>
        <Route exact path="/library-services">
          <RequireAuth>
            <LibraryServices />
          </RequireAuth>
        </Route>
        <Route exact path="/resources">
          <RequireAuth>
            <Resources />
          </RequireAuth>
        </Route>
        <Route exact path="/profile">
          <RequireAuth>
            <Profile />
          </RequireAuth>
        </Route>
        <Route exact path="/appointment">
          <RequireAuth>
            <Schedule />
          </RequireAuth>
        </Route>
        <Route exact path="/sign-out" component={SignOutPage} />
        <Route exact path="/help">
          <RequireAuth>
            <Help />
          </RequireAuth>
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
}

export default App;
