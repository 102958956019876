import React from "react";
import PropTypes from "prop-types";

import { Button } from "../Button/Button";
import { Popover } from "../Popover/Popover";

import AvatarFemale from "../assets/demo-images/Avatar_Female_Default.svg";
import InfoIcon from "@mui/icons-material/Info";

import "./card.css";
import { formatDate } from "../../utils/helpers.js";

export const Card = ({
  title,
  imageSrc,
  cardType,
  dashboardContent,
  children,
  hasButton,
  onClick,
  isProfileDisabled,
  ...props
}) => {
  dashboardContent = dashboardContent || null;
  const formattedEnrollmentDate = formatDate(dashboardContent.enrollmentDate);
  const formattedTermEndDate = formatDate(dashboardContent.termEndDate);

  return (
    <div className="storybook-card-container" data-testid="card-container">
      <div className="storybook-card-image" data-testid="card-image">
        <img src={imageSrc} alt="" />
      </div>

      <div className="storybook-card-content" data-testid="card-content">
        <h2 className="storybook-card-title" data-testid="card-title">
          {title}
        </h2>
        {cardType === "student-profile" && (
          <>
            <p className="cccid" data-testid="cccid">
              CCCID: {dashboardContent.cccId}
            </p>
            <p className="cccid" data-testid="enrollment-date">
              {dashboardContent.enrollmentDate ? (
                <>Enrollment Date: {formattedEnrollmentDate}</>
              ) : (
                <>
                  Enrollment Date: {"-/-/--"}
                  <Popover
                    content="Your enrollment date will be available after you sign your enrollment agreement during Onboarding."
                    children={<InfoIcon />}
                    openOnHover={true}
                    isPadded={false}
                    size="xlarge"
                  />
                </>
              )}
            </p>
            <p className="term-end-date" data-testid="term-end-date">
              {dashboardContent.termEndDate ? (
                <>End of Term Date: {formattedTermEndDate}</>
              ) : (
                <>
                  End of Term Date: {"-/-/--"}
                  <Popover
                    content="End of Term Date will be available after you signed CSEP"
                    children={<InfoIcon />}
                    openOnHover={true}
                    isPadded={false}
                    size="xlarge"
                  />
                </>
              )}
            </p>
            {dashboardContent.text}
          </>
        )}
        {children && <>{children}</>}
      </div>
      {hasButton && (
        <div className="storybook-card-buttons" data-testid="card-buttons">
          <Button
            buttonType="basic"
            label={props.btnLabel}
            onClick={onClick}
            disabled={isProfileDisabled}
          />
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  /**
   * Student's name.
   */
  title: PropTypes.string,
  /**
   * Student's image
   */
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Dashboard card type.
   */
  cardType: PropTypes.oneOf(["student-profile", "my-team", "other"]),
  /**
   * Card dashboard content.
   */
  dashboardContent: PropTypes.shape({
    cccId: PropTypes.string,
    enrollmentDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    termEndDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hasButton: PropTypes.bool,
  }),
  /*
   * Button text.
   */
  btnLabel: PropTypes.string,
  isProfileDisabled: PropTypes.bool,
  children: PropTypes.node,
};

Card.defaultProps = {
  title: "Jane Doe",
  imageSrc: AvatarFemale,
  dashboardContent: {},
  hasButton: false,
  btnLabel: "My Profile",
  isProfileDisabled: false,
};
