import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as tokenActions from "../../redux/actions/tokenActions";
import * as userActions from "../../redux/actions/userActions";
import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as myteamActions from "../../redux/actions/myteamActions";
import * as avatarActions from "../../redux/actions/avatarActions";
import * as broadcastMsgActions from "../../redux/actions/broadcastMsgActions";
import { useHistory } from "react-router-dom";
import { handleError } from "../../api/apiUtils";
import { identifyHotjarStudent } from "../../utils/hotjar";
import { gtagEventStudentInfo } from "../../utils/gtag";
import { SkeletonDashboard } from "../Skeleton/SkeletonDashboard";
import useLiveChat from "./../../hooks/live-chat";
import { useAuthentication } from "../../hooks/useAuthentication";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

/**
 * Use to trigger authentication errors in render
 * @param object error
 * @returns
 */
const AuthenticationHandler = ({ error }) => {
  useEffect(() => {
    if (error) {
      // Throw in a setTimeout to avoid console error logging
      setTimeout(() => {
        throw error;
      }, 0);
    }
  }, [error]);

  return null;
};

const RequireAuth = ({
  children,
  token,
  user,
  requireAuth = true,
  saveCode,
  saveToken,
  saveUser,
  appointment,
  appointmentLinks,
  saveAppointment,
  saveAppointmentLinks,
  loading,
  avatars,
  fetchAvatars,
  updateAvatar,
  setKickOffScheduled,
  ...propToPass
}) => {
  const { liveChat } = useLiveChat();
  const history = useHistory();

  const { isLoading, authError, isAuthenticated } = useAuthentication({
    requireAuth,
    token,
    user,
    saveToken,
    saveUser,
  });

  useEffect(() => {
    liveChat();

    if (user?.cccId) {
      gtagEventStudentInfo(user);
      identifyHotjarStudent(user);
    }
  }, [user]);

  if (isLoading) {
    return <SkeletonDashboard />;
  }

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      const url = new URLSearchParams(location.search);
      const hash = location.hash; //url.get("hash");
      if (hash && hash.includes("access_token")) {
        history.replace("/");
      }
    }
  }, [user, history]);

  const WrappedComponentWithProps = (
    <>
      <AuthenticationHandler error={authError} />
      {React.cloneElement(children, {
        user,
        saveUser,
        appointment,
        appointmentLinks,
        saveAppointment,
        saveAppointmentLinks,
        loading,
        token,
        avatars,
        fetchAvatars,
        updateAvatar,
        setKickOffScheduled,
        ...propToPass,
      })}
    </>
  );

  return WrappedComponentWithProps;
};

const mapStateToProps = (state) => ({
  token: state.token,
  user: state.user,
  appointment: state.appointment,
  appointmentLinks: state.appointmentLinks,
  loading: state.loading,
  avatars: state.avatars,
});

const mapDispathToProps = {
  saveCode: tokenActions.saveCode,
  saveToken: tokenActions.saveToken,
  saveUser: userActions.saveUser,
  updateUser: userActions.updateUser,
  saveAppointment: appointmentActions.saveAppointment,
  saveAppointmentLinks: appointmentActions.saveAppointmentLinks,
  fetchAvatars: avatarActions.fetchAvatars,
  updateAvatar: avatarActions.updateAvatar,
  setKickOffScheduled: userActions.setKickOffScheduled,
};

export default connect(mapStateToProps, mapDispathToProps)(RequireAuth);
