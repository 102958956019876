import { jwtDecode } from "jwt-decode";
import axios from "../api/axios";

// Get the code passed by callback
export function getCode() {
  const code = new URLSearchParams(location.search).get("code");

  return code;
}

export function getToken() {
  const hash = new URLSearchParams(location.hash);
  const accessToken = hash.get("#access_token");
  const idToken = hash.get("id_token");
  return { accessToken, idToken };
}

export function getTokenCode() {
  // Get the code passed by callback
  const code = getCode();

  return axios
    .post("/oauth2/token", null, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        grant_type: "authorization_code",
        client_id: "4qq8lke2qgmq0nak0ld957uqhi",
        code: code,
        redirect_uri: "http://localhost:3000",
      },
    })
    .then((response) => {
      console.log("oauth2/token response..", response.data);
      return response.data;
    })
    .catch((error) => {
      console.log("error..", error);
    })
    .finally(() => {
      console.log("finally..");
    });
}

export const refreshToken = () => {
  // TODO: Refresh token using AWS Cognito
  // Refresh token
  // return state.token;
  // const { setAuth } useAuth();

  const refresh = async () => {
    const response = await axios.get("/refresh", {
      withCredentials: true, // sends cooke that has response token
    });
    setAuth((prev) => ({
      ...prev,
      accessToken: response.data.accessToken,
    }));
    return response.data.accessToken;
  };
  return refresh;
};

function verifyToken() {
  // use AWS
}

export function decodeToken(token) {
  return {
    accessTokenDecoded: jwtDecode(token.accessToken),
    idTokenDecoded: jwtDecode(token.idToken),
  };
}
