import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./appbadge.css";

/**
 * Badge content component for the app badge.
 */
const AppBadgeContent = (props) => {
  const { icon, altText, title, disabled, disabledMsg, children } = props;

  return (
    <>
      {icon && <img src={icon} alt={altText} />}
      <div className="storybook-app-badge--copy">
        <span>{title}</span>
        {children && <p>{children}</p>}
        {disabled && disabledMsg && (
          <span className="hover-tip">{disabledMsg}</span>
        )}
      </div>
    </>
  );
};

export const AppBadge = (props) => {
  const {
    id,
    title,
    icon,
    link,
    disabled,
    disabledMsg = "",
    active,
    target = "_blank",
    handleClick,
    linkType = "internal",
    altText = "",
    children,
    ...restProps
  } = props;

  const commonProps = {
    className: "storybook-app-badge",
    disabled: disabled,
    active: active ? active.toString() : "false",
    "data-testid": `appBadge-${id}`,
    ...restProps,
  };

  const appBadgeContentProps = {
    icon,
    altText,
    title,
    disabled,
    disabledMsg,
    children,
  };

  const renderContent = () => <AppBadgeContent {...appBadgeContentProps} />;

  if (link?.length > 0) {
    const LinkComponent = linkType === "external" ? "a" : NavLink;
    const linkProps = linkType === "external" ? { href: link } : { to: link };

    return (
      <LinkComponent {...linkProps} target={target} {...commonProps}>
        {renderContent()}
      </LinkComponent>
    );
  }

  return (
    <section
      {...commonProps}
      onClick={(e) => {
        if (!disabled) {
          handleClick(id, title, e);
        }
      }}
    >
      {renderContent()}
    </section>
  );
};

AppBadge.propTypes = {
  /**
   * Badge title
   */
  title: PropTypes.string,
  /**
   * Badge svg icon
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Badge alt text
   */
  altText: PropTypes.string,
  /**
   * Badge link
   */
  link: PropTypes.string,
  /**
   * Link type
   */
  linkType: PropTypes.oneOf(["internal", "external"]),
  /**
   * Check if app badge is enabled or not
   * depending on student enrollment status
   */
  disabled: PropTypes.bool,
  /**
   * Disabled message. default is an empty string
   */
  disabledMsg: PropTypes.string,
  /**
   * Check if app badge is active or not
   */
  active: PropTypes.bool,
  /**
   * Optional badge ID
   */
  id: PropTypes.any,
  /**
   * Optional click handler
   */
  handleClick: PropTypes.func,
  /**
   * Optional children
   */
  children: PropTypes.any,
  /**
   * Link target
   */
  target: PropTypes.string,
};

AppBadge.defaultProps = {
  id: "1",
  title: "App Badge",
  disabledMsg: "",
  active: true,
  target: "_blank",
  linkType: "internal",
};
