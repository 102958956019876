import * as types from "./actionTypes";
import * as arservicesApi from "../../api/arservicesApi";
import { handleError } from "../../api/apiUtils";
import { beginApiCall, apiCallError } from "./apiStatusActions.js";

export function fetchARServicesSuccess(arservices) {
  return { type: types.FETCH_ARSERVICES_SUCCESS, arservices };
}

export function postEnrollmentVerificationSuccess(enrollmentVerification) {
  return {
    type: types.POST_ENROLLMENTVERIFICATION_SUCCESS,
    enrollmentVerification,
  };
}

export function fetchCompletedProgramsSuccess(completedPrograms) {
  return {
    type: types.FETCH_COMPLETED_PROGRAMS_SUCCESS,
    completedPrograms
  };
}

export function fetchARServices(token) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const arservices = await arservicesApi.getAdmissionsRecords(token);
      dispatch(fetchARServicesSuccess(arservices));
      return arservices;
    } catch (error) {
      dispatch(apiCallError(error));
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function postEnrollmentVerification(token, data) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const enrollmentVerification =
        await arservicesApi.postEnrollmentVerification(token, data);
      dispatch(postEnrollmentVerificationSuccess(enrollmentVerification));
      return enrollmentVerification;
    } catch (error) {
      dispatch(apiCallError(error));
      dispatch(handleError(error));
      throw error;
    }
  };
}

export function fetchCompletedPrograms(token) {
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const { completedPrograms } = await arservicesApi.getCompletedPrograms(token);
      dispatch(fetchCompletedProgramsSuccess(completedPrograms));
      return completedPrograms;
    } catch (error) {
      dispatch(apiCallError(error));
      dispatch(handleError(error));
      throw error;
    }
  };
}
