import React from "react";
import PropTypes from "prop-types";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Intro } from "@storybook/stories/Intro/Intro";
import { CallToAction } from "@storybook/stories/CallToAction/CallToAction";
import { Resources } from "@storybook/stories/Resources/Resources";
import { learnerStatusLookup } from "../../utils/helpers/data";
import {
  LibraryServicesData,
  LibraryCTAContent,
  LibraryNotEnrolledCTAContent,
} from "../../utils/helpers/library-services";

const EPP = learnerStatusLookup.ENROLLED_IN_PROGRAM_PATHWAY;
const SPP = learnerStatusLookup.STARTED_PROGRAM_PATHWAY;
const EBSCOhostUrl =
  "https://search.ebscohost.com/login.aspx?authtype=sso&custid=ns291331&group=main&profile=ehost";

export const LibraryServices = ({ user }) => {
  const enrolled = user.learnerStatus === EPP || user.learnerStatus === SPP;

  const handleCTA = () => {
    window.open(EBSCOhostUrl, "_blank").focus();
  };

  return (
    <article className="library-services" data-testid="libraryContainer">
      <PortalHeader user={user} activeLink="services" />
      <main>
        <div className="container">
          {user && (
            <>
              <Intro
                title="Library Services"
                content={
                  <>
                    The Calbright College Library provides essential online
                    resources to support student learning, anywhere, anytime.
                    Our digital collection includes e-books, journals,
                    multimedia, and research databases, promoting academic
                    success through accessible and innovative technology.
                  </>
                }
              />
              <CallToAction
                props={{
                  cardTitle: "EBSCOhost",
                  enrolled: enrolled,
                  cardContent: LibraryCTAContent,
                  notEnrCardContent: LibraryNotEnrolledCTAContent,
                  btnLabel: "Explore Library Services",
                  btnSubmit: handleCTA,
                  message: "",
                  closeMessage: "",
                }}
              />
              <Resources resources={LibraryServicesData} />
            </>
          )}
        </div>
      </main>
    </article>
  );
};

LibraryServices.propTypes = {
  user: PropTypes.shape({}),
};

export default LibraryServices;
