import React from 'react';

/**
 * List of accordion items used in Wellness Services Page.
 */
export const AccordionItems = [
  {
    title: "What is TimelyCare?",
    content: "TimelyCare is Calbright’s virtual wellness services provider. TimelyCare provides free telehealth services, including medical and behavioral health, to actively enrolled students. TimelyCare also provides an on-demand digital self-care library.",
    active: true
  },
  {
    title: "Who can access TimelyCare?",
    content:
      <>
        <p>All actively enrolled Calbright students may access TimelyCare services. To become “actively enrolled” you must complete at least one academic assignment after you sign the enrollment agreement during Onboarding.</p>
      </>,
    active: false
  },
  {
    title: "How do I schedule consultations with TimelyCare?",
    content:
      <>
        <p>Click the button above to go to your TimelyCare dashboard. You can schedule consultations either through your online dashboard or by calling their phone number. Please note that you might be asked to provide your member (student) ID when calling. You can find your ID on the left side of the My Profile page. Please contact <a href="mailto:success@calbright.org">success@calbright.org</a> if you have questions.</p>
      </>,
    active: false
  },
  {
    title: "Why can’t I access TimelyCare?",
    content:
      <>
        <p>TimelyCare is not available until 72 hours after you have:</p>
        <ul>
          <li>Attended Onboarding </li>
          <li>Signed your Enrollment Agreement</li>
          <li>Completed your first academic assignment</li>
        </ul>
        <p>Once you’ve made progress on your coursework, you should be able to register for TimelyCare services. Unfortunately, at this time Calbright alumni don't have access to TimelyCare services. Please contact <a href="mailto:success@calbright.org">success@calbright.org</a> for more information.</p>
      </>,
    active: false
  },
  {
    title: "I am no longer a Calbright student. Can I still access TimelyCare services?",
    content: "Unfortunately, TimelyCare services are available only to currently enrolled Calbright College students. Students who have not completed their first academic assignment do not have access to TimelyCare.",
    active: false
  },
  {
    title: "How much do TimelyCare services cost?",
    content: "TimelyCare services are completely free for all eligible students. This service is offered at no cost regardless of whether you have health insurance. Students will never receive a bill or a charge to their student account for accessing TimelyCare services.",
    active: false
  },
  {
    title: "How many visits can I have with TimelyCare?",
    content: "TimelyCare visits are unlimited to all current students.",
    active: false
  },
  {
    title: "Are my visits with TimelyCare private?",
    content: "Yes! Calbright College and its employees are not notified of student visits. Visits will remain confidential at all times. Laws are also in place to protect patients’ privacy. TimelyCare is designed to be private, secure and HIPAA-compliant, to allow students to safely consult with one of their therapists, psychiatrists or physicians.",
    active: false
  },
];