import React from "react";
import PropTypes from "prop-types";

import { PortalHeader } from "../../components/Header/PortalHeader";
import { Intro } from "@storybook/stories/Intro/Intro";
import { Resources as ResourcesBlock } from "@storybook/stories/Resources/Resources";
import { ResourcesData as resources } from "../../utils/helpers/resources";

export const Resources = ({ user }) => {
  return (
    <article className="page-resources">
      <PortalHeader user={user} activeLink="resources" />
      <main>
        {user && (
          <section>
            <div className="container">
              <Intro
                title="Resources for Students and Families"
                content={<>Additional support for you and your family.</>}
              />
              <ResourcesBlock resources={resources} />
            </div>
          </section>
        )}
      </main>
    </article>
  );
};

Resources.propTypes = {
  user: PropTypes.shape({}),
};

export default Resources;
// export default RequireAuth(Resources);
