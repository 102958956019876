import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

import "@storybook/styles/global.css";
import "./index.css";

const store = configureStore();

render(
  <ErrorBoundary FallbackComponent={<ErrorPage />}>
    <ReduxProvider store={store}>
      <Router>
        <App />
      </Router>
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById("app")
);
