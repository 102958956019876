import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userInfoReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.SET_USER_SUCCESS:
      return action.user;
    case types.UPDATE_USER_SUCCESS:
      return action.user;
    case types.FIRSTSAA_VIEWED_SUCCESS:
      return { ...state, showFirstSAA: false };
    case types.FTUX_VIEWED_SUCCESS:
      return { ...state, showFTUX: false };
    case types.FTUX_RESET_SUCCESS:
      return { ...state, showFTUX: true };
    case types.UPDATE_USER_PREREQ:
      return { ...state, prereqStatus: action.payload };
    case types.UPDATE_USER_PROGRAM:
      return { ...state, currentProgram: action.payload };
    default:
      return state;
  }
}

export function setKickOffScheduledReducer(
  state = initialState.kickoffScheduled,
  action
) {
  switch (action.type) {
    case types.KICKOFF_SCHEDULED_SUCCESS:
      return action.kickoffScheduled;
    default:
      return state;
  }
}
