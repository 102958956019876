import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { Button } from "../Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import * as Icons from "@mui/icons-material";
import "./modal.css";

/**
 * Dialog box outside of normal user interaction with the application.
 */
export const Modal = ({
  title,
  children,
  size,
  icon,
  openDialog,
  confirmText,
  cancelText,
  confirmClick,
  cancelClick,
  showCloseIcon,
  extraClass,
  closeOnEsc,
  ...props
}) => {
  const sizes = ["small", "medium", "large", "extra-large"];
  const modalRef = useRef(null);

  if (!sizes.includes(size.toLowerCase())) {
    size = "medium";
  }

  const toggleModal = () => {
    if (!modalRef.current) return;
    modalRef.current?.hasAttribute("open")
      ? modalRef.current.close()
      : modalRef.current.showModal();
  };

  useEffect(() => {
    if (openDialog) {
      toggleModal();
    }
  }, [openDialog]);

  useEffect(() => {
    if (closeOnEsc) return;

    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeOnEsc]);

  let IconComponent = null;
  icon && (IconComponent = Icons[icon.name]);

  return (
    <dialog
      className={[
        "storybook-modal",
        `storybook-modal--${size}`,
        extraClass,
      ].join(" ")}
      ref={modalRef}
      id="modalDialog"
      data-testid="modalDialog"
    >
      <div className="storybook-modal-header">
        {showCloseIcon && (
          <button
            className="storybook-modal-close"
            data-testid="closeModal"
            onClick={cancelClick}
          >
            <CloseIcon />
            <span className="sr-only">Close Modal</span>
          </button>
        )}
      </div>

      {icon && (
        <div
          className="storybook-modal-icon-container"
          data-testid="modalIconContainer"
        >
          {icon.name
            ? IconComponent && (
                <IconComponent
                  color={icon.color}
                  sx={{ fontSize: icon.size }}
                />
              )
            : icon.isImage && (
                <img src={icon.imgUrl} width={icon.size} alt="" />
              )}
        </div>
      )}
      {title && <h2 className="storybook-modal-title" data-testid="modalTitle">{title}</h2>}
      <div className="storybook-modal-body" data-testid="modalBody">
        {children}
      </div>
      {(cancelText || confirmText) && (
        <div className="storybook-modal-footer" data-testid="modalFooter">
          {cancelText && (
            <Button
              buttonType="basic"
              label={cancelText}
              onClick={cancelClick}
              aria-label={cancelText}
            />
          )}
          {confirmText && (
            <Button
              buttonType="default"
              label={confirmText}
              aria-label={confirmText}
              onClick={confirmClick}
            />
          )}
        </div>
      )}
    </dialog>
  );
};

Modal.propTypes = {
  /**
   * Title of the modal window.
   */
  title: PropTypes.string,
  /**
   * Contents of the modal window.
   */
  children: PropTypes.node.isRequired,
  /**
   * How large should the modal be?
   */
  size: PropTypes.oneOf(["small", "medium", "large", "extra-large"]).isRequired,
  /**
   * Icon to include at the top of the modal. This is the MUI Icon name e.g. AccessAlarmIcon.
   * The icon name is required but a color and size can also be included in the object passed.
   */
  icon: PropTypes.shape({
    /** Icon name. */
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    isImage: PropTypes.bool,
    imgUrl: PropTypes.string,
  }),

  /**
   * Image can be used instead of an icon.
   */
  image: PropTypes.string,
  /**
   * Confirm Text is the text displayed on the action button. If none is provided, not confirm button will be shown.
   */
  confirmText: PropTypes.string,
  /**
   * This is the text label on the cancel button. If none is provided, it will not be displayed on the modal window.
   */
  cancelText: PropTypes.string,
  /**
   * The function that runs when confirm choice is clicked.
   */
  confirmClick: PropTypes.func,
  /**
   * The function that runs wheen cancel button is clicked. Same function runs on x icon and backdrop.
   */
  cancelClick: PropTypes.func,
  /**
   * Show or hide the close icon. Default is set to true.
   */
  showCloseIcon: PropTypes.bool,
  /**
   * Additional modal class
   */
  extraClass: PropTypes.string,
  /**
   * Close modal by esc. Default is set to true.
   */
  closeOnEsc: PropTypes.bool,
};

Modal.defaultProps = {
  children: (
    <p>
      This is test content for the modal. Additional content can go here as
      well.
    </p>
  ),
  size: "medium",
  confirmText: "Confirm",
  confirmClick: () => document.getElementById("modalDialog").close(),
  cancelClick: () => document.getElementById("modalDialog").close(),
  showCloseIcon: true,
  extraClass: "text-center",
  closeOnEsc: true,
};
