import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const preReqStepReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PRE_REQ_MOVE_STEP:
      return {
        ...state, preReqStep: action.payload
      };
    default:
      return state;
  }
};

export default preReqStepReducer;
