import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { Header } from "@storybook/stories/Header/Header";
import { Alert } from "@storybook/stories/Alert/Alert";
import { Modal } from "@storybook/stories/Modal/Modal";
import { Button } from "@storybook/stories/Button/Button";
import { Aside } from "@storybook/stories/Aside/Aside";
import { AvatarList } from "@storybook/stories/AvatarList/AvatarList";
import GreenCheck from "../../assets/profile/green-check.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate } from "@storybook/utils/helpers.js";
import isStudentEnrolled from "../../utils/isStudentEnrolled";
import { updateAvatarUrl } from "../../api/avatarApi";
import { FeatureFlag } from "../../components/FeatureFlag/FeatureFlag";

import "./profile.css";

export const Profile = ({
  user,
  updateUser,
  token,
  avatars,
  fetchAvatars,
  updateAvatar,
}) => {
  const [isEnableWithdrawForm, setIsEnableWithdrawForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const learnerStatus =
    user?.learnerStatus === undefined ? "" : user.learnerStatus;

  const handleOpenAvatarModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!avatars) fetchAvatars(token);

    if (avatars) {
      const currentAvatarIcon = updateAvatarUrl(user.profileImageUrl);
      const findSelectedtAvatar = avatars.filter(
        (avatar) => avatar.icon === currentAvatarIcon
      );
      setSelectedAvatar(findSelectedtAvatar[0]);
    }
  }, [avatars]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const updateSelectedAvatar = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const updateActiveAvatar = () => {
    if (user.profileImageUrl !== selectedAvatar.icon) {
      const updatedUser = {
        ...user,
        profileImageUrl: selectedAvatar.icon,
      };
      updateUser(updatedUser);
      updateAvatar(token, updatedUser.profileImageUrl);

      toast.success(
        <>
          <img
            className="green-check"
            src={GreenCheck}
            alt="green check icon"
          />
          You have successfully changed your Avatar!
        </>,
        {
          toastId: "avatar-change-success",
          role: "alert",
          position: "top-right",
          hideProgressBar: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        }
      );
    }

    toggleModal();
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  useEffect(() => {
    if (user?.withdrawDeadline) {
      const today = new Date();
      const withdrawDeadline = new Date(user.withdrawDeadline);
      today.setHours(23, 59, 59, 999);
      withdrawDeadline.setHours(23, 59, 59, 999);

      setIsEnableWithdrawForm(today <= withdrawDeadline);
    }
  }, [user]);

  return (
    <article className="storybook-profile" data-testid="profileContainer">
      <Header
        user={user}
        onLogin={() => setUser(user)}
        onLogout={() => setUser({})}
        activeLink="profile"
        avatarsize="medium"
      />

      <ToastContainer autoClose={3000} className="storybook-profile-toast" />
      {user && (
        <main>
          <div className="container">
            <div className="storybook-profile-content">
              <div>
                <FeatureFlag
                  flagName="AR_SERVICES_ENABLED"
                  showIfEnvEnabled={true}
                >
                  <Alert
                    type="info"
                    dismissable={true}
                    onDismiss={() => handleDismiss(message.id)}
                  >
                    <h3>
                      Admissions and Records Services has moved to a new page!
                    </h3>
                    <p>
                      We have created a new page for{" "}
                      <NavLink to="/admissions-records">
                        Admissions and Records Services
                      </NavLink>{" "}
                      under the Services menu, please check for more details!
                    </p>
                  </Alert>
                </FeatureFlag>
                <section
                  className="storybook-profile-intro"
                  data-testid="profileIntro"
                >
                  <div className="page-title">
                    <h1>My Profile</h1>
                    <p>
                      View and manage your enrolled program, check for important
                      information and update personal details.
                    </p>
                  </div>
                </section>
                <section
                  className="storybook-profile-details white-container"
                  data-testid="profileDetails"
                >
                  <div className="storybook-profile-card">
                    <img
                      className="storybook-profile-img"
                      src={user.profileImageUrl}
                      alt={`Avatar of ${user.firstName + " " + user.lastName}`}
                    />
                    <h2 className="storybook-profile-name showInMobile">
                      {user.firstName + " " + user.lastName}
                    </h2>
                    <Button
                      buttonType="basic"
                      label="Change My Avatar"
                      size="medium"
                      onClick={handleOpenAvatarModal}
                    />
                  </div>
                  <div>
                    <p className="storybook-profile--cccid">
                      CCCID: {user.cccId}
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>Enrollment Date</span>
                      <span>{formatDate(user.enrollmentDate)}</span>
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>End of Term Date</span>
                      <span>{formatDate(user.currentTermEndDate)}</span>
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>Drop Deadline</span>
                      <span className="red-color">
                        {formatDate(user?.dropDeadline)}
                      </span>
                    </p>
                    <p className="storybook-profile--userinfo">
                      <span>Withdraw Deadline</span>
                      <span className="red-color">
                        {formatDate(user?.withdrawDeadline)}
                      </span>
                    </p>
                  </div>
                  <div className="storybook-profile--userstatus">
                    <h2 className="showInDesktop">
                      {user?.firstName + " " + user.lastName}
                    </h2>
                    <h3>Program</h3>
                    <p>{user.currentProgram}</p>
                    <h3>Enrollment Status</h3>
                    <p>
                      {!isStudentEnrolled(learnerStatus) &&
                        `Not Actively Enrolled`}
                      {learnerStatus.startsWith("Completed Program") &&
                        `Alumni`}
                      {learnerStatus.startsWith("Enrolled in Program") &&
                        `Provisionally Enrolled`}
                      {learnerStatus.startsWith("Started Program") &&
                        `Actively Enrolled`}
                    </p>
                    <h3>Address</h3>
                    <p>{user?.address}</p>
                    <h3>Email Address</h3>
                    <p>{user.email}</p>
                    <h3>Phone Number</h3>
                    <p>{formatPhoneNumber(user.phoneNumber)}</p>
                  </div>
                </section>
              </div>
              <FeatureFlag showIfEnvDisabled={true}>
                <Aside cornerType="all" title="Admissions and Records Services">
                  <div className="storybook-profile-aside-block">
                    <p className="storybook-profile-aside--action">
                      Request Transcript
                    </p>
                    <p>To receive your Calbright transcript </p>
                    <Button
                      buttonType="basic"
                      label="Transcript Request Form"
                      size="medium"
                      onClick={() =>
                        window.open(
                          "https://forms.gle/7iN1qKdLtputuyaZ6",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  {isEnableWithdrawForm && (
                    <div className="storybook-profile-aside-block">
                      <p className="storybook-profile-aside--action">
                        Withdrawing from Calbright
                      </p>
                      <p>
                        To withdraw from your program before your withdrawal{" "}
                        <b>deadline</b>{" "}
                      </p>
                      <Button
                        buttonType="basic"
                        label="Course Drop Request Form"
                        size="medium"
                        onClick={() =>
                          window.open(
                            "https://docs.google.com/forms/d/e/1FAIpQLScEuGrCLLLw3vINciFJytb2ia_hMv_sQolWslTnSsZII6Nc6g/formrestricted",
                            "_blank"
                          )
                        }
                      />
                    </div>
                  )}
                  <div className="storybook-profile-aside-block">
                    <p className="storybook-profile-aside--action">
                      Requesting an Enrollment Verification Letter
                    </p>
                    <p>To receive your letter, complete </p>
                    <Button
                      buttonType="basic"
                      label="Enrollment Verification Form"
                      size="medium"
                      onClick={() =>
                        window.open(
                          "https://docs.google.com/forms/d/e/1FAIpQLScUaf8vMMeGUxkYnZrf7D5385_X4pMX8Jj_dXx3XCTKDwsFlg/viewform",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className="storybook-profile-aside-block">
                    {user?.lms === "Canvas" ? (
                      <>
                        <p className="storybook-profile-aside--action">
                          Accessing your Completion Badges
                        </p>
                        <p>To access your earned </p>
                        <Button
                          buttonType="basic"
                          label=""
                          size="medium"
                          onClick={() =>
                            window.open(
                              "https://calbright.badgr.com/auth/login",
                              "_blank"
                            )
                          }
                        >
                          <span className="sr-only">Opens in new window </span>
                          Digital Badge{" "}
                          <span className="sr-only">
                            to access your completion badges
                          </span>
                        </Button>
                      </>
                    ) : (
                      <>
                        <p className="storybook-profile-aside--action">
                          Accessing your Certificate of Competency
                        </p>
                        <p>
                          To receive all your Calbright credentials after you’ve
                          completed your program,{" "}
                        </p>
                        <Button
                          buttonType="basic"
                          label=""
                          size="medium"
                          onClick={() =>
                            window.open(
                              "https://www.credential.net/retrieve-credentials",
                              "_blank"
                            )
                          }
                        >
                          <span className="sr-only">Opens in new window</span>
                          Fill out this form
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="storybook-profile-aside-block">
                    <p>
                      To update your personal information, please contact our
                      Enrollment Services team at{" "}
                    </p>
                    <a href="mailto:success@calbright.org">
                      success@calbright.org
                    </a>
                  </div>
                </Aside>
              </FeatureFlag>
              {isModalOpen && (
                <Modal
                  openDialog={true}
                  showCloseIcon={true}
                  confirmText="Apply"
                  confirmClick={updateActiveAvatar}
                  cancelText="Cancel"
                  cancelClick={toggleModal}
                  size="large"
                >
                  <AvatarList
                    avatars={avatars}
                    triggerAvatarUpdate={updateSelectedAvatar}
                    currentAvatarId={selectedAvatar?.id}
                  />
                </Modal>
              )}
            </div>
          </div>
        </main>
      )}
    </article>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({}),
};

export default Profile;
