import React from "react";
import { NavLink } from "react-router-dom";
import AdmissionsIcon from "../../assets/help/admissions-records.svg";
import CourseworkIcon from "../../assets/help/coursework.svg";
import GeneralIcon from "../../assets/help/general.svg";
import TechIcon from "../../assets/help/tech.svg";
import CanvasIcon from "../../assets/help/canvas.svg";

export const Faqs = [
  {
    id: 1,
    title: "Will I get a degree through your program?",
    content: (
      <p>
        Once you’ve completed your program, you will receive a Certificate of
        Competency. At that time you may also{" "}
        <a href="https://forms.gle/7iN1qKdLtputuyaZ6" target="_blank">
          request a transcript
        </a>{" "}
        for your coursework completed at Calbright. If you’re interested in
        pursuing a degree and/or earning credits, we can refer you to another
        California Community College that offers associate degrees and other
        courses for credit.
      </p>
    ),
    category: "General Topics",
  },
  {
    id: 2,
    title: "Will I receive college credit?",
    content: (
      <p>
        While our programs help you learn industry-recognized competencies, they
        are not for credit. While you will receive a Certificate of Competency
        at the end of your program, you won’t receive credits that are
        transferable to other colleges or universities.
      </p>
    ),
    category: "General Topics",
  },
  {
    id: 3,
    title: "What is the difference between credit and noncredit classes?",
    content: (
      <p>
        In general, credit classes are designed for learners who are interested
        in earning college credits toward an associate degree or certificate or
        who want to transfer to a four-year college or university. Noncredit
        classes are generally designed for personal enrichment, professional
        development, or specific skill acquisition. Completion of non-credit
        courses receive a certificate of completion. Noncredit courses may be
        transferrable to other community colleges. Upon completion of a
        noncredit class, students may receive a grade of Pass (P), No Pass (NP)
        or Satisfactory Progress (SP).
      </p>
    ),
    category: "General Topics",
  },
  {
    id: 4,
    title: "Can I apply for financial aid?",
    content: (
      <p>
        We do not offer Financial Aid at this time as there are no out of pocket
        costs to attend Calbright College.
      </p>
    ),
    category: "General Topics",
  },
  {
    title: "Where is Calbright located?",
    content: (
      <>
        Calbright College is the first fully online California Community College
        serving adult learners statewide so there is no physical campus.{" "}
        <address>
          Our mailing address is 1102 Q St, Sacramento, CA 95811
        </address>
        .
      </>
    ),
    category: "General Topics",
  },
  {
    id: 5,
    title: "When does my class/semester start and end?",
    content: (
      <p>
        Calbright College doesn't have traditional semester or quarter terms,
        and instead offers rolling enrollment. This means that learners can
        start during most weeks throughout the year. We offer skills-based
        training programs with flexible schedules geared toward working adult
        learners. We do follow an annual academic calendar, so please see our
        website for the current academic catalog and policies that are active
        upon your enrollment. You’ll be able to start your coursework as soon as
        you complete an enrollment agreement, and you’ll receive a certificate
        of completion when you finish your coursework. In most programs, you
        also have an opportunity to take an industry certification exam. Please
        discuss the estimated length of your program with your Success
        Counselor.
      </p>
    ),
    category: "General Topics",
  },
  {
    id: 6,
    title: "How do I get feedback for an assignment?",
    content: (
      <p>
        Instructors will grade and respond to your assignments within 48 hours
        of submission. You’ll receive a notification that your assignment has
        been graded. Please contact your instructor if you’d like additional
        feedback. You can locate your instructor on the Student Portal under{" "}
        <NavLink to="my-team">My Team</NavLink>.{" "}
      </p>
    ),
    category: "Coursework",
  },
  {
    id: 7,
    title: "What’s the next step after I pass an assignment?",
    content: (
      <p>
        Passing an assignment means that you’ve mastered the competency and are
        prepared to move to the next skill. If an assignment isn’t locked, you
        can move on to another assignment while waiting to receive feedback from
        the instructional team. When you’ve passed an assignment, move on to the
        next one.
      </p>
    ),
    category: "Coursework",
  },
  {
    id: 8,
    title: "How can I successfully complete an assignment?",
    content: (
      <p>
        When you’re ready to start an assignment in your program, be sure to
        review the directions and grading policy. The directions and grading
        criteria will help you understand how to complete the assignment and
        demonstrate competency. If you don’t have enough information to complete
        the assignment, you can review the course content again or meet with an
        instructor or peer tutor for help.
      </p>
    ),
    category: "Coursework",
  },
  {
    id: 9,
    title:
      "How do I maintain active participation in my program to stay enrolled?",
    content: (
      <p>
        It’s important to remain academically active in your program, which
        includes participation in program discussion forums, submitting
        assignments and meeting with your instructor.
      </p>
    ),
    category: "Coursework",
  },
  {
    id: 10,
    title: "How long will it take to get through a Calbright program pathway?",
    content: (
      <p>
        Calbright programs are competency-based programs and allow students to
        proceed at their own pace. There’s no fixed timeline for completion; you
        can accelerate or decelerate based on your schedule and needs. The
        average time it takes to complete a program ranges from 6 to 18 months,
        but it depends on each student’s comfort level with the program topics
        and the time they have to dedicate to learning and submitting
        assignments.
      </p>
    ),
    category: "Coursework",
  },
  {
    id: 11,
    title: "How do I request a transcript?",
    content: (
      <p>
        Complete this{" "}
        <a href="https://forms.gle/7iN1qKdLtputuyaZ6" target="_blank">
          transcript request form
        </a>
        .
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 12,
    title:
      "How do I get a copy of my Calbright certificate (certificate of competency)?",
    content: (
      <p>
        A Certificate of Competency is automatically sent after successful
        completion of your program pathway. If you need a replacement copy, you
        can download it at{" "}
        <a
          href="https://www.credential.net/retrieve-credentials"
          target="_blank"
        >
          Accredible
        </a>
        .
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 13,
    title: "How do I get an enrollment verification letter?",
    content: (
      <>
        <p>
          Complete this{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScUaf8vMMeGUxkYnZrf7D5385_X4pMX8Jj_dXx3XCTKDwsFlg/viewform"
            target="_blank"
          >
            enrollment verification request form
          </a>
          .
        </p>
        <p>
          To receive a valid verification letter, you’ll need to attend an
          onboarding session to enroll.
        </p>
      </>
    ),
    category: "Admissions and Records",
  },
  {
    id: 14,
    title: "Is Calbright Accredited?",
    content: (
      <p>
        Yes, Calbright College is accredited by the Distance Education
        Accrediting Commission (DEAC).{" "}
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 15,
    title: "How do I update my personal information?",
    content: (
      <p>
        Please email{" "}
        <a href="mailto:registrar@calbright.org">
          Enrollment Services at Calbright College
        </a>
        .
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 16,
    title: "How do I send my transcripts to an employer?",
    content: (
      <p>
        You can request a transcript by completing our{" "}
        <a href="https://forms.gle/7iN1qKdLtputuyaZ6" target="_blank">
          online transcript request form
        </a>
        .
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 17,
    title: "How do I go set up accommodations as a disabled student?",
    content: (
      <p>
        If you have a documented disability, email Accessibility Services for
        more information at{" "}
        <a href="mailto:accessibilityservices@calbright.org">
          accessibilityservices@calbright.org
        </a>{" "}
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 18,
    title:
      "My approved accommodations have not been provided. Where can I find help?",
    content: (
      <p>
        Your accessibility success counselor can help. Email{" "}
        <a href="mailto:accessibilityservices@calbright.org">
          accessibilityservices@calbright.org
        </a>{" "}
        to request assistance
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 19,
    title: "If I drop my program will I still be enrolled at Calbright?",
    content: (
      <p>
        Calbright students are enrolled in their identified program of study. If
        a student drops their program they will not be actively enrolled at
        Calbright.
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 20,
    title: "How do I correct a mistake on my transcript?",
    content: (
      <p>
        Please email{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a> for
        help with your transcript.
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 21,
    title: "How do I file a complaint?",
    content: (
      <p>
        If you have a question and/or concern regarding equity, discrimination
        and/or harassment, please visit our{" "}
        <a href="https://www.calbright.org/report-a-concern/" target="_blank">
          Report a Concern page
        </a>{" "}
        for more information or to submit a complaint.
      </p>
    ),
    category: "Admissions and Records",
  },
  {
    id: 22,
    title:
      "I received an email from Tech Support saying my student account have been suspended. Why?",
    content: (
      <p>
        Your account may be suspended if we find activity or files stored in
        your student account that violate our policies. Examples of policy
        violations include materials that violate copyright laws, such as music
        files, movies, videos or materials that are not related to Calbright
        College’s official activities. Please check for an email to the personal
        email address you used to submit your application that details the
        reason for the suspension. If you have any additional questions, please
        email <a href="mailto:success@calbright.org">success@calbright.org</a>.
      </p>
    ),
    category: "Technology and Access",
  },
  {
    id: 23,
    title: "What is my username?",
    content: (
      <>
        <p>
          Your username is your Calbright College email address, which is
          firstname.lastname@calbrightcollege.org{" "}
        </p>
        <p>
          {" "}
          A fictional student, Martha Mendez, would have the email address
          martha.mendez@calbrightcollege.org.
        </p>
      </>
    ),
    category: "Technology and Access",
  },
  {
    id: 24,
    title: "How do I reset my student email password?",
    content: (
      <p>
        Please email Enrollment Services at{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a> or call{" "}
        <a href="tel:+18339560225">1-833-956-0225</a>. We’ll send a new password
        to the personal email address you used to register for Calbright
        College.
      </p>
    ),
    category: "Technology and Access",
  },
  {
    id: 25,
    title:
      "My Calbright issued equipment (computer, hotspot, etc.) isn’t working, how can I fix it?",
    content: (
      <p>
        Please email Enrollment Services at{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a> or by
        phone at <a href="tel:+18339560225">1-833-956-0225</a>.
      </p>
    ),
    category: "Technology and Access",
  },
  {
    id: 26,
    title:
      "My personal computer isn’t working, how can I get help to continue my work?",
    content: (
      <p>
        We can help troubleshoot login or issues with access to our student
        resources, however, we don’t provide hardware or software support for
        personal computers. Our device lending library can offer a Chromebook
        and/or internet hotspot so you can complete our programs. You can email
        Enrollment Services at{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a> or call
        <a href="tel:+18339560225">1-833-956-0225</a>.
      </p>
    ),
    category: "Technology and Access",
  },
  {
    id: 27,
    title: "How do I access my assigned courses?",
    content: (
      <p>
        Visit the home page of the Calbright Student Portal where you’ll see the
        courses you’re currently enrolled in, next steps and multiple ways to
        access your coursework.
      </p>
    ),
    category: "Technology and Access",
  },
  {
    id: 28,
    title:
      "I am having trouble accessing my Strut coursework through the student portal.",
    content: (
      <p>
        If you’re receiving error messages when attempting to access your
        coursework, please check your browser settings. Generally, disabling
        privacy extensions or allowing cookies will resolve these issues. If
        you’re still experiencing issues or need some help, email Enrollment
        Services at{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a> or call
        <a href="tel:+18339560225">1-833-956-0225</a>.
      </p>
    ),
    category: "Technology and Access",
  },
  {
    id: 29,
    title: "What is a learning management system (LMS)?",
    content: (
      <p>
        A learning management system is an essential tool for delivering a
        high-quality online education. At Calbright, we use our LMS to
        distribute program content, assess and validate your learning, and
        follow your program progress. The LMS also provides you with the
        opportunity to earn badges, build your professional portfolio and engage
        with your instructor.
      </p>
    ),
    category: "General Topics",
  },
  {
    id: 30,
    title: "What is Calbright’s LMS?",
    content: (
      <p>
        Our current LMS is Strut. However, we’ll be moving all programs to
        Canvas by the end of 2024. All coursework in Canvas will be available
        through the student portal.
      </p>
    ),
    category: "General Topics",
  },
  {
    id: 31,
    title: "What is Canvas?",
    content: (
      <p>
        Canvas is our learning management system. It’s where you’ll find all
        your program materials.{" "}
      </p>
    ),
    category: "Canvas",
  },
  {
    id: 32,
    title: "When will my course move to Canvas?",
    content: (
      <p>
        You’ll receive an email and find information on the student portal well
        before your program is moved to Canvas. All programs will be moved to
        Canvas by December 2024.{" "}
      </p>
    ),
    category: "Canvas",
  },
  {
    id: 33,
    title: "When will I no longer have access to Strut?",
    content: <p>Access to Strut will close by December 2024.</p>,
    category: "Canvas",
  },
  {
    id: 34,
    title: "How can I access Canvas?",
    content: (
      <p>
        You’ll access Canvas through a link on the student portal, the same way
        you currently access Strut.{" "}
      </p>
    ),
    category: "Canvas",
  },
  {
    id: 35,
    title: "How can I access coursework on my mobile device?",
    content: (
      <p>
        We recommend you download the Canvas Student app for your mobile device.
        Once downloaded, use your Calbright email address and password (single
        sign-on) to log in. We encourage you to turn on the notifications to
        ensure you receive important reminders and messages from your
        instructor. Using the{" "}
        <a
          href="https://community.canvaslms.com/t5/Canvas-Mobile-Forum/bd-p/mobile_forum"
          target="_blank"
          aria-describedby="canvas-mobile-hint"
        >
          Canvas Mobile Forum
        </a>
        , you can ask questions, access{" "}
        <a
          href="https://community.canvaslms.com/t5/Canvas-Mobile/ct-p/canvas_mobile?tab=recent"
          target="_blank"
          aria-describedby="canvas-mobile-hint"
        >
          Canvas Mobile user guides{" "}
        </a>{" "}
        and find answers to most common questions and issues.{" "}
        <span id="canvas-mobile-hint" class="sr-only">
          opens in a new window
        </span>
      </p>
    ),
    category: "Canvas",
  },
  {
    id: 36,
    title: "How can I get additional help with Canvas?",
    content: (
      <p>
        Canvas provides Calbright students with 24/7 access to support via phone
        at <a href="tel:+18556337505">855 633-7505</a> and email at{" "}
        <a href="mailto:support@instructure.com">support@instructure.com</a>.
      </p>
    ),
    category: "Canvas",
  },
  {
    id: 36,
    title:
      "I’m having trouble accessing my coursework through the student portal.",
    content: (
      <p>
        Please check your browser if you’re getting error messages when you try
        accessing your coursework. Generally, disabling privacy extensions or
        allowing cookies will resolve these issues. If you’re still experiencing
        issues, please contact Enrollment Services by email at{" "}
        <a href="mailto:success@calbright.org">success@calbright.org</a>.
      </p>
    ),
    category: "Canvas",
  },
];

const categoryOrder = [
  "General Topics",
  "Technology and Access",
  "Coursework",
  "Admissions and Records",
  "Canvas",
].map((category, index) => ({ id: index + 1, name: category }));

const unsortedCategories = [...new Set(Faqs.map((faq) => faq.category))];

export const Categories = unsortedCategories.sort((a, b) => {
  return categoryOrder.indexOf(a) - categoryOrder.indexOf(b);
});

export const FAQIcons = {
  "General Topics": GeneralIcon,
  Coursework: CourseworkIcon,
  "Admissions and Records": AdmissionsIcon,
  "Technology and Access": TechIcon,
  Canvas: CanvasIcon,
};

export const QuickLinks = [1, 3, 26, 27, 6, 7, 10, 11];

// Filter FAQs based on id as represented QuickLinks array
export const QuickFAQs = Faqs.filter((faq) => QuickLinks.includes(faq.id));
