import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import { preReqStepAction } from "../../../redux/actions/preReqStepAction";
import { verifyPrereqCertificate } from "../../../api/prereqApi";

import { Textarea } from "@storybook/stories/Textarea/Textarea";
import { preReqMoreStep2 } from "./PreReqMoreStep2";

const PreReqMoreStep1 = () => {
  const dispatch = useDispatch();
  const profExp = localStorage.getItem('professionalExperience') || '';
  const [error, setError] = useState(null);
  const [textareaVal, setTextareaVal] = useState(profExp);
  const [loading, setLoading] = useState(false);

  const submitExperience = () => {
    setError(null);

    try {
      dispatch(preReqStepAction(preReqMoreStep2));
    } catch (e) {
      if (e.name === 'QuotaExceededError') {
        setError('LocalStorage limit exceeded.');
      } else {
        setError('An error occurred: ', e);
      }
    }
  }

  useEffect(() => {
    localStorage.setItem('professionalExperience', textareaVal);
  }, [textareaVal]);

  return (
    <>
      <Textarea
        title="Describe any past professional experience where you provided technical support in the workplace. For example, if you built, maintained or supported any computer hardware or software, please mention those examples, or describe any technology projects you have done professionally or independently."
        placeholder="Type any of your past experience here"
        submitValue={ () => submitExperience() }
        getValue={ (value) => setTextareaVal(value) }
        btnLoading={loading}
        maxLength={5000}
        value={profExp}
        error={error}
      />
    </>
  );
}

export const preReqMoreStep1 = {
  icon: null,
  title: null,
  openDialog: true,
  showCloseIcon: false,
  confirmText: null,
  size: 'extra-large',
  showCloseIcon: true,
  extraClass: 'modal-prereq text-left',
  children: <PreReqMoreStep1 />,
};