// My App logos
import {
  GoogleCalendarLogo,
  LinkedInLogo,
  SlackLogo,
  GmailLogo,
  GoogleDriveLogo,
  ProgramAccessLogo
} from "../../assets";

import { EPP_MSG, SPP_MSG } from "./data";

/**
 * List of app icons used in the student dashboard.
 */
export const appIcons = [
  {
    id: 1,
    key: "programAccess",
    title: "Program Access",
    icon: ProgramAccessLogo,
    handleClick: (e) => {},
    disabledMsg: EPP_MSG,
  },
  {
    id: 2,
    key: "linkedIn",
    title: "LinkedIn Learning",
    icon: LinkedInLogo,
    link: "",
    disabledMsg: SPP_MSG,
  },
  {
    id: 3,
    key: "slack",
    title: "Slack",
    icon: SlackLogo,
    link: "https://calbright-students.slack.com",
    disabledMsg: EPP_MSG,
  },
  {
    id: 4,
    key: "gmail",
    title: "Gmail",
    icon: GmailLogo,
    link: "https://mail.google.com",
    disabledMsg: EPP_MSG,
  },
  {
    id: 5,
    key: "googleDrive",
    title: "Drive",
    icon: GoogleDriveLogo,
    link: "https://drive.google.com",
    disabledMsg: EPP_MSG,
  },
  {
    id: 6,
    key: "googleCalendar",
    title: "Calendar",
    icon: GoogleCalendarLogo,
    link: "https://calendar.google.com",
    disabledMsg: EPP_MSG,
  },
];
