import { useEffect, useState } from "react";
import { formatDate } from "@storybook/utils/helpers.js";
// import { ARServicesData } from "../utils/helpers/ar-services";
import { fetchARServices } from "../redux/actions/arservicesActions";
import { getDate } from "../utils/isWithinDateRange";

import { useDispatch, useSelector } from "react-redux";

/**
 * Hook retrieves ARServices form submission dates and updates the ARServicesData array accordingly.
 * @param {array} ARServicesData array of objects used to display arservices page data.
 * @returns updatedARData an updated array of objects to display arservices page data.
 */
export const useARFormSubmmissions = (ARServicesData) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const arservices = useSelector((state) => state.arservices);

  const [updatedARData, setUpdatedARData] = useState(ARServicesData);

  useEffect(() => {
    if (!arservices) {
      dispatch(fetchARServices(token));
      return;
    }

    const today = getDate();
    const dropDeadlineDate = getDate(new Date(user?.dropDeadline));
    const isBeforeDropDeadline = today <= dropDeadlineDate;
    const { requestTranscriptDateSubmitted, verificationFormDateSubmitted } =
      arservices;

    let title = "Withdrawing from Calbright";
    let description =
      "To withdraw from the program, please submit the Withdraw Form below.";

    if (isBeforeDropDeadline) {
      title = "Drop from Calbright";
      description =
        "To drop from your program before your drop deadline, please submit the Drop/Withdraw Form";
    }

    const updatedARServicesData = ARServicesData.map((item, index) => {
      if (index === 0 && requestTranscriptDateSubmitted) {
        return {
          ...item,
          cornerMsg: `Form submitted on ${formatDate(
            requestTranscriptDateSubmitted
          )}`,
        };
      }

      if (index === 1 && verificationFormDateSubmitted) {
        return {
          ...item,
          cornerMsg: `Form submitted on ${formatDate(
            verificationFormDateSubmitted
          )}`,
        };
      }

      if (index === 2) {
        return {
          ...item,
          title,
          description,
        };
      }

      return item;
    });

    setUpdatedARData(updatedARServicesData);
  }, [arservices, token, fetchARServices]);

  return updatedARData;
};

export default useARFormSubmmissions;
