import React from "react";

import {
  RequestTranscript,
  AccessingCertificatesCompetency,
  WithdrawingFromCalbright,
  RequestingEnrollmentVerificationLetter,
} from "../../assets";

import { RequestTranscriptForm } from "../../components/ARTabs/RequestTranscriptForm/RequestTranscriptForm";
import { EnrollVerificationForm } from "../../components/EnrollVerificationForm/EnrollVerificationForm";
import { CertificatesCompetency } from "../../components/ARTabs/CertificatesCompetency/CertificatesCompetency";
import { Withdrawing } from "../../components/ARTabs/Withdrawing/Withdrawing";

export const ARServicesData = [
  {
    id: 1,
    title: "Request Transcript",
    description:
      "To receive your Calbright transcript, please complete the Transcript Request Form",
    image: RequestTranscript,
    focusMode: true,
    content: (
      <div className="storybook-learning-content">
        <RequestTranscriptForm />
      </div>
    ),
  },
  {
    id: 2,
    title: "Request an Enrollment Verification Letter",
    description:
      "To receive your letter, please complete the Enrollment Verification Form",
    image: RequestingEnrollmentVerificationLetter,
    focusMode: true,
    content: (
      <div className="storybook-learning-content">
        <EnrollVerificationForm />
      </div>
    ),
  },
  {
    id: 3,
    title: "",
    description: "",
    image: WithdrawingFromCalbright,
    focusMode: true,
    content: (
      <div className="storybook-learning-content">
        <Withdrawing />
      </div>
    ),
  },
  {
    id: 4,
    title: "Access Certificates of Completion",
    description:
      "To access your earned badges, log in to Calbright Digital Credentialing Site",
    image: AccessingCertificatesCompetency,
    content: (
      <div className="storybook-learning-content">
        <CertificatesCompetency />
      </div>
    ),
  },
];
