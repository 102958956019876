import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./listitem.css";

import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

/**
 * A ListItem is a single item that forms part of the content of a list.
 * This can be a menu item, drop down navigation, etc.
 */
export const ListItem = ({ parentId, item, active }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const subMenuRef = useRef(null);

  const toggleSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleClickOutside = (event) => {
    if (
      subMenuRef.current &&
      !subMenuRef.current.contains(event.target) &&
      event.target.tagName !== "SPAN"
    ) {
      setOpenSubMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li
      className={`storybook-list-item${
        item.dropdownItems ? " storybook-list-item--with-dropdown" : ""
      }`}
      id={`${parentId}-${item.id}`}
      data-testid={`${parentId}-${item.id}`}
    >
      {item.url ? (
        <NavLink to={item.url} exact={true}>
          {item.label}
        </NavLink>
      ) : (
        <>
          <span
            className={item.id === active ? "active" : ""}
            onClick={() => toggleSubMenu()}
            aria-haspopup="true"
          >
            {item.label}

            {item.dropdownItems &&
              item.dropdownItems.length > 0 &&
              (openSubMenu ? (
                <KeyboardArrowUpOutlinedIcon className="storybook-list-item-icon" />
              ) : (
                <KeyboardArrowDownOutlinedIcon className="storybook-list-item-icon" />
              ))}
          </span>
          {item.dropdownItems && openSubMenu && (
            <>
              <ul
                className={`storybook-list--dropdown ${
                  openSubMenu ? "open" : ""
                }`}
                data-testid="list-dropdown"
                ref={subMenuRef}
              >
                {item.dropdownItems.map((dditem, index) => (
                  <ListItem key={index} item={dditem} active={active} />
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </li>
  );
};

ListItem.propTypes = {
  /**
   * Label displayed by the list item.
   */
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    dropdownItems: PropTypes.array,
  }),
  /**
   * Determines if the item is active or 'selected'.
   */
  active: PropTypes.string,
};

ListItem.defaultProps = {
  item: {
    id: "home",
    label: "Home",
    dropdownItems: [
      {
        id: "services",
        label: "Services",
        url: "/",
      },
    ],
  },
  active: "",
};
