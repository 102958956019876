import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@storybook/stories/Skeleton/Skeleton";
import { Button } from "@storybook/stories/Button/Button";
import { studentProgramLookup } from "../../../utils/helpers/data";

import { fetchCompletedPrograms } from "../../../redux/actions/arservicesActions";

import { IconError } from "./../../../assets";
import "./../ar-styles.css";

const ACCREDIBLE_URL = "https://v2.accounts.accredible.com/retrieve-credentials?app=recipient-portal&origin=https:%2F%2Fwww.credential.net";
const CANVAS_URL = "https://calbright.badgr.com/auth/login";

export const CertificatesCompetency = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const completedPrograms = useSelector((state) => state.completedPrograms);

  const handleRetrieve = (program, completedAt) => {
    const redirectUrl = redirectBasedOnProgram(program, completedAt);
    window.open(redirectUrl, '_blank');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (completedPrograms) return;
        setLoading(true);
        await dispatch(fetchCompletedPrograms(token));
      } catch (error) {
        setError("Something went wrong! Please try again or contact us.");
        console.error("Error fetching completed programs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token, completedPrograms]);

  return (
    <div className="ar-content">
      <h2>Calbright College’s Digital Credentialing Site</h2>
      <p>
        Digital credentials help translate learning outcomes into validated
        skills that both students and employers can understand. Badges give
        learners agency over their learning and career path by clearly
        communicating the employer-valued skills they’ve either already
        demonstrated or still need to earn.
      </p>

      {loading && (
        <>
          {Array(4)
            .fill(null)
            .map((_, i) => (
              <Skeleton key={i} classes="text height-2x" />
            ))}
        </>
      )}

     {!loading && completedPrograms?.length === 0 && (
        <div className="ar-content--error">
          <img src={IconError} alt="error" />
          <b>
            Currently you have no Certificates of Competency. Certificates of
            Competency will show up once you complete a program with us.
          </b>
        </div>
      )}

      {!loading &&
        completedPrograms?.length > 0 &&
        completedPrograms.map((program, index) => (
          <div key={index} className="ar-content--certificate-row">
            <span>{program.title} Program Certificates of Competency</span>
            <Button
              label="Retrieve"
              onClick={() =>
                handleRetrieve(program.title, program.completedAt)
              }
            />
          </div>
      ))}

      {error && (
        <div className="ar-content--error">
          <p className="error">{error}</p>
       </div>
      )}
    </div>
  );
}

const redirectBasedOnProgram = (program, completedAt) => {
  const completionDate = new Date(completedAt);

  switch (program) {
    case studentProgramLookup.CYBERSECURITY:
      return completionDate < new Date("2024-10-01") ?
        ACCREDIBLE_URL : CANVAS_URL;

    case studentProgramLookup.DATA_ANALYSIS:
      return completionDate < new Date("2024-09-01") ?
        ACCREDIBLE_URL : CANVAS_URL;

    case studentProgramLookup.IT_SUPPORT:
      return completionDate < new Date("2024-11-06") ?
        ACCREDIBLE_URL : CANVAS_URL;

    case studentProgramLookup.PROJECT_MANAGEMENT:
      return completionDate < new Date("2024-11-12") ?
        ACCREDIBLE_URL : CANVAS_URL;

    default:
      return ACCREDIBLE_URL;
  }
};