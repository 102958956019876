import axiosInstance from "../api/axios";
import { handleResponse, handleError } from "./apiUtils";

/**
 * Sends a post request to /update-program endpoint to update student intended program.
 * @param {string} token
 * @param { string } program
 * @returns
 */
export function updateProgram(token, program) {
  return axiosInstance
    .post(
      "/update_program",
      { program: program },
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}