import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';

import './search.css';

/**
 * Search bar used for the site and dashboard.
 * You can pass an <code>initialQuery</code> prop to set the initial search query.
 * The <code>onSearch</code> prop allows you to specify a function to be called when a search is triggered.
 */
export const SearchBar = ({ initialQuery = '', onSearch, onChange, placeholder="", suggestions = [], handleOnClick, onFocus, onBlur }) => {
  const [query, setQuery] = useState(initialQuery);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const filteredSuggestions = useMemo(() => {
    if (!query) {
      return suggestions;
    }
    return suggestions.filter(s => s.toLowerCase().includes(query.toLowerCase()));
  }, [query, suggestions]);

  const changeQuery = (newQuery) => {
    setQuery(newQuery);

    if (onChange) {
      onChange(newQuery);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (onSearch){
        onSearch(query);
      }
    }
  };

  const handleOnChange = (e) => {
    const newQuery = e.target.value;
    changeQuery(newQuery);
  };

  const handleOnFocus = (e) => {
    setIsInputFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e) => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 100);
    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  return (
    <div className="storybook-search-container" data-testid="search-container" role="search">
      <input
        className="storybook-search-input"
        type="text"
        placeholder={placeholder}
        aria-label="Search"
        value={query}
        onChange={ handleOnChange }
        onKeyDown={ handleOnKeyDown }
        onFocus={ handleOnFocus }
        onBlur={ handleOnBlur }
        onClick= { handleOnClick }
        aria-describedby="search-instructions"
        title="Press Enter to perform search."
        data-testid="search-input"
      />
      <SearchIcon
        className="storybook-search-icon" 
        aria-label="Search"
        onClick={() => {
          if (onSearch) {
            onSearch(query);
          }
        }}
      />
      <p id="search-instructions" className="sr-only">Press Enter to perform the search.</p>
      {
        isInputFocused && filteredSuggestions.length > 0 &&
        <div className="storybook-search-recent-container" data-testid="recent-container">
          <div className="storybook-search-recent-header">Recent Searches</div>
          <div>
            {
              filteredSuggestions.map((suggestion, i) => (
                <div key={i} className="storybook-search-recent-item" onClick={() => changeQuery(suggestion)}>{suggestion}</div>
              ))
            }
          </div>
        </div>
      }
      
    </div>
  );
}

SearchBar.propTypes = {
  /**
   * Search term to be passed to SearchBar component.
   */
  initialQuery: PropTypes.string,
  /**
   * Specify a function to be called when a search is triggered.
   */
  onSearch: PropTypes.func,
  /**
   * Speficy a function to be called when search text changes. (future result dropdown loader).
   */
  onChange: PropTypes.func,
  /**
   * Array of search terms.
   */
  suggestions: PropTypes.array,
};

SearchBar.defaultProps = {
  initialQuery: '',
  suggestions: [],
}