import * as types from "../actions/actionTypes";
import initialState from "./initialState";

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

export default function loadingReducer(state = initialState.loading, action) {
  switch (action.type) {
    case types.SHOW_LOADING:
      return action.loading;
    default:
      return state;
  }
}
