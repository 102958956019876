import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import vcgAuth from "./../../api/vcgAuth";
import { Header } from "@storybook/stories/Header/Header";
import { Wellness } from "@storybook/stories/Wellness/Wellness";
import { Accordion } from "@storybook/stories/Accordion/Accordion";
import { AccordionItems } from "../../utils/helpers/wellness-questions";
import {
  wellnessItems,
  servicesContent,
  notEnrCardContent,
} from "../../utils/helpers/wellness";
import { learnerStatusLookup } from "../../utils/helpers/data";

import "./services.css";

export const Services = ({ user }) => {
  if (Object.keys(user).length === 0) {
    return <Redirect to={process.env.AUTH_REDIRECT_URL} />;
  }

  const enrolled =
    user.learnerStatus === learnerStatusLookup["STARTED_PROGRAM_PATHWAY"];
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleAuth = () => {
    setIsLoading(true);
    window.open(process.env.AUTH_TIMELY_CARE, '_blank');
    setIsLoading(false);
    setMessage(
      'You have been successfully redirected to TimelyCare authentication.'
    );
  };

  const closeMessage = () => {
    setMessage(null);
  };

  return (
    <article className="services">
      <Header
        user={user}
        onLogin={() =>
          setUser({
            firstName: "Mariah",
            lastName: "Villalobos",
            name: "Mariah Villalobos",
            cccId: "E397458",
            hasPhoto: true,
            photoUrl: AvatarFemale,
            enrollmentDate: "",
            termEndDate: "",
            learningProgress: "schedule-onboarding",
          })
        }
        onLogout={() => setUser(undefined)}
        activeLink="services"
        avatarsize="medium"
      />
      <main>
        <div className="container">
          <Wellness
            items={wellnessItems}
            enrolled={enrolled}
            title="Wellness Services"
            subtitle={`Life can be tough; getting help doesn’t have to be. As a Calbright student, you have access to free virtual wellness services provided by TimelyCare. Here are some of the areas where TimelyCare can help:`}
            cardTitle="TimelyCare"
            cardContent={servicesContent}
            notEnrCardContent={notEnrCardContent}
            btnLabel="Explore TimelyCare"
            btnSubmit={handleAuth}
            isLoading={isLoading}
            closeMessage={closeMessage}
            message={message}
          />
          <Accordion
            items={AccordionItems}
            title="Frequently Asked Questions"
          />
        </div>
      </main>
    </article>
  );
};

Services.propTypes = {
  user: PropTypes.shape({}),
};

export default Services;
